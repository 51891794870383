import styled from "styled-components";

const Financial = () => {
  return (
    <Wrapper>
      <div className="Integrated-background">200</div>
      <div className="Integrated-content">
        <h1>Integrated 200+ Tokens</h1>
        <span>
          Trade effortlessly across more than 200+ tokens, offering maximum flexibility and opportunities for traders.
          Enjoy seamless access to a vast crypto marketplace.
        </span>
        <button
          style={{
            fontWeight: "500",
          }}
          onClick={() => window.open("https://app.yummy.fi/#/trade", "_blank")}
          className="default-btn"
        >
          Explore Markets
        </button>
      </div>
      <div className="line-bottom" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1368px;
  padding: 0 24px;
  margin: 0 auto;
  position: relative;
  height: 400px;

  .line-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    z-index: 30;

    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(28, 216, 229, 1) 50%, rgba(255, 255, 255, 0) 100%);
  }

  .Integrated-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #293d40;
    font-size: 400px;
    font-weight: 700;
    line-height: 100%; /* 400px */

    background: linear-gradient(180deg, #052d33 22.38%, #010b0d 78.57%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(to top, #000 0%, transparent 100%);
    }
  }

  .Integrated-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    h1 {
      color: #fff;
      text-align: center;
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }

    span {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  @media screen and (max-width: 1023px) {
    .Integrated-background {
      font-size: 240px;
    }

    .Integrated-content {
      width: 100%;
      padding: 0 24px;

      h1 {
        width: 300px;
      }

      span {
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 48px 16px;
    height: 496px;

    .Integrated-background {
      font-size: 128px;
    }

    .Integrated-content {
      padding: 0 16px;

      h1 {
      }

      span {
      }
    }
  }
`;

export default Financial;
