import { Menu } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import "./AssetDropdown.css";
import "./AssetDropdownTheme.scss";
import coingeckoIcon from "img/ic_coingecko_16.svg";
import arbitrumIcon from "img/ic_arbitrum_16.svg";
import avalancheIcon from "img/ic_avalanche_16.svg";
import fantomIcon from "img/ic_ftm_16.svg";
import beraIcon from "img/ic_bera_40.svg";

import opIcon from "img/ic_op_16.svg";
import metamaskIcon from "img/ic_metamask_16.svg";
import { useWeb3React } from "@web3-react/core";

import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ICONLINKS, PLATFORM_TOKENS } from "config/tokens";
import { addTokenToMetamask } from "lib/wallets";
import { useChainId } from "lib/chains";
import { ARBITRUM, FANTOM } from "config/chains";
import { Token } from "domain/tokens";
import triangleIcon from "img/triangle.svg";
import triangleLightIcon from "img/triangleLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";
type Props = {
  assetSymbol: string;
  assetInfo?: Token;
  isV2Only?: boolean;
};

function AssetDropdown({ assetSymbol, assetInfo, isV2Only }: Props) {
  const { isLightTheme, lightThemeClassName } = useThemeContext() as any;
  const { active } = useWeb3React();
  const { chainId } = useChainId();
  let { coingecko, arbitrum, fantom, op, reserves, berachain_bartio } =
    ICONLINKS[isV2Only ? FANTOM : chainId][assetSymbol] || {};
  const unavailableTokenSymbols =
    {
      42161: ["ETH"],
      43114: ["AVAX"],
      250: ["FTM"],
      // 10: ["OP"],
    }[chainId] || [];

  return (
    <Menu>
      <Menu.Button as="div" className="dropdown-arrow center-both">
        {/* <FiChevronDown size={20} /> */}
        <img alt="" src={isLightTheme ? triangleLightIcon : triangleIcon} />
      </Menu.Button>
      <Menu.Items as="div" className={`asset-menu-items ${lightThemeClassName}`}>
        <Menu.Item>
          <>
            {reserves && assetSymbol === "YLP" && (
              <ExternalLink href={reserves} className="asset-item">
                <img src={chainId === ARBITRUM ? arbitrumIcon : avalancheIcon} alt="Proof of Reserves" />
                <p>
                  <span>Proof of Reserves</span>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        <Menu.Item>
          <>
            {coingecko && (
              <ExternalLink href={coingecko} className="asset-item">
                <img src={coingeckoIcon} alt="Open in Coingecko" />
                <p>
                  <span>Open in Coingecko</span>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        <Menu.Item>
          <>
            {arbitrum && (
              <ExternalLink href={arbitrum} className="asset-item">
                <img src={arbitrumIcon} alt="Open in explorer" />
                <p>
                  <span>Open in Explorer</span>
                </p>
              </ExternalLink>
            )}
            {fantom && (
              <ExternalLink href={fantom} className="asset-item">
                <img src={fantomIcon} alt="Open in explorer" />
                <p>
                  <span>Open in Explorer</span>
                </p>
              </ExternalLink>
            )}
            {berachain_bartio && (
              <ExternalLink href={berachain_bartio} className="asset-item">
                <img src={beraIcon} alt="Open in explorer" />
                <p>
                  <span>Open in Explorer</span>
                </p>
              </ExternalLink>
            )}
            {op && (
              <ExternalLink href={op} className="asset-item">
                <img src={opIcon} alt="Open in explorer" />
                <p>
                  <span>Open in Explorer</span>
                </p>
              </ExternalLink>
            )}
          </>
        </Menu.Item>
        <Menu.Item>
          <>
            {active && unavailableTokenSymbols.indexOf(assetSymbol) < 0 && (
              <div
                onClick={() => {
                  let token = assetInfo
                    ? { ...assetInfo, image: assetInfo.imageUrl }
                    : PLATFORM_TOKENS[chainId][assetSymbol];
                  addTokenToMetamask(token);
                }}
                className="asset-item"
              >
                <img src={metamaskIcon} alt={`Add to Metamask`} />
                <p>
                  <span>Add to Metamask</span>
                </p>
              </div>
            )}
          </>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

export default AssetDropdown;
