import cx from "classnames";
import { LEVERAGE_MARKS, LEVERAGE_MARKS_ORDERLY } from "lib/legacy";

import Modal from "../Modal/Modal";
import "../ExchangeV2/PositionSeller.css";
import { preventSpecialCharacters } from "utils/helpers";
import Slider, { SliderTooltip } from "rc-slider";
import { useState } from "react";
import { helperToast } from "lib/helperToast";
const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
export default function OrderlySelectLeverage(props) {
  const { isVisible, setIsVisible, updateMaxLeverage, currentMaxLeverage, chainId } = props;

  const [leverageOption, setLeverageOption] = useState(currentMaxLeverage);
  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1 || val > 50) {
      setLeverageOption(1);
      return;
    }
    setLeverageOption(target.value);
  };
  const onChangeLeverage = (value) => {
    if (value && value.includes(".")) {
      var arr = value.split(".");
      if (arr[1].length <= 0) setLeverageOption(value);
    } else {
      setLeverageOption(value);
    }
  };
  const handleUpdateMaxLeverage = async () => {
    try {
      await updateMaxLeverage({ leverage: leverageOption })
      helperToast.success(
        <div>
          Updated max leverage
          <br />
        </div>
      );
      setIsVisible(false)
    }
    catch {
      helperToast.error(
        <div>
          Update max leverage failed
          <br />
        </div>
      );
    }
    finally {

    }
  }
  return (
    <div className="PositionEditor">
      <Modal
        className={`PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1`}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={"Max Account Leverage"}
        allowContentTouchMove
        maxWidth="480px"
      >
        <div className="content-container">
          <div className="Exchange-leverage-slider-container" style={{ marginTop: "8px" }}>
            <div className="percentahe-value">
              <input
                className="percentahe-input"
                value={leverageOption}
                // disabled
                type="number"
                onChange={({ target }) => onChangeLeverage(target.value)}
                onBlur={handleBlurLeverage}
                onKeyDown={preventSpecialCharacters}
              />
              <span>x</span>
            </div>
            <div
              className={cx("Exchange-leverage-slider", "App-slider", {
                normalSlider: true,
              })}
            >
              <Slider
                min={1.0}
                max={50}
                step={1}
                marks={LEVERAGE_MARKS_ORDERLY()}
                handle={leverageSliderHandle}
                onChange={(value) => setLeverageOption(value)}
                defaultValue={leverageOption}
                value={leverageOption}
              />
            </div>
          </div>
        </div>
        <div className="Exchange-swap-button-container update-levrage-button-container">
          <button className="border-btn" onClick={() => setIsVisible(false)}>Cancel</button>
          <button className="default-btn Exchange-swap-button" onClick={handleUpdateMaxLeverage}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
}
