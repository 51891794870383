import { useAccount, useChains, useCollateral, useDeposit } from "@orderly.network/hooks";
import { AccountStatusEnum } from "@orderly.network/types";
import { useWeb3React } from "@web3-react/core";
import { Popover } from "antd";
import { usePortfolio1 } from "hooks/usePortfolio1";
import { formatAmount, formatNumber, parseValue } from "lib/numbers";
import { useEffect, useMemo, useReducer, useState } from "react";
import styled from "styled-components";

const BalanceManager = ({ handlDeposit, handleWithdraw }) => {
  const [index, setIndex] = useState(0);
  const [updateVal, forceUpdate] = useReducer((x) => x + 1, 0);

  const [chains] = useChains("mainnet");
  const {
    account: accountInfo,
    state: { status },
  } = useAccount();
  const token = useMemo(
    () => chains.find((item) => item.network_infos.chain_id === accountInfo.chainId)?.token_infos[0],
    [chains, accountInfo.chainId]
  );

  const { freeCollateral, totalValue } = useCollateral();
  const balances = useMemo(() => {
    return [
      {
        label: "Total Value",
        value: totalValue ? formatAmount(parseValue(totalValue || 0, 18), 18, 2, true) + " USDC" : "0.00 USDC",
      },
      {
        label: "Free Collateral",
        value: freeCollateral ? formatAmount(parseValue(freeCollateral || 0, 18), 18, 2, true) + " USDC" : "0.00 USDC",
      },
    ];
  }, [freeCollateral, totalValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % balances.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // const onOpen = () => {
  //   open();
  //   forceUpdate();
  // };
  return (
    <Popover
      key={updateVal}
      showArrow={false}
      content={
        <PopoverContent>
          <div className="PopoverContent_balances">
            {balances.map((balance) => (
              <div className="PopoverContent_item" key={balance.label}>
                {balance.label}
                <div className="PopoverContent_value">{balance.value}</div>
              </div>
            ))}
          </div>
          <div className="actions-btn">
            <button className="default-btn" onClick={handlDeposit}>
              Deposit
            </button>
            <button className="border-btn" onClick={handleWithdraw}>
              Withdraw
            </button>
          </div>
        </PopoverContent>
      }
    >
      <BalanceManagerFrame>
        <div className="BalanceManager_infos" style={{ transform: `translateY(-${(index * 100) / balances.length}%)` }}>
          {balances.map((balance) => {
            return (
              <div className="BalanceManager_info" key={balance.label}>
                <div className="BalanceManager_label">{balance.label}</div>
                <div className="BalanceManager_value">{balance.value}</div>
              </div>
            );
          })}
        </div>

        <button onClick={handlDeposit}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
              fill="#fff"
            />
          </svg>
        </button>
      </BalanceManagerFrame>
    </Popover>
  );
};

const BalanceManagerFrame = styled.div`
  display: flex;
  width: 160px;
  height: 40px;
  padding: 0px 8px 0px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  @media screen and (max-width: 420px) {
    width: 140px;
  }
  border-radius: 4px;
  background: var(--Nature-1, #18191a);
  position: relative;
  border: 1px solid #2b3139;
  overflow: hidden;

  .BalanceManager_infos {
    position: absolute;
    top: 0;
    left: 16px;

    transition: 200ms;
  }

  .BalanceManager_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    height: 40px;
  }

  .BalanceManager_label {
    color: var(--Text-Text_Secondary, #919499);
    font-size: 10px;
    font-weight: 700;
    line-height: 140%; /* 14px */
  }

  .BalanceManager_value {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 140%; /* 16.8px */
  }

  button {
    margin-left: auto;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border: none;
    outline: none;

    border-radius: 100px;
    background: var(--Button-Background, #1eb2fc);

    &:hover {
      opacity: 0.8;
    }
  }
`;

const PopoverContent = styled.div`
  width: 292px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  .actions-btn {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    button {
      flex: 1;
    }
  }
  .PopoverContent_balances {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    align-self: stretch;
  }

  .PopoverContent_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    color: var(--Text-Text_Secondary, #919499);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }

  .PopoverContent_value {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
`;

export default BalanceManager;
