import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatNumber } from "lib/numbers";
import moment from "moment";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axios from "axios";

import { SECONDS_PER_DAY } from "lib/legacy";
import { useThemeContext } from "contexts/ThemeProvider";
import { getClientTimezoneOffset } from "lib/dates";

import no_data_icon from "img/portfolio/noChart.svg";

const DURATIONS = [
  { label: "24h", value: moment().unix() - SECONDS_PER_DAY },
  { label: "7d", value: moment().unix() - SECONDS_PER_DAY * 7 },
  { label: "30d", value: moment().unix() - SECONDS_PER_DAY * 30 },
  { label: "All", value: 1700091200 },
];

const PortfolioChart = ({ account, chainId }) => {
  const [chartData, setChartData] = useState([]);
  const [period, setPeriod] = useState(DURATIONS[2].value);
  const { isLightTheme, lightThemeClassName } = useThemeContext();

  useEffect(() => {
    if (account) {
      const fetcher = async () => {
        const periodType = period === DURATIONS[0].value ? "hourly" : "daily";
        const { data: response } = await axios.get(`https://api.yummy.fi/stats/bartio/portfolio/${account}/chart`, {
          params: {
            period: "daily",
            from: period,
            to: Math.floor(Date.now() / 1000),
          },
        });

        const mappedData = response.data.map((d) => ({
          ...d,
          portfolioUpdated: d.portfolioUpdated ? (Number(d.portfolioUpdated) <= 0.001 ? 0.0 : d.portfolioUpdated) : 0.0,
          xAxis: moment(d.timestamp * 1000).format("HH:mm"),
          date: moment(d.timestamp * 1000).format(`MMM DD, YYYY HH:mm [GMT+]${getClientTimezoneOffset()}`),
        }));

        setChartData(mappedData);
      };

      fetcher();
      const timer = setInterval(fetcher, 30000);

      return () => clearInterval(timer);
    }
  }, [account, chainId, period]);

  const formatAxis = (tickItem) => {
    return `$${formatNumber(tickItem, 1)}`;
  };

  const CustomTooltip = ({ active, payload, formatter }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="stats-label"
          style={{
            backgroundColor: isLightTheme ? "#fff" : "#2E3033",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: isLightTheme ? "4px 0px 24px 0px rgba(0, 0, 0, 0.2)" : "none",
          }}
        >
          <div style={{ color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99" }}>{payload[0].payload.date}</div>
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div
              className="stats-row-key"
              style={{
                color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99",
              }}
            >
              Portfolio Value
            </div>
            <div className="stats-row-value">${formatNumber(payload[0].payload.portfolioUpdated, 2)}</div>
          </div>
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div
              className="stats-row-key"
              style={{
                color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99",
              }}
            >
              Your Collateral
            </div>
            <div className="stats-row-value">${formatNumber(payload[0].payload.totalCollateral, 2)}</div>
          </div>
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div
              className="stats-row-key"
              style={{
                color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99",
              }}
            >
              Free Collateral
            </div>
            <div className="stats-row-value">${formatNumber(payload[0].payload.freeCollateral, 2)}</div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Wrapper className={lightThemeClassName}>
      {chartData.length > 0 && (
        <>
          {/* <div className="chart-duration">
            {DURATIONS.map((duration) => {
              return (
                <div
                  key={duration.value}
                  className={`duration-item ${duration.value === period ? "active" : ""}`}
                  onClick={() => setPeriod(duration.value)}
                >
                  {duration.label}
                </div>
              );
            })}
          </div> */}
          <div className="chart-content chart-portfofio">
            <ResponsiveContainer width="100%" height={380}>
              <AreaChart data={chartData} stackOffset="sign">
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#7BF57B" stopOpacity={0.1} />
                    <stop offset="95%" stopColor="#7BF57B" stopOpacity={0} />
                  </linearGradient>
                </defs>
                {/* <CartesianGrid vertical={false} stroke={isLightTheme ? "#0000001A" : "#282D41"} /> */}
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                  dataKey="xAxis"
                  height={12}
                  minTickGap={15}
                  fontSize={12}
                />
                <YAxis
                  // yAxisId="right"
                  // axisLine={false}
                  tickLine={false}
                  stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                  width={40}
                  tickFormatter={formatAxis}
                  orientation="right"
                  fontSize={12}
                />
                <Area
                  type="monotone"
                  dataKey="portfolioUpdated"
                  stroke="#7EE55C"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
      {chartData.length === 0 && (
        <div className="no-record-chart">
          <img src={no_data_icon} alt="no_data_icon" className="no-record-icon-styled" />
          <span>No Data</span>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  background: #18191a;
  position: relative;

  .chart-portfofio {
    position: relative;
    bottom: -30px;
    @media screen and (max-width: 700px) {
      bottom: -5px;
    }
  }

  .chart-duration {
    display: flex;
    align-items: flex-start;
    gap: var(--Base-Unit-S-12, 12px);
    margin-bottom: 8px;

    .duration-item {
      color: #919499;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--Main-Primary, #fff);
      }
    }
  }

  .no-record-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;

    img {
      position: absolute;
      inset: 0;

      opacity: 0.2;
      mix-blend-mode: luminosity;
    }

    span {
      color: var(--Text-Text_Secondary, #919499);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  .chart-content {
    min-height: 240px;
  }

  .recharts-layer.recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-layer.recharts-cartesian-axis-tick {
        text,
        tspan {
          fill: #999a9e;
        }
      }
    }
  }

  &.theme--light {
    background: #fff;

    .chart-duration {
      .duration-item {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        &:hover,
        &.active {
          color: #02b27f;
        }
      }
    }

    .recharts-layer.recharts-cartesian-axis {
      .recharts-cartesian-axis-ticks {
        .recharts-layer.recharts-cartesian-axis-tick {
          text,
          tspan {
            fill: #0d1a16b2;
          }
        }
      }
    }
  }
`;

export default PortfolioChart;
