import { useThemeContext } from "contexts/ThemeProvider";
import { formatDate } from "lib/dates";
import { formatNumber } from "lib/numbers";
import React, { FC } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import styled from "styled-components";
import { formatTimestamp2LocalDate } from "utils/formatDate";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

interface Props {
  data:
    | {
        amount: string;
        timestamp: number;
      }[]
    | undefined;
}
const CustomTooltip = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const dateFormat = formatTimestamp2LocalDate(Number(payload[0].payload.timestamp) * 1000, "MM-DD");

    return (
      <TooltipWrapper className="custom-tooltip" style={{ borderRadius: "4px" }}>
        <div className="date">{`${dateFormat || "--/--"}`}</div>
        <div className="body-tooltip-group">
          <div className="dot-styled"></div>
          <div style={{}} className="label">
            ${`${payload[0].value ? formatNumber(payload[0].value) : "0.00"}`}
          </div>
        </div>
      </TooltipWrapper>
    );
  }

  return null;
};
const TinyBarChart: FC<Props> = ({ data }) => {
  const { isLightTheme } = useThemeContext() as any;
  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height={"100%"}>
        <BarChart margin={{ bottom: -2 }} data={data}>
          <XAxis dataKey="none" tickSize={0} height={1} />
          <Tooltip active={false} content={<CustomTooltip />} />
          <Bar dataKey={"amount"} fill={isLightTheme ? "#02B27F" : "#1EB2FC"} />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default TinyBarChart;

const ChartWrapper = styled.div`
  height: 80px;
`;

const TooltipWrapper = styled.div`
  .data-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .label {
    color: white;
    font-weight: 500;
  }
  .body-tooltip-group {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-top: 10px;
  }
  .dot-styled {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1eb2fc;
  }
`;
