import { usePrivateQuery } from "@orderly.network/hooks";
import cx from "classnames";
import { getTimestampsWithDate, shortenAddress } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import { cloneElement, useMemo, useState } from "react";
import copy from "img/ic_copy_v2_16.svg";
import { helperToast } from "lib/helperToast";
import { useCopyToClipboard } from "react-use";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "hooks/useUserPosition";
import PaginationV2 from "pages/AnalyticsV3/components/PaginationV2";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";
const FundingHistory = ({ numberOfDay }) => {
  const TABS = [
    {
      label: "Deposits & Withdrawals",
      key: "Deposit",
    },
    {
      label: "Funding",
      key: "Funding",
    },
  ];
  const [page, setPage] = useState(1);
  const [pageFunding, setPageFunding] = useState(1);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const { targetDayTimestamp, targetDayFormatted, endOfToday, endOfTodayFormatted } =
    getTimestampsWithDate(numberOfDay);
  const depositWithdrawInfo = usePrivateQuery(
    `/v1/asset/history?page=1&size=300&start_t=${targetDayTimestamp}&end_t=${endOfToday}`
  );
  const fundingInfo = usePrivateQuery(
    `/v1/funding_fee/history?page=1&size=300&start_t=${targetDayTimestamp}&end_t=${endOfToday}`
  );
  const depositWithdrawList = useMemo(() => {
    if (depositWithdrawInfo?.data) {
      return depositWithdrawInfo?.data;
    }
    return [];
  }, [depositWithdrawInfo]);
  const fundingList = useMemo(() => {
    if (fundingInfo?.data) {
      return fundingInfo?.data;
    }
    return [];
  }, [depositWithdrawInfo]);
  const [, copyToClipboard] = useCopyToClipboard();

  const filterDepositWithDraw = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return depositWithdrawList?.slice(startIndex, endIndex) || [];
  }, [depositWithdrawList, page]);

  const filterFunding = useMemo(() => {
    const startIndex = (pageFunding - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = pageFunding * DEFAULT_PAGE_SIZE_POSITIONS;
    return fundingList?.slice(startIndex, endIndex) || [];
  }, [fundingList, pageFunding]);
  const renderTabSection = () => {
    return {
      Deposit: (
        <div className="collaterals">
          <div className="collaterals-header">
            <div>Token</div>
            <div>Time</div>
            <div>TxID</div>
            <div>Type</div>
            <div>Amount</div>
            <div>Status</div>
          </div>
          <div className="collaterals-body">
            {depositWithdrawList &&
              depositWithdrawList.length > 0 &&
              filterDepositWithDraw.map((item, index) => {
                return (
                  <div className="collaterals-row table-f-item" key={index}>
                    <div className="assets">
                      {<img src={`https://oss.orderly.network/static/symbol_logo/${item.token}.png`} alt="icon" />}{" "}
                      <span>{item.token} </span>
                    </div>
                    <div className="time">
                      <div>
                        <span>
                          {moment(new Date(item.created_time)).format(dateFormat)}{" "}
                          <span className="muted">{moment(new Date(item.created_time)).format(timeFormat)}</span>
                        </span>
                      </div>
                    </div>
                    <div
                      className="TxID"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        copyToClipboard(item.tx_id);
                        helperToast.success("TxID copied to your clipboard");
                      }}
                    >
                      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                        {shortenAddress(item.tx_id, 15)}{" "}
                        <img src={copy} style={{ width: 16, height: 16 }} alt="Copy txID" />
                      </div>
                    </div>
                    <div className="Type">
                      <span className={`${item.side === "DEPOSIT" ? "positive" : "negative"}`}>
                        {item.side === "DEPOSIT" ? "Deposit" : "Withdraw"}
                      </span>
                    </div>
                    <div className="Amount">
                      {item.side === "DEPOSIT" ? (
                        <span className="positive">+{formatAmount(parseValue(item.amount, 8), 8, 2, true)}</span>
                      ) : (
                        <span className="negative">-{formatAmount(parseValue(item.amount, 8), 8, 2, true)}</span>
                      )}
                    </div>
                    <div className="Status">{item.trans_status}</div>
                  </div>
                );
              })}
            {depositWithdrawList.length === 0 && <div className="no-data-record">No data</div>}
          </div>
          {depositWithdrawList && depositWithdrawList.length > DEFAULT_PAGE_SIZE_POSITIONS && (
            <PaginationV2
              rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
              rows={depositWithdrawList.length}
              currentPage={page}
              setCurrentPage={setPage}
            />
          )}
        </div>
      ),
      Funding: (
        <div className="collaterals">
          <div className="collaterals-header">
            <div>Symbol</div>
            <div>Time</div>
            <div>Funding Rate / Annual Rate</div>
            <div>Payment Type</div>
            <div>Funding Fee (USDC)</div>
          </div>
          <div className="collaterals-body">
            {fundingList &&
              fundingList.length > 0 &&
              filterFunding.map((item, index) => {
                const tokenSymbol = item.symbol.split("_")[1];
                return (
                  <div className="collaterals-row" key={index}>
                    <div className="assets">
                      <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="icon" />{" "}
                      <span>{tokenSymbol}/USDC </span>
                    </div>
                    <div className="time">
                      <div>
                        <span>
                          {moment(new Date(item.created_time)).format(dateFormat)}{" "}
                          <span className="muted">{moment(new Date(item.created_time)).format(timeFormat)}</span>
                        </span>
                      </div>
                    </div>
                    <div className="payment-rate">
                      {limitDecimals(item?.funding_rate || 0, 6).replace("-", "")}% /{" "}
                      {limitDecimals((item?.funding_rate || 0) * 1095, 6).replace("-", "")}%
                    </div>
                    <div className="payment-type">{item?.payment_type === "Receive" ? "Receive" : "Paid"}</div>
                    <div className="funding-fee">
                      {item.payment_type === "Receive" ? (
                        <span className="positive">
                          +{formatAmount(parseValue(item.funding_fee, 8), 8, 4, true).replace("-", "")}
                        </span>
                      ) : (
                        <span className="negative">-{formatAmount(parseValue(item.funding_fee, 8), 8, 4, true)}</span>
                      )}
                    </div>
                  </div>
                );
              })}
            {fundingList && fundingList.length > DEFAULT_PAGE_SIZE_POSITIONS && (
              <PaginationV2
                rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
                rows={fundingList.length}
                currentPage={pageFunding}
                setCurrentPage={setPageFunding}
              />
            )}
            {fundingList.length === 0 && <div className="no-data-record">No data</div>}
          </div>
        </div>
      ),
    }[activeTab.key];
  };
  return (
    <div className={`Portfolio-positions Exchange-updated-ui exchange-v2`} style={{ marginTop: 24 }}>
      <div className="Portfolio-tabs">
        {TABS.map((tab) => {
          return (
            <div
              className={cx("tab", {
                active: activeTab.key === tab.key,
              })}
              onClick={() => setActiveTab(tab)}
              key={tab.key}
            >
              {tab.label}{" "}
            </div>
          );
        })}
      </div>
      <div className="Portfolio-tab-content">{cloneElement(renderTabSection(), {})}</div>
    </div>
  );
};
export default FundingHistory;
