import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import cx from "classnames";
import styled from "styled-components";

import { createChart } from "krasulya-lightweight-charts";

import {
  USD_DECIMALS,
  SWAP,
  INCREASE,
  CHART_PERIODS,
  getLiquidationPrice,
  ACTIVE_CHAIN_IDS,
  arrayURLFetcher,
  getStatsInfo,
  getTokenStatsInfo,
  importImage,
} from "lib/legacy";
import { useChartPrices } from "domain/legacy";
import Tab from "../Tab/Tab";

import ChartTokenSelector from "./ChartTokenSelector";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { getTokenInfo } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { formatAmount, numberWithCommas, parseValue } from "lib/numbers";
import { getNormalizedTokenSymbol, getToken, getTokenLowerCase, getTokens } from "config/tokens";
import { formatDateTime } from "lib/dates";
import { t, Trans } from "@lingui/macro";
import { availableNetworksForChart } from "components/TVChartContainer/constants";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import { useMediaQuery } from "react-responsive";
import useSWR from "swr";
import { getServerUrl } from "config/backend";
import { formatNumber } from "lib/numbers";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import icStar from "img/trade/star.svg";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";
import TVChartContainerSwap from "components/TVChartContainer/TVChartContainerSwap";
const PRICE_LINE_TEXT_WIDTH = 15;

const timezoneOffset = -new Date().getTimezoneOffset() * 60;

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdy && toToken.isUsdy) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdy) {
    return toToken;
  }
  if (toToken.isUsdy) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "4h";

const getSeriesOptions = () => ({
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
  lineColor: "#5472cc",
  topColor: "rgba(49, 69, 131, 0.4)",
  bottomColor: "rgba(42, 64, 103, 0.0)",
  lineWidth: 2,
  priceLineColor: "#3a3e5e",
  downColor: "#fa3c58",
  wickDownColor: "#fa3c58",
  upColor: "#0ecc83",
  wickUpColor: "#0ecc83",
  borderVisible: false,
});

const getChartOptions = (width, height) => ({
  width,
  height,
  layout: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    textColor: "#ccc",
    fontFamily: "Relative",
  },
  localization: {
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
    timeFormatter: (businessDayOrTimestamp) => {
      return formatDateTime(businessDayOrTimestamp - timezoneOffset);
    },
  },
  grid: {
    vertLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
    horzLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
  timeScale: {
    rightOffset: 5,
    borderVisible: false,
    barSpacing: 5,
    timeVisible: true,
    fixLeftEdge: true,
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
  priceScale: {
    borderVisible: false,
  },
  crosshair: {
    horzLine: {
      color: "#aaa",
    },
    vertLine: {
      color: "#aaa",
    },
    mode: 0,
  },
});

export default function ExchangeTVChartSwap(props) {
  const {
    isProChart,
    chartToken,
    setChartToken,
    swapOption,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    chainId,
    positions,
    savedShouldShowPositionLines,
    orders,
    setToTokenAddress,
    isSwap = false,
  } = props;

  const [currentChart, setCurrentChart] = useState();
  const [currentSeries, setCurrentSeries] = useState();
  const [fromToken, setFromToken] = useState({});
  const [toToken, setToToken] = useState({});
  const [tradeVersion, setTradeVersion] = useLocalStorageSerializeKey(["trade-version"], "V2");
  const VersionTab = ["V2", "V1"];
  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-period"], DEFAULT_PERIOD);
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }
  const theme = useThemeContext();
  const isPad = useMedia("(max-width: 1350px)");
  const [hoveredCandlestick, setHoveredCandlestick] = useState();
  // const { data: dataStatsAll } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: tokenAllStats } = useSWR(getServerUrl(chainId, "/token-stats"), {
  //   fetcher: arrayURLFetcher,
  //   refreshInterval: 10000,
  // });
  // const currentStats = getStatsInfo(dataStatsAll);
  // const tokenStats = getTokenStatsInfo(chainId, tokenAllStats, chartToken?.address);
  // const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  // const toToken = getTokenInfo(infoTokens, toTokenAddress);
  useEffect(() => {
    setFromToken(getTokenInfo(infoTokens, fromTokenAddress));
    setToToken(getTokenInfo(infoTokens, toTokenAddress));
  }, [JSON.stringify(infoTokens), fromTokenAddress]);

  useEffect(() => {
    const tmp = getChartToken(swapOption, fromToken, toToken, chainId);
    setChartToken(tmp);
  }, [swapOption, fromToken, toToken, chainId]);

  const symbol = chartToken ? (chartToken.isWrapped ? chartToken.baseSymbol : chartToken.symbol) : undefined;
  const marketName = chartToken ? symbol + "_USD" : undefined;
  const previousMarketName = usePrevious(marketName);

  const currentOrders = useMemo(() => {
    if (swapOption === SWAP || !chartToken) {
      return [];
    }

    return orders
      .filter((order) => {
        if (order.type === SWAP) {
          // we can't show non-stable to non-stable swap orders with existing charts
          // so to avoid users confusion we'll show only long/short orders
          return false;
        }

        const indexToken = getToken(chainId, order.indexToken);
        return order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped);
      })
      .map((order) => {
        const title = `${order.type === INCREASE ? `Inc.` : `Dec.`} ${getNormalizedTokenSymbol(
          getTokenLowerCase(chainId, order.indexToken).symbol
        )} ${order.isLong ? `Long` : `Short`}`;
        const color = "#3a3e5e";

        return {
          price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 3)),
          color,
          title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
        };
      });
  }, [orders, chartToken, swapOption, chainId]);

  const currentPositions = useMemo(() => {
    if (!positions || !chartToken) {
      return [];
    }
    return positions
      .filter((p) => p.indexToken.address === chartToken.address)
      .map((position) => {
        const longOrShortText = position.isLong ? `Long` : `Short`;
        return {
          open: {
            price: parseFloat(
              formatAmount(position.averagePrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2)
            ),
            title: `Open ${position.indexToken.symbol} ${longOrShortText}`,
          },
          liquidation: {
            price: parseFloat(
              formatAmount(getLiquidationPrice(position), USD_DECIMALS, position?.indexToken?.displayDecimals || 2)
            ),
            title: `Liq. ${position.indexToken.symbol} ${longOrShortText}`,
          },
        };
      });
  }, [chartToken, positions]);

  const chartLines = useMemo(() => {
    const lines = [];
    if (currentOrders.length > 0) {
      lines.push(...currentOrders);
    }

    if (currentPositions.length > 0) {
      currentPositions.forEach((position) => {
        lines.push(position.open);
        lines.push(position.liquidation);
      });
    }

    return lines;
  }, [currentOrders, currentPositions]);

  const ref = useRef(null);
  const chartRef = useRef(null);

  const currentAveragePrice =
    chartToken.maxPrice && chartToken.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;
  const [priceData, updatePriceData] = useChartPrices(
    chainId,
    chartToken.symbol,
    chartToken.isStable,
    period,
    currentAveragePrice
  );

  const [chartInited, setChartInited] = useState(false);
  useEffect(() => {
    if (marketName !== previousMarketName) {
      setChartInited(false);
    }
  }, [marketName, previousMarketName]);

  const scaleChart = useCallback(() => {
    const from = Date.now() / 1000 - (7 * 24 * CHART_PERIODS[period]) / 2 + timezoneOffset;
    const to = Date.now() / 1000 + timezoneOffset;
    currentChart.timeScale().setVisibleRange({ from, to });
  }, [currentChart, period]);
  const isPadScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const onCrosshairMove = useCallback(
    (evt) => {
      if (!evt.time) {
        setHoveredCandlestick(null);
        return;
      }

      for (const point of evt.seriesPrices.values()) {
        setHoveredCandlestick((hoveredCandlestick) => {
          if (hoveredCandlestick && hoveredCandlestick.time === evt.time) {
            // rerender optimisations
            return hoveredCandlestick;
          }
          return {
            time: evt.time,
            ...point,
          };
        });
        break;
      }
    },
    [setHoveredCandlestick]
  );

  useEffect(() => {
    if (isProChart || !chartRef?.current || !ref.current || !priceData || !priceData.length || currentChart) {
      return;
    }

    const chart = createChart(
      chartRef.current,
      getChartOptions(chartRef.current.offsetWidth, chartRef.current.offsetHeight)
    );

    chart.subscribeCrosshairMove(onCrosshairMove);

    const series = chart.addCandlestickSeries(getSeriesOptions());
    series.applyOptions({
      priceFormat: {
        type: "price",
        precision: chartToken.displayDecimals || 2,
        minMove: chartToken.displayDecimals === 4 ? 0.0001 : 0.01,
      },
    });
    setCurrentChart(chart);
    setCurrentSeries(series);
  }, [isProChart, chartRef?.current, ref, priceData, currentChart, onCrosshairMove]);

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);

  useEffect(() => {
    if (!currentChart) {
      return;
    }
    const resizeChart = () => {
      currentChart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
    };
    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, [currentChart]);

  useEffect(() => {
    if (currentSeries && priceData && priceData.length) {
      currentSeries.setData(priceData);

      if (!chartInited) {
        scaleChart();
        setChartInited(true);
      }
    }
  }, [priceData, currentSeries, chartInited, scaleChart]);

  useEffect(() => {
    const lines = [];
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          let tokenSymbol;
          if (indexToken && indexToken.symbol) {
            tokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;
          }
          const title = `${order.type === INCREASE ? `Inc.` : `Dec.`} ${tokenSymbol} ${
            order.isLong ? `Long` : `Short`
          }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 3)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (positions && positions.length > 0) {
        const color = "#3a3e5e";
        positions.forEach((position) => {
          const longOrShortText = position.isLong ? `Long` : `Short`;
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 3)),
              color,
              title: `Open ${position.indexToken.symbol} ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );

          const liquidationPrice = getLiquidationPrice(position);
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 3)),
              color,
              title: `Liq. ${position.indexToken.symbol} ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, positions, currentSeries, chainId, savedShouldShowPositionLines]);

  const candleStatsHtml = useMemo(() => {
    if (!priceData) {
      return null;
    }
    const candlestick = hoveredCandlestick || priceData[priceData.length - 1];
    if (!candlestick) {
      return null;
    }

    const className = cx({
      "ExchangeChart-bottom-stats": true,
      positive: candlestick.open <= candlestick.close,
      negative: candlestick.open > candlestick.close,
      [`length-${String(parseInt(candlestick.close)).length}`]: true,
    });

    const toFixedNumbers = chartToken.displayDecimals || 2;

    return (
      <div className={className}>
        <span className="ExchangeChart-bottom-stats-label">O</span>
        <span className="ExchangeChart-bottom-stats-value">{candlestick.open.toFixed(toFixedNumbers)}</span>
        <span className="ExchangeChart-bottom-stats-label">H</span>
        <span className="ExchangeChart-bottom-stats-value">{candlestick.high.toFixed(toFixedNumbers)}</span>
        <span className="ExchangeChart-bottom-stats-label">L</span>
        <span className="ExchangeChart-bottom-stats-value">{candlestick.low.toFixed(toFixedNumbers)}</span>
        <span className="ExchangeChart-bottom-stats-label">C</span>
        <span className="ExchangeChart-bottom-stats-value">{candlestick.close.toFixed(toFixedNumbers)}</span>
      </div>
    );
  }, [hoveredCandlestick, priceData]);

  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;
  let last24hPrice;
  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      last24hPrice = parseValue(price.close, 30);
      if (price.time < timeThreshold) {
        break;
      }
      if (!low) {
        low = price.low;
      }
      if (!high) {
        high = price.high;
      }

      if (price.high > high) {
        high = price.high;
      }
      if (price.low < low) {
        low = price.low;
      }

      deltaPrice = price.open;
    }
  }
  let price24hChange;
  if (last24hPrice && chartToken && chartToken.maxPrice) {
    price24hChange = chartToken?.maxPrice?.sub(last24hPrice);
  }

  if (price24hChange && last24hPrice) {
    delta = price24hChange.mul(1000000).div(last24hPrice);
    deltaPercentage = price24hChange;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${formatAmount(delta, 4, 2, false)}%`;
    } else {
      deltaPercentageStr = `${formatAmount(delta, 4, 2, false)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  if (!chartToken) {
    return null;
  }

  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);
  };

  return (
    <div
      style={{
        height: isProChart ? 70 : "49.6rem",
      }}
      className="ExchangeChart tv"
      ref={ref}
    >
      <div className="ExchangeChart-top App-box App-box-border" style={{ padding: "15px 0 15px 24px" }}>
        {!isSwap ? (
          <div className="ExchangeChart-top-inner">
            <div>
              <div className="ExchangeChart-title">
                <ChartTokenSelector
                  chainId={chainId}
                  selectedToken={chartToken}
                  swapOption={swapOption}
                  infoTokens={infoTokens}
                  onSelectToken={onSelectToken}
                  className="chart-token-selector"
                />
              </div>
            </div>
            <div className="info-main-price info-main-swap">
              <div className="ExchangeChart-main-price">
                {!chartToken.maxPrice && <div className="skeleton-box" style={{ width: "60px" }} />}
                {chartToken.maxPrice &&
                  `$${formatAmount(chartToken.maxPrice, USD_DECIMALS, chartToken.displayDecimals || 2, true)}`}
              </div>
              <div className="ExchangeChart-info-label">
                {!chartToken.minPrice && <div className="skeleton-box" style={{ width: "60px" }} />}
                {chartToken.minPrice &&
                  `$${formatAmount(chartToken.minPrice, USD_DECIMALS, chartToken.displayDecimals || 2, true)}`}
              </div>
              <div className={cx({ positive: deltaPercentage > 0, negative: deltaPercentage < 0, deltaChange: true })}>
                {!deltaPercentageStr && <div className="skeleton-box" style={{ width: "60px" }} />}
                {deltaPercentageStr && deltaPercentageStr}
              </div>
            </div>
            <div className="ExchangeChart-additional-info changed-24h">
              <div className="ExchangeChart-info-label">
                <span>24H CHANGE</span>
              </div>
              <div className={cx({ positive: deltaPercentage > 0, negative: deltaPercentage < 0 })}>
                {!deltaPercentageStr && <div className="skeleton-box" style={{ width: "60px" }} />}
                {deltaPercentageStr && deltaPercentageStr}
              </div>
            </div>
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24H HIGH</span>
              </div>
              <div>
                {!high && <div className="skeleton-box" style={{ width: "60px" }} />}
                {high && numberWithCommas(high.toFixed(chartToken.displayDecimals || 2))}
              </div>
            </div>
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24H LOW</span>
              </div>
              <div>
                {!low && <div className="skeleton-box" style={{ width: "60px" }} />}
                {low && numberWithCommas(low.toFixed(chartToken.displayDecimals || 2))}
              </div>
            </div>
            {swapOption !== SWAP && (
              <>
                <div className="ExchangeChart-additional-info">
                  <div className="ExchangeChart-info-label">
                    <Tooltip
                      placement={isPad ? "topRight" : "top"}
                      autoAdjustOverflow={isPad ? true : false}
                      overlayClassName={` ${theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""}`}
                      className="custom-antd-tooltip"
                      title={<div className="customTooltip">Sum of open {symbol}/USDC long positions.</div>}
                    >
                      <span>OPEN INTEREST (L)</span>
                    </Tooltip>
                  </div>
                  <span>
                    {!chartToken.guaranteedUsd == null || chartToken.maxLongCapacity == null ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      `${
                        chartToken.guaranteedUsd
                          ? formatNumber(formatAmount(chartToken.guaranteedUsd, 30, false))
                          : "0.00"
                      }/${formatNumber(formatAmount(chartToken.maxLongCapacity, USD_DECIMALS, 0, false))}`
                    )}
                  </span>
                </div>
                <div className="ExchangeChart-additional-info">
                  <div className="ExchangeChart-info-label">
                    <Tooltip
                      placement={isPad ? "topRight" : "top"}
                      autoAdjustOverflow={isPad ? true : false}
                      overlayClassName={`custom-popup-antd-tooltip ${
                        theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                      }`}
                      className="custom-antd-tooltip"
                      title={<div className="customTooltip">Sum of open {symbol}/USD short positions.</div>}
                    >
                      <span>OPEN INTEREST (S)</span>
                    </Tooltip>
                  </div>
                  <span>
                    {!chartToken.globalShortSize == null || chartToken.maxGlobalShortSize == null ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      `${
                        chartToken.globalShortSize
                          ? formatNumber(formatAmount(chartToken.globalShortSize, 30, false))
                          : "0.00"
                      }/${formatNumber(formatAmount(chartToken.maxGlobalShortSize, 30, false))}`
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="swap-token-selected-wrapper">
            <div className="icon-token-group">
              <img src={importImage(`ic_${chartToken?.symbol?.toLowerCase()}_24.svg`)} className="icon-from" />
              <img src={importImage(`ic_usdc_24.svg`)} className="icon-to" />
            </div>
            <div className="info-token-group">
              <div className="price-token-group">
                <div className="price-value">
                  {!chartToken.maxPrice && <div className="skeleton-box" style={{ width: "60px" }} />}
                  {chartToken.maxPrice &&
                    `$${formatAmount(chartToken.maxPrice, USD_DECIMALS, chartToken.displayDecimals || 2, true)}`}
                </div>
                <div className="symbol-value">{chartToken?.symbol}/USDC</div>
              </div>
              <div
                className={cx("apr-token-group", {
                  positive: deltaPercentage > 0,
                  negative: deltaPercentage < 0,
                  deltaChange: true,
                })}
              >
                <span>
                  {price24hChange && (price24hChange.gt(0) ? "+" : price24hChange.lt(0) ? "-" : "")}$
                  {formatAmount(price24hChange || 0, USD_DECIMALS, 4, true).replace("-", "")}
                </span>
                {!deltaPercentageStr && <div className="skeleton-box" style={{ width: "60px" }} />}
                {deltaPercentageStr && <span>{"(" + deltaPercentageStr + ")"}</span>}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div style={{
        display: isProChart ? 'none' : 'unset'
      }} className="ExchangeChart-bottom App-box App-box-border">
        <div className="ExchangeChart-bottom-header">
          <div className="ExchangeChart-bottom-controls">
            <Tab options={Object.keys(CHART_PERIODS)} option={period} setOption={setPeriod} />
          </div>
          {candleStatsHtml}
        </div>
        <div className="ExchangeChart-bottom-content" ref={chartRef}></div>
      </div> */}
      {!isPadScreen && (
        <div className="ExchangeChart-bottom App-box App-box-border">
          {availableNetworksForChart.includes(chainId) && chartToken.symbol && chainId ? (
            <TVChartContainerSwap
              chartLines={chartLines}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              symbol={chartToken.symbol}
              chainId={chainId}
              onSelectToken={onSelectToken}
              period={period}
              setPeriod={setPeriod}
            />
          ) : (
            <p className="ExchangeChart-error">Sorry, chart is not supported on this network yet.</p>
          )}
        </div>
      )}
    </div>
  );
}

const StyledTooltip = styled.div`
  div.Tooltip-popup:has(.customTooltip) {
    width: fit-content;
  }
`;
