import { useMemo, useState } from "react";
import PositionClosedShare from "components/ExchangeV2/PositionClosedShare";
import PositionClosed from "components/ExchangeV2/PositionClosed";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import PositionShareClosedV1 from "components/Exchange/PositionShareClosedV1";
import PositionClosedV1 from "components/ExchangeV2/PositionClosedV1";
import ClosedPositionItemV3 from "./ClosedPositionItemV3";
import PositionClosedV3 from "components/ExchangeV2/PositionClosedV3";
import ClosedPositionItemMobileV3 from "./ClosedPositionItemMobileV3";
import PositionClosedShareV3 from "components/ExchangeV2/ClosedPositionShareV3";

const ClosedPositionList = (props) => {
  const [page, setPage] = useState(1);

  const { positions, loading, marketTokensInfo, marketAsssets, chainId, account } = props;
  const [positionToShare, setPositionToShare] = useState(null);
  const [positionToShareV1, setPositionToShareV1] = useState(null);
  const [isPositionDetailsVisible, setIsPositionDetailsVisible] = useState(undefined);
  const [isPositionDetailsVisibleV1, setIsPositionDetailsVisibleV1] = useState(undefined);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [isPositionShareModalOpenV3, setIsPositionShareModalOpenV3] = useState(false);
  const [isPositionShareModalOpenV1, setIsPositionShareModalOpenV1] = useState(false);
  const [isClosedPositionModalV3, setIsClosedPositionModalV3] = useState(false);
  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  const handleOpenClosedPositionModalV3 = (position) => {
    setSelectedPosition(position);
    setIsClosedPositionModalV3(true);
  };
  return (
    <div className="PositionsList">
      <table className={`Exchange-list large App-box ${positions?.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          {filterPositions.length > 0 && (
            <tr className="Exchange-list-header">
              <th>
                <span>Symbol</span>
              </th>
              <th>
                <span>Closed/Max Closed</span>
              </th>
              <th>
                <span>Avg. Open</span>
              </th>
              <th>
                <span>Avg. Close</span>
              </th>
              <th>
                <span>Net PnL</span>
              </th>
              <th>
                <span>Time Opened</span>
              </th>
              <th>
                <span>Time Closed</span>
              </th>
              {/* <th>
                <span>Updated Time</span>
              </th> */}
              <th></th>
            </tr>
          )}

          {!loading && positions.length === 0 && (
            <tr className="no-position">
              <td colSpan="15">
                <div className="no-data-record">No position history</div>
              </td>
            </tr>
          )}
          {filterPositions.length > 0 &&
            filterPositions.map((position, index) => {
              return (
                <ClosedPositionItemV3
                  key={index}
                  position={position}
                  handleSelectedPosition={handleOpenClosedPositionModalV3}
                  setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
                  setSelectedPosition={setSelectedPosition}
                />
              );
            })}
        </tbody>
      </table>
      <div className="Exchange-list small">
        {positions.length === 0 && loading && (
          <div className="Exchange-empty-positions-list-note App-card">
            <div className="skeleton-box" style={{ height: "25px" }} />
          </div>
        )}
        {positions.length === 0 && <div className="no-data-record">No position history</div>}
        {filterPositions.length > 0 &&
          filterPositions.map((position, index) => {
            return (
              <ClosedPositionItemMobileV3
                key={index}
                position={position}
                handleSelectedPosition={handleOpenClosedPositionModalV3}
                setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
                setSelectedPosition={setSelectedPosition}
              />
            );
          })}
      </div>
      {isClosedPositionModalV3 && (
        <PositionClosedV3
          isVisible={isClosedPositionModalV3}
          setIsVisible={setIsClosedPositionModalV3}
          position={selectedPosition}
        />
      )}
      {isPositionShareModalOpen && (
        <PositionClosedShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          selectedPosition={positionToShare}
          chainId={chainId}
          account={account}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {isPositionShareModalOpenV3 && (
        <PositionClosedShareV3
          setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
          isPositionShareModalOpen={isPositionShareModalOpenV3}
          position={selectedPosition}
          chainId={chainId}
          account={account}
        />
      )}
      {isPositionShareModalOpenV1 && (
        <PositionShareClosedV1
          setIsPositionShareModalOpen={setIsPositionShareModalOpenV1}
          isPositionShareModalOpen={isPositionShareModalOpenV1}
          positionToShare={positionToShareV1}
          chainId={chainId}
          account={account}
        />
      )}
      {isPositionDetailsVisible && (
        <PositionClosed
          isVisible={isPositionDetailsVisible}
          setIsVisible={setIsPositionDetailsVisible}
          position={selectedPosition}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
        />
      )}
      {isPositionDetailsVisibleV1 && (
        <PositionClosedV1
          isVisible={isPositionDetailsVisibleV1}
          setIsVisible={setIsPositionDetailsVisibleV1}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default ClosedPositionList;
