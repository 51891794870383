import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="#fff"
        strokeLinejoin="round"
        d="M7.476 17.74a7.972 7.972 0 01-4.426 1.39l4.426-1.39zm0 0c.194.03.39.048.59.052l.01-.5-.317-.387c-.13.107-.263.21-.399.307-1.267-.262-2.35-1.136-2.911-2.361l3.027 2.89zM19.38 6.258a.5.5 0 00.327.92c.287-.036.571-.086.852-.151-.282.29-.585.559-.908.803a.5.5 0 00-.197.42c.008.177.011.355.011.534v.003c.021 3.12-1.154 6.115-3.256 8.315-2.102 2.199-4.953 3.42-7.914 3.399H8.29a10.67 10.67 0 01-4.348-.924 8.537 8.537 0 004.451-1.898.5.5 0 00-.307-.886c-1.275-.026-2.434-.759-3.06-1.905a4.44 4.44 0 001.207-.17.5.5 0 00-.034-.97c-1.433-.303-2.54-1.485-2.824-2.968.41.138.837.217 1.27.231a.5.5 0 00.304-.91c-1.38-.965-1.938-2.775-1.423-4.38 2.145 2.415 5.117 3.876 8.296 4.043a.5.5 0 00.514-.606c-.37-1.686.39-3.409 1.824-4.212 1.427-.799 3.191-.504 4.312.737a.5.5 0 00.472.155 8.433 8.433 0 001.352-.4c-.258.324-.568.601-.917.82zM2.922 19.63c1.653.9 3.494 1.372 5.368 1.37 3.103.022 6.085-1.258 8.279-3.554s3.417-5.417 3.395-8.663c0-.186-.004-.372-.012-.556a8.5 8.5 0 001.502-1.462L2.922 19.63z"
      ></path>
    </svg>
  );
}

export default Icon;
