import { PoolFrame } from "pages/Earn/styles";

import ylp_ic from "img/ylp.svg";
import green_arrow_ic from "img/green-arrow.svg";

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Footer from "components/Footer/Footer";
import BuySellYlp from "pages/Earn/BuySellYlp";
import PoolCompositionYlp from "pages/Earn/PoolCompositionYlp";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";
import {
  BASIS_POINTS_DIVISOR,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  YLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
} from "lib/legacy";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { getContract } from "config/contracts";
import { getNativeToken, getTokens } from "config/tokens";

import RewardReader from "abis/RewardReader.json";
import ReaderV2 from "abis/ReaderV2.json";
import YlpManager from "abis/YlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import VaultV2 from "abis/VaultV2.json";
import Vault from "abis/Vault.json";
import Token from "abis/Token.json";

import { FANTOM } from "config/chains";
import { useYmyPrice } from "domain/legacy";
import { useTokenStats } from "dataProvider";
import { COINCOLORS, convertToPercents } from "lib/helper";
import GenericChart from "pages/Analytics/components/GenericChart";

const { AddressZero } = ethers.constants;

const Ylp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const { active, library, account } = useWeb3React();
  const [ylpValue, setYlpValue] = useState("");
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);

  const { ymyPrice } = useYmyPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedYlpTrackerAddress = getContract(chainId, "StakedYlpTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdyAddress = getContract(chainId, "USDY");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const feeYlpTrackerAddress = getContract(chainId, "FeeYlpTracker");
  const vaultAddress = getContract(chainId, "Vault");
  const ylpManagerAddress = getContract(chainId, "YlpManager");

  const ymyAddress = getContract(chainId, "YMY");
  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");
  const yslpAddress = getContract(chainId, "YSLP");
  const ylpAddress = getContract(chainId, "YLP");

  const esYmyAddress = getContract(chainId, "ES_YMY");
  const bnYmyAddress = getContract(chainId, "BN_YMY");
  const bonusYmyTrackerAddress = getContract(chainId, "BonusYmyTracker");
  const feeYmyTrackerAddress = getContract(chainId, "FeeYmyTracker");
  const feeYslpTrackerAddress = getContract(chainId, "FeeYslpTracker");

  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const ylpVesterAddress = getContract(chainId, "YlpVester");
  const yslpVesterAddress = getContract(chainId, "YslpVester");
  const stakedYslpTrackerAddress = getContract(chainId, "StakedYslpTracker");

  const tokensForBalanceAndSupplyQuery = [stakedYlpTrackerAddress, usdyAddress];
  const rewardTrackersForStakingInfo = [
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    stakedYlpTrackerAddress,
    feeYlpTrackerAddress,
    stakedYslpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  const vesterAddresses = [ymyVesterAddress, ylpVesterAddress, yslpVesterAddress];
  const walletTokens = [ymyAddress, esYmyAddress, ylpAddress, stakedYmyTrackerAddress, yslpAddress];
  const depositTokens = [
    ymyAddress,
    esYmyAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    bnYmyAddress,
    ylpAddress,
    yslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedYmyTrackerAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYlpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { data: tokenBalances } = useSWR(
    [`Ylp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
    }
  );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Ylp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Ylp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: ylpBalance } = useSWR(
    [`Ylp:ylpBalance:${active}`, chainId, feeYlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: aums } = useSWR([`Ylp:getAums:${active}`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Ylp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Ylp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Ylp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Ylp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedYmySupply } = useSWR(
    [
      `Ylp:stakedYmySupply:${active}:${stakedYmyTrackerAddress}`,
      chainId,
      ymyAddress,
      "balanceOf",
      stakedYmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`BuySellYlp:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress, yslpAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Ylp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let ymySupply = totalSupply;

  const usdySupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  const ylpAmount = parseValue(ylpValue, YLP_DECIMALS);

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const ylpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);

  const ylpPrice =
    aum && aum.gt(0) && ylpSupply.gt(0)
      ? aum.mul(expandDecimals(1, YLP_DECIMALS)).div(ylpSupply)
      : expandDecimals(1, USD_DECIMALS);

  const ylpSupplyUsd = ylpSupply.mul(ylpPrice).div(expandDecimals(1, YLP_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  const nativeToken = getTokenInfo(infoTokens, AddressZero);

  let ylpBalanceUsd;
  if (ylpBalance) {
    ylpBalanceUsd = ylpBalance.mul(ylpPrice).div(expandDecimals(1, YLP_DECIMALS));
  }

  let totalApr = bigNumberify(0);

  let feeYlpTrackerAnnualRewardsUsd;
  let feeYlpTrackerApr;

  if (
    stakingData &&
    stakingData.feeYlpTracker &&
    stakingData.feeYlpTracker.tokensPerInterval &&
    nativeToken &&
    nativeToken.minPrice &&
    ylpSupplyUsd &&
    ylpSupplyUsd.gt(0)
  ) {
    feeYlpTrackerAnnualRewardsUsd = stakingData.feeYlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeToken.minPrice)
      .div(expandDecimals(1, 18));
    feeYlpTrackerApr = feeYlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(ylpSupplyUsd);
    totalApr = totalApr.add(feeYlpTrackerApr);
  }

  let stakedYlpTrackerAnnualRewardsUsd;
  let stakedYlpTrackerApr;

  if (
    ymyPrice &&
    stakingData &&
    stakingData.stakedYlpTracker &&
    stakingData.stakedYlpTracker.tokensPerInterval &&
    ylpSupplyUsd &&
    ylpSupplyUsd.gt(0)
  ) {
    stakedYlpTrackerAnnualRewardsUsd = stakingData.stakedYlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(ymyPrice)
      .div(expandDecimals(1, 18));
    stakedYlpTrackerApr = stakedYlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(ylpSupplyUsd);
    totalApr = totalApr.add(stakedYlpTrackerApr);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedYmySupply,
    ymyPrice,
    ymySupply
  );

  const [tokenStatsData, tokenStatsLoading] = useTokenStats({});

  const chartLegendItems =
    tokenStatsData && tokenStatsData.tokenSymbols
      ? tokenStatsData.tokenSymbols.map((token, i) => ({
          key: token,
          color: getTokenColor(i),
          fillOpacity: 0.5,
        }))
      : [];

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={ylp_ic} alt="ylp_ic" />{" "}
            <div>
              Majors pools - YLP
              <p>Purchase YLP tokens to earn BERA, esYMY fees from swaps and leveraged trading.</p>
            </div>
          </h1>
        </div>
        <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link disabled">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div>
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {formatAmount(totalApr, 2, 2, true) !== "0.00" ? (
              <>
                <h5 className="positive">{formatAmount(totalApr, 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatAmount(feeYlpTrackerApr, 2, 2, false)}% <br className="br-mobile" />+ esYMY{" "}
                {formatAmount(stakedYlpTrackerApr, 2, 2, false)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(ylpSupply, YLP_DECIMALS, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatAmount(ylpSupply, YLP_DECIMALS, 2, true)} YLP</h5>$
                {formatNumber(formatAmount(ylpSupplyUsd, USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.ylpSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "ylpSupply", 18, 2, true)} YLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "ylpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {ylpBalance ? (
              <>
                <h5>{formatAmount(ylpBalance, YLP_DECIMALS, 2, true)} YLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {ylpBalanceUsd ? (
              <>${formatNumber(formatAmount(ylpBalanceUsd, USD_DECIMALS, USD_DECIMALS))}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {ylpBalance ? (
              <>
                <h5>{formatAmount(ylpBalance, YLP_DECIMALS, 2, true)} YLP</h5>$
                {formatNumber(formatAmount(ylpBalanceUsd, USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart">
            <h3>Token Distribution</h3>
            {/* <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="name" color="red" />
                <YAxis />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
              </AreaChart>
            </ResponsiveContainer> */}
            <GenericChart
              syncId={"syncYlp"}
              loading={tokenStatsLoading}
              data={tokenStatsData ? tokenStatsData.poolAmountUsd : null}
              controls={{
                convertToPercents: convertToPercentsHandler,
              }}
              yaxisDataKey="all"
              items={chartLegendItems}
              type="Area"
              height={270}
              hideHeader
            />
          </div>
          <PoolCompositionYlp
            isBuying={isBuying}
            ylpAmount={ylpAmount}
            ylpPrice={ylpPrice}
            usdySupply={usdySupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellYlp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            ylpValue={ylpValue}
            setYlpValue={setYlpValue}
            ylpAmount={ylpAmount}
            ylpPrice={ylpPrice}
            usdySupply={usdySupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Ylp;

const convertToPercentsHandler = (data) => convertToPercents(data, { totalKey: "all", ignoreKeys: [] });

function getTokenColor(index) {
  return COINCOLORS[index % COINCOLORS.length];
}
