/* eslint-disable no-fallthrough */
import React from "react";
import styled from "styled-components";
import cx from "classnames";

import { useThemeContext } from "contexts/ThemeProvider";

import useBackHistory from "hooks/useBackHistory";
import Avatar from "components/Avatar";
import { importImage } from "lib/legacy";
import { DataTable, SkeletonLoadingTable, TableRow } from "../helper/table";
import Pagination from "pages/Analytics/components/Pagination";

import usePositionInfo from "hooks/usePositionInfo";
import { Link, useParams } from "react-router-dom";
import useMarketTokensInfo from "hooks/useMarketTokensInfo";
import { formatAmountInDollar, parseValue } from "lib/numbers";
import moment from "moment";
import usePositionActions from "hooks/usePositionActions";
import { BigNumber } from "ethers";
import { useMediaQuery } from "react-responsive";
import LinkSquareIcon from "components/Icon/LinkSquareIcon";
import { TYPE_MAP_TYPE } from ".";
import { getExplorerUrl } from "config/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import Tooltip from "components/Tooltip/Tooltip";
import BackIcon from "components/Icon/BackIcon";

const renderDeltaCollateral = (action) => {
  let prefix = "";

  //Get prefix for Collateral Delta
  switch (action.actionType) {
    case "EDIT_COLLATERAL":
      if (action.isPlus) {
        prefix = "+";
      } else {
        prefix = "-";
      }
      break;

    case "OPEN_POSITION":
    case "ADD_POSITION":
      prefix = "+";
      break;

    case "DECREASE_POSITION":
    case "CLOSE_POSITION":
    case "LIQUIDATE_POSITION":
    case "ADD_TRAILING_STOP":
      prefix = "-";
      break;

    default:
      prefix = "";
  }

  //Get number for Collateral Delta
  switch (action.actionType) {
    case "OPEN_POSITION":
    case "DECREASE_POSITION":
    case "CLOSE_POSITION":
    case "ADD_POSITION":
    case "LIQUIDATE_POSITION":
      return prefix + formatAmountInDollar(action.collateral, 30, 2, true);

    case "EDIT_COLLATERAL":
      return prefix + formatAmountInDollar(action.amount, 30, 2, true);

    default:
      return null;
  }
};

const renderDeltaSize = (action) => {
  let prefix = "";

  //Get prefix for Size Delta
  switch (action.actionType) {
    case "OPEN_POSITION":
    case "ADD_POSITION":
      prefix = "+";
      break;

    case "DECREASE_POSITION":
    case "CLOSE_POSITION":
    case "LIQUIDATE_POSITION":
    case "ADD_TRAILING_STOP":
      prefix = "-";
      break;

    case "EDIT_COLLATERAL":
    default:
      prefix = "";
  }

  //Get number for Size Delta
  switch (action.actionType) {
    case "OPEN_POSITION":
    case "DECREASE_POSITION":
    case "CLOSE_POSITION":
    case "ADD_POSITION":
    case "LIQUIDATE_POSITION":
      return prefix + formatAmountInDollar(action.tradeVolume, 30, 2, true);

    case "EDIT_COLLATERAL":
    default:
      return null;
  }
};

const PositionDetail = ({ chainId, isValidChain }) => {
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { assets } = useMarketTokensInfo();
  const { posId } = useParams();
  const { data: positionInfo, isLoading: isPositionInfoLoading } = usePositionInfo(chainId, posId);
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
    usePositionActions(chainId, posId);

  const { symbol, decimals, latestPrice, tokenSymbol } = assets[positionInfo.tokenId] || {};
  const back = useBackHistory("/analytic-v2/top-positions");

  const tokenImage = tokenSymbol ? importImage(`ic_${tokenSymbol.toLowerCase()}_40.svg`) : undefined;
  const marketPrice = parseValue(latestPrice, 30);

  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          <div className="breadcrumb">
            <div className="back-btn" onClick={back}>
              {/* <img src={isLightTheme ? left_arrowLight : left_arrow} alt="left_arrow" /> */}
              <BackIcon fill={isLightTheme && "#0D1A16"} />
            </div>
            {isPositionInfoLoading ? (
              <SkeletonLoadingPositionInfo />
            ) : (
              <>
                <div className="position">
                  {tokenImage ? <img src={tokenImage} alt="tokenImage" /> : <div className="skeleton-box token-img" />}
                  <div className="position-detail">
                    <div className="position-detail-top">
                      {symbol}
                      <span className={cx({ positive: positionInfo.isLong })}>
                        {positionInfo.isLong ? "LONG" : "SHORT"}
                      </span>{" "}
                      <div className="leverage">
                        {Number(positionInfo.leverage) ? Number(positionInfo.leverage).toFixed(2) : "--"}X
                      </div>
                    </div>
                    <div className="position-detail-bottom">
                      <div className="position-detail-price">
                        Entry Price:{" "}
                        <span>
                          {formatAmountInDollar(positionInfo.averagePrice, 30, decimals || 2, true, {
                            autoDisplayDecimals: true,
                          })}
                        </span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
                        <path d="M1 0.5V11.5" stroke="white" />
                      </svg>
                      <div className="position-detail-price">
                        {positionInfo.isClosed && (
                          <>
                            Exit Price:{" "}
                            <span>
                              {formatAmountInDollar(data?.rows?.[0]?.markPrice, 30, decimals || 2, true, {
                                autoDisplayDecimals: true,
                              })}
                            </span>
                          </>
                        )}
                        {!positionInfo.isClosed && (
                          <>
                            Mark Price:{" "}
                            <span>
                              {formatAmountInDollar(marketPrice, 30, decimals || 2, true, {
                                autoDisplayDecimals: true,
                              })}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wallet">
                  Wallet:{" "}
                  {positionInfo.owner ? (
                    <Avatar address={positionInfo.owner} size="24px" chainId={chainId} />
                  ) : (
                    <span className="skeleton-box wallet-img" />
                  )}{" "}
                  {positionInfo.owner ? (
                    <Link to={`/analytic-v2/account/${positionInfo.owner}`}>
                      {isMobile
                        ? positionInfo.owner?.slice(0, 6) +
                          "..." +
                          positionInfo.owner?.slice(positionInfo.owner.length - 4, positionInfo.owner.length)
                        : positionInfo.owner}
                    </Link>
                  ) : (
                    <span>
                      <div className="skeleton-box" />
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="summary">
            <div className="sumary-item">
              PosID: <div>{!isPositionInfoLoading ? `#${posId}` : <div className="skeleton-box" />}</div>
            </div>
            <div className="sumary-item">
              Size:{" "}
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.size, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            <div className="sumary-item">
              Collateral Value:{" "}
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.collateral, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            <div className="sumary-item">
              <Tooltip
                handle={`PnL: `}
                position={isMobile ? "left-top" : "center-top"}
                // className="fitnowrap"
                renderContent={() => {
                  return (
                    <div>
                      {positionInfo.isClosed
                        ? "Realized PnL after all paid fees"
                        : "Unrealized PnL after accrued funding & borrow fees"}
                    </div>
                  );
                }}
              />
              <div
                className={cx({
                  positive: positionInfo.pnlAfterFee && BigNumber.from(positionInfo.pnlAfterFee).gt(0),
                  negative: positionInfo.pnlAfterFee && BigNumber.from(positionInfo.pnlAfterFee).lt(0),
                })}
              >
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.pnlAfterFee, 30, 2, true, {
                    showPlus: true,
                  })
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            <div className="sumary-item">
              Paid Fees:{" "}
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.paidFees, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            {positionInfo.liquidationPrice !== null && (
              <div className="sumary-item">
                Liquidation Price:{" "}
                <div className="yellow">
                  {!isPositionInfoLoading ? (
                    formatAmountInDollar(positionInfo.liquidationPrice, 30, decimals || 2, true, {
                      autoDisplayDecimals: true,
                    })
                  ) : (
                    <div className="skeleton-box" />
                  )}
                </div>
              </div>
            )}
          </div>
          <DataTable $noIndex $rowHeightSkeletonMobile="146.38px" className={lightThemeClassName}>
            <div className="head">
              <div className="col-name">Time</div>
              <div className="col-name">Action</div>
              <div className="col-name">Collateral Delta</div>
              <div className="col-name">Size Delta</div>
              <div className="col-name">Paid Fee</div>
              <div className="col-name">Executed Price</div>
            </div>
            <div className="body">
              {!isLoading &&
                data?.rows?.map((row, i) => {
                  const positionFee = BigNumber.from(row.positionFee);
                  const fundingFee = BigNumber.from(row.fundingFee);
                  const borrowFee = BigNumber.from(row.borrowFee);
                  const paidFees = positionFee.add(fundingFee).add(borrowFee);

                  const hasPaidFee = !["LIQUIDATE_POSITION", "EDIT_COLLATERAL"].includes(row.actionType);
                  return (
                    <TableRow key={i} noScaleOnHover>
                      <div d-label="Time" className="col-body">
                        {moment(row?.createdAt * 1000).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                      <div d-label="Action" className="col-body">
                        {row.actionType === "ADD_TRAILING_STOP"
                          ? row.trailingStopAll
                            ? "Close"
                            : "Decrease"
                          : TYPE_MAP_TYPE[row.actionType]}
                      </div>
                      <div
                        d-label="Collateral Delta"
                        className={cx("col-body", {
                          "col-hide-mobile": renderDeltaCollateral(row) === null,
                        })}
                      >
                        {renderDeltaCollateral(row)}
                      </div>
                      <div
                        d-label="Size Delta"
                        className={cx("col-body", {
                          "col-hide-mobile": renderDeltaSize(row) === null,
                        })}
                      >
                        {renderDeltaSize(row)}
                      </div>
                      <div
                        d-label="Paid Fee"
                        className={cx("col-body", {
                          "col-hide-mobile": !hasPaidFee,
                        })}
                      >
                        {hasPaidFee && formatAmountInDollar(paidFees, 30, 2, true)}
                      </div>
                      <div d-label="Executed Price" className="col-body">
                        {formatAmountInDollar(row.markPrice, 30, 2, true, {
                          autoDisplayDecimals: true,
                        })}
                      </div>
                      {isSmallDevice && (
                        <ExternalLink
                          d-label=""
                          className="col-body col-link"
                          href={`${getExplorerUrl(chainId)}tx/${row.transactionHash}`}
                        >
                          <LinkSquareIcon />
                        </ExternalLink>
                      )}
                      <ExternalLink
                        className="icon-last col-link"
                        href={`${getExplorerUrl(chainId)}tx/${row.transactionHash}`}
                      >
                        <LinkSquareIcon />
                      </ExternalLink>
                    </TableRow>
                  );
                })}
            </div>
            {isLoading && <SkeletonLoadingTable noHeading />}
          </DataTable>
          {data?.rows?.length > 0 && (
            <Pagination
              className="pagination-analytics"
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              row={data.count}
            />
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

function SkeletonLoadingPositionInfo() {
  return (
    <>
      <div className="position skeleton">
        <div className="skeleton-box token-img" />
        <div className="position-detail">
          <div className="position-detail-top">
            <div className="skeleton-box" />
          </div>
          <div className="position-detail-bottom">
            <div className="skeleton-box" />
          </div>
        </div>
      </div>
      <div className="wallet skeleton">
        Wallet: <div className="skeleton-box wallet-img" />
        <span>
          <div className="skeleton-box" />
        </span>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0;

  .breadcrumb {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.6);

    .back-btn {
      display: flex;
      padding: var(--Border_radius, 4px);
      align-items: flex-start;
      gap: 10px;
      border-radius: var(--Base-Unit-XS-8, 8px);
      background: var(--Nature-2, #20233b);
      cursor: pointer;

      &:hover {
        path {
          fill: #34f5ae;
        }
      }
    }

    .position {
      flex: 1;
      display: flex;
      align-items: center;
      gap: var(--Base-Unit-XS-8, 8px);
      flex: 1 0 0;

      & > img {
        width: 48px;
        height: 48px;
      }

      & > .skeleton-box.token-img {
        width: 48px;
        height: 48px;
        border-radius: 999px;
      }

      .position-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        .position-detail-top {
          display: flex;
          align-items: baseline;
          gap: var(--Border_radius, 4px);

          color: #ffffff;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 140%; /* 22.4px */

          span {
            color: #e43e53;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%; /* 16.8px */

            &.isLong {
              color: var(--Primary, #1eb2fc);
            }
          }

          .posId {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          .leverage {
            display: flex;
            padding: 0px var(--Border_radius, 4px);
            align-items: center;
            gap: 10px;
            border-radius: var(--Border_radius, 4px);
            background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));
            color: var(--Leverage-Text, #ffc700);
            font-size: 12px;
            font-weight: 400;
            line-height: 120%; /* 15.6px */
            height: 16px;
          }
        }

        .position-detail-bottom {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);

          .position-detail-price {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            span {
              color: #fff;
            }
          }
        }
      }

      &.skeleton {
        .skeleton-box.token-img {
          width: 48px;
          height: 48px;
          border-radius: 999px;
        }

        .position-detail {
          width: 500px;

          .position-detail-bottom {
            margin-top: 4px;
          }

          .skeleton-box {
            height: 19.7px;
          }
        }
      }
    }

    .wallet {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      display: flex;
      align-items: center;
      gap: var(--Base-Unit-XS-8, 8px);

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
      }

      & > .skeleton-box.wallet-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        flex-shrink: 0;
      }

      span {
        width: 326px;
        display: flex;

        @media screen and (max-width: 767px) {
          width: 175px;
        }
      }

      &.skeleton {
        & > .skeleton-box.wallet-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        span {
          width: 326px;
          display: flex;
        }
      }
    }
  }

  .summary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    margin: 16px 0 24px;

    .sumary-item {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      display: flex;
      align-items: baseline;
      gap: 4px;

      & > div {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */

        &.green {
          color: #1eb2fc;
        }

        &.yellow {
          color: #ffb200;
        }
      }

      &:has(.skeleton-box) {
        & > div {
          align-self: center;
        }
        .skeleton-box {
          width: 50px;
          display: flex;
        }
      }
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-top: 0;

    .breadcrumb {
      flex-wrap: wrap;

      .wallet {
        width: 100%;
      }
    }

    .summary {
      .sumary-item {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .breadcrumb {
      margin-bottom: 16px;

      .position {
        & > img {
          width: 24px;
          height: 24px;
        }

        & > .skeleton-box.token-img {
          width: 24px;
          height: 24px;
          border-radius: 999px;
        }

        &.skeleton {
          .skeleton-box.token-img {
            width: 24px;
            height: 24px;
          }

          .position-detail {
            width: 200px;

            .position-detail-bottom {
              margin-top: 4px;
            }

            .skeleton-box {
              height: 19.7px;
            }
          }
        }
      }

      .wallet {
        &.skeleton {
          span {
            width: 175px;
          }
        }
      }
    }

    .summary {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;

      .sumary-item {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &.theme--light {
    .breadcrumb {
      border-bottom-color: rgba(13, 26, 22, 0.7);

      .back-btn {
        background: var(--Nature-2, #f2f5f7);
      }

      .position {
        .position-detail-top {
          color: #0d1a16;

          .posId {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }
        }

        .position-detail-bottom {
          svg {
            path {
              stroke: #0d1a16;
            }
          }

          .position-detail-price {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

            span {
              color: #0d1a16;
            }
          }
        }
      }

      .wallet {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        a {
          color: #0d1a16;
        }
      }
    }

    .summary {
      .sumary-item {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        .Tooltip-handle {
          color: #0d1a16b2;
        }

        div {
          color: #0d1a16;

          &.yellow {
            color: var(--Functional-Orange, #d49007);
          }
        }
      }
    }
  }
`;

export default PositionDetail;
