import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import cx from "classnames";
import { FOK, IOC, LIMIT, POST_ONLY } from "lib/legacy";
import Checkbox from "components/Checkbox/Checkbox";
import CheckboxRadio from "components/Checkbox/CheckboxRadio";

const EXECUTETABS = [
  {
    name: "Post Only",
    value: POST_ONLY,
  },
  {
    name: "IOC",
    value: IOC,
  },
  {
    name: "FOK",
    value: FOK,
  },
];
const ORDER_CONFIRM = [
  {
    name: "Order Confirm",
  },
];
const ReduceOnlyCard = ({
  isConfirmModal,
  setIsConfirmModal,
  setValue,
  executeType,
  setExecuteType,
  redeuceEnable,
  setReduceEnable,
  orderOption,
  isShowTPSL,
}) => {
  const onChange = () => {
    setReduceEnable && setReduceEnable(!redeuceEnable);
  };
  useEffect(() => {
    if (isShowTPSL) setExecuteType("")
  }, [isShowTPSL]);
  return (
    <div className="toogle-card-container" style={{ marginBottom: 0 }}>
      {/* <div className="title-container">
        <Checkbox isChecked={redeuceEnable} setIsChecked={() => onChange()}>
          <div className="group-actions">
            <span className="bold">Reduce Only</span>
          </div>
        </Checkbox>
      </div> */}
      <div className="reduce-container">
        <div className="execute-tab-container">
          {EXECUTETABS.map((tab) => (
            <CheckboxRadio
              key={tab.value}
              disabled={orderOption !== LIMIT || isShowTPSL}
              isChecked={tab.value === executeType}
              setIsChecked={() => {
                if (executeType !== tab.value) setExecuteType(tab.value);
                else setExecuteType("");
              }}
            >
              <div className="group-actions">
                <span>{tab.name}</span>
              </div>
            </CheckboxRadio>
          ))}
        </div>
        {/* <div className="execute-tab-container">
          {ORDER_CONFIRM.map((tab) => (
            <Checkbox key={tab.name} isChecked={isConfirmModal} setIsChecked={() => setIsConfirmModal(!isConfirmModal)}>
              <div className="group-actions">
                <span>{tab.name}</span>
              </div>
            </Checkbox>
          ))}
        </div> */}
      </div>
    </div>
  );
};
export default ReduceOnlyCard;
