import { useEffect, useState } from "react";
import axios from "axios";
import { getServerBaseUrl } from "config/backend";

export const DEFAULT_SORT = "pnl DESC";

function useOrderlyVolume(symbol) {
  const [data, setData] = useState({});
  useEffect(() => {
    async function getContests() {
      try {
        const { data: response } = await axios.get(
          `https://api.orderly.org/v1/public/futures_market?broker_id=navigator&symbol=${symbol}`
        );
        if (response?.data?.rows) setData(response?.data?.rows?.[0] || {});
      } catch (error) {
      } finally {
      }
    }

    getContests();
    const timerId = setInterval(getContests, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [symbol]);

  return data;
}

export default useOrderlyVolume;
