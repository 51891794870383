import { importImage } from "lib/legacy";
import React from "react";
import styled from "styled-components";
interface Type {
  symbol: string;
  isLong: boolean;
  orderOption: string;
  leverageOption: string;
  type?: string;
  posId?: string;
  img?: string;
}

const TokenTopInfo: React.FC<Type> = ({ symbol, isLong, orderOption, leverageOption, type = "", posId, img }) => {
  return (
    <TagBox className={`${type}`}>
      <Token>
        {img ? (
          <img src={img} style={{ width: 24, height: 24 }} alt="" />
        ) : (
          <img className="token" src={importImage("ic_" + symbol.toLowerCase() + "_24.svg")} alt="" />
        )}

        <div className="title">
          <span>
            {symbol}/USD {posId && <span className="muted">#{posId}</span>}
          </span>
        </div>
      </Token>
      <div className="position-tags-v1">
        <SideTag className={`${isLong ? "long" : "short"}`}>{isLong ? "Long" : "Short"}</SideTag>
        <SideTag>{orderOption}</SideTag>
        {leverageOption && <LeverateTag>{leverageOption + "x" || "--x"}</LeverateTag>}
      </div>
    </TagBox>
  );
};
export default TokenTopInfo;
const Token = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;

  .token {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;
const TagBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  &.small {
    margin-bottom: 16px;
  }
  &.null {
    margin-bottom: 0;
  }
  &.m8 {
    margin-bottom: 8px;
  }

  .position-tags-v1 {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
const SideTag = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #fff;
  text-transform: uppercase;

  background: var(--Chips-Market_Background, rgba(255, 255, 255, 0.1));
  &.long {
    color: #90fb75;
    background: var(--Chips-Long_Background, rgba(126, 229, 92, 0.1));
  }
  &.short {
    color: #f6475d;
    background: var(--Chips-Short_Background, rgba(246, 71, 93, 0.1));
  }
`;
const LeverateTag = styled.div`
  border-radius: 8px;
  background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #ffc700;
`;
