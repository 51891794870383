import React, { useEffect, useMemo, useState } from "react";
import "./OrderBook.scss";
import { Select } from "antd";
import triangle_down from "img/trade/triangle_down.svg";
import { useMarketTradeStream, useOrderbookStream, useSymbolsInfo } from "@orderly.network/hooks";
import { getDecimalsFromTick, getDepthLevelsByMarkPrice } from "lib/legacy";
import { commify } from "@ethersproject/units";
import { limitDecimals } from "lib/numbers";
import { useDepthLevels } from "hooks/useOrderlyDepthLevel";
import Tooltip from "components/Tooltip/Tooltip";
const ORDER_BOOK = "Order Book";
const LAST_TRADE = "Last Trades";
const TABS = [ORDER_BOOK, LAST_TRADE];
const LEVELS = [
  { name: "0.1", value: 0.1 },
  { name: "1", value: 1 },
  { name: "10", value: 10 },
  { name: "100", value: 100 },
];
const DEPTH_LEVELS = {
  0.0001: 1000,
  0.001: 1000,
  0.01: 1000,
  0.1: 1000,
  1: 1000,
  10: 1000,
  100: 1000,
};
const NUM_SIDE_ORDERBOOK = 8;
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};
const getPrecisionByStep = (step) => {
  if (step >= 1) return 2;
  if (step >= 0.1) return 4;
  if (step >= 0.001) return 6;
  return 8;
};
const groupOrderBook = (orders, step) => {
  const grouped = {};
  orders
    ?.filter((x) => !Number.isNaN(x[0]))
    .forEach(([price, qty]) => {
      const groupedPrice = (Math.floor(price / step) * step).toFixed(getPrecisionByStep(step));
      if (!grouped[groupedPrice]) {
        grouped[groupedPrice] = { qty: 0, total: 0 };
      }
      grouped[groupedPrice].qty += qty;
    });

  const groupedArray = Object.entries(grouped)
    .map(([price, { qty }]) => [parseFloat(price), qty])
    .sort((a, b) => a[0] - b[0]);

  let accumulatedTotal = 0;

  const resultWithTotal = groupedArray.map(([price, qty]) => {
    accumulatedTotal += qty;
    return [price, qty, accumulatedTotal];
  });

  return resultWithTotal;
};
const OrderlyOrderBook = ({trades, symbol, chartSymbol, markPrice, setChooseOrderBookPrice, ordersV3 }) => {
  const [view, setView] = useState(ORDER_BOOK);
  const depthLevels = useDepthLevels(markPrice, symbol);
  const [level, setLevel] = React.useState(0.1);
  const [data, { isLoading }] = useOrderbookStream(symbol, undefined, {
    level: DEPTH_LEVELS[`${level}`],
  });
  useEffect(() => {
    if (depthLevels) {
      // const findLevel = depthLevels?.find((x) => x?.value === level);
      if (depthLevels?.[0]?.value !== level) {
        setLevel(depthLevels[0]?.value);
      }
    }
  }, [depthLevels]);
  // const { data: trades } = useMarketTradeStream(symbol, { limit: 30 });
  const decimals = useMemo(() => {
    const stringLevel = level?.toString() || "";
    const decimalIndex = stringLevel?.indexOf(".");
    if (decimalIndex === -1) return 0;
    return stringLevel.length - decimalIndex - 1;
  }, [level]);
  const symbolsInfo = useSymbolsInfo();
  const symbolInfo = symbolsInfo[symbol]();
  const [baseDecimals, quoteDecimals] = getDecimalsFromTick(symbolInfo);
  const baseFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 4 });
  const quoteFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: quoteDecimals });
  const groupedData = useMemo(() => {
    if (!data) return { bids: [], asks: [] };

    return {
      bids: groupOrderBook(data.bids, level).reverse(),
      asks: groupOrderBook(data.asks, level),
    };
  }, [data, level]);

  const asks = useMemo(() => {
    if (groupedData.asks.length >= NUM_SIDE_ORDERBOOK) return groupedData.asks.slice(0, NUM_SIDE_ORDERBOOK);
    else {
      let lossLength = NUM_SIDE_ORDERBOOK - groupedData.asks.length;
      const data = [];
      for (let i = 0; i < lossLength; i++) {
        data.push([0, 0, 0]);
      }
      return [...data, ...groupedData.asks];
    }
  }, [groupedData]);

  const bids = useMemo(() => {
    if (groupedData.bids.length >= NUM_SIDE_ORDERBOOK) return groupedData.bids.slice(0, NUM_SIDE_ORDERBOOK);
    else {
      let lossLength = NUM_SIDE_ORDERBOOK - groupedData.bids.length;
      const data = [];
      for (let i = 0; i < lossLength; i++) {
        data.push([0, 0, 0]);
      }
      return [...groupedData.asks, ...data];
    }
  }, [groupedData]);
  const firstAsk = useMemo(() => {
    const length = asks.length;
    if (length > 0) return asks[length - 1][2];
  }, [asks]);
  const firstBid = useMemo(() => {
    const length = bids.length;
    if (length > 0) return bids[0][2];
  }, [bids]);
  const lastPrice = useMemo(() => {
    if (trades.length > 0) {
      return trades[0].price;
    }
  }, [trades]);
  const spreadRatio = useMemo(() => {
    let bestBid = bids?.[0]?.[0];
    for (let i = 0; i < bids.length; i++) {
      if (bids[i][0] > 0) {
        bestBid = bids[i][0];
        break;
      }
    }
    const bestAsk = asks?.[6]?.[0];
    if (!bestBid || !bestAsk) return 0;

    const midPrice = (bestBid + bestAsk) / 2;
    const spreadRatio = ((bestAsk - bestBid) / midPrice) * 100;

    return spreadRatio.toFixed(4);
  }, [groupedData]);
  const orders = useMemo(() => {
    return ordersV3?.filter((x) => x?.orderId || !x?.algo_type?.includes("TP_SL")) || [];
  }, [ordersV3]);
  // console.log("????", orders, decimals);

  return (
    <div className="orderbook-container">
      <div className="header">
        <div className="tab-header">
          {TABS.map((tab) => (
            <span key={tab} onClick={() => setView(tab)} className={`${tab === view ? "active" : ""}`}>
              {tab}
            </span>
          ))}
        </div>
        <Select
          showArrow
          suffixIcon={<img className="triangle_down" src={triangle_down} alt="" />}
          onChange={(option) => {
            setLevel(option);
          }}
          defaultValue={level}
          placement="bottomRight"
          style={{
            width: "fit-content",
          }}
          value={level}
          options={depthLevels}
          className="transparent-ant-select-selection-item"
        />
      </div>
      <div className="order-header">
        <div className="order-value">{view === ORDER_BOOK ? "Price(USDC)" : "Time"}</div>
        <div className="order-value end-text">{view === ORDER_BOOK ? `Qty(${chartSymbol})` : "Price(USDC)"}</div>
        <div className="order-value end-text">
          {view === ORDER_BOOK ? `Total(${chartSymbol})` : `Qty(${chartSymbol})`}
        </div>
      </div>
      {view === ORDER_BOOK && (
        <div className="orderbook-body">
          <div className="asks">
            {asks
              ?.filter(([price]) => !Number.isNaN(price)).reverse()
              ?.map(([price, quantity, aggregated], index) => {
                const gradient = (100 * aggregated) / firstAsk;
                let findOrder;
                if (price && orders && orders.length > 0) {
                  findOrder = orders.find(
                    (x) =>
                      Number(limitDecimals(x?.price || x?.trigger_price || 0, decimals)) ===
                      Number(limitDecimals(price, decimals)) && x.side === "SELL"
                  );
                }
                return (
                  <div className="orderbook-line" style={{
                    background: `linear-gradient(to right, rgba(228, 62, 83, 0.1) ${gradient}%, transparent ${gradient}%)`,
                  }}>
                    <div
                      className="order-row ask"
                      key={`ask-${index}`}


                      onClick={() => {
                        if (price) setChooseOrderBookPrice(price);
                      }}
                    >
                      <div className="price">{price ? quoteFormatter.format(price) : "-"}</div>
                      <div className="qty">{quantity ? baseFormatter.format(quantity) : "-"}</div>
                      <div
                        className="total"
                      >
                        {aggregated ? baseFormatter.format(aggregated) : "-"}
                      </div>
                      {findOrder && <div className="has-order-book" />}
                    </div>
                  </div>

                );
              })}
          </div>
          <div className="current-price">
            <div className="last-price">{lastPrice ? commify(lastPrice) : "-"}</div>
            <div className="mark-price">
              <Tooltip
                handle={<div className="mark-price">{data?.markPrice ? commify(data?.markPrice) : "-"}</div>}
                position="center-top"
                renderContent={() => {
                  return (
                    <>
                      Obtained from a third-party oracle, the mark price is calculated as the median of three prices:
                      the last price, the fair price based on the funding rate basis, and the fair price based on the
                      order books.
                    </>
                  );
                }}
              ></Tooltip>
            </div>
            <div className="funding-rate">
              <Tooltip
                handle={<div className="funding-rate">{spreadRatio ? spreadRatio : "-"}%</div>}
                position="right-top"
                renderContent={() => {
                  return <>Spread Ratio of the ask1 and bid1.</>;
                }}
              ></Tooltip>
            </div>
          </div>
          <div className="bids">
            {bids
              ?.filter(([price]) => !Number.isNaN(price)).reverse()
              ?.map(([price, quantity, aggregated], index) => {
                const gradient = (100 * aggregated) / firstBid;
                let findOrder;
                if (price && orders && orders.length > 0) {
                  findOrder = orders.find(
                    (x) =>
                      Number(limitDecimals(x?.price || x?.trigger_price || 0, decimals)) === Number(price) &&
                      x.side === "BUY"
                  );
                }
                return (
                  <div className="orderbook-line"
                    style={{
                      background: `linear-gradient(to right, rgba(14, 203, 129, 0.1) ${gradient}%, transparent ${gradient}%)`,
                    }}
                  >
                    <div
                      className="order-row bid"
                      key={`bid-${index}`}
                      onClick={() => {
                        if (price) setChooseOrderBookPrice(price);
                      }}

                    >
                      <div className="price">{quoteFormatter.format(price)}</div>
                      <div className="qty">{baseFormatter.format(quantity)}</div>
                      <div
                        className="total"
                      >
                        {baseFormatter.format(aggregated)}
                      </div>
                      {findOrder && <div className="has-order-book" />}
                    </div>
                  </div>

                );
              })}
          </div>
        </div>
      )}
      {view === LAST_TRADE && (
        <div className="orderbook-body orderbook-body-last-trade">
          <div className="asks">
            {trades?.map(({ price, size, side, ts }, index) => {
              const isLong = side === "BUY";
              return (
                <div className={`order-row`} key={`trades-${index}`}>
                  <div className="timestamp">{formatTimestamp(ts)}</div>
                  <div className={`qty ${isLong ? "buy-trade" : "sell-trade"}`}>{price}</div>
                  <div className={`total ${isLong ? "buy-trade" : "sell-trade"}`}>{size}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderlyOrderBook;
