import { Trans } from "@lingui/macro";
import { FOK, IOC, LEVERAGE_MARKS_ORDERLY, POST_ONLY, STOP_MARKET, decimalsRules } from "lib/legacy";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useMemo, useRef, useState } from "react";
import "./SwapBox.css";

import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import triangle_down from "img/trade/triangle_down.svg";
import triangle_down_blue from "img/blue-triangle.svg";
import { LIMIT, LONG, MARKET, SHORT, STOP_LIMIT, SWAP } from "lib/legacy";
import styled from "styled-components";

import { commify } from "@ethersproject/units";
import { useAccount, useLeverage, useMarketTradeStream, useOrderEntry, useTickerStream } from "@orderly.network/hooks";
import { AccountStatusEnum, OrderSide, OrderType } from "@orderly.network/types";
import { Select } from "antd";
import { IconMinus, IconPlus } from "assets";
import DepositModalOrderly from "components/DepositModalOrderly";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import Tooltip from "components/Tooltip/Tooltip";
import OrderlySelectLeverage from "components/TradeMerged/OrderlySelectLeverage";
import { useThemeContext } from "contexts/ThemeProvider";
import { helperToast } from "lib/helperToast";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { preventSpecialCharacters } from "utils/helpers";
import ConfirmationBoxV3 from "./ConfirmationBoxV3";
import PercentageButtons from "./PercentageButtons";
import ReduceOnlyCard from "./ReduceOnlyCard";
import TPSLCardV3 from "./TPSLCardV3";
import { useMediaQuery } from "react-responsive";
import OrderlyOrderBook from "./OrderlyOrderBook";
import OrderlyOrderBookMobile from "./OrderlyOrderBookMobile";

const TOTAL = "Total";
const QTY = "Quantity";
window.Buffer = window.Buffer || require("buffer").Buffer;

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(1)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
export default function SwapBoxWithOrderly(props) {
  const {
    active,
    library,
    account,
    swapOption,
    setSwapOption,
    chainId,
    savedSlippageAmount,
    defaultChartToken,
    setSavedSlippageAmount,
    mergeList,
    pairInfo,
    aggregated,
    isShowConfirmModal,
    setIsShowConfirmModal,
    chooseOrdeBookPrice,
    setIsConfirming,
    isConfirming,
    ordersV3,
    setChooseOrderBookPrice,
    trades,
  } = props;
  const theme = useThemeContext();
  const token = useMemo(() => {
    return defaultChartToken?.split("/USD")?.[0];
  }, [defaultChartToken]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const accountLeverage = useMemo(() => {
    if (aggregated && aggregated.total_collateral_value && aggregated.notional) {
      return aggregated.notional / aggregated.total_collateral_value;
    }
    return 0;
  }, [aggregated]);
  const orderlySymbol = useMemo(() => {
    if (token) return `PERP_${token}_USDC`;
    return null;
  }, [token]);

  const currentPair = useMemo(() => {
    if (pairInfo && pairInfo?.length > 0 && orderlySymbol) {
      return pairInfo.find((x) => x.symbol === orderlySymbol);
    }
  }, [pairInfo, orderlySymbol]);
  const maxQtyPercision = useMemo(() => {
    if (currentPair) {
      const { base_tick } = currentPair;
      const tickStr = base_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair]);
  const maxPricePercision = useMemo(() => {
    if (currentPair) {
      const { quote_tick } = currentPair;
      const tickStr = quote_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair]);
  const stream = useTickerStream(orderlySymbol);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalChangeMaxLeverageOpen, setMoalChangeMaxLeverageOpen] = useState(false);
  const [isHigherSlippageAllowed] = useState(false);
  const [anchorInput, setAnchorInput] = useLocalStorageSerializeKey("default-size", QTY);
  // const { userReferralCode, userReferralInviter } = useUserReferralCodeV2(chainId, account);

  const chartSymbol = getTokenSymbolFromString(defaultChartToken) || "";
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [isStopLoss, setIsStopLoss] = useState(false);

  const [takeProfitTriggerPrice, setTakeProfitTriggerPrice] = useState("0");
  const [stopLossTriggerPrice, setStopLossTriggerPrice] = useState("0");
  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  const [isShowTakeProfit, setIsShowTakeProfit] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [creating, setCreating] = useState(false);

  const [isTypingFromValue, setIsTypingFromValue] = useState(false);
  const [takeProfitPrice, setTakeProfitPrice] = useState("");
  const [stopLossPrice, setStopLossPrice] = useState("");
  const [tpPnl, setTpPnl] = useState("");
  const [slPnl, setSlPnl] = useState("");

  const [executeType, setExecuteType] = useLocalStorageSerializeKey("orderly-execute-type", "");
  const [redeuceEnable, setReduceEnable] = useLocalStorageSerializeKey("reduce-enable", false);

  // console.log("???", code);
  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "orderly-trade-option"], LIMIT);
  useEffect(() => {
    setIsTypingFromValue(true);
    const timeout = setTimeout(() => {
      setIsTypingFromValue(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [fromValue]);
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const [maxLeverageOrderly, { update }] = useLeverage();
  // console.log("maxLeverageOrderly", {maxLeverageOrderly, leverageLevers});
  const [leverageOption, setLeverageOption] = useState(maxLeverageOrderly || 1);
  const [showMargin, setShowMargin] = useState(false);

  useEffect(() => {
    if (maxLeverageOrderly) {
      setLeverageOption(maxLeverageOrderly);
      setTempLeverageOption(maxLeverageOrderly);
    }
  }, [maxLeverageOrderly]);
  const {
    submit,
    setValue,
    setValues,
    maxQty,
    estLeverage,
    estLiqPrice,
    formattedOrder,
    metaState: { errors },
  } = useOrderEntry(orderlySymbol, {
    initialOrder: {
      side: isLong ? OrderSide.BUY : OrderSide.SELL,
      order_type:
        orderOption === MARKET
          ? OrderType.MARKET
          : orderOption === LIMIT
            ? OrderType.LIMIT
            : orderOption === STOP_MARKET
              ? OrderType.STOP_MARKET
              : OrderType.STOP_LIMIT,
    },
  });
  // console.log("????", formattedOrder);
  const availableQty = useMemo(() => {
    if (maxQty && maxQty.toString().includes("e-")) {
      return 0;
    }
    return maxQty || 0;
  }, [maxQty]);
  useEffect(() => {
    if (chooseOrdeBookPrice) {
      if (orderOption !== MARKET) {
        if (orderOption === LIMIT || orderOption === STOP_MARKET) {
          setTriggerPriceValue(chooseOrdeBookPrice);
          if (orderOption === LIMIT) {
            setValue("order_price", chooseOrdeBookPrice);
          } else setValue("trigger_price", chooseOrdeBookPrice);
        } else {
          setTriggerStopPriceValue(chooseOrdeBookPrice);
          setValue("order_price", chooseOrdeBookPrice);
        }
      }
    }
  }, [chooseOrdeBookPrice]);
  useEffect(() => {
    setFromValue("");
    setValues({
      order_quantity: 0,
      total: 0,
    });
  }, [anchorInput]);
  useEffect(() => {
    switch (orderOption) {
      case MARKET:
        if (executeType) setExecuteType("");
        break;
      case LIMIT:
        break;
      case STOP_MARKET:
        if (executeType) setExecuteType("");
        break;
      case STOP_LIMIT:
        if (executeType) setExecuteType("");
        break;
      default:
        break;
    }

    setFromValue("");
    setTriggerPriceValue("");
    setTriggerStopPriceValue("");
  }, [orderOption]);
  useEffect(() => {
    if (isLong) setValue("side", OrderSide.BUY);
    else setValue("side", OrderSide.SELL);
  }, [isLong]);

  useEffect(() => {
    if (orderOption === MARKET)
      setValues({
        order_quantity: 0,
        total: 0,
        trigger_price: "",
        order_price: "",
        order_type: OrderType.MARKET,
      });
    else if (orderOption === LIMIT)
      setValues({
        order_quantity: 0,
        total: 0,
        trigger_price: "",
        order_price: "",
        order_type: OrderType.LIMIT,
      });
    else if (orderOption === STOP_MARKET)
      setValues({
        order_quantity: 0,
        total: 0,
        trigger_price: "",
        order_price: "",
        order_type: OrderType.STOP_MARKET,
      });
    else
      setValues({
        order_quantity: 0,
        total: 0,
        trigger_price: "",
        order_price: "",
        order_type: OrderType.STOP_LIMIT,
      });
  }, [orderOption]);
  useEffect(() => {
    let type = OrderType.MARKET;
    switch (executeType) {
      case POST_ONLY:
        setValues({
          order_type: OrderType.POST_ONLY,
        });
        return;
      case IOC:
        setValues({
          order_type: OrderType.IOC,
        });
        return;
      case FOK:
        setValues({
          order_type: OrderType.FOK,
        });
        return;
      default:
        if (orderOption === MARKET) type = OrderType.MARKET;
        else if (orderOption === LIMIT) type = OrderType.LIMIT;
        else if (orderOption === STOP_MARKET) type = OrderType.STOP_MARKET;
        else type = OrderType.STOP_LIMIT;
        setValues({
          order_type: type,
        });
        return;
    }
  }, [executeType]);
  useEffect(() => {
    let type = OrderType.MARKET;
    switch (executeType) {
      case POST_ONLY:
        setValues({
          order_type: OrderType.POST_ONLY,
        });
        return;
      case IOC:
        setValues({
          order_type: OrderType.IOC,
        });
        return;
      case FOK:
        setValues({
          order_type: OrderType.FOK,
        });
        return;
      default:
        if (orderOption === MARKET) type = OrderType.MARKET;
        else if (orderOption === LIMIT) type = OrderType.LIMIT;
        else if (orderOption === STOP_MARKET) type = OrderType.STOP_MARKET;
        else type = OrderType.STOP_LIMIT;
        setValues({
          order_type: type,
        });
        return;
    }
  }, []);
  const { account: accountOrderly, state } = useAccount();

  const isMarketOrder = orderOption === MARKET;
  const isLimitOrder = orderOption === LIMIT;
  const isStopLimitOrder = orderOption === STOP_LIMIT;

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [triggerStopPriceValue, setTriggerStopPriceValue] = useState("");

  const onTriggerPriceChange = (evt) => {
    const value = evt.target.value;
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        if (orderOption === LIMIT) {
          setValue("order_price", value);
        } else setValue("trigger_price", value);
        setTriggerPriceValue(evt.target.value || "");
      }
    } else {
      if (orderOption === LIMIT) {
        setValue("order_price", value);
      } else setValue("trigger_price", value);
      setTriggerPriceValue(evt.target.value || "");
    }
  };
  const onTriggerStopPriceChange = (evt) => {
    setTriggerStopPriceValue(evt.target.value || "");
    setValue("order_price", evt.target.value || "");
  };

  const onTriggerRatioChange = (evt) => {
    const value = evt.target.value;
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        setTriggerRatioValue(evt.target.value || "");
        setValue("trigger_price", value);
      }
    } else {
      setTriggerRatioValue(evt.target.value || "");
      setValue("trigger_price", value);
    }
  };

  const isPrimaryEnabled = () => {
    if (!fromValue || !Number(fromValue)) return false;
    if (errors) return false;
    return true;
  };

  const getPrimaryText = () => {
    if (!fromValue || !Number(fromValue)) return "Enter quantity";

    if (errors) {
      if (errors?.total?.type === "min") {
        if (errors?.total?.message?.includes("The order value should be greater or equal to "))
          return "Min order: " + errors?.total?.message?.split("The order value should be greater or equal to ")[1];
      }

      const messages = Object.values(errors)
        .map((item) => item.message)
        .filter((message) => message !== undefined);
      return messages?.[0];
    }

    if (isLong) {
      return `Long`;
    }
    return `Short`;
  };
  useEffect(() => {
    if (!isTypingFromValue) {
      if (orderOption !== MARKET && orderOption !== STOP_MARKET) {
        if (formattedOrder?.total && formattedOrder?.order_quantity && formattedOrder?.order_price) {
          if (anchorInput === TOTAL) {
            if (fromValue != formattedOrder?.total && formattedOrder?.total !== "0") {
              setFromValue(formattedOrder?.total);
            }
          }
        }
      }
    }
  }, [
    triggerPriceValue,
    triggerStopPriceValue,
    formattedOrder?.total,
    formattedOrder?.order_quantity,
    formattedOrder?.order_price,
    formattedOrder?.trigger_price,
    orderOption,
    anchorInput,
    fromValue,
  ]);
  const onFromValueChange = (e) => {
    const value = e.target.value;
    const percision = anchorInput === QTY ? maxQtyPercision : 2;
    if (value.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= percision) {
        setFromValue(value);
        if (anchorInput === QTY) setValue("order_quantity", Number(value || 0));
        else setValue("total", Number(value || 0));
      } else {
        const newValueStr = value.split(".");
        let newValue = newValueStr[0];
        for (let i = 0; i < percision; i++) {
          newValue += newValueStr[1][i];
        }
        setFromValue(newValue);
        if (anchorInput === QTY) {
          setValue("order_quantity", Number(newValue || 0));
        } else {
          if (executeType && value && triggerPriceValue) {
            setValue("total", Number(value || 0));
            setValue("order_quantity", Number(limitDecimals(value / triggerPriceValue, maxQtyPercision) || 0));
          } else setValue("total", Number(value || 0));
        }
      }
    } else {
      setFromValue(value);
      if (anchorInput === QTY) {
        setValue("order_quantity", Number(value || 0));
      } else {
        if (executeType && value && triggerPriceValue) {
          setValue("total", Number(value || 0));
          setValue("order_quantity", Number(limitDecimals(value / triggerPriceValue, maxQtyPercision) || 0));
        } else setValue("total", Number(value || 0));
      }
    }
  };

  const onClickPrimary = () => {
    if (!active) {
      props.connectWallet();
      return;
    }
    if (isShowConfirmModal) setIsConfirming(true);
    else handleCreateOrderlyOrder();
  };
  const handleCreateOrderlyOrder = async () => {
    const textQty = `${formattedOrder ? formattedOrder?.order_quantity : "-"} ${chartSymbol}`;
    try {
      await submit();
      helperToast.success(
        <div>
          Create order {textQty}
          <br />
        </div>
      );
      setFromValue("");
      setTriggerPriceValue("");
      setTriggerStopPriceValue("");
      setTakeProfitPrice("");
      setStopLossPrice("");
      setSlPnl("");
      setTpPnl("");
    } catch (error) {
      helperToast.error(error?.message || "Failed to create order");
    } finally {
      setIsConfirming(false);
    }
  };
  const isStopOrder = orderOption === STOP_MARKET;
  const showFromAndToSection = true;
  const showTriggerPriceSection = !isSwap && !isMarketOrder;
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder;

  const handleSelectPercentage = (percentage) => {
    if (!availableQty || parseValue(availableQty || 0, 30).eq(0)) {
      return;
    }
    if (anchorInput === QTY) {
      const maxColateral = parseValue(availableQty || 0, 30)
        .mul(parseValue(percentage, 30))
        .div(parseValue(1, 30));
      setFromValue(formatAmount(maxColateral, 30, maxQtyPercision, false));
      setValue("order_quantity", Number(formatAmount(maxColateral, 30, maxQtyPercision, false)));
    } else {
      const maxSize = getMaxSize();
      const newSize = parseValue(maxSize || 0, 30)
        .mul(parseValue(percentage, 30))
        .div(parseValue(1, 30));
      setFromValue(formatAmount(newSize, 30, 2, false));
      setValue("total", Number(formatAmount(newSize, 30, 2, false)));
    }
  };
  const isRegistered = state.status >= AccountStatusEnum.SignedIn;
  const hasOrderlyKey = state.status >= AccountStatusEnum.EnableTrading;
  const registerAccount = async () => {
    try {
      setRegistering(true);
      await accountOrderly.createAccount();

      helperToast.success("Registration complete!");
    } catch (error) {
      helperToast.error("Registration failed!");
    } finally {
      setRegistering(false);
    }
  };

  const createKeyAccount = async () => {
    try {
      setCreating(true);
      await accountOrderly.createOrderlyKey(365);

      helperToast.success("Key registration complete!");
    } catch (error) {
      helperToast.error("Key registration failed!");
    } finally {
      setCreating(false);
    }
  };
  const TotalUSD = useMemo(() => {
    if (anchorInput === TOTAL) return fromValue || 0;
    if (orderOption === LIMIT) {
      return limitDecimals((fromValue || 0) * (triggerPriceValue || 0), 2);
    } else if (orderOption === STOP_MARKET || orderOption === LIMIT) {
      return limitDecimals((fromValue || 0) * (triggerPriceValue || 0), 2);
    } else if (orderOption === STOP_LIMIT) return limitDecimals((fromValue || 0) * (triggerStopPriceValue || 0), 2);
    return formattedOrder?.total;
  }, [anchorInput, formattedOrder?.total, fromValue, orderOption, triggerPriceValue, triggerStopPriceValue]);
  const getMaxSize = () => {
    if (orderOption === MARKET || orderOption === STOP_MARKET) {
      return (availableQty || 0) * (stream?.mark_price || 0);
    } else if (orderOption === LIMIT) {
      return (availableQty || 0) * (triggerPriceValue || stream?.mark_price || 0);
    } else {
      return (availableQty || 0) * (triggerStopPriceValue || stream?.mark_price || 0);
    }
  };

  const [tempLeverageOption, setTempLeverageOption] = useState(leverageOption);

  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1 || val > 50) {
      setTempLeverageOption(1);
      return;
    }
    setTempLeverageOption(Number(val));
  };

  const onChangeLeverage = (value) => {
    setTempLeverageOption(value);
  };

  const marginRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (marginRef.current && !marginRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setShowMargin(false);
      }
    };

    if (showMargin) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMargin]);

  const handleToggleMargin = (e) => {
    e.stopPropagation();
    if (!showMargin) {
      setTempLeverageOption(maxLeverageOrderly || 1);
    }
    setShowMargin(!showMargin);
  };
  const openPrice = useMemo(() => {
    if (orderOption === LIMIT) return triggerPriceValue || 0;
    return stream?.mark_price || 0;
  }, [orderOption, triggerPriceValue, stream?.mark_price]);

  return (
    <SwapBoxOrderly className={`Exchange-swap-box ${swapOption === LONG ? "box-trade-long" : "box-trade-short"}`}>
      {isSmallScreen && (
        <OrderlyOrderBookMobile
          symbol={`PERP_${token}_USDC`}
          chartSymbol={token}
          markPrice={stream?.["mark_price"]}
          setChooseOrderBookPrice={setChooseOrderBookPrice}
          ordersV3={ordersV3}
          trades={trades}
          orderOption={orderOption}
          isShowTakeProfit={isShowTakeProfit}
        />
      )}
      <div className="sapbox-orderly">
        <div className="perp-type">
          <div className="group-btn">
            <div className="wrap-btn-group">
              <div className="wrap-btn-skew" style={{ pointerEvents: "none" }}>
                <div className="text-btn" style={{ color: "white" }}>
                  Cross Margin
                </div>
                <div className="clippath -skew-x-15deg" style={{ background: "#2B3139" }} />
              </div>
              <button
                ref={buttonRef}
                onClick={handleToggleMargin}
                disabled={!maxLeverageOrderly}
                className="wrap-btn-skew btn-change-margin"
              >
                <div className="text-btn">
                  {" "}
                  {maxLeverageOrderly || "-"}x <img alt="" src={triangle_down_blue} />
                </div>
                <div
                  style={{
                    backgroundColor: showMargin ? "#45474za" : "#2B3139",
                  }}
                  className="clippath -skew-x-15deg"
                />
              </button>
            </div>{" "}
            {/* <button
            ref={buttonRef}
            style={{
              backgroundColor: showMargin ? "#45474a" : "#1D1F26",
            }}
            class="right btn-change-margin"
            onClick={handleToggleMargin}
            disabled={!maxLeverageOrderly}
          >
            {maxLeverageOrderly || "-"}x <img alt="" src={triangle_down} />
          </button> */}
            {showMargin && (
              <WrapChangeMargin ref={marginRef}>
                <div className="leverage-container-title">Leverage</div>
                <div className="leverage-container">
                  <div className="leverage-input">
                    <div className="leverage-input-container">
                      <button
                        className="leverage-input-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (tempLeverageOption <= 1) return;
                          onChangeLeverage(tempLeverageOption - 1);
                        }}
                        disabled={tempLeverageOption <= 1}
                      >
                        <IconMinus size={12} />
                      </button>
                      <input
                        type="number"
                        min="1"
                        max="50"
                        value={tempLeverageOption}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => onChangeLeverage(e.target.value)}
                        onBlur={handleBlurLeverage}
                      />
                      <button
                        className="leverage-input-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (tempLeverageOption >= 50) return;
                          onChangeLeverage(tempLeverageOption + 1);
                        }}
                        disabled={tempLeverageOption >= 50}
                      >
                        <IconPlus size={12} />
                      </button>
                    </div>
                  </div>
                  <div
                    className="Exchange-leverage-slider App-slider negative normalSlider"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Slider
                      min={1}
                      max={50}
                      step={1}
                      value={tempLeverageOption}
                      onChange={(value) => setTempLeverageOption(value)}
                      handle={leverageSliderHandle}
                      marks={LEVERAGE_MARKS_ORDERLY()}
                    />
                  </div>
                  <button
                    className="default-btn Exchange-swap-button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await update({ leverage: tempLeverageOption });
                      helperToast.success(
                        <div>
                          Updated max leverage
                          <br />
                        </div>
                      );
                      setShowMargin(false);
                    }}
                  >
                    Save
                  </button>
                </div>
              </WrapChangeMargin>
            )}
            {showMargin && <div className="blur-bg" onClick={() => setShowMargin(false)} />}
          </div>
        </div>
        <div className="swap-box-v2">
          <div className="tab-exchange-container">
            <div
              className={`tab-content tab-swap-option ${swapOption === LONG ? "tab-long-active" : ""}`}
              onClick={() => setSwapOption(LONG)}
            >
              LONG
            </div>
            <div
              className={`tab-content tab-swap-option tab-swap-option-short ${swapOption === SHORT ? "tab-short-active" : ""
                }`}
              onClick={() => setSwapOption(SHORT)}
            >
              SHORT
            </div>
            <div className={`tab-content tab-option ${isLong ? "tab-option-long" : "tab-option-short"}`}>
              <Select
                showArrow
                suffixIcon={<img className="triangle_down" src={triangle_down} alt="" />}
                onChange={(option) => {
                  onOrderOptionChange(option);
                }}
                defaultValue={orderOption}
                placement="bottomRight"
                style={{
                  width: "fit-content",
                }}
                options={[
                  {
                    value: LIMIT,
                    label: LIMIT,
                  },
                  {
                    value: MARKET,
                    label: MARKET,
                  },
                  {
                    value: STOP_MARKET,
                    label: STOP_MARKET,
                  },
                  {
                    value: STOP_LIMIT,
                    label: STOP_LIMIT,
                  },
                ]}
                className="transparent-ant-select-selection-item"
              />
            </div>
          </div>
        </div>
        <div
          className={`Exchange-swap-box-inner App-box-highlight box-trade-v2
        `}
        >
          <div className="align-right w-100" style={{ marginTop: "12px", marginBottom: "12px" }}>
            <span style={{ fontSize: "12px" }}>
              <span className="muted">Available:</span>{" "}
              {anchorInput === QTY
                ? formatAmount(parseValue(availableQty || 0, 30), 30, maxQtyPercision, true)
                : commify(limitDecimals(getMaxSize(), 2))}{" "}
              {anchorInput === QTY ? chartSymbol : "USDC"}
            </span>
          </div>
          {showFromAndToSection && (
            <>
              {showTriggerRatioSection && (
                <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                  <div className="Exchange-swap-section-top">
                    <div className="muted">
                      <span>Price</span>
                    </div>
                  </div>
                  <div className="Exchange-swap-section-bottom">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.0"
                        className="Exchange-swap-input small"
                        value={triggerRatioValue}
                        onChange={onTriggerRatioChange}
                        onKeyDown={preventSpecialCharacters}
                      />
                    </div>
                  </div>
                </div>
              )}
              {showTriggerPriceSection && (
                <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                  {/* <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{orderOption === LIMIT ? "Limit Price" : "Stop Price"}</span>
                  </div>
                  <div className="muted align-right clickable" onClick={() => {}}>
                    <span>Mark: ${usdFormatter.format(stream?.mark_price)}</span>
                  </div>
                </div> */}
                  <div className="Exchange-swap-section-bottom">
                    <div className="input-label">{orderOption === LIMIT ? "Limit Price" : "Stop Price"}</div>
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.00"
                        className="Exchange-swap-input"
                        value={triggerPriceValue}
                        onChange={onTriggerPriceChange}
                        onKeyDown={preventSpecialCharacters}
                      />
                    </div>
                    <div className="PositionEditor-token-symbol">USD</div>
                  </div>
                </div>
              )}
              {orderOption === STOP_LIMIT && (
                <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                  {/* <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{"Limit Price"}</span>
                  </div>
                  <div
                    className="muted align-right clickable"
                    onClick={() => {
                      setTriggerStopPriceValue(
                        formatAmountFree(0, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken])
                      );
                    }}
                  >
                    <span>Mark: ${usdFormatter.format(stream?.mark_price)}</span>
                  </div>
                </div> */}
                  <div className="Exchange-swap-section-bottom">
                    <div className="input-label">Limit Price</div>
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.0"
                        className="Exchange-swap-input"
                        value={triggerStopPriceValue}
                        onChange={onTriggerStopPriceChange}
                        onKeyDown={preventSpecialCharacters}
                      />
                    </div>
                    <div className="PositionEditor-token-symbol">USD</div>
                  </div>
                </div>
              )}

              <div className="Exchange-swap-section exchange-section-first" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-bottom">
                  <div className="input-label">Size</div>
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={fromValue}
                      onChange={onFromValueChange}
                    />
                  </div>
                  <div>
                    <Select
                      showArrow
                      suffixIcon={<img className="triangle_down" src={triangle_down} alt="" />}
                      onChange={(option) => {
                        setAnchorInput(option === "USDC" ? TOTAL : QTY);
                      }}
                      // defaultValue={chartSymbol}
                      placement="bottomRight"
                      style={{
                        width: "fit-content",
                      }}
                      value={anchorInput === QTY ? chartSymbol : "USDC"}
                      options={[
                        {
                          value: chartSymbol,
                          label: chartSymbol,
                        },
                        {
                          value: "USDC",
                          label: "USDC",
                        },
                      ]}
                      className="transparent-ant-select-selection-item"
                    />
                  </div>
                </div>
              </div>

              <PercentageButtons
                onChangePercentage={handleSelectPercentage}
                balance={
                  Number(
                    formatAmount(
                      parseValue(anchorInput === QTY ? availableQty || 0 : getMaxSize() || 0, 30),
                      30,
                      anchorInput === QTY ? maxQtyPercision || 2 : 2,
                      false
                    )
                  ) || 0
                }
                value={Number(fromValue || 0)}
                decimals={6}
                fullBalance={parseValue(anchorInput === QTY ? availableQty || 0 : getMaxSize() || 0, 10)}
                isUsd={true}
              />
              {/* <div className="divider" /> */}
              <div className="exchange-info-container">
                {/* <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Pos. Size</Trans>
                </div>
                <div className="bold">{"-"}</div>
              </div> */}
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label fz-12">
                    <Trans>Pos. Qty</Trans>
                  </div>
                  <div className="bold fz-12">
                    {formattedOrder?.order_quantity || "-"} {chartSymbol}
                  </div>
                </div>

                <div className="Exchange-info-row">
                  <div className="Exchange-info-label fz-12">
                    <Trans>Liq. Price</Trans>
                  </div>
                  <div className="bold fz-12">
                    {estLiqPrice
                      ? `${formatAmount(parseValue(estLiqPrice, 30), 30, decimalsRules(estLiqPrice), true)}`
                      : "-"}
                  </div>
                </div>
                <div className="Exchange-info-row" style={{ width: "100%" }}>
                  <div className="Exchange-info-label">
                    <Tooltip
                      className="tooltip-s fz-12"
                      renderContent={() => {
                        return <div className="tooltip-content">Your actual Leverage of the whole account</div>;
                      }}
                      popupStyled={{ width: "100px" }}
                      position={"left-bottom"}
                      handle="Account Leverage"
                    />
                  </div>
                  <div className="bold fz-12" style={{ display: "flex", alignItems: "center" }}>
                    {accountLeverage &&
                      estLeverage &&
                      Number(limitDecimals(accountLeverage, 2)) !== Number(limitDecimals(estLeverage, 2)) ? (
                      <>
                        <span style={{ color: "#828899" }}>{limitDecimals(accountLeverage, 2)}x</span>
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        <div>
                          <span style={{ marginLeft: "4px" }}>{limitDecimals(estLeverage, 2)}x</span>
                        </div>{" "}
                      </>
                    ) : (
                      <span>{accountLeverage ? limitDecimals(accountLeverage, 2) : "0.00"}x</span>
                    )}
                  </div>
                </div>
              </div>
              {/* {orderOption === LIMIT && <div className="divider" />} */}
            </>
          )}
          {/* <div className="Exchange-leverage-box Exchange-leverage-box-orderly">
          <div className="max-account-levrage-container">
            <div className="bold">
              {" "}
              <Tooltip
                className="tooltip-s"
                renderContent={() => {
                  return <div className="tooltip-content"> Your max Leverage of the whole account</div>;
                }}
                popupStyled={{ width: "100px" }}
                position={"left-bottom"}
                handle="Max Account Leverage"
              />{" "}
            </div>
            <div className="leverage-pen positive bold">
              <span>
                {accountLeverage ? limitDecimals(accountLeverage, 2) + "x" : "-"} / {maxLeverageOrderly}x
              </span>
              <button
                className="PositionDropdown-dots-icon"
                style={{ position: "relative", top: "2px", width: 20, height: 20, borderRadius: 20, marginLeft: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setMoalChangeMaxLeverageOpen(true);
                }}
              >
                <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
              </button>
            </div>
          </div>
        </div> */}
          {[MARKET, LIMIT].includes(orderOption) && <div className="divider-dash" />}
          {[MARKET, LIMIT].includes(orderOption) && (
            <TPSLCardV3
              show={isShowTakeProfit}
              setShow={setIsShowTakeProfit}
              maxPricePercision={maxPricePercision}
              maxQtyPercision={maxQtyPercision}
              setValue={setValue}
              formattedOrder={formattedOrder}
              takeProfitPrice={takeProfitPrice}
              setTakeProfitPrice={setTakeProfitPrice}
              stopLossPrice={stopLossPrice}
              setStopLossPrice={setStopLossPrice}
              setValues={setValues}
              executeType={executeType}
              openPrice={openPrice}
              isLong={isLong}
              tpPnl={tpPnl}
              setTpPnl={setTpPnl}
              slPnl={slPnl}
              setSlPnl={setSlPnl}
            />
          )}

          <div className="divider-dash" />
          <ReduceOnlyCard
            setValue={setValue}
            isConfirmModal={isShowConfirmModal}
            setIsConfirmModal={setIsShowConfirmModal}
            executeType={executeType}
            setExecuteType={setExecuteType}
            redeuceEnable={redeuceEnable}
            setReduceEnable={setReduceEnable}
            orderOption={orderOption}
            isShowTPSL={isShowTakeProfit}
          />
          <div className="divider-dash" />
          <div className="Exchange-swap-button-container">
            {isRegistered && hasOrderlyKey && Number(maxQty) > 0 && (
              <button
                className={`App-cta Exchange-swap-button`}
                onClick={onClickPrimary}
                disabled={!isPrimaryEnabled()}
              >
                {getPrimaryText()}
              </button>
            )}
            {isRegistered && hasOrderlyKey && !(Number(maxQty) > 0) && (
              <button
                className={`App-cta Exchange-swap-button`}
                onClick={() => setIsDepositModalVisible(true)}
              >
                Deposit Funds
              </button>
            )}
            {account && !isRegistered && (
              <button className={`App-cta Exchange-swap-button`} onClick={registerAccount} disabled={registering}>
                {registering ? "Registering..." : "Register"}
              </button>
            )}
            {account && isRegistered && !hasOrderlyKey && (
              <button className={`App-cta Exchange-swap-button`} onClick={createKeyAccount} disabled={creating}>
                {creating ? "Enabling..." : "Enable Trading"}
              </button>
            )}
            {!account && (
              <button
                className={`App-cta Exchange-swap-button`}
                onClick={onClickPrimary}
              // disabled={!isPrimaryEnabled()}
              >
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      </div>

      {isConfirming && (
        <ConfirmationBoxV3
          library={library}
          isLong={isLong}
          collateral={fromValue}
          orderOption={orderOption}
          posSize={BigNumber.from(1)}
          posQty={formattedOrder?.order_quantity || "-"}
          markPrice={BigNumber.from(1)}
          openPosFee={BigNumber.from(1)}
          liqPrice={estLiqPrice}
          feeDiscount={BigNumber.from(1)}
          onConfirmationClick={handleCreateOrderlyOrder}
          spread={BigNumber.from(1)}
          setIsConfirming={setIsConfirming}
          chainId={chainId}
          leverage={maxLeverageOrderly}
          isMarketOrder={isMarketOrder}
          isSubmitting={isSubmitting}
          isTakeProfit={isTakeProfit}
          isStopLoss={isStopLoss}
          takeProfitTriggerPrice={takeProfitTriggerPrice}
          stopLossTriggerPrice={stopLossTriggerPrice}
          savedSlippageAmount={savedSlippageAmount}
          setSavedSlippageAmount={setSavedSlippageAmount}
          chartSymbol={chartSymbol}
          isLimitOrder={isLimitOrder}
          limitPrice={isStopOrder || isStopLimitOrder ? triggerStopPriceValue : triggerPriceValue}
          isStopOrder={isStopOrder}
          stopPrice={triggerPriceValue}
          isStopLimitOrder={isStopLimitOrder}
          totalUSD={TotalUSD || ""}
          takeProfitPrice={takeProfitPrice}
          stopLossPrice={stopLossPrice}
        />
      )}

      {isDepositModalVisible && (
        <DepositModalOrderly isVisible={isDepositModalVisible} setIsVisible={setIsDepositModalVisible} />
      )}
      {modalChangeMaxLeverageOpen && (
        <OrderlySelectLeverage
          isVisible={modalChangeMaxLeverageOpen}
          setIsVisible={setMoalChangeMaxLeverageOpen}
          updateMaxLeverage={update}
          currentMaxLeverage={maxLeverageOrderly}
          chainId={chainId}
        />
      )}
    </SwapBoxOrderly>
  );
}

const SwapBoxOrderly = styled.div`
  position: relative;

  .blur-bg {
    @media screen and (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(2px);
      z-index: 998;
    }
  }

  .btn-change-margin {
    outline: none;
    border: none;
    background-color: transparent;
    background: transparent;
  }

  .group-btn {
    height: 32px;
    border-radius: 5px;
    overflow: hidden;
  }

  .wrap-btn-group {
    gap: 5px;
    margin: 0 -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .-skew-x-15deg {
    transform: translate(0) translateY(0) translateZ(0) rotate(0) rotateX(0) rotateY(0) rotate(0) skew(-15deg) skewY(0)
      scaleX(1) scaleY(1) scaleZ(1);
  }

  .wrap-btn-skew {
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
      /* color: #1eb2fc; */

      /* 12/Bold */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 16.8px */
      .clippath {
        background-color: #3A424D !important;
      }
      /* img {
        filter: brightness(0) saturate(100%) invert(38%) sepia(60%) saturate(1094%) hue-rotate(187deg) brightness(88%)
          contrast(100%);
      } */
    }
  }

  .clippath {
    position: absolute;
    inset: 0;
    background-color: #1d1f26;
    border-radius: 5px;
  }

  .text-btn {
    color: Text/Text_Primary;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    position: relative;
    z-index: 50;
    color: #1eb2fc;
  }

  .Exchange-swap-section-inputs {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 8px;

    .Exchange-swap-section-top {
      padding-bottom: 12px;
    }

    .Exchange-swap-section-top-label {
      color: var(--Text-Text_Secondary, #828899);
      font-size: 14px;
      line-height: 140%; /* 19.6px */
    }

    .Exchange-swap-section-bottom {
      padding: 0;
    }

    .token-section {
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .Exchange-leverage-box-orderly {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;

    & > .bold {
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }

    .Exchange-leverage-stats {
      width: 100%;
      display: flex;
      align-items: stretch;
    }

    .Exchange-leverage-stat {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;

      color: var(--Text-Text_Secondary, #828899);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:last-child {
        text-align: right;
      }

      span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
      }
    }
  }

  .maxQties {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    text-align: left;
  }
`;

const WrapChangeMargin = styled.div`
  position: absolute;
  top: 53px;
  right: 12px;
  width: 297px;
  padding: 12px 18px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: #1d1f26;
  z-index: 100;

  @media screen and (max-width: 767px) {
    position: fixed;
    /* top: 0; */
    right: 0;
    width: 100%;

    left: 0;
    height: fit-content;
    bottom: 0;
    top: unset;
    border-radius: 0;
    z-index: 999;
  }

  .leverage-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .leverage-input {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      flex: 1;
      color: white;
      text-align: center;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 600;
    }

    span {
      color: white;
    }
  }

  .leverage-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #323543;
  }

  .leverage-input-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #181a20;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
    outline: none;
    border: none;
    &:hover:not(:disabled) {
      background-color: rgb(10, 10, 10);
      color: #206ce5;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .leverage-container-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
`;
