import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backendV2";
import { useChainId } from "lib/chains";
import { ACTIVE_CHAIN_IDS, ACTIVE_CHAIN_IDS_V2, arrayURLFetcher, getStatsInfoV2, getStatsInfoV3 } from "lib/legacy";
import { BASE, BERACHAIN_BARTIO, FANTOM, FANTOM_TESTNET, OP, OP_TESTNET } from "config/chains";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  // const { data: dataStatsAll } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //     refreshInterval: 10000,
  //   },
  // );
  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrl(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  // const { ymyPrice } = useYmyPrice(chainId, {}, active);
  // const { ymyPriceFromFantom, ymyPriceFromOP, ymyPriceFromArbitrum } = useYmyPrice(
  //   chainId,
  //   { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
  //   active
  // );
  const currentStats = getStatsInfoV3(dataStatsAll);
  // console.log("?????", currentStats);
  // const whitelistedTokens = getWhitelistedTokens(chainId);
  // const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  // const ylpManagerAddress = getContract(chainId, "YlpManager");
  // const ylpManagerAddressFANTOM = getContract(FANTOM, "YlpManager");
  // const ylpManagerAddressOP = getContract(OP, "YlpManager");
  // const ylpManagerAddressARB = getContract(ARBITRUM, "YlpManager");

  // const readerAddress = getContract(chainId, "Reader");
  // const ymyAddress = getContract(chainId, "YMY");
  // const ylpAddress = getContract(chainId, "YLP");
  // const usdyAddress = getContract(chainId, "USDY");
  // const ylpVesterAddress = getContract(chainId, "YlpVester");
  // const ymyVesterAddress = getContract(chainId, "YmyVester");
  // const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums`, chainId, ylpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  // });

  // const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  // let adjustedUsdySupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdyAmount) {
  //     adjustedUsdySupply = adjustedUsdySupply.add(tokenInfo.usdyAmount);
  //   }
  // }

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: ymySupplyOfYmyVester } = useSWR(
  //   [`Dashboard:ymySupplyOfYmyVester:${active}`, chainId, ymyAddress, "balanceOf", ymyVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: ymySupplyOfYlpVester } = useSWR(
  //   [`Dashboard:ymySupplyOfYlpVester:${active}`, chainId, ymyAddress, "balanceOf", ylpVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const {
  //   total: totalYmyStaked,
  //   fantom: fantomYmyStaked,
  //   fantomlegacy: fantomYmyStakedLegacy,
  //   op: opYmyStaked,
  //   oplegacy: opYmyStakedLegacy,
  //   arbitrum: arbitrumStakedYmy,
  // } = useTotalYmyStaked();
  // let currentChainYmyStaked = fantomYmyStaked;
  // if (chainId === OP) currentChainYmyStaked = opYmyStaked;
  // if (chainId === ARBITRUM) currentChainYmyStaked = arbitrumStakedYmy;

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, ylpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, ylpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 5000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR([`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, ylpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 5000,
  // });

  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumFANTOMlegacy;
  // if (aumsFANTOMlegacy && aumsFANTOMlegacy.length > 0) {
  //   aumFANTOMlegacy = aumsFANTOMlegacy[0].add(aumsFANTOMlegacy[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let ylpPrice;
  // let ylpSupply;
  // let ylpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   ylpSupply = totalSupplies[3];
  //   if (ymySupplyOfYmyVester && ymySupplyOfYlpVester) {
  //     ylpSupply = ylpSupply.sub(ymySupplyOfYmyVester).sub(ymySupplyOfYlpVester);
  //   }

  //   ylpPrice =
  //     aum && aum.gt(0) && ylpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, YLP_DECIMALS)).div(ylpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   ylpMarketCap = ylpPrice.mul(ylpSupply).div(expandDecimals(1, YLP_DECIMALS));
  // }

  // let tvl;
  // if (currentChainYmyStaked && aum && ymyPrice) {
  //   tvl = ymyPrice.mul(currentChainYmyStaked).div(expandDecimals(1, YMY_DECIMALS)).add(aum);
  // }

  // let fantomTvl;
  // if (fantomYmyStaked && aumFANTOM && ymyPriceFromFantom) {
  //   fantomTvl = ymyPriceFromFantom.mul(fantomYmyStaked).div(expandDecimals(1, YMY_DECIMALS)).add(aumFANTOM);
  // }

  // let fantomLegacyTvl;
  // if (fantomYmyStakedLegacy && aumFANTOMlegacy && ymyPriceFromFantom) {
  //   fantomLegacyTvl = ymyPriceFromFantom.mul(fantomYmyStakedLegacy).div(expandDecimals(1, YMY_DECIMALS)).add(aumFANTOMlegacy);
  // }
  // let opTvl;
  // if (opYmyStaked && opYmyStakedLegacy && aumOP && ymyPriceFromOP) {
  //   opTvl = ymyPriceFromOP.mul(opYmyStaked.add(opYmyStakedLegacy)).div(expandDecimals(1, YMY_DECIMALS)).add(aumOP);
  // }

  // let arbTvl;
  // if (arbitrumStakedYmy && aumArb && ymyPriceFromArbitrum) {
  //   arbTvl = ymyPriceFromArbitrum.mul(arbitrumStakedYmy).div(expandDecimals(1, YMY_DECIMALS)).add(aumArb);
  // }
  // let totalTvl;
  // if (ylpMarketCap && ymyPrice && totalYmyStaked && aumOP && aumFANTOM && aumArb && fantomTvl && opTvl && arbTvl && fantomLegacyTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(fantomLegacyTvl)
  // }

  const valueProvider = useMemo(() => {
    return {
      // totalVolume: {
      //   total: currentStats.totalVolume,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalVolume,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalVolume,
      // },
      // total24hVolume: {
      //   total: currentStats.totalFees,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalFees,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalFees,
      // },
      // openInterest: {
      //   total: currentStats.longOpenInterest,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.longOpenInterest,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.longOpenInterest,
      // },
      trades: {
        total: currentStats?.trades,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.trades,
      },
      trades24H: {
        total: currentStats.trades24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.trades24H,
      },
      volume: {
        total: currentStats?.volume,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.volume,
      },
      volume24H: {
        total: currentStats.volume24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.volume24H,
      },
      fees: {
        total: currentStats.fees,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.fees,
      },
      fees24H: {
        total: currentStats.fees24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.fees24H,
      },
      realizedPnl24H: {
        total: currentStats.realizedPnl24H,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.realizedPnl24H,
      },
      openInterest: {
        total: currentStats?.openInterest,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.openInterest,
      },
      yslpPrice: {
        total: currentStats?.yslpPrice,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.yslpPrice,
      },
      yslpTvl: {
        total: currentStats?.yslpTvl,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.yslpTvl,
      },
      yslpStaked: {
        total: currentStats?.yslpStaked,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.yslpStaked,
      },
      totalUsers: {
        total: currentStats?.users,
        [BERACHAIN_BARTIO]: currentStats?.[BERACHAIN_BARTIO]?.data?.users,
      },
    };
  }, [currentStats]);
  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
