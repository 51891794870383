import pie_chart_ic from "img/earn/piechart.svg";
import Tooltip from "components/Tooltip/Tooltip";

import ylp_ic from "img/ylp.svg";
import yslp_ic from "img/yslp.svg";
import Countdown from "react-countdown";
import useVaultYmyContract from "hooks/contracts/useVaultYmyContract";

import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import { PoolCompositionYslpModal } from "pages/Earn/PoolCompositionYslpModal";
import { PoolCompositionYlpModal } from "pages/Earn/PoolCompositionYlpModal";
import { formatAddKeysAmount, formatKeyAmount, formatNumber, formatAmount } from "lib/numbers";
import {
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  YLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  YLP_COOLDOWN_DURATION,
  YSLP_COOLDOWN_DURATION,
} from "lib/legacy";
import { FANTOM, getConstant } from "config/chains";
import { useYmyPrice } from "domain/legacy";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";

import YlpManager from "abis/YlpManager.json";
import VaultYslp from "abis/VaultYslp.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import { ethers } from "ethers";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { EarnFrame } from "pages/Earn/styles";
import VestSection from "pages/Earn/VestSection";
import { useInfoTokens } from "domain/tokens";
import { getTokens } from "config/tokens";
import StakeSection from "pages/Earn/StakeSection";

const { AddressZero } = ethers.constants;

const Earn = ({ setPendingTxns }) => {
  const [isModalCompositionYslpOpen, setIsModalCompositionYslpOpen] = useState(false);
  const [isModalCompositionYlpOpen, setIsModalCompositionYlpOpen] = useState(false);

  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const { ymyPrice } = useYmyPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const ymyAddress = getContract(chainId, "YMY");
  const yslpAddress = getContract(chainId, "YSLP");
  const esYmyAddress = getContract(chainId, "ES_YMY");
  const bnYmyAddress = getContract(chainId, "BN_YMY");
  const ylpAddress = getContract(chainId, "YLP");
  const usdyAddress = getContract(chainId, "USDY");

  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");
  const bonusYmyTrackerAddress = getContract(chainId, "BonusYmyTracker");
  const feeYmyTrackerAddress = getContract(chainId, "FeeYmyTracker");

  const stakedYlpTrackerAddress = getContract(chainId, "StakedYlpTracker");
  const feeYlpTrackerAddress = getContract(chainId, "FeeYlpTracker");

  const stakedYslpTrackerAddress = getContract(chainId, "StakedYslpTracker");
  const feeYslpTrackerAddress = getContract(chainId, "FeeYslpTracker");

  const ylpManagerAddress = getContract(chainId, "YlpManager");
  const yslpManagerAddress = getContract(chainId, "VaultYslp");

  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const ylpVesterAddress = getContract(chainId, "YlpVester");
  const yslpVesterAddress = getContract(chainId, "YslpVester");

  const vesterAddresses = [ymyVesterAddress, ylpVesterAddress, yslpVesterAddress];

  const walletTokens = [ymyAddress, esYmyAddress, ylpAddress, stakedYmyTrackerAddress, yslpAddress];
  const depositTokens = [
    ymyAddress,
    esYmyAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    bnYmyAddress,
    ylpAddress,
    yslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedYmyTrackerAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYlpTrackerAddress,
    feeYslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    stakedYlpTrackerAddress,
    feeYlpTrackerAddress,
    stakedYslpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedYmySupply } = useSWR(
    [
      `StakeV2:stakedYmySupply:${active}:${stakedYmyTrackerAddress}`,
      chainId,
      ymyAddress,
      "balanceOf",
      stakedYmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress, yslpAddress];

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [`YlpSwap:lastPurchaseTime:${active}`, chainId, ylpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, YlpManager),
    }
  );

  const { data: lastPurchaseTimeYSLP } = useSWR(
    [
      `YlpSwap:lastPurchaseTimeYSLP:${active}`,
      chainId,
      yslpManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultYslp),
    }
  );

  const { data: tokenBalances } = useSWR(
    [`YlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(YLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  const redemptionTimeYSLP = lastPurchaseTimeYSLP ? lastPurchaseTimeYSLP.add(YSLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindowYSLP = redemptionTimeYSLP && parseInt(Date.now() / 1000) < redemptionTimeYSLP;

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let ymySupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const { yslpPrice } = useVaultYmyContract(chainId);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedYmySupply,
    ymyPrice,
    ymySupply,
    yslpPrice
  );

  let estYLPRewardsPerWeek = 0;
  if (processedData && processedData.ylpAprTotal && processedData.ylpBalanceUsd) {
    estYLPRewardsPerWeek = processedData.ylpAprTotal.mul(processedData.ylpBalanceUsd).div(365).mul(7).div(100);
  }
  let estYSLPRewardsPerWeek = 0;
  if (processedData && processedData.yslpAprTotal && processedData.yslpBalanceUsd) {
    estYSLPRewardsPerWeek = processedData.yslpAprTotal.mul(processedData.yslpBalanceUsd).div(365).mul(7).div(100);
  }

  let totalRewardTokens;
  if (processedData && processedData.bnYmyInFeeYmy && processedData.bonusYmyInFeeYmy) {
    totalRewardTokens = processedData.bnYmyInFeeYmy.add(processedData.bonusYmyInFeeYmy);
  }
  const countdownRenderer = (countdownProps) => {
    const {
      formatted: { hours, minutes, seconds },
    } = countdownProps;
    return <>{`${hours}:${minutes}:${seconds}`}</>;
  };

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);

  return (
    <EarnFrame>
      <StakeSection
        processedData={processedData}
        ymyPrice={ymyPrice}
        vestingData={vestingData}
        setPendingTxns={setPendingTxns}
        totalRewardTokens={totalRewardTokens}
      />

      <section className="Earn-Section">
        <h1 className="Section-Title">Pools</h1>
        <div className="Section-DoublePaper">
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={ylp_ic} alt="ylp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Majors pool - YLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionYlpOpen(true)} />
                </h3>
                <p>Index by crypto assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/ylp">
                Get YLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.ylpAprTotal ? (
                    <Tooltip
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "ylpAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(
                                formatKeyAmount(processedData, "ylpAprForNativeToken", 2, 2),
                                2
                              )}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed YMY APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "ylpAprForEsYmy", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total staked
                  <div>
                    {processedData.ylpSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "ylpSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "ylpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet balance
                  <div>
                    {processedData.ylpBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "ylpBalance", YLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "ylpBalanceUsd", USD_DECIMALS, YLP_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Deposited</span>
                  {processedData.ylpBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "ylpBalance", YLP_DECIMALS, 2, true)} YSLP</h5>
                      <span>${formatNumber(formatKeyAmount(processedData, "ylpBalanceUsd", USD_DECIMALS, 2))}</span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeYlpTrackerRewardsUsd",
                        "stakedYlpTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeYlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeYlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${wrappedTokenSymbol}, `}
                    {processedData.stakedYlpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedYlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esYMY`}
                  </span>
                </div>
              </div>
              <Link
                className={`border-btn full ${inCooldownWindow ? "disabled" : ""}`}
                to={inCooldownWindow ? "#" : "/earn/ylp"}
              >
                {inCooldownWindow ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown period"
                  renderContent={() => {
                    return "There is a 10-minute cooldown period after each stake.";
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTime}
                    date={new Date(Number(redemptionTime) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
          <div className="Section-Paper">
            <div className="Section-PaperHead">
              <img src={yslp_ic} alt="yslp_ic" />
              <div className="Section-PaperHeadDetails">
                <h3>
                  Stable pool - YSLP
                  <img src={pie_chart_ic} alt="pie_chart_ic" onClick={() => setIsModalCompositionYslpOpen(true)} />
                </h3>
                <p>Index by stable assets</p>
              </div>
              <Link className="Section-PaperHeadAction border-btn" to="/earn/yslp">
                Get YSLP
              </Link>
            </div>
            <div className="divider" />
            <div className="Section-PaperBody">
              <div className="Section-PaperBodyTop">
                <div className="Section-PaperBodyTopItem">
                  APR
                  {processedData.yslpAprTotal ? (
                    <Tooltip
                      className="tooltip-apr"
                      handle={
                        <h5 className="positive">
                          {formatNumber(formatKeyAmount(processedData, "yslpAprTotal", 2, 2), 2)}%
                        </h5>
                      }
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                              value={`${formatNumber(formatKeyAmount(processedData, "yslpAprForNativeToken", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label="Escrowed YMY APR"
                              value={`${formatNumber(formatKeyAmount(processedData, "yslpAprForEsYmy", 2, 2), 2)}%`}
                              showDollar={false}
                            />
                            <div style={{ marginTop: "8px", fontWeight: 400 }}>
                              <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                            </div>
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                  )}
                </div>
                <div className="Section-PaperBodyTopItem">
                  Total staked
                  <div>
                    {processedData.yslpSupply ? (
                      <>
                        <span>{formatKeyAmount(processedData, "yslpSupply", 18, 2, true)}</span> $
                        {formatNumber(formatKeyAmount(processedData, "yslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-PaperBodyTopItem">
                  Wallet balance
                  <div>
                    {processedData.yslpBalance ? (
                      <>
                        <span>{formatKeyAmount(processedData, "yslpBalance", YLP_DECIMALS, 2, true)}</span>$
                        {formatNumber(formatKeyAmount(processedData, "yslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottom">
                <div className="Section-PaperBodyBottomItem">
                  <span>Deposited</span>
                  {processedData.yslpBalance ? (
                    <>
                      <h5>{formatKeyAmount(processedData, "yslpBalance", YLP_DECIMALS, 2, true)} YLP</h5>
                      <span>
                        ${formatNumber(formatKeyAmount(processedData, "yslpBalanceUsd", USD_DECIMALS, USD_DECIMALS), 2)}
                      </span>
                    </>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="Section-PaperBodyBottomItem">
                  <span>Rewards</span>
                  <h5>
                    $
                    {formatNumber(
                      formatAddKeysAmount(
                        processedData,
                        "feeYslpTrackerRewardsUsd",
                        "stakedYslpTrackerRewardsUsd",
                        USD_DECIMALS,
                        USD_DECIMALS
                      )
                    )}
                  </h5>
                  <span>
                    {processedData?.feeYslpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "feeYslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} ${wrappedTokenSymbol}, `}
                    {processedData.stakedYslpTrackerRewards &&
                      `$${formatNumber(
                        formatKeyAmount(processedData, "stakedYslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                      )} esYMY`}
                  </span>
                </div>
              </div>

              <Link
                className={`border-btn full ${inCooldownWindowYSLP ? "disabled" : ""}`}
                to={inCooldownWindowYSLP ? "#" : "/earn/yslp"}
              >
                {inCooldownWindowYSLP ? "Cooldown to unstake" : "Unstake"}
              </Link>
              <div className="Section-PaperBodyBottomItemRows">
                <Tooltip
                  handle="Cooldown period"
                  renderContent={() => {
                    return "There is a 10-minute cooldown period after each stake.";
                  }}
                />
                <h5>
                  <Countdown
                    key={redemptionTimeYSLP}
                    date={new Date(Number(redemptionTimeYSLP) * 1000)}
                    renderer={countdownRenderer}
                    daysInHours
                  />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <VestSection
        vestingData={vestingData}
        totalRewardTokens={totalRewardTokens}
        processedData={processedData}
        setPendingTxns={setPendingTxns}
      />
      <PoolCompositionYlpModal
        isVisible={isModalCompositionYlpOpen}
        setIsVisible={setIsModalCompositionYlpOpen}
        infoTokens={infoTokens}
      />
      <PoolCompositionYslpModal
        isVisible={isModalCompositionYslpOpen}
        setIsVisible={setIsModalCompositionYslpOpen}
        infoTokens={infoTokens}
      />
      <Footer />
    </EarnFrame>
  );
};

export default Earn;
