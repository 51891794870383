import { PoolFrame } from "pages/Earn/styles";

import yslp_ic from "img/yslp.svg";
import green_arrow_ic from "img/green-arrow.svg";

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Footer from "components/Footer/Footer";
import BuySellYslp from "pages/Earn/BuySellYslp";
import PoolCompositionYslp from "pages/Earn/PoolCompositionYslp";
import useVaultYmyContract from "hooks/contracts/useVaultYmyContract";

import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_YSLP } from "config/chains";
import { useYmyPrice } from "domain/legacy";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BASIS_POINTS_DIVISOR,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  YLP_DECIMALS,
  YSLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
} from "lib/legacy";
import { getNativeToken, getTokens } from "config/tokens";
import { getContract } from "config/contracts";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";

import RewardReader from "abis/RewardReader.json";
import ReaderV2 from "abis/ReaderV2.json";
import YlpManager from "abis/YlpManager.json";
import Vault from "abis/Vault.json";
import Token from "abis/Token.json";
import RewardTracker from "abis/RewardTracker.json";

import { ethers } from "ethers";

export const MINT_YSLP = "Mint YSLP";
export const REDEEM_YSLP = "Redeem YSLP";

const { AddressZero } = ethers.constants;

const Yslp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const [isBuying, setIsBuying] = useState(true);
  const history = useHistory();

  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_YSLP,
    isUseDefaultChain: false,
  });

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { ymyPrice } = useYmyPrice(chainId, {}, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  // const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedYslpTrackerAddress = getContract(chainId, "StakedYslpTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdyAddress = getContract(chainId, "USDY");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const ylpManagerAddress = getContract(chainId, "YlpManager");

  const ymyAddress = getContract(chainId, "YMY");
  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");
  const yslpAddress = getContract(chainId, "YSLP");

  const esYmyAddress = getContract(chainId, "ES_YMY");
  const bnYmyAddress = getContract(chainId, "BN_YMY");
  const bonusYmyTrackerAddress = getContract(chainId, "BonusYmyTracker");
  const feeYmyTrackerAddress = getContract(chainId, "FeeYmyTracker");
  const feeYslpTrackerAddress = getContract(chainId, "FeeYslpTracker");

  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const yslpVesterAddress = getContract(chainId, "YslpVester");

  const tokensForBalanceAndSupplyQuery = [stakedYslpTrackerAddress, usdyAddress];
  const rewardTrackersForStakingInfo = [
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    stakedYslpTrackerAddress,
    feeYslpTrackerAddress,
    stakedYslpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  const vesterAddresses = [ymyVesterAddress, yslpVesterAddress, yslpVesterAddress];
  const walletTokens = [ymyAddress, esYmyAddress, yslpAddress, stakedYmyTrackerAddress, yslpAddress];
  const depositTokens = [
    ymyAddress,
    esYmyAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    bnYmyAddress,
    yslpAddress,
    yslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedYmyTrackerAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYslpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  // const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  // const { data: tokenBalances } = useSWR(
  //   [`Yslp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
  //   }
  // );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Yslp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Yslp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`Yslp:getAums:${active}`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Yslp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Yslp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Yslp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Yslp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: yslpBalance } = useSWR(
    [`Yslp:yslpBalance:${active}`, chainId, feeYslpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: stakedYmySupply } = useSWR(
    [
      `Yslp:stakedYmySupply:${active}:${stakedYmyTrackerAddress}`,
      chainId,
      ymyAddress,
      "balanceOf",
      stakedYmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const tokensForSupplyQuery = [ymyAddress, yslpAddress, usdyAddress, yslpAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Yslp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let ymySupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  // const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const yslpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const { yslpPrice } = useVaultYmyContract(chainId);

  // const yslpPrice =
  //   aum && aum.gt(0) && yslpSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, YSLP_DECIMALS)).div(yslpSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  // const yslpSupplyUsd = yslpSupply.mul(yslpPrice).div(expandDecimals(1, YSLP_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  // const nativeToken = getTokenInfo(infoTokens, AddressZero);

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedYmySupply,
    ymyPrice,
    ymySupply,
    yslpPrice
  );

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={yslp_ic} alt="yslp_ic" />
            <div>
              Stable pool - YSLP
              <p>Purchase YSLP tokens to earn BERA fees from swaps and leveraged trading.</p>
            </div>
          </h1>
        </div>
        <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link disabled">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div>
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {processedData.yslpAprTotal ? (
              <>
                <h5 className="positive">{formatKeyAmount(processedData, "yslpAprTotal", 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatKeyAmount(processedData, "yslpAprForNativeToken", 2, 2, true)}%{" "}
                <br className="br-mobile" />+ esYMY {formatKeyAmount(processedData, "yslpAprForEsYmy", 2, 2, true)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.yslpSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "yslpSupply", 18, 2, true)} YSLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "yslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(yslpSupply, 18, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatKeyAmount(processedData, "yslpSupply", 18, 2, true)} YSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "yslpSupplyUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {yslpBalance ? (
              <>
                <h5>{formatAmount(yslpBalance, YLP_DECIMALS, 2, true)} YSLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {processedData?.yslpBalanceUsd ? (
              <>${formatNumber(formatKeyAmount(processedData, "yslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {yslpBalance ? (
              <>
                <h5>{formatAmount(yslpBalance, YLP_DECIMALS, 2, true)} YSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "yslpBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart">
            <h3>Token Distribution</h3>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="name" color="red" />
                <YAxis />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <PoolCompositionYslp />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellYslp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Yslp;

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
