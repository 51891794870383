import { Menu } from "@headlessui/react";
import addTpIcon from "img/trade/add_tp.svg";
import shareIcon from "img/trade/share.svg";
import threeDotsIcon from "img/trade/three-dot.svg";
import { useMediaQuery } from "react-responsive";
import "./PositionDropdown.css";

function PositionDropdownV3({
  handlAddTrailingStop,
  handlAddCollateral,
  handleShare,
  handlAddToPosition,
  handleAddTPSL,
  handleEditLeverage,
  handleEditTakeProfit,
  handleEditStopLoss,
  position,
}) {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Menu>
      <Menu.Button as="div">
        <button className="PositionDropdown-dots-icon">
          <img src={threeDotsIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "15px" }} />
          {/* {isSmallScreen ? "Edit Order" : ""} */}
        </button>
      </Menu.Button>
      <div className="PositionDropdown-extra-options">
        <Menu.Items as="div" className="menu-items">
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleShare && handleShare();
              }}
            >
              <img src={shareIcon} alt="icon" />
              <p>
                <span>Share Position</span>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                handleAddTPSL && handleAddTPSL();
              }}
            >
              <img src={addTpIcon} alt="icon" />
              <p>
                <span>Add TP/SL</span>
              </p>
            </div>
          </Menu.Item>

        </Menu.Items>
      </div>
    </Menu>
  );
}

export default PositionDropdownV3;
