import { useAccount } from "@orderly.network/hooks";
import { AccountStatusEnum, ChainNamespace } from "@orderly.network/types";
import { useWeb3React } from "@web3-react/core";
import Modal from "components/Modal/Modal";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY } from "config/localStorage";
import { helperToast } from "lib/helperToast";
import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

interface IContext {
  checkAccount: () => boolean;
}

const OrderlyNetworkConnectionWrapper: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [creating, setCreating] = useState(false);

  const { account, connector } = useWeb3React();
  const { account: accountOrderly, state } = useAccount();
  const { chainId } = window?.ethereum || {};
  const isRegistered = state.status >= AccountStatusEnum.SignedIn;
  const hasOrderlyKey = state.status >= AccountStatusEnum.EnableTrading;
  // useEffect(() => {
  //   if (timer != null) {
  //     clearTimeout(timer);
  //   }
  //   timer = setTimeout(() => {
  //     if (state.status < AccountStatusEnum.EnableTrading && accountOrderly.address != null) {
  //       setOpen(true);
  //       timer = undefined;
  //     }
  //   }, 3_000) as unknown as number;
  // }, [state, setOpen, accountOrderly]);
  useEffect(() => {
    if (window.location.hash?.includes("#/trade") && account && accountOrderly) {
      if (!isRegistered || !hasOrderlyKey) {
        if (!open) setOpen(true);
      } else if (open) setOpen(false);
    }
  }, [window.location.hash, hasOrderlyKey, isRegistered, accountOrderly, account]);
  useEffect(() => {
    (async () => {
      if (!account) {
        accountOrderly?.disconnect();
        window?.localStorage?.removeItem("chain-namespace");
        return;
      }

      const provider = await connector?.getProvider();
      const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? false;
      window?.localStorage?.setItem("chain-namespace", ChainNamespace.evm);
      accountOrderly
        ?.setAddress(account, {
          chain: {
            id: chainId,
            namespace: ChainNamespace.evm,
          },
          provider,
          wallet: {
            name: currentProviderName || "Metamask",
          },
        })
        ?.then(() => {
          window.localStorage.setItem("chain-namespace", ChainNamespace.evm);
        });
    })();
  }, [accountOrderly, account, chainId, connector]);

  const registerAccount = async () => {
    try {
      setRegistering(true);
      await accountOrderly.createAccount();

      helperToast.success("Registration complete!");
    } catch (error) {
      helperToast.error("Registration failed!");
    } finally {
      setRegistering(false);
    }
  };

  const createKeyAccount = async () => {
    try {
      setCreating(true);
      await accountOrderly.createOrderlyKey(365);

      helperToast.success("Key registration complete!");
    } catch (error) {
      helperToast.error("Key registration failed!");
    } finally {
      setCreating(false);
    }
  };

  const checkAccount = () => {
    if (state.status < AccountStatusEnum.EnableTrading && accountOrderly.address != null) {
      setOpen(true);

      return false;
    }

    return true;
  };

  return (
    <Context.Provider
      value={{
        checkAccount,
      }}
    >
      {children}
      {/* <Modal1 isVisible={open} setIsVisible={setOpen} label="Connect with Orderly Network" allowContentTouchMove>
        <p>
          <span>1.</span> Register your account first.
        </p>
        <button className="default-btn" disabled={isRegistered || registering} onClick={registerAccount}>
          {registering ? "Registering..." : "Register"}
        </button>
        <p>
          <span>2.</span> Create a key pair to interact with our API. It will be stored in your browser’s local storage
          and is unique per device.
        </p>
        <button className="default-btn" disabled={hasOrderlyKey || creating} onClick={createKeyAccount}>
          {creating ? "Creating Key..." : "Create Key"}
        </button>
      </Modal1> */}
    </Context.Provider>
  );
};

const Context = createContext<IContext | null>(null);

export const useOrderlyNetworkConnectionContext = () => {
  const value = useContext(Context);

  if (!value) {
    throw new Error("Must wrap inside OrderlyNetworkConnectionContext");
  }

  return value;
};

const Modal1 = styled(ModalWithPortal)`
  .Modal-content {
    max-width: 400px;
  }

  .Modal-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  button {
    width: 100%;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin: 0;

    display: flex;
    align-items: center;

    align-items: flex-start;
    gap: 4px;
  }
`;

export default OrderlyNetworkConnectionWrapper;
