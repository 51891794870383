import Tooltip from "components/Tooltip/Tooltip";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  formatNumber,
  parseValue,
} from "lib/numbers";

import gum_ic from "img/gum.svg";
import multipoint_ic from "img/multipoint.svg";
import bera_ic from "img/ic_bera_24.svg";
import esymy_ic from "img/esymy.svg";
import { Link } from "react-router-dom";
import YMYAprTooltip from "components/Stake/YMYAprTooltip";
import { useTotalEsYmyStaked, useTotalYmyStaked } from "domain/legacy";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import styled from "styled-components";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { getContract } from "config/contracts";
import { helperToast } from "lib/helperToast";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";

import Token from "abis/Token.json";
import RewardRouter from "abis/RewardRouter.json";

import { ethers } from "ethers";
import { approveTokens } from "domain/tokens";
import Modal from "components/Modal/Modal";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Checkbox from "components/Checkbox/Checkbox";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useMedia } from "react-use";

const { AddressZero } = ethers.constants;

const StakeSection = ({ processedData, ymyPrice, vestingData, setPendingTxns, totalRewardTokens }) => {
  const isMobile = useMedia("(max-width: 767px)");

  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);

  let { total: totalYmyStaked } = useTotalYmyStaked();
  let { total: totalEsYmyStaked } = useTotalEsYmyStaked();
  const { chainId } = useChainId();

  let stakedYmySupplyUsd;
  if (!totalYmyStaked.isZero() && ymyPrice) {
    stakedYmySupplyUsd = totalYmyStaked.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let stakedEsYmySupplyUsd;
  if (!totalEsYmyStaked.isZero() && ymyPrice) {
    stakedEsYmySupplyUsd = totalEsYmyStaked.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let estYMYRewardsPerWeek = 0;
  if (processedData && processedData.ymyAprTotal && processedData.ymyBalanceUsd) {
    estYMYRewardsPerWeek = processedData.ymyAprTotal.mul(processedData.ymyBalanceUsd).div(365).mul(7).div(100);
  }

  let estEsYMYRewardsPerWeek = 0;
  if (processedData && processedData.ymyAprTotal && processedData.esYmyBalanceUsd) {
    estEsYMYRewardsPerWeek = processedData.ymyAprTotal.mul(processedData.esYmyBalanceUsd).div(365).mul(7).div(100);
  }

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { account, active, library } = useWeb3React();
  const isYmyTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");

  const esYmyAddress = getContract(chainId, "ES_YMY");
  const ymyAddress = getContract(chainId, "YMY");

  const showStakeYmyModal = () => {
    if (!isYmyTransferEnabled) {
      helperToast.error(`YMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake YMY`);
    setStakeModalMaxAmount(processedData.ymyBalance);
    setStakeValue("");
    setStakingTokenSymbol("YMY");
    setStakingTokenAddress(ymyAddress);
    setStakingFarmAddress(stakedYmyTrackerAddress);
    setStakeMethodName("stakeYmy");
  };

  const showStakeEsYmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esYMY`);
    setStakeModalMaxAmount(processedData.esYmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esYMY");
    setStakingTokenAddress(esYmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsYmy");
  };
  const showUnstakeEsYmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esYMY`);
    let maxAmount = processedData.esYmyInStakedYmy;
    if (
      processedData.esYmyInStakedYmy &&
      vestingData &&
      vestingData.ymyVesterPairAmount.gt(0) &&
      maxUnstakeableYmy &&
      maxUnstakeableYmy.lt(processedData.esYmyInStakedYmy)
    ) {
      maxAmount = maxUnstakeableYmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.ymyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esYMY");
    setUnstakeMethodName("unstakeEsYmy");
  };

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  let stakedYmyMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.ymyInStakedYmy &&
    processedData.bnYmyInFeeYmy &&
    processedData.esYmyInStakedYmy &&
    (processedData.ymyInStakedYmy.gt(0) || processedData.esYmyInStakedYmy.gt(0))
  ) {
    stakedYmyMultiplierPoints = processedData.ymyInStakedYmy
      .mul(expandDecimals(1, 18))
      .div(processedData.ymyInStakedYmy.add(processedData.esYmyInStakedYmy))
      .mul(processedData.bnYmyInFeeYmy)
      .div(expandDecimals(1, 18));
  }

  let stakedEsYmyMultiplierPoints = bigNumberify(0);
  if (
    processedData &&
    processedData.esYmyInStakedYmy &&
    processedData.bnYmyInFeeYmy &&
    processedData.ymyInStakedYmy &&
    (processedData.ymyInStakedYmy.gt(0) || processedData.esYmyInStakedYmy.gt(0))
  ) {
    stakedEsYmyMultiplierPoints = processedData.esYmyInStakedYmy
      .mul(expandDecimals(1, 18))
      .div(processedData.ymyInStakedYmy.add(processedData.esYmyInStakedYmy))
      .mul(processedData.bnYmyInFeeYmy)
      .div(expandDecimals(1, 18));
  }

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusYmyTrackerRewards && processedData.bnYmyInFeeYmy) {
    multiplierPointsAmount = processedData.bonusYmyTrackerRewards.add(processedData.bnYmyInFeeYmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }

  let maxUnstakeableYmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.ymyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusYmyInFeeYmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.ymyVesterPairAmount);
    const stakedTokens = processedData.bonusYmyInFeeYmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableYmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const bonusYmyInFeeYmy = processedData ? processedData.bonusYmyInFeeYmy : undefined;

  const showUnstakeYmyModal = () => {
    if (!isYmyTransferEnabled) {
      helperToast.error(`YMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake YMY`);
    let maxAmount = processedData.ymyInStakedYmy;
    if (
      processedData.ymyInStakedYmy &&
      vestingData &&
      vestingData.ymyVesterPairAmount.gt(0) &&
      maxUnstakeableYmy &&
      maxUnstakeableYmy.lt(processedData.ymyInStakedYmy)
    ) {
      maxAmount = maxUnstakeableYmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.ymyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("YMY");
    setUnstakeMethodName("unstakeYmy");
  };

  return (
    <section className="Earn-Section">
      <h1 className="Section-Title">Stake</h1>

      <div className="Section-StakePapers">
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead">
            <div className="Section-StakePaperHeadDetails">
              <img src={gum_ic} alt="gum_ic" />
              <div>
                <h3>YMY & esYMY</h3>
                <p>Stake Yummy Governance Token</p>
              </div>
            </div>
            <div className="Section-StakePaperHeadItems">
              <div className="Section-StakePaperHeadItem">
                APR
                {processedData.ymyAprTotalWithBoost ? (
                  <Tooltip
                    className="tooltip-apr"
                    handle={
                      <div className="positive">
                        {formatKeyAmount(processedData, "ymyAprTotalWithBoost", 2, 2, true)}%
                      </div>
                    }
                    renderContent={() => (
                      <YMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                )}
              </div>
              <div className="Section-StakePaperHeadItem">
                MPs APR
                <Tooltip
                  className="excep"
                  handle={`100.00%`}
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        Boost your rewards with Multiplier Points.&nbsp;
                        <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">More info</ExternalLink>.
                      </span>
                    );
                  }}
                />
              </div>
              <div className="Section-StakePaperHeadItem">
                Boost
                {processedData.boostBasisPoints ? (
                  <Tooltip
                    position={isMobile ? "left-bottom" : "right-bottom"}
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    renderContent={() => {
                      return (
                        <div>
                          You are earning{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                          </span>{" "}
                          more {nativeTokenSymbol} rewards using{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.bnYmyInFeeYmy, 18, 4, 2, true)}
                          </span>{" "}
                          Staked Multiplier Points.
                          <br />
                          <div style={{ marginTop: "8px" }}>
                            <span>Use the "Compound" button to stake your Multiplier Points.</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
            </div>
            <Link className="Section-StakePaperHeadAction border-btn" to="/buy-gum">
              Buy YMY
            </Link>
          </div>
          <div className="Section-StakePaperBody">
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total YMY staked
                  <div>
                    {totalYmyStaked ? (
                      <>
                        <h5>{formatAmount(totalYmyStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedYmySupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs staked
                  <div>
                    <h5>{formatAmount(stakedYmyMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  YMY in wallet
                  <div>
                    {processedData?.ymyBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "ymyBalance", 18, 2, true)}</h5> $
                        {formatNumber(formatKeyAmountFixed(processedData, "ymyBalanceUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  YMY staked
                  <div>
                    {processedData?.ymyInStakedYmy ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "ymyInStakedYmy", 18, 2, true)}</h5>$
                        {formatNumber(formatKeyAmount(processedData, "ymyInStakedYmyUsd", USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeYmyModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeYmyModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
            <div className="Section-StakePaperBodyItem">
              <div className="Section-StakePaperBodyItemStats">
                <div className="Section-StakePaperBodyItemStatItem">
                  Total esYMY staked
                  <div>
                    {totalEsYmyStaked ? (
                      <>
                        <h5> {formatAmount(totalEsYmyStaked, 18, 2, true)}</h5> $
                        {formatNumber(formatAmount(stakedEsYmySupplyUsd, USD_DECIMALS, USD_DECIMALS))}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="divider" />
                <div className="Section-StakePaperBodyItemStatItem">
                  MPs staked
                  <div>
                    <h5>{formatAmount(stakedEsYmyMultiplierPoints, 18, 2, true)}</h5>
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esYMY in wallet
                  <div>
                    {processedData.esYmyBalance ? (
                      <>
                        <h5>{formatKeyAmountFixed(processedData, "esYmyBalance", 18, 2, true)}</h5> $
                        {formatNumber(
                          formatKeyAmountFixed(processedData, "esYmyBalanceUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemStatItem">
                  esYMY staked
                  <div>
                    {processedData?.esYmyInStakedYmy ? (
                      <>
                        <h5>{formatKeyAmount(processedData, "esYmyInStakedYmy", 18, 2, true)}</h5>$
                        {formatNumber(
                          formatKeyAmount(processedData, "esYmyInStakedYmyUsd", USD_DECIMALS, USD_DECIMALS)
                        )}
                      </>
                    ) : (
                      <>
                        <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                        <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                      </>
                    )}
                  </div>
                </div>
                <div className="Section-StakePaperBodyItemActions">
                  <button className="default-btn" onClick={showStakeEsYmyModal}>
                    Stake
                  </button>
                  <button className="border-btn" onClick={showUnstakeEsYmyModal}>
                    Unstake
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-StakePaper">
          <div className="Section-StakePaperHead">
            <div className="Section-StakePaperHeadDetails">
              <div>
                <h3>Total Rewards</h3>
                <p>Rewards from staking YMY, esYMY, YLP and YSLP</p>
              </div>
              <div className="Section-StakePaperHeadDetailTotal">
                <h3>Total</h3>
                <p>
                  {processedData.totalRewardsUsd ? (
                    `$${formatNumber(formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, USD_DECIMALS))}`
                  ) : (
                    <div
                      className="skeleton-box"
                      style={{
                        height: 20,
                      }}
                    />
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="right-box-rows">
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={bera_ic} alt="bera_ic" />
                {nativeTokenSymbol} ({wrappedTokenSymbol})
              </div>
              <div className="right-box-row-value">
                {processedData.totalNativeTokenRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalNativeTokenRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YMY & esYMY`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeYmyTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeYmyTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`YLP`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeYlpTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeYlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label="YSLP"
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeYslpTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "feeYslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={gum_ic} alt="gum_ic" />
                YMY
              </div>
              <div className="right-box-row-value">
                {processedData.totalVesterRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YMY Vault`}
                            showDollar={false}
                            value={`${formatKeyAmount(processedData, "ymyVesterRewards", 18, 4, true)} ($${formatNumber(
                              formatKeyAmount(processedData, "ymyVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`YLP Vault`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "ylpVesterRewards",
                              18,
                              4,
                              true
                            )} ($${formatKeyAmount(processedData, "ylpVesterRewardsUsd", USD_DECIMALS, 2, true)})`}
                          />
                          <StatsTooltipRow
                            label="YSLP Vault"
                            showDollar={false}
                            value={`${formatKeyAmount(processedData, "yslpVesterRewards", 18, 4, true)} ($${formatNumber(
                              formatKeyAmount(processedData, "yslpVesterRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={esymy_ic} alt="esymy_ic" />
                esYMY
              </div>
              <div className="right-box-row-value">
                {processedData.totalEsYmyRewards ? (
                  <div className="value native-token-reward">
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        <>
                          {formatKeyAmount(processedData, "totalEsYmyRewards", 18, 4, true)}{" "}
                          <span class="reward-dollar">
                            $
                            {formatNumber(
                              formatKeyAmount(processedData, "totalEsYmyRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )}
                          </span>
                        </>
                      }
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={`YMY & esYMY`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedYmyTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedYmyTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label={`YLP`}
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedYlpTrackerRewards",
                              18,
                              4
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedYlpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                          <StatsTooltipRow
                            label="YSLP"
                            showDollar={false}
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedYslpTrackerRewards",
                              18,
                              4,
                              true
                            )} ($${formatNumber(
                              formatKeyAmount(processedData, "stakedYslpTrackerRewardsUsd", USD_DECIMALS, USD_DECIMALS)
                            )})`}
                          />
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="right-box-row">
              <div className="right-box-row-title">
                <img src={multipoint_ic} alt="multipoint_ic" />
                Multiplier Points
              </div>
              <div className="right-box-row-value">
                {processedData?.bonusYmyTrackerRewards ? (
                  `${formatKeyAmount(processedData, "bonusYmyTrackerRewards", 18, 4, true)}`
                ) : (
                  <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                )}
              </div>
            </div>
            {/* <div className="right-box-row">
              <div className="right-box-row-title">Staked Multiplier Points</div>
              <div className="right-box-row-value">
                {processedData?.bnYmyInFeeYmy ? (
                  `${formatKeyAmount(processedData, "bnYmyInFeeYmy", 18, 4, true)}`
                ) : (
                  <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                )}
              </div>
            </div> */}
            {/* <div className="right-box-row">
              <div className="right-box-row-title">Total</div>
              <div className="right-box-row-value">

              </div>
            </div> */}
            <div className="actions">
              <button
                className="default-btn"
                disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                onClick={() => setIsCompoundModalVisible(true)}
              >
                Compound
              </button>
              <button
                className="view-btn border-btn"
                onClick={() => setIsClaimModalVisible(true)}
                disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
              >
                Claim
              </button>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusYmyInFeeYmy={bonusYmyInFeeYmy}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData?.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
    </section>
  );
};

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esYMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esYMY amount includes the esYMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esYMY to YMY later, you will be required to deposit a reserve amount of YMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusYmyInFeeYmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusYmyInFeeYmy &&
    bonusYmyInFeeYmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusYmyInFeeYmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusYmyInFeeYmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusYmyInFeeYmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimYmy, setShouldClaimYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-ymy"],
    true
  );
  const [shouldClaimEsYmy, setShouldClaimEsYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-ymy"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimYmy,
        false, // shouldStakeYmy
        shouldClaimEsYmy,
        false, // shouldStakeEsYmy
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Claim General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimYmy} setIsChecked={setShouldClaimYmy}>
              <span>Claim YMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsYmy} setIsChecked={setShouldClaimEsYmy}>
              <span>Claim esYMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  // const shouldClaimYmy = false; // delete here
  // const shouldStakeYmy = false; // delete here

  // const [shouldClaimYmytest,setShouldClaimYmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-claim-ymy"], true); // delete here
  // const [shouldStakeYmytest,setShouldStakeYmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-stake-ymy"], true); // delete here

  // open here //

  const [shouldClaimYmy, setShouldClaimYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-ymy"],
    true
  );
  const [shouldStakeYmy, setShouldStakeYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-ymy"],
    true
  );

  // open here //

  const [shouldClaimEsYmy, setShouldClaimEsYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-ymy"],
    true
  );
  const [shouldStakeEsYmy, setShouldStakeEsYmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-ymy"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const ymyAddress = getContract(chainId, "YMY");
  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, ymyAddress, "allowance", account, stakedYmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeYmy && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving YMY...`;
    }
    if (needApproval) {
      return `Approve YMY`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: ymyAddress,
        spender: stakedYmyTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimYmy || shouldStakeYmy,
        shouldStakeYmy,
        shouldClaimEsYmy || shouldStakeEsYmy,
        shouldStakeEsYmy,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeYmy = (value) => {
    if (value) {
      setShouldClaimYmy(true);
    }
    setShouldStakeYmy(value);
  };

  const toggleShouldStakeEsYmy = (value) => {
    if (value) {
      setShouldClaimEsYmy(true);
    }
    setShouldStakeEsYmy(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Compound General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <span>Stake Multiplier Points</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimYmy} setIsChecked={setShouldClaimYmy} disabled={shouldStakeYmy}>
              <span>Claim YMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeYmy} setIsChecked={toggleShouldStakeYmy}>
              <span>Stake YMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsYmy} setIsChecked={setShouldClaimEsYmy} disabled={shouldStakeEsYmy}>
              <span>Claim esYMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsYmy} setIsChecked={toggleShouldStakeEsYmy}>
              <span>Stake esYMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default StakeSection;
