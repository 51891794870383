import usdc from "img/ic_usdc.e_24.svg";
import styled from "styled-components";

import ModalWithPortal from "components/Modal/ModalWithPortal";
import { formatAmount, limitDecimals, numberFormat, parseValue } from "lib/numbers";
import { useMemo, useState } from "react";
import "./index.scss";
import { useAccount, useCollateral, usePrivateQuery, useWithdraw } from "@orderly.network/hooks";
import { helperToast } from "lib/helperToast";
import Tooltip from "components/Tooltip/Tooltip";
import { BERACHAIN_BARTIO } from "config/chains";
import { commify } from "@ethersproject/units";

const MIN_WITHDRAW_AMOUNT = 0;

const WithdrawModalOrderly = (props) => {
  const { isVisible, setIsVisible } = props;

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const { account } = useAccount();

  const collateral = useCollateral();

  const { withdraw, availableWithdraw } = useWithdraw();
  // console.log("?????", orderlyAccount);
  const withdrawConfig = usePrivateQuery("/v1/public/token?t=withdraw");
  // console.log("?????", withdrawConfig?.data);
  const feeChain = useMemo(() => {
    if (withdrawConfig?.data) {
      const chainDetails = withdrawConfig?.data?.[0]?.chain_details;
      if (chainDetails) {
        const token = chainDetails.find((x) => Number(x.chain_id) === BERACHAIN_BARTIO);
        if (token) return token?.withdrawal_fee || 0;
      }
    }
    return 0;
  }, [withdrawConfig]);
  //actions
  const handleSettle = async () => {
    // if (availableWithdraw < collateral.availableBalance) {

    // }
    try {
      await account.settle();
      helperToast.success("Settle successfully");
    } catch (error) {
      helperToast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const handleWithdraw = async () => {
    try {
      await withdraw({
        chainId: Number(account.chainId),
        amount: amount.toString(),
        token: "USDC",
        allowCrossChainWithdraw: false,
      });
      helperToast.success("Withdraw successfully");
      setIsVisible(false);
    } catch (error) {
      helperToast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onMax = () => {
    setAmount(collateral.availableBalance);
  };

  const onChangeAmount = (e) => {
    const value = e.target.value;

    setAmount(value);
  };

  //validation
  const canWithdraw =
    //amount > 0
    amount &&
    Number(amount) > 0 &&
    //exceeds balance
    Number(amount) <= collateral.availableBalance &&
    Number(amount) > MIN_WITHDRAW_AMOUNT &&
    //loading
    !loading;

  const getButtonContent = () => {
    if (!amount || Number(amount) < 0) return "Enter an amount";
    if (Number(amount) > collateral.availableBalance) return "Exceeds balance";
    if (Number(amount) <= MIN_WITHDRAW_AMOUNT) return `Quantity must large than ${MIN_WITHDRAW_AMOUNT}`;

    return "Withdraw";
  };

  return (
    <ModalWithPortal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Orderly withdraw"
      className={`hasDivider non-scrollable Collateral-modal-deposit Collateral-Modal-Withdraw`}
    >
      <span className="Modal-description">Funds are withdrawn from the Orderly balance into your standard wallet.</span>
      <ModalContent>
        <div className="Exchange-swap-section exchange-section-first">
          <div className="Exchange-swap-section-top">
            <div className="muted align-right clickable" onClick={onMax}>
              <span>Balance: {formatAmount(parseValue(collateral.freeCollateral, 6), 6, 2, true)}</span>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={amount}
                onChange={onChangeAmount}
              />
              <div className="Exchange-swap-max" onClick={onMax}>
                <span>MAX</span>
              </div>
            </div>
            <div className="Exchange-swap-token">
              <img src={usdc} alt="USDC" />
              USDC
            </div>
          </div>
        </div>
        {collateral?.unsettledPnL && collateral?.unsettledPnL !== 0 ? (
          <div className="unsettle-container">
            <div className="unsettle-label">
              <Tooltip
                handle={<div className="unsettle-label">Unsettled:</div>}
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      Unsetteled balance can not be withdrawn. In order to withdraw, please settle your balance first.
                    </>
                  );
                }}
              ></Tooltip>{" "}
              <span className={`${collateral?.unsettledPnL > 0 ? "positive" : "negative"}`}>
                {commify(limitDecimals(collateral?.unsettledPnL, 4))}
              </span>{" "}
              USDC
            </div>
            <div className="settle-btn" onClick={handleSettle}>
              Settle
            </div>
          </div>
        ) : null}
        <div className="unsettle-container">
          <div className="unsettle-label">Withdraw fee:</div>
          <div>{feeChain ? feeChain : "-"} USDC</div>
        </div>
      </ModalContent>
      <button className="default-btn full" onClick={handleWithdraw} disabled={!canWithdraw}>
        {getButtonContent()}
      </button>
    </ModalWithPortal>
  );
};

const ModalContent = styled.div`
  .Exchange-swap-section-top {
    grid-template-columns: auto;
  }

  /* .Exchange-swap-input-container {
    & > div {
      padding: 2px 12px;
      color: #080715;
      border-radius: 4px;
      z-index: 1;
      font-size: var(--font-sm);
      background: #1EB2FC;
      height: 24px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }
    }
  } */

  .Exchange-swap-token {
    font-weight: 700;
    line-height: 140%;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 12px;
  }
`;

export default WithdrawModalOrderly;
