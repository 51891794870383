import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import moment from "moment";
import { commify } from "@ethersproject/units";
import { useMemo } from "react";
import { max } from "date-fns";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
export function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
const ClosedPositionItemV3 = (props) => {
  const { setSelectedPosition, position, setPositionToShare, setIsPositionShareModalOpen, handleSelectedPosition } =
    props;
  const tokenSymbol = getBaseOrderlyAsset(position.symbol);
  const isLong = position.max_position_qty > 0 ? true : false;

  const realPnl = position?.realized_pnl || 0;
  const netPnl =
    (position?.realized_pnl || 0) - (position?.accumulated_funding_fee || 0) - (position?.trading_fee || 0);
  const isLoss = netPnl < 0;
  const pnlPrefix = isLoss ? "-" : "+";

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  return (
    <tr className="table-content clickable" onClick={() => handleSelectedPosition(position)}>
      <td className="clickable" style={{ width: "200px" }}>
        <div style={{ paddingLeft: "10px" }}>
          <div className={`${isLong > 0 ? `long-tag` : `short-tag`}`} />
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title fz-12">{tokenSymbol}/USDC</div> <div style={{ height: "4px" }}></div>
            <div className="Exchange-list-info-label">
              <span className="cancel-order-txt">
                {position?.type === "liquidated"
                  ? "Liquidated"
                  : position?.position_status === "partial_closed"
                  ? "Partial Closed"
                  : "Closed"}
              </span>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div className="fz-12">
          {Math.abs(position.closed_position_qty)}/{Math.abs(position.max_position_qty)}
        </div>
      </td>
      <td className="clickable fz-12">
        {formatAmount(
          parseValue(position.avg_open_price || 0, 30),
          30,
          decimalsRules(position.avg_open_price),
          true
        ).replace("-", "")}
      </td>
      <td className="clickable fz-12">
        {formatAmount(
          parseValue(position.avg_close_price || 0, 30),
          30,
          decimalsRules(position.avg_close_price),
          true
        ).replace("-", "")}
      </td>
      <td className="clickable fz-12">
        <div
          className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
          style={{
            color: realPnl < 0 ? "#E43E53" : "#C1FF99 ",
          }}
        >
          <Tooltip
            handle={
              <span>
                {pnlPrefix}
                {formatAmount(parseValue(netPnl, 30), 30, 2, true).replace("-", "").replace("-", "")}{" "}
                <span className="muted">USDC</span>
              </span>
            }
            position="right-bottom"
            renderContent={() => {
              return (
                <>
                  <StatsTooltipRow
                    label={`Real. PnL`}
                    value={`${realPnl > 0 ? "" : "-"}
                    ${formatAmount(parseValue(realPnl, 30), 30, 2, true).replace("-", "").replace("-", "")} USDC`}
                    showDollar={false}
                  />
                  <StatsTooltipRow
                    label={`Funding Fee`}
                    value={commify(limitDecimals(position.accumulated_funding_fee), 2) + " USDC"}
                    showDollar={false}
                  />
                  <StatsTooltipRow
                    label={`Trading Fee`}
                    value={commify(limitDecimals(position.trading_fee, 2)) + " USDC"}
                    showDollar={false}
                  />
                </>
              );
            }}
          />
        </div>{" "}
        <div style={{ height: "4px" }}></div>
        {/* <div className={`fz-12 bold ${!isLoss ? "positive" : "negative"}`}>
          {limitDecimals(
            (Math.abs(position.realized_pnl) /
              (Math.abs(position.closed_position_qty) * Math.abs(position.avg_close_price))) *
            100,
            2
          )}
          %
        </div> */}
      </td>
      <td className="fz-12">
        <div>{moment(new Date(position.open_timestamp)).format(dateFormat)}</div> <div style={{ height: "4px" }}></div>
        <div className="muted fz-12 bold">{moment(new Date(position.open_timestamp)).format(timeFormat)}</div>
      </td>
      <td className="fz-12">
        <div>{moment(new Date(position.last_update_time)).format(dateFormat)}</div> <div style={{ height: "4px" }}></div>
        <div className="muted fz-12 bold">{moment(new Date(position.last_update_time)).format(timeFormat)}</div>
      </td>
      {/* <td>
        <div>{moment(new Date(position.last_update_time)).format(dateFormat)}</div> <div style={{ height: "4px" }}></div>
        <div className="muted fz-12 bold">{moment(new Date(position.last_update_time)).format(timeFormat)}</div>
      </td> */}
      <td style={{ width: "30px" }}>
        <button
          className="PositionDropdown-dots-icon fz-12"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedPosition(position);
            setIsPositionShareModalOpen(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
        </button>
      </td>
    </tr>
  );
};
export default ClosedPositionItemV3;
