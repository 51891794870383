import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { formatAmount, formatNumber, parseValue } from "lib/numbers";
import moment from "moment";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axios from "axios";

import { getTimestampsWithDate, SECONDS_PER_DAY } from "lib/legacy";
import { useThemeContext } from "contexts/ThemeProvider";

import no_data_icon from "img/portfolio/noChart.svg";
import { usePrivateQuery } from "@orderly.network/hooks";

const DURATIONS = [
  { label: "24h", value: moment().unix() - SECONDS_PER_DAY },
  { label: "7d", value: moment().unix() - SECONDS_PER_DAY * 7 },
  { label: "30d", value: moment().unix() - SECONDS_PER_DAY * 30 },
  { label: "All", value: 1700091200 },
];

const PortfolioChart = ({ numberOfDay, totalValue, dailyInfo }) => {
  const { isLightTheme, lightThemeClassName } = useThemeContext();
  const { targetDayTimestamp, targetDayFormatted, endOfToday, endOfTodayFormatted } = getTimestampsWithDate(numberOfDay);

  const assetsChart = useMemo(() => {
    if (dailyInfo?.data && totalValue) {
      if (dailyInfo?.data?.length > 0) {
        if (moment(dailyInfo?.data?.[0]?.snapshot_time).format(`YYYY-MM-DD`) === moment(Date.now()).format(`YYYY-MM-DD`)) {
          return [...[
            {
              xAxis: moment(Date.now()).format("MM-DD"),
              date: moment(Date.now()).format(`YYYY-MM-DD`),
              portfolioUpdated: totalValue
            }
          ], ...dailyInfo?.data?.slice(1)?.map((item) => ({
            xAxis: moment(item?.snapshot_time).format("MM-DD"),
            value: item.account_value,
            date: moment(item?.snapshot_time).format(`YYYY-MM-DD`),
            portfolioUpdated: item.account_value ? (Number(item.account_value) <= 0.001 ? 0.0 : item.account_value) : 0.0,
          })) || [],].reverse()
        }
      }
      return [...[
        {
          xAxis: moment(Date.now()).format("MM-DD"),
          date: moment(Date.now()).format(`YYYY-MM-DD`),
          portfolioUpdated: totalValue
        }
      ], ...dailyInfo?.data?.map((item) => ({
        xAxis: moment(item?.snapshot_time).format("MM-DD"),
        value: item.account_value,
        date: moment(item?.snapshot_time).format(`YYYY-MM-DD`),
        portfolioUpdated: item.account_value ? (Number(item.account_value) <= 0.001 ? 0.0 : item.account_value) : 0.0,
      })) || [],]?.reverse()
    }
    return [];
  }, [dailyInfo, totalValue]);
  const volumeChart = useMemo(() => {
    if (dailyInfo?.data) {
      return dailyInfo?.data?.map((item) => ({
        timestamp: item.snapshot_time,
        value: item.perp_volume,
      }));
    }
    return [];
  }, [dailyInfo]);
  const pnlChart = useMemo(() => {
    if (dailyInfo?.data) {
      return dailyInfo?.data?.map((item) => ({
        timestamp: item.snapshot_time,
        value: item.pnl,
      }));
    }
    return [];
  }, [dailyInfo]);

  const formatAxis = (tickItem) => {
    return `${formatNumber(tickItem, 1)}`;
  };

  const CustomTooltip = ({ active, payload, formatter }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="stats-label"
          style={{
            backgroundColor: isLightTheme ? "#fff" : "#2E3033",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: isLightTheme ? "4px 0px 24px 0px rgba(0, 0, 0, 0.2)" : "none",
          }}
        >
          <div style={{ color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99" }}>{payload[0].payload.date}</div>
          <div
            className="stats-row"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            {/* <div
              className="stats-row-key"
              style={{
                color: isLightTheme ? "#0D1A16B2" : "#FFFFFF99",
              }}
            >
              Asset Value
            </div> */}
            <div className="stats-row-value">
              {formatAmount(parseValue(payload[0]?.payload.portfolioUpdated || 0, 8), 8, 2, true)} <span className="muted">USDC</span>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Wrapper className={lightThemeClassName}>
      {assetsChart.length > 0 && (
        <>
          <div className="chart-content chart-portfofio">
            <ResponsiveContainer width="100%" height={240}>
              <AreaChart data={assetsChart} stackOffset="sign">
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1EB2FC" stopOpacity={0.1} />
                    <stop offset="95%" stopColor="#1EB2FC" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                  dataKey="xAxis"
                  height={12}
                  minTickGap={15}
                  fontSize={12}
                />
                <YAxis
                  tickLine={false}
                  stroke={isLightTheme ? "#0D1A16B2" : "#FFFFFF66"}
                  width={40}
                  tickFormatter={formatAxis}
                  orientation="right"
                  fontSize={12}
                />
                <Area
                  type="monotone"
                  dataKey="portfolioUpdated"
                  stroke="#1EB2FC"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
      {assetsChart.length === 0 && (
        <div className="no-record-chart">
          <img src={no_data_icon} alt="no_data_icon" className="no-record-icon-styled" />
          <span>No Data</span>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* border-radius: 16px; */
  /* padding: 24px; */
  height: 100%;
  /* background: #18191a; */
  position: relative;

  .chart-portfofio {
    position: relative;
    bottom: -30px;
    @media screen and (max-width: 700px) {
      bottom: -5px;
    }
  }

  .chart-duration {
    display: flex;
    align-items: flex-start;
    gap: var(--Base-Unit-S-12, 12px);
    margin-bottom: 8px;

    .duration-item {
      color: #919499;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--Main-Primary, #fff);
      }
    }
  }

  .no-record-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      position: absolute;
      inset: 0;

      opacity: 0.2;
      mix-blend-mode: luminosity;
    }

    span {
      color: var(--Text-Text_Secondary, #919499);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  .chart-content {
    min-height: 240px;
  }

  .recharts-layer.recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-layer.recharts-cartesian-axis-tick {
        text,
        tspan {
          fill: #999a9e;
        }
      }
    }
  }

  &.theme--light {
    background: #fff;

    .chart-duration {
      .duration-item {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        &:hover,
        &.active {
          color: #02b27f;
        }
      }
    }

    .recharts-layer.recharts-cartesian-axis {
      .recharts-cartesian-axis-ticks {
        .recharts-layer.recharts-cartesian-axis-tick {
          text,
          tspan {
            fill: #0d1a16b2;
          }
        }
      }
    }
  }
`;

export default PortfolioChart;
