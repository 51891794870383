import { useState } from "react";

import { importImage, USD_DECIMALS } from "lib/legacy";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import RowsDropdown from "./RowsDropdown";
import PositionHistory from "./PositionHistory";
import ClockIcon from "../Icon/Clock";
import { secondsToHMS } from "components/TradeMerged/ClosedPositionItemV2";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import useUserPositionActivitiesV1 from "hooks/useUserPositionActivitiesV1";
import PositionHistoryV1 from "./PositionHistoryV1";

export default function PositionClosedV1(props) {
  const { isVisible, position, setIsVisible, chainId, marketTokensInfo } = props;
  const { account } = useWeb3React();
  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const leverage = parseValue(position.size, 30)
    .mul(parseValue(1, USD_DECIMALS))
    .div(parseValue(position.collateral, 30));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const duration = secondsToHMS(position.closedAt - position.openedAt);

  const roiPrefix = position.roi < 0 ? "-" : "+";
  const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
  const isLoss = position.realisedPnl.includes("-");
  const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
  const posQty = parseValue(position.size, 30).mul(parseValue(1, 30)).div(parseValue(position.averagePrice, 30));
  const { positions: positionActivities, details } = useUserPositionActivitiesV1(account, position.id);
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Closed Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.indexToken.symbol}</div>{" "}
                <div className="position-id">#{position?.posId}</div>
                <div
                  className="postion-history-btn"
                  onClick={() => {
                    setIsOpenPositionHistory(true);
                  }}
                >
                  <ClockIcon />
                </div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {/* {position?.positionType === "Market Order" ? "Market" : position?.positionType} */}
                  Market
                </div>
                <div className="leverage-box">{formatAmount(leverage, 30, 2, true)}X</div>
              </div>
            </div>
          </div>
          <div className="content-container" style={{ marginBottom: 24 }}>
            <div className="gray-divider" />
            <div className="order-details-container">
              <div className="title">Position Details</div>
              <div className="square-container">
                <ExchangeInfoRow label={`Realized PnL`}>
                  <div
                    className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
                    style={{
                      color: position.realisedPnl.includes("-") ? "#E43E53" : "#C1FF99 ",
                    }}
                  >
                    {pnlPrefix}${formatAmount(parseValue(position.realisedPnl, 30), 30, 2, true).replace("-", "")}
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`ROI`}>
                  <div
                    className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
                    style={{
                      color: roiPrefix.includes("-") ? "#E43E53" : "#C1FF99 ",
                    }}
                  >
                    {roiPrefix}
                    {limitDecimals(Number(position.roi) < -100 ? -100 : Number(position.roi), 2).replace("-", "")}%
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Qty`}>
                  {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Leverage`}>
                  <div className="position-list-leve">{formatAmount(leverage, 30, 2, true)}X</div>
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Collateral`}>
                  ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(parseValue(position.exitPrice, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(parseValue(position.averagePrice, 30), 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time Opened`}>
                  {moment(new Date(position.openTime * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.openTime * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Duration`}>{duration}</ExchangeInfoRow>
              </div>

              <RowsDropdown
                handler={
                  <ExchangeInfoRow
                    label={<div className="title">Fees</div>}
                    // isPositive={position.paidFees.gte(0)}
                  ></ExchangeInfoRow>
                }
              >
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Paid`}>
                    {details ? "$" + formatAmount(parseValue(details.fee, 30), 30, 4, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Position`}>
                    {details ? "$" + formatAmount(parseValue(details.accruedPositionFee, 30), 30, 4, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>-</ExchangeInfoRow>
                </div>
              </RowsDropdown>
            </div>
          </div>
          {details && (
            <PositionHistoryV1
              isVisible={isOpenPositionHistory}
              setIsVisible={setIsOpenPositionHistory}
              position={details}
              rows={positionActivities}
              chainId={chainId}
              optionalHeader={
                <div className="order-title-line order-title-line-details">
                  <div className="position-info-container">
                    <div className="position-info">
                      <img src={importImage("ic_" + position?.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="" />
                      <div className="title">{position?.indexToken.symbol}</div>{" "}
                      <div className="position-id">#{position?.posId}</div>
                    </div>
                    <div className="position-tags">
                      <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                        {position?.isLong ? "LONG" : "SHORT"}
                      </div>
                      <div className="side">
                        {/* {position?.positionType === "Market Order" ? "Market" : position?.positionType} */}
                        MARKET
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          )}
        </Modal>
      )}
    </div>
  );
}
