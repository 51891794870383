import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import YMY_ETH from "img/YMY-ETH.svg";
// import opBackdrop from "img/home/opBackdrop.svg";

import { ARBITRUM, BERACHAIN_BARTIO, FANTOM } from "config/chains";
import { formatAmountFree, formatNumber } from "lib/numbers";

import { useThemeContext } from "contexts/ThemeProvider";
import useProcessedData from "hooks/useProcessedData";
import useSwitchChain from "hooks/useSwitchChain";
import { caculateAPYHourly } from "lib/helper";
import Tokens from "./Tokens";

// const YMY_WETH_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

const YieldVault = ({ chainId, active, library }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [switchChain] = useSwitchChain(chainId);

  // const processedDataFANTOM = useProcessedData(FANTOM);
  // const processedDataOP = useProcessedData(OP);
  const processedDataBARTIO = useProcessedData(BERACHAIN_BARTIO);

  const [statsYMYWFTM, setStatsYMYWFTM] = useState({
    price: 0,
    apr: 10,
  });

  // const [statsYMYWETH, setStatsYMYWETH] = useState({
  //   price: 0,
  //   apr: 1,
  // });

  const totalApyYMY_FTM = statsYMYWFTM.apr
    ? formatNumber(String(caculateAPYHourly(Number(statsYMYWFTM.apr), 4080)), 2)
    : "--";

  let aprTotalYmy;
  let apyYmy;
  // let dailyApyYmy;
  aprTotalYmy = parseFloat(formatAmountFree(processedDataBARTIO?.ymyAprTotal, 2));
  apyYmy = aprTotalYmy ? formatNumber(caculateAPYHourly(aprTotalYmy, 4080), 2) : null;
  // dailyApyYmy = aprTotalYmy ? formatNumber(caculateAPYDaily(aprTotalYmy, 1), 4) : null;

  let aprTotalYlp;
  let apyYlp;
  // let dailyApyYlp;
  aprTotalYlp = parseFloat(formatAmountFree(processedDataBARTIO?.ylpAprTotal, 2));
  apyYlp = aprTotalYlp ? formatNumber(caculateAPYHourly(aprTotalYlp, 4080), 2) : null;

  // let aprTotalYmyARB;
  // let apyYmyARB;
  // // let dailyApyYmyARB;
  // aprTotalYmyARB = parseFloat(formatAmountFree(processedDataARB?.ymyAprTotal, 2));
  // apyYmyARB = aprTotalYmyARB ? formatNumber(caculateAPYHourly(aprTotalYmyARB, 4080), 2) : null;
  // // dailyApyYmyARB = aprTotalYmyARB ? formatNumber(caculateAPYDaily(aprTotalYmyARB, 1), 4) : null;

  // let aprTotalYlpARB;
  // let apyYlpARB;
  // // let dailyApyYlpARB;
  // aprTotalYlpARB = parseFloat(formatAmountFree(processedDataARB?.ylpAprTotal, 2));
  // apyYlpARB = aprTotalYlpARB ? formatNumber(caculateAPYHourly(aprTotalYlpARB, 4080), 2) : null;
  // // dailyApyYlpARB = aprTotalYlpARB ? formatNumber(caculateAPYDaily(aprTotalYlpARB, 1), 4) : null;

  const apy = {
    apyYmy: {
      [BERACHAIN_BARTIO]: apyYmy,
      // [ARBITRUM]: apyYmyARB,
    },
    apyYlp: {
      [BERACHAIN_BARTIO]: apyYlp,
      // [ARBITRUM]: apyYlpARB,
    },
    apyYMY_FTM: {
      [BERACHAIN_BARTIO]: totalApyYMY_FTM,
    },
    priceYMY_FTM: {
      [BERACHAIN_BARTIO]: statsYMYWFTM.price,
    },
  };

  return (
    <Wrapper>
      <Tokens active={active} chainId={chainId} library={library} apy={apy} />
    </Wrapper>
  );
};

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    gap: 16px;
  }

  .content-ylp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--base-unit-xs-8, 8px);
  align-self: stretch;

  & > span {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
`;

const TokenItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex: 1;
  background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

  &.op {
    background: linear-gradient(90deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
  }

  .info {
    .symbol {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      img[alt="ymy_icon"] {
        width: 36px;
        height: 36px;
      }
      img:not([alt="ymy_icon"]) {
        width: 54px;
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
    }

    .stats {
      display: flex;
      gap: 16px;
      margin-top: 16px;

      .stat-row {
        color: #ffffff99;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;

        .value {
          color: #1eb2fc;

          &.skeleton-box {
            width: 50px !important;
          }
        }

        &:last-child {
          padding-left: 16px;
          border-left: 1px solid #ffffff1a;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ViewPoolBtn = styled(Link)`
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #080715;
  background-color: #1eb2fc;
  padding: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #4787e3;
    color: #080715 !important;
  }
`;

const Wrapper = styled.div`
  margin: 64px 0 0;

  @media (max-width: 767px) {
    margin: 36px 0 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    .desc {
      color: #0d1a16 !important;
    }

    ${TokenItem} {
      border-color: var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

      &.arb {
        background: linear-gradient(90deg, rgba(18, 140, 255, 0.1) 0%, rgba(4, 134, 255, 0) 100%);
      }

      .stat-row {
        color: var(--Text-Text_Primary, #0d1a16);

        .value {
          color: var(--Primary, #02b27f);
        }
      }
    }

    ${ViewPoolBtn} {
      background: #02b27f;
    }
  }
`;

export default YieldVault;
