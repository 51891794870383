import Tab from "components/Tab/Tab";
import Comparison from "pages/Markets/FundingTab/Comparison";
import Overview from "pages/Markets/FundingTab/Overview";
import { useState } from "react";
import styled from "styled-components";

const OVERVIEW = "Overview";
const COMPARISON = "Comparison";

const OPTION = [OVERVIEW, COMPARISON];
const OPTION_LABELS = {
  [OVERVIEW]: OVERVIEW,
  [COMPARISON]: COMPARISON,
};

const FundingTab = () => {
  const [option, setOption] = useState(OVERVIEW);

  const onOptionChange = (option) => {
    setOption(option);
  };

  const renderedComponent = {
    [OVERVIEW]: <Overview />,
    [COMPARISON]: <Comparison />,
  }[option];

  return (
    <Wrapper>
      <Tab1 options={OPTION} option={option} optionLabels={OPTION_LABELS} onChange={onOptionChange} />
      {renderedComponent}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  background-color: #1e1e1e;
  border-radius: 16px;
`;

const Tab1 = styled(Tab)`
  grid-template-columns: 1fr 1fr;
  height: 44px;
  text-align: center;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  border-radius: 4px;
  background: var(--natural-1, #2e3033);
  user-select: none;
  overflow: hidden;
  box-sizing: content-box;
  margin-bottom: 16px;

  * {
    user-select: none;
  }

  > div {
    height: 44px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    border-radius: 10px;

    color: var(--Text-Text_Secondary, #919499);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }

  .active {
    border: 2px solid #41454d;
    color: var(--Text-Text_Secondary, #fff);
  }
`;

export default FundingTab;
