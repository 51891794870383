import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { decimalsRules, getBaseOrderlyAsset, importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import moment from "moment";
import { useMemo } from "react";
import { commify } from "@ethersproject/units";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
const ClosedPositionItemMobileV3 = (props) => {
  const { setSelectedPosition, position, setPositionToShare, setIsPositionShareModalOpen, handleSelectedPosition } =
    props;
  const tokenSymbol = getBaseOrderlyAsset(position.symbol);
  const isLong = position.max_position_qty > 0 ? true : false;

  const realPnl = position?.realized_pnl || 0;
  const netPnL =
    (position?.realized_pnl || 0) - (position?.accumulated_funding_fee || 0) - (position?.trading_fee || 0);
  const isLoss = netPnL < 0;
  const pnlPrefix = isLoss ? "-" : "+";
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  return (
    <div className="App-card">
      <div className="App-card-title">
        <div className={`${isLong ? `long-tag` : `short-tag`}`} style={{ top: 13 }} />
        <span className="Exchange-list-title" style={{ paddingLeft: "4px" }}>
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="" /> {tokenSymbol}/USDC{" "}
          <span className="cancel-order-txt">
            {position?.type === "liquidated"
              ? "Liquidated"
              : position?.position_status === "partial_closed"
              ? "Partial Closed"
              : "Closed"}
          </span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="App-card-row">
          <div className="label">
            <span>Closed/Max Closed</span>
          </div>
          <div>
            {Math.abs(position.closed_position_qty)}/{Math.abs(position.max_position_qty)}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Open</span>
          </div>
          <div>
            {formatAmount(
              parseValue(position?.avg_open_price || 0, 30),
              30,
              decimalsRules(position?.avg_open_price),
              true
            ).replace("-", "")}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Close</span>
          </div>
          <div>
            {formatAmount(
              parseValue(position?.avg_close_price || 0, 30),
              30,
              decimalsRules(position?.avg_close_price),
              true
            ).replace("-", "")}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Real. PnL</span>
          </div>
          <div>
            <div
              className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
              style={{
                color: position?.realized_pnl < 0 ? "#E43E53" : "#C1FF99 ",
              }}
            >
              <Tooltip
                handle={
                  <span>
                    {pnlPrefix}
                    {formatAmount(parseValue(netPnL, 30), 30, 2, true).replace("-", "").replace("-", "")}{" "}
                    <span className="muted">USDC</span>
                  </span>
                }
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Real. PnL`}
                        value={`${realPnl > 0 ? "" : "-"}
                                 ${formatAmount(parseValue(realPnl, 30), 30, 2, true)
                                   .replace("-", "")
                                   .replace("-", "")} USDC`}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={`Funding Fee`}
                        value={commify(limitDecimals(position.accumulated_funding_fee), 2) + " USDC"}
                        showDollar={false}
                      />
                      <StatsTooltipRow
                        label={`Trading Fee`}
                        value={commify(limitDecimals(position.trading_fee, 2)) + " USDC"}
                        showDollar={false}
                      />
                    </>
                  );
                }}
              />
            </div>
            {/* <div className={`fz-12 bold ${!isLoss ? "positive" : "negative"}`}>
              {limitDecimals(
                (Math.abs(position?.realized_pnl) /
                  (Math.abs(position?.closed_position_qty) * Math.abs(position?.avg_close_price))) *
                100,
                2
              )}
              %
            </div> */}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Time Opened</span>
          </div>
          <div>
            <div>{moment(new Date(position?.open_timestamp)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position?.open_timestamp)).format(timeFormat)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Time Closed</span>
          </div>
          <div>
            <div>{moment(new Date(position?.last_update_time)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position?.last_update_time)).format(timeFormat)}</div>
          </div>
        </div>
        {/* <div className="App-card-row">
          <div className="label">
            <span>Updated Time</span>
          </div>
          <div>
            <div>{moment(new Date(position?.last_update_time)).format(dateFormat)}</div>
            <div className="muted fz-12 bold">{moment(new Date(position?.last_update_time)).format(timeFormat)}</div>
          </div>
        </div> */}
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(position)}
          // disabled={position?.size == 0}
        >
          <span>View</span>
        </button>
        <button
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedPosition(position);
            setIsPositionShareModalOpen(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          {/* Share */}
        </button>
      </div>
    </div>
  );
};
export default ClosedPositionItemMobileV3;
