import { useWeb3React } from "@web3-react/core";
import {
  CLOSED_POSITION,
  OPEN_ORDER,
  OPEN_POSITION,
  SECONDS_PER_DAY,
  TRADE_ACTIVITY,
  getPageTitle,
  getTimestampsWithDate
} from "lib/legacy";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { useChainId } from "lib/chains";
import { formatAmount, formatNumber, limitDecimals, parseValue } from "lib/numbers";

import SEO from "components/Common/SEO";

import FooterV2 from "components/Footer/Footer";
import icClock from "img/ic_clock.svg";


// import ReaderMsp from "abis/ReaderMsp.json";

import { useAccount, useChains, useCollateral, useDeposit, usePositionStream, usePrivateQuery } from "@orderly.network/hooks";
import { useOrderlyNetworkConnectionContext } from "App/OrderlyNetworkConnectionWrapper";
import TransactionDepositModalOrderly from "components/DepositModalOrderly";
import Tooltip from "components/Tooltip/Tooltip";
import WithdrawModalOrderly from "components/WithdrawModalOrderly";
import { useThemeContext } from "contexts/ThemeProvider";
import iconUSDC from "img/ic_usdc_24.svg";
import moment from "moment";
import { ExchangeMergePortfolio } from "pages/ExchangeMerged/ExchangeMergePortfolio";
import FundingHistory from "pages/ExchangeMerged/FundingHistory";
import "styles/AccountOverview.scss";
import PortfolioChart from "./PortfolioChart";
import { commify } from "@ethersproject/units";
export const TAB_OPTIONS = [
  OPEN_POSITION,
  OPEN_ORDER,
  CLOSED_POSITION,
  TRADE_ACTIVITY,
  // DEPOSITS,
  // WITHDRAWS,
  // CASH_CASHOUT,
];

const FILTER_BY_OPTIONS = [
  {
    label: "All time",
    value: null,
  },
  {
    label: "24 Hours",
    value: moment().unix() - SECONDS_PER_DAY,
  },
  {
    label: "7 Days",
    value: moment().unix() - SECONDS_PER_DAY * 7,
  },
  {
    label: "1 Month",
    value: moment().unix() - SECONDS_PER_DAY * 30,
  },
];

export default function PortfolioOrderly({
  setPendingTxns,
  connectWallet,
  savedSlippageAmount,
  savedSelectedDexes,
  savedIsPnlInLeverage,
  savedShowPnlAfterFees,
  pendingTxns,
}) {
  const { active, library, account } = useWeb3React();

  //----------
  const { chainId } = useChainId();
  const { isLightTheme, lightThemeClassName } = useThemeContext();

  const [chains] = useChains("mainnet");
  const {
    account: accountInfo,
    state: { status },
  } = useAccount();
  const token = useMemo(
    () => chains.find((item) => item.network_infos.chain_id === accountInfo.chainId)?.token_infos[0],
    [chains, accountInfo.chainId]
  );
  const deposit = useDeposit({
    address: token?.address,
    decimals: token?.decimals,
    srcToken: token?.symbol,
    srcChainId: Number(accountInfo.chainId),
  });
  const { checkAccount } = useOrderlyNetworkConnectionContext();
  const [isWithdrawModalOrderly, setIsWithdrawModalOrderly] = useState(false);
  const [isDepositModalOrderly, setIsDepositModalOrderly] = useState(false);
  const [numberOfDay, setNumberOfDay] = useState(7);
  const [modalType, setModalType] = useState("withdraw");
  const { freeCollateral, totalValue } = useCollateral();

  const collateralData = [
    {
      symbol: "USDC",
      marginBallance: 0,
      locked: 0,
      withdraw: 0,
      img: iconUSDC,
    },
  ];
  const styleTick = useMemo(() => {
    if (true) {
      const marginUsage = parseFloat(formatAmount(parseValue(0, 30), 30, 30));
      const goal = (marginUsage * 180) / 250;

      return {
        transform: `translateX(calc(-50% - 8px)) rotate(${Math.floor(goal)}deg)`,
      };
    }
    return {};
  }, []);
  const [orderlyPosition] = usePositionStream();
  const TAB_FILTER = [
    {
      name: "7D",
      value: 7,
    },
    {
      name: "30D",
      value: 30,
    },
    {
      name: "90D",
      value: 90,
    }
  ]
  const { targetDayTimestamp, targetDayFormatted, endOfToday, endOfTodayFormatted } = getTimestampsWithDate(numberOfDay);
  const dailyInfo = usePrivateQuery(
    `/v1/client/statistics/daily?page=1&size=10&start_date=${targetDayFormatted}&end_date=${endOfTodayFormatted}`
  );
  const balance24hChange = useMemo(() => {
    if (dailyInfo?.data && totalValue !== null) {
      return totalValue - (dailyInfo?.data?.[0]?.account_value || 0)
    }
    return 0;
  }, [dailyInfo, totalValue])
  const riskRate = useMemo(() => {
    if (
      orderlyPosition?.aggregated &&
      orderlyPosition?.aggregated?.margin_ratio &&
      orderlyPosition?.aggregated?.maintenance_margin_ratio
    ) {
      return orderlyPosition?.aggregated.maintenance_margin_ratio / orderlyPosition?.aggregated.margin_ratio;
    }
    return 0;
  }, [orderlyPosition?.aggregated]);
  return (
    <SEO title={getPageTitle("Portfolio")}>
      {isDepositModalOrderly && (
        <TransactionDepositModalOrderly isVisible={isDepositModalOrderly} setIsVisible={setIsDepositModalOrderly} />
      )}
      {isWithdrawModalOrderly && (
        <WithdrawModalOrderly isVisible={isWithdrawModalOrderly} setIsVisible={setIsWithdrawModalOrderly} />
      )}

      <Wrapper className={`${lightThemeClassName} Portfolio default-container page-layout`}>

        <div className="Portfolio-heading">
          <div className="Portfolio-heading-text">Portfolio</div>
          <div className={`tab-filters`}>
            {TAB_FILTER.map((tab) => {
              return (
                <div className={`tab-filter ${tab.value === numberOfDay ? "tab-filter-active" : ""}`}
                  onClick={() => setNumberOfDay(tab.value)}
                  key={tab.value}>
                  {tab.name}
                </div>
              );
            })}
          </div>

        </div>
        <div className="Portfolio-stats">
          <div className="Portfolio-stats-top">
            <div className="Portfolio-stats-top-left">
              <div className="margin-balance">
                <div className="label "> Margin Balance :</div>
                <div className="balance-wallet-amount">
                  <div className="balance-wallet-amount-top bold" style={{ color: 'white' }}>
                    {formatAmount(parseValue(totalValue || 0, 18), 18, 2, true)} <span>USDC</span>
                  </div>
                  <div className={`balance-wallet-amount-bottom ${balance24hChange > 0 ? "positive" : balance24hChange < 0 ? "negative" : ""}`}>{balance24hChange > 0 ? "+" : ""}{commify(limitDecimals(balance24hChange, 2))} <span className="muted">USDC (24h)</span></div>
                </div>
              </div>
              <div className="dash-line"></div>

              <div className="Portfolio-stats-top-margin-item">
                {" "}
                <div className="clock">
                  <div className="portfolio-card-clock">
                    <div className="clock">
                      <img src={icClock} alt="icClock" />
                      <div className="clock-tick" style={styleTick}>
                        <div className="clock-tick-main" />
                        <div className="clock-tick-dot" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="muted-color label">Margin Ratio</div>
                <Tooltip
                  renderContent={() => (
                    <div>The Risk rate is used to assess the risk level of an account.
                      When the Risk rate reaches 100%, the account will be liquidated Risk rate = Maintenance margin ratio / Margin ratio * 100%</div>
                  )}
                  handle={
                    <div className="ratio-value">
                      {" "}
                      {riskRate
                        ? formatNumber(limitDecimals(riskRate, 2))
                        : "-"}
                      %
                    </div>
                  }
                  position="center-top"
                  className="excep"
                />
              </div>
              <div className="orderly-action">
                <button className="default-btn" onClick={() => setIsDepositModalOrderly(true)}>
                  Deposit
                </button>
                <button className="border-btn" onClick={() => setIsWithdrawModalOrderly(true)}>
                  Withdraw
                </button>
                {/* <button className="border-btn">Transfer</button> */}
              </div>
            </div>
            <div className="Portfolio-stats-top-right">
              <PortfolioChart dailyInfo={dailyInfo} account={account} chainId={chainId} numberOfDay={numberOfDay} totalValue={totalValue} />
            </div>
          </div>
        </div>


        <div className="Portfolio-history">
          <div className="Portfolio-history-heading">
            <div className="Portfolio-history-title">History</div>
          </div>
        </div>
        <ExchangeMergePortfolio
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          savedShowPnlAfterFees={savedShowPnlAfterFees}
          savedSlippageAmount={savedSlippageAmount}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          numberOfDay={numberOfDay}
        />

        <FundingHistory numberOfDay={numberOfDay} />
      </Wrapper>
      <FooterV2 />
    </SEO>
  );
}

const Wrapper = styled.div`
  .pagination-v2 {
    margin: 16px 24px;
  }
  .Exchange-list.small {
    padding-left: 0;
    padding-right: 0;
    .App-card {
      &:not(:first-child) {
        border-top: 1px dashed var(--Border, #2B3139);
      }
    }
  }
  .tab-filters {
    display: flex;
    gap: 16px;
    align-items: center;
    .tab-filter {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: #848E9C;
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
    .tab-filter-active {
      color: #1EB2FC;
    }
  }
  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  .collaterals {
    margin-top: 8px;
    background-color: #181a20;

    border: 1px solid #2B3139;
    .action {
      text-align: right;
    }
    .collaterals-header {
      border-bottom: 1px solid var(--Border, #2B3139);
      background: var(--Nature-2, #1d1f26);
      padding: 8px 12px;
      gap: 16px;
      display: flex;
      @media screen and (max-width: 700px) {
        display: none;
      }
      > div {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        flex: 1;
        color: #848e9c;
      }
    }
    .collaterals-body {
      .table-f-item {
        div {
          display: flex !important;
          align-items: center !important;
          height: fit-content !important;
        }
        @media screen and (max-width: 700px){
          /* div {
            width: 100%;
          } */

        }
      }
      .collaterals-row {
        .assets {
          display: flex;
          align-items: center;
          gap: 4px;
          img {
            width: 24px;
            height: 24px;
          }

          span {
            line-height: 24px;
          }
        }
        padding: 8px 12px;
        gap: 16px;
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        align-items: center;
        &:not(:first-child) {
          border-top: 1px dashed var(--Border, #2B3139);
        }
        @media screen and (max-width: 700px) {
          flex-direction: column;
          .time,
          .TxID,
          .Type,
          .payment-rate,
          .payment-type,
          .Amount,
          .funding-fee,
          .Status {
            width: 100%;
            flex:none;
            &:before {
              color: #848e9c;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
          }
          .time {
            &:before {
              content: "Time";
            }
          }
          .TxID {
            &:before {
              content: "TxID";
            }
          }
          .Type {
            &:before {
              content: "Type";
            }
          }
          .Amount {
            &:before {
              content: "Amount";
            }
          }
          .Status {
            &:before {
              content: "Status";
            }
          }
          .payment-rate {
            &:before {
              content: "Funding Rate / Annual Rate";
            }
          }
          .payment-type {
            &:before {
              content: "Payment Type";
            }
          }
          .funding-fee {
            &:before {
              content: "Funding Fee (USDC)";
            }
          }
          .margin-ballance {
            &:before {
              content: "Margin Balance";
              color: #848e9c;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
          }
          .locked {
            &:before {
              content: "Locked";
              color: #848e9c;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
          }
          .withdraw {
            &:before {
              content: "Withdrawable";
              color: #848e9c;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
          }
          > div {
            display: flex;
            justify-content: space-between;
          }
          .assets {
            width: 100%;
            justify-content: flex-end;
          }
        }
        > div {
          flex: 1;
        }
        .action {
          display: flex;
          gap: 12px;
          justify-content: flex-end;
          font-weight: 500;
          color: #0ecb81;
          cursor: pointer;
        }
      }
    }
  }
  padding-top: 48px;
  .wallet-amount {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .Portfolio-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;

    .Portfolio-heading-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }

    .Portfolio-heading-desc {
      display: flex;
      align-items: center;
      text-decoration: none;

      color: #919499;
      font-size: 13px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
      cursor: pointer;

      /* &:hover {
        color: #1EB2FC;
        color: #1EB2FC;

        svg {
          g {
            opacity: 1;
          }

          path {
            fill: #1EB2FC;
            fill: #1EB2FC;
          }
        }
      } */
    }
  }

  .Portfolio-stats {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .divider {
      margin: 0;
      border-bottom: none;
    }
  }

  .Portfolio-stats-top {
    width: 100%;
    display: grid;
    grid-template-columns: 424px 1fr;
    gap: 24px;

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      gap: 16px;
    }
    > div {
      background-color: #181a20;
      padding: 24px;
      border: 1px solid #2B3139;
    }
  }
  .wallet-amount-tooltip {
    .Tooltip-handle {
      align-items: flex-end;
    }
  }

  .Portfolio-stats-top-left {
    @media screen and (max-width: 700px) {
      padding: 16px;
    }
    .dash-line {
      width: 100%;
      border-top: 1px dashed var(--Border, #2B3139);
      margin: 24px 0;
    }
    .margin-balance {
      display: flex;
      justify-content: space-between;
      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #848e9c;
      }
    }
    .balance-wallet-amount {
      &-top {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #848e9c;
        span {
          color: #848e9c;
          font-size: 12px;
        }
      }
      &-bottom {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        span {
          color: #848e9c;
        }
      }
    }
  }
  .Portfolio-stats-top-margin-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    .label {
      font-weight: 500;
      line-height: 140%;
    }
    .ratio-value {
      color: #0ecb81;
      font-weight: 700;
      line-height: 140%;
      font-size: 20px;
    }
    .clock {
      width: 78px;
      img {
        width: 100%;
      }
    }
  }
  .orderly-action {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    > button {
      flex: 1;
    }
  }

  .Portfolio-stats-top-left-item {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    .Tooltip-handle:has(p) {
      margin-right: 4px;
    }
    line-height: 140%; /* 19.6px */

    & + .Portfolio-stats-top-left-item {
      margin-top: 16px;
    }

    .portfolio-card-clock {
      img {
        width: 24px;
        height: 20px;
      }
    }
  }

  .Portfolio-stats-top-right {
    @media screen and (max-width: 700px) {
      padding: 16px;
    }
  }

  .Portfolio-stats-bottom {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }

  .Portfolio-stats-bottom-item {
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; /* 22.4px */

    & > .Tooltip .Tooltip-handle {
      display: flex;
      align-items: center;
    }

    & > span {
      display: block;
      color: var(--Text-Text_Secondary, #919499);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-bottom: 8px;
    }
  }

  .Portfolio-stats-bottom-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: 16px;
  }

  .Portfolio-summary {
    display: grid;
    grid-template-columns: 424px 1fr;
    gap: 24px;
    .portfolio-card-box-title {
      background: #2e6eee;
      border-radius: 16px 16px 0 0;
      padding: 24px;
      font-size: 14px;

      font-weight: 500;
      line-height: 140%;
      .portfolio-card-total {
        font-size: 24px;
        margin-top: 8px;
        font-weight: 700;
        line-height: 150%;
      }
    }

    .portfolio-card {
      .portfolio-card-top1 {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 16px;
        background: #18191a;
      }

      /*
      & > * {
        display: flex;
        padding: 24px;
      } */
      .portfolio-card-rows {
        .Tooltip-handle {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          color: #919499;
        }
        display: flex;
        padding: 24px 24px 16px 24px;
        &.portfolio-card-rows2 {
          padding: 16px 24px 0px 24px;
          border-top: 1px solid var(--Border, #2B3139);
        }
        &.portfolio-card-rows1 {
          border-top: 1px solid var(--Border, #2B3139);
          padding: 16px 24px;
        }
        justify-content: space-between;

        .portfolio-card-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;

          .portfolio-card-key {
            color: #919499;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            .Tooltip-handle {
              color: #919499;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }

          .portfolio-card-heading {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .Tooltip-handle {
              color: #919499;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }

            .portfolio-card-connected,
            .portfolio-card-disconnected {
              color: var(--Primary, #c1ff99);
              font-size: 14px;
              font-weight: 500;
              line-height: 120%; /* 16.8px */
              display: flex;
              align-items: center;
              gap: var(--Base-Unit-XS-8, 8px);

              .portfolio-card-dot {
                width: var(--Base-Unit-XS-8, 8px);
                height: var(--Base-Unit-XS-8, 8px);
                background-color: #c1ff99;
                border-radius: 50%;
              }
            }

            .portfolio-card-disconnected {
              color: #919499;

              .portfolio-card-dot {
                background-color: #919499;
              }
            }
          }

          .portfolio-card-top {
            flex-direction: column;
            align-items: stretch;
            gap: 16px;
            border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));

            .portfolio-card-box {
              display: flex;
              flex-direction: column;
              align-items: stretch;
              gap: 8px;
            }

            .portfolio-card-total {
              color: var(--Primary, #c1ff99);
              font-size: 24px;
              font-weight: 700;
              line-height: 140%; /* 33.6px */
            }

            .portfolio-card-value {
              font-size: 14px;
              font-weight: 700;
              line-height: 140%; /* 19.6px */
            }
          }
        }
      }

      .portfolio-card-middle {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-top: 16px;
        border-radius: 16px;
        background: var(--Nature-1, #18191a);
        .portfolio-card-heading {
          color: #919499;
        }
        .portfolio-card-clock {
          display: flex;
          align-items: center;
          gap: var(--Base-Unit-S-12, 12px);

          .clock {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 50%;
              transform: translateX(-50%);
              width: 30px;
              height: 19px;
              background-color: #172152;
              border-radius: 10rem 10rem 0 0;
            }

            .clock-tick {
              display: flex;
              align-items: center;
              transform-origin: right;

              width: fit-content;
              position: absolute;
              bottom: -2px;
              left: 50%;
              transform: translateX(calc(-50% - 8px)) rotate(0deg);
              z-index: 1;

              .clock-tick-main {
                height: 2px;
                width: 12px;
                background-color: white;
              }

              .clock-tick-dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: white;
              }
            }
          }

          .portfolio-card-clock-value {
            font-size: 24px;
            font-weight: 700;
            line-height: 150%; /* 33.6px */
          }
        }
      }

      .portfolio-card-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        padding: 24px;

        &.inactive {
          grid-template-columns: 1fr;
        }
      }
    }

    .portfolio-chart {
      .no-record-chart {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
        border-radius: 16px;

        @media screen and (max-width: 767px) {
          padding: 16px;
        }
      }
    }
  }

  .Portfolio-collateral {
    margin-top: 72px;

    .Portfolio-collateral-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      margin-bottom: 16px;
    }
  }

  .Portfolio-history {
    margin: 32px 0 0;

    .Portfolio-history-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-bottom: 16px;

      .Portfolio-history-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 33.6px */
      }
    }

    .Portfolio-history-container {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .Portfolio-history-item {
        display: flex;
        padding: 24px;
        align-items: center;
        gap: var(--Base-Unit-S-12, 12px);
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--Nature-1, #18191a);

        .Portfolio-history-item-info {
          display: flex;
          /* flex-direction: column;
          align-items: flex-start; */
          gap: var(--Base-Unit-XS-8, 8px);
          flex: 1 0 0;

          .Portfolio-history-item-title {
            color: #919499;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }

          .Portfolio-history-item-value {
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 28px */
          }
        }
      }
    }
  }

  .Portfolio-positions {
    background-color: #181a20;
    border: 1px solid #2B3139;
    padding: 0 !important;
    .Box-info {
      @media screen and (max-width: 700px) {
        margin-top: 8px;
      }
    }
    .PositionsList {
      padding: 0;
      .no-array-length {
        padding: 24px;
        margin-top: 0 !important;
      }
    }
    .Portfolio-tabs {
      @media screen and (min-width: 900px) {
        width: fit-content;
      }
      padding: 12px 16px;
      gap: 16px;
      display: flex;
      align-items: flex-start;

      overflow-y: auto;

      position: relative;
      z-index: 1;

      .tab {
        display: flex;
        color: #848e9c;
        justify-content: center;
        align-items: center;
        gap: 4px;

        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: #fff;
        }

        &.active {
          color: #1EB2FC;
        }
      }
    }

    .Portfolio-tab-content {
      position: relative;
      z-index: 1;

      .PositionsList table {
        margin-top: -16px;
        /* border-collapse: separate; */

        border-collapse: collapse;

        .table-content {
          background: transparent;

          td {
            border-bottom: 1px dashed var(--Border, #2B3139) !important;
          }
          &:last-child {
            td {
              border-bottom: none !important;
            }
          }
        }
        .Exchange-list-header {
          /* top: 0; */
          margin-bottom: 12px;
        }

        .Exchange-list-header th span {
          font-size: 12px !important;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 140%;
          color: var(--Text-Text_Secondary, #848e9c) !important;
        }

        .no-data-record {
          border-top: 1px solid #2B3139;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .Portfolio-heading {
    }

    .Portfolio-summary {
      grid-template-columns: 1fr 1fr;
    }

    .Portfolio-collateral {
      margin-top: 64px;
    }

    .Portfolio-history {
      margin-top: 64px;

      .Portfolio-history-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .Portfolio-positions {
    }
  }

  @media screen and (max-width: 767px) {
    .Portfolio-heading {
      flex-direction: column;
      align-items: flex-start;

      .Portfolio-heading-text {
        font-size: 24px;
      }
    }

    .Portfolio-summary {
      grid-template-columns: 1fr;

      .portfolio-card {
        .portfolio-card-top,
        .portfolio-card-middle,
        .portfolio-card-bottom {
          padding: 16px;
          gap: 16px;
        }
      }
    }

    .Portfolio-collateral {
      margin-top: 40px;
    }

    .Portfolio-history {
      margin-top: 40px;

      .Portfolio-history-container {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }

    .Portfolio-positions {
    }
  }
`;
