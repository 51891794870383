import Modal from "components/Modal/Modal";
import React from "react";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import ExchangeInfoRow from "./ExchangeInfoRow";
import moment from "moment";
import { formatAmount, formatAmountByPriceToken, parseValue } from "lib/numbers";
import { BigNumber } from "ethers";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { getTokenDisplayDecimals } from "config/tokens";
import { THEME_KEY } from "config/localStorage";
import { getTokenSymbolFromString } from "domain/tokens";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export const MAPPED_ACTION_TYPES = {
  INCREASE: "Increase Position",
  OPEN: "Open Position",
  ADD_TRAILING_STOP: "Add Trailing Stop",
  DECREASE: "Decrease Position",
  CLOSE: "Close Position",
  DEPOSIT: "Deposit Collateral",
  WITHDRAW: "Withdraw Collateral",
  LIQUIDATE: "Liquidate Position",
};

const PositionHistoryV1 = ({ isVisible, setIsVisible, optionalHeader, rows, position, chainId = DEFAULT_CHAIN_ID }) => {
  const mappedRows = {};

  if (rows) {
    const handledRows = rows.map((row) => {
      return row;
    });

    handledRows.forEach((row) => {
      if (!Array.isArray(mappedRows[row.action])) {
        mappedRows[row.action] = [row];
      } else {
        mappedRows[row.action].push(row);
      }
    });
  }
  return (
    <Modal
      className={`positionHistory-modal`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label={"Position History"}
      optionalHeader={optionalHeader}
    >
      <div class="history-container">
        {Object.keys(mappedRows)
          .reverse()
          .map((type, index, origin) => {
            return (
              <>
                <RowsDropdown
                  key={type}
                  handler={
                    <RowsDropdownHandler>
                      {MAPPED_ACTION_TYPES[type]} ({mappedRows[type].length})
                    </RowsDropdownHandler>
                  }
                >
                  {mappedRows[type].reverse().map((row) => {
                    const size = parseValue(row.size || 0, 30);
                    const averagePrice = parseValue(row.price || 1, 30);
                    const collateral = parseValue(row.collateral || 1, 30);
                    const triggerAmount = size.mul(parseValue(1, 30)).div(parseValue(position.entryPrice || 1, 30));
                    const quantity = size.mul(parseValue(1, 30)).div(averagePrice);
                    const leverage =
                      row?.collateral && collateral.gt(0) ? size.mul(parseValue(1, 30)).div(collateral) : 0;
                    const profitLoss = parseValue(row?.realisedPnl || 0, 30);
                    const _roi = parseValue(row?.roi || 0, 30);
                    const roi = formatAmount(_roi, 30, 2, true);
                    if (row.action === "OPEN") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) > 0 ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          {/* <ExchangeInfoRow label={`Leverage`}>{formatAmount(leverage, 30, 2, true)}X</ExchangeInfoRow> */}
                          <ExchangeInfoRow label={`Open Price`}>
                            ${formatAmount(averagePrice, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Collateral`}>
                            ${formatAmount(collateral.sub(parseValue(row?.fee || 0, 30)), 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Size`}>${formatAmount(size, 30, 2, true)}</ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Qty`}>
                            {formatAmount(quantity, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.action === "WITHDRAW") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Leverage`}>
                            <div className="position-list-leve">{formatAmount(leverage, 30, 2, true)}X</div>
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Size`}>${formatAmount(size, 30, 2, true)}</ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Qty`}>
                            {formatAmount(quantity, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.action === "DECREASE") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() == 0 ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            ${formatAmount(parseValue(row.price || 1, 30), 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Type`}>Decrease Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.action === "DEPOSIT") {
                      const collareral = parseValue(row.collateral || 0, 30);
                      // const OldCollareral = collareral.sub(BigNumber.from(row.amount || 0));

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(collareral, 30, 2, true)}
                          </ExchangeInfoRow>
                          {/* <ExchangeInfoRow label={`Old Collateral`}>
                            ${formatAmount(OldCollareral, 30, 2, true)}
                          </ExchangeInfoRow> */}
                        </div>
                      );
                    }

                    if (row.action === "INCREASE") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            ${formatAmount(parseValue(row.price || 1, 30), 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Market</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.action === "CLOSE") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(collateral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              parseValue(row.price || 0, 30),
                              30,
                              position?.currentMarket?.decimals ||
                                getTokenDisplayDecimals(position?.indexToken?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Close Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.action === "LIQUIDATE") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.timestamp * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.timestamp * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(collateral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              parseValue(row.price || 0, 30),
                              30,
                              position?.currentMarket?.decimals ||
                                getTokenDisplayDecimals(position?.indexToken?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(position?.indexToken?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Liquidate Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    return null;
                  })}
                </RowsDropdown>
                {index !== origin.length - 1 && <div className="gray-divider" style={{ marginBlock: 8 }} />}
              </>
            );
          })}
      </div>
    </Modal>
  );
};

export default PositionHistoryV1;
