import Modal from "components/Modal/Modal";
import React from "react";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import ExchangeInfoRow from "./ExchangeInfoRow";
import moment from "moment";
import { formatAmount, formatAmountByPriceToken, parseValue } from "lib/numbers";
import { BigNumber } from "ethers";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { getTokenDisplayDecimals } from "config/tokens";
import { THEME_KEY } from "config/localStorage";
import { getTokenSymbolFromString } from "domain/tokens";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export const MAPPED_ACTION_TYPES = {
  ADD_POSITION: "Increase Position",
  OPEN_POSITION: "Open Position",
  ADD_TRAILING_STOP: "Add Trailing Stop",
  DECREASE_POSITION: "Decrease Position",
  CLOSE_POSITION: "Close Position",
  INCREASE_COLLATERAL: "Deposit Collateral",
  INCREASE_LEVERAGE: "Withdraw Collateral",
  LIQUIDATE_POSITION: "Liquidate Position",
};

const PositionHistory = ({ isVisible, setIsVisible, optionalHeader, rows, position, chainId = DEFAULT_CHAIN_ID }) => {
  const mappedRows = {};
  if (rows) {
    const handledRows = rows.map((row) => {
      if (row.actionType === "EDIT_COLLATERAL") {
        if (row.isPlus) {
          return {
            ...row,
            actionType: "INCREASE_COLLATERAL",
          };
        }
        return {
          ...row,
          actionType: "INCREASE_LEVERAGE",
        };
      }

      return row;
    });

    handledRows.forEach((row) => {
      if (!Array.isArray(mappedRows[row.actionType])) {
        mappedRows[row.actionType] = [row];
      } else {
        mappedRows[row.actionType].push(row);
      }
    });
  }
  return (
    <Modal
      className={`positionHistory-modal`}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label={"Position History"}
      optionalHeader={optionalHeader}
    >
      <div class="history-container">
        {Object.keys(mappedRows)
          .reverse()
          .map((type, index, origin) => {
            return (
              <>
                <RowsDropdown
                  key={type}
                  handler={
                    <RowsDropdownHandler>
                      {MAPPED_ACTION_TYPES[type]} ({mappedRows[type].length})
                    </RowsDropdownHandler>
                  }
                >
                  {mappedRows[type].reverse().map((row) => {
                    const size = BigNumber.from(row.tradeVolume || 0);
                    const averagePrice = BigNumber.from(row.averagePrice || 0);
                    const collateral = BigNumber.from(row.collateral || 0);

                    const quantity = size.mul(parseValue(1, 30)).div(averagePrice);
                    const leverage = size.mul(parseValue(1, 30)).div(collateral);
                    const profitLoss = BigNumber.from(row.profitLoss || 0);
                    const _roi = profitLoss.mul(parseValue(1, 30)).div(collateral).mul(100);
                    const roi = formatAmount(_roi, 30, 2, true);
                    if (row.actionType === "OPEN_POSITION") {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) > 0 ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Leverage`}>{formatAmount(leverage, 30, 2, true)}X</ExchangeInfoRow>
                          <ExchangeInfoRow label={`Open Price`}>
                            $
                            {formatAmount(
                              row.markPrice,
                              30,
                              position?.currentMarket?.decimals || getTokenDisplayDecimals(position?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Collateral`}>
                            ${formatAmount(row.collateral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Size`}>
                            ${formatAmount(row.tradeVolume, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Qty`}>
                            {formatAmount(quantity, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "INCREASE_LEVERAGE" && !row.isPlus) {
                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Leverage`}>
                            <div className="position-list-leve">{formatAmount(leverage, 30, 2, true)}X</div>
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Size`}>
                            ${formatAmount(row.tradeVolume, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Pos.Qty`}>
                            {formatAmount(quantity, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "DECREASE_POSITION") {
                      const triggerAmount = size.mul(parseValue(1, 30)).div(row.markPrice);
                      const profitLoss = BigNumber.from(row.profitLoss || 0);
                      const _roi = profitLoss.mul(parseValue(1, 30)).div(collateral).mul(100);
                      const roi = formatAmount(_roi, 30, 2, true);

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() == 0 ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              row.markPrice,
                              30,
                              position?.currentMarket?.decimals || getTokenDisplayDecimals(position?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Type`}>Decrease Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "INCREASE_COLLATERAL" && row.isPlus) {
                      const collareral = BigNumber.from(row.collateral || 0);
                      const OldCollareral = collareral.sub(BigNumber.from(row.amount || 0));

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(collareral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Old Collateral`}>
                            ${formatAmount(OldCollareral, 30, 2, true)}
                          </ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "ADD_POSITION") {
                      const triggerAmount = size.mul(parseValue(1, 30)).div(BigNumber.from(row.markPrice || 0));

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              row.markPrice,
                              30,
                              position?.currentMarket?.decimals || getTokenDisplayDecimals(position?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Market</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "CLOSE_POSITION") {
                      const triggerAmount = size.mul(parseValue(1, 30)).div(row.markPrice);
                      const profitLoss = BigNumber.from(row.profitLoss || 0);
                      const _roi = profitLoss.mul(parseValue(1, 30)).div(collateral).mul(100);
                      const roi = formatAmount(_roi, 30, 2, true);

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(row.collateral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              row.markPrice,
                              30,
                              position?.currentMarket?.decimals || getTokenDisplayDecimals(position?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Close Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    if (row.actionType === "LIQUIDATE_POSITION") {
                      const triggerAmount = size.mul(parseValue(1, 30)).div(row.markPrice);
                      const profitLoss = BigNumber.from(row.profitLoss || 0);
                      const _roi = profitLoss.mul(parseValue(1, 30)).div(collateral).mul(100);
                      const roi = formatAmount(_roi, 30, 2, true);

                      return (
                        <div className="square-container">
                          <ExchangeInfoRow label={`Date/Time`}>
                            {moment(new Date(row.createdAt * 1000)).format(dateFormat)}{" "}
                            {moment(new Date(row.createdAt * 1000)).format(timeFormat)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow
                            label={`Realized PnL`}
                            isPositive={profitLoss.gte(0)}
                            textStyled={{
                              color: profitLoss.gt(0) ? "#1EB2FC" : profitLoss.isZero() ? "#fff" : "#E43E53",
                            }}
                          >
                            {profitLoss.gt(0) ? "+" : profitLoss.isZero() ? "" : "-"}$
                            {formatAmount(profitLoss, 30, 2, true).replace("-", "")} ({_roi.gt(0) && "+"}
                            {roi}%)
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Collateral`}>
                            ${formatAmount(row.collateral, 30, 2, true)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Price`}>
                            $
                            {formatAmount(
                              row.markPrice,
                              30,
                              position?.currentMarket?.decimals || getTokenDisplayDecimals(position?.symbol),
                              true
                            )}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Trigger Amount`}>
                            {formatAmount(triggerAmount, 30, 4, true)} {getTokenSymbolFromString(position?.symbol)}
                          </ExchangeInfoRow>
                          <ExchangeInfoRow label={`Order Type`}>Liquidate Position</ExchangeInfoRow>
                        </div>
                      );
                    }

                    return null;
                  })}
                </RowsDropdown>
                {index !== origin.length - 1 && <div className="gray-divider" style={{ marginBlock: 8 }} />}
              </>
            );
          })}
      </div>
    </Modal>
  );
};

export default PositionHistory;
