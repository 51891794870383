import cx from "classnames";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";

import { commify } from "@ethersproject/units";
import { secondsToHMS } from "components/TradeMerged/ClosedPositionItemV3";
import styled from "styled-components";
import RowsDropdown from "./RowsDropdown";
import { StyledWrap } from "components/Exchange/OrderEditorV3";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export default function PositionClosedV3(props) {
  const { isVisible, position, setIsVisible } = props;
  const tokenSymbol = getBaseOrderlyAsset(position.symbol);
  const isLong = position.max_position_qty > 0;
  const isLoss = position.realized_pnl < 0;
  const pnlPrefix = isLoss ? "-" : "+";
  const duration = secondsToHMS(
    Math.floor(position.close_timestamp / 1000) - Math.floor(position.open_timestamp / 1000)
  );

  return (
    <Wrap className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Position History"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <StyledWrap className="head">
                <div className="header">
                  <div className={`${isLong ? `long-tag` : `short-tag`}`} />
                  <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
                  <div className="position-info">
                    <div className="title">{tokenSymbol}/USDC</div>
                  </div>
                  <span className="cancel-order-txt">
                    {position?.type === "liquidated"
                      ? "Liquidated"
                      : position?.position_status === "partial_closed"
                      ? "Partial Closed"
                      : "Closed"}
                  </span>
                </div>
              </StyledWrap>
            </div>
          </div>
          <div className="content-container" style={{ marginBottom: 24, marginTop: 16 }}>
            <div className="gray-divider" />
            <div className="order-details-container">
              <div className="square-container">
                <ExchangeInfoRow label={`Realized PnL`}>
                  <div
                    className={cx({
                      positive: position.realized_pnl > 0,
                      negative: position.realized_pnl < 0,
                    })}
                  >
                    {pnlPrefix}
                    {commify(limitDecimals(position.realized_pnl, 4)).replace("-", "")}{" "}
                    <span className="muted">USDC</span>
                  </div>
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`ROI`}>
                  <div
                    className={cx({
                      positive: position.realized_pnl > 0,
                      negative: position.realized_pnl < 0,
                    })}
                  >
                    <span>
                      {limitDecimals(
                        (Math.abs(position.realized_pnl) /
                          (Math.abs(position.closed_position_qty) * Math.abs(position.avg_close_price))) *
                        100,
                        2
                      )}
                      %
                    </span>
                  </div>
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Close/MaxClosed`}>
                  {Math.abs(position.closed_position_qty)}/{Math.abs(position.max_position_qty)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Notional`}>
                  {commify(
                    limitDecimals(
                      (position.closed_position_qty * position.avg_close_price)?.toString()?.replace("-", ""),
                      2
                    )
                  )}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Quantity`}>
                  {Math.abs(position.max_position_qty)} {tokenSymbol}
                </ExchangeInfoRow> */}

                <ExchangeInfoRow label={`Avg. Close`}>
                  {formatAmount(
                    parseValue(position.avg_close_price || 0, 30),
                    30,
                    decimalsRules(position.avg_close_price),
                    true
                  ).replace("-", "")}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Open`}>
                  {formatAmount(
                    parseValue(position.avg_open_price || 0, 30),
                    30,
                    decimalsRules(position.avg_open_price),
                    true
                  ).replace("-", "")}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Time Opened`}>
                  {moment(new Date(position.open_timestamp)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.open_timestamp)).format(timeFormat)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Time Closed`}>
                  {moment(new Date(position.last_update_time)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.last_update_time)).format(timeFormat)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Updated Time`}>
                  {moment(new Date(position.last_update_time)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.last_update_time)).format(timeFormat)}
                </ExchangeInfoRow> */}
                <div className="gray-divider" style={{ marginBottom: "4px", marginTop: "8px" }} />
              </div>
              <RowsDropdown handler={<ExchangeInfoRow label={<div className="title">Fees</div>}></ExchangeInfoRow>}>
                <div className="square-container square-fee-container">
                  {" "}
                  <ExchangeInfoRow label={`Funding Fee`}>
                    {position?.accumulated_funding_fee < 0
                      ? "-" + commify(limitDecimals(Math.abs(position.accumulated_funding_fee), 4)) + " "
                      : commify(limitDecimals(position.accumulated_funding_fee, 4))}{" "}
                    USDC
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Trading Fee`}>
                    {commify(limitDecimals(position.trading_fee, 4))} USDC
                  </ExchangeInfoRow>
                </div>
              </RowsDropdown>
            </div>
          </div>
        </Modal>
      )}
    </Wrap>
  );
}
const Wrap = styled.div`
  .content-container {
    @media screen and (max-width: 700px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
`;
