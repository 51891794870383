import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";
import { Trans, t, Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import cx from "classnames";
import ArrowUp from "img/icons/icon-up.svg";
import ChartPad from "img/icons/chart-pad.svg";
import ChartPadLight from "img/chart-pad-light.svg";
import {
  FUNDING_RATE_PRECISION,
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  getPositionKey,
  getPositionContractKey,
  getLeverage,
  getDeltaStr,
  useAccountOrders,
  getPageTitle,
  getMarketTokensInfo,
  getCompletePosition,
  CLOSED_POSITION,
  getMergedPositionV1,
  INCREASE,
  getMergedOrderV1,
  mergelistAssetsVersions,
} from "lib/legacy";
import { getConstant, getExplorerUrl } from "config/chains";
import { approvePlugin, useMinExecutionFee, cancelMultipleOrders, useTrades } from "domain/legacy";

import { getContract } from "config/contracts";

import Reader from "abis/ReaderV2.json";
import VaultV2 from "abis/VaultV2.json";
import Router from "abis/Router.json";
import Token from "abis/Token.json";
import ReaderYslp from "abis/ReaderYslp.json";

import Checkbox from "components/Checkbox/Checkbox";
import SwapBox from "components/Exchange/SwapBox";
import ExchangeTVChart, { getChartToken } from "components/Exchange/ExchangeTVChart";
import PositionsList from "components/Exchange/PositionsList";
import OrdersList from "components/Exchange/OrdersList";
import TradeHistory from "components/Exchange/TradeHistory";
import ExchangeWalletTokens from "components/Exchange/ExchangeWalletTokens";
import ExchangeBanner from "components/Exchange/ExchangeBanner";
import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";

import "./Exchange.css";
import "./Exchange.scss";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import {
  getToken,
  getTokenBySymbol,
  getTokens,
  getWhitelistedTokens,
  TRADE_ONLY_V1_TOKENS,
  TRADE_V1_TOKENS,
} from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import TradingView from "../../components/Exchange/TradingView";
import TradeHistoryV2 from "components/Exchange/TradeHistoryV2";
import OrdersListV2 from "components/Exchange/OrdersListV2";
import ExchangeTVChartPad from "components/Exchange/ExchangeTVChartPad";
import { useMediaQuery } from "react-responsive";
import useMarketAssets from "hooks/useMarketAssets";
import useAssetsPrices from "hooks/useAssetsPrices";
import { useThemeContext } from "contexts/ThemeProvider";

import ExchangeTVChartV2 from "components/ExchangeV2/ExchangeTVChart";
import useUserPosition from "hooks/useUserPosition";
import useUserOrder from "hooks/useUserOrder";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import SwapBoxV2 from "components/ExchangeV2/SwapBox";
import PositionListMerged from "components/TradeMerged/PositionList";
import OrderList from "components/TradeMerged/OrderList";
import TradeActivityList from "components/TradeMerged/TradeActivityList";
import useUserTradeActivity from "hooks/useUserTradeActivity";
import useUserClosedPositions from "hooks/useUserClosedPositions";
import ClosedPositionList from "components/TradeMerged/ClosedPositionList";
import useUserPositionV1 from "hooks/useUserPositionV1";
import useUserClosedPositionsV1 from "hooks/useUserClosedPositionsV1";
import useUserTradeActivityV1 from "hooks/useUserTradeActivityV1";
import useUserOpenOdersV1 from "hooks/useUserOpenOdersV1";
import useUserOrdersInfo, { mergedApiOnchainOrders } from "hooks/useUserOrdersInfo";
import {
  MarketsType,
  useMarkets,
  useOrderStream,
  usePositionStream,
  usePrivateQuery,
  useQuery,
} from "@orderly.network/hooks";
import ExchangeTVChartV3 from "components/ExchangeV2/ExchangeTVChartV3";
import SwapBoxV3 from "components/ExchangeV2/SwapBoxV3";
import { OrderStatus } from "@orderly.network/types";
import SwapBoxWithOrderly from "components/ExchangeV2/SwapBoxWithOrderly";

const { AddressZero } = ethers.constants;

const PENDING_POSITION_VALID_DURATION = 600 * 1000;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;
const TRADE_V1 = "trade-v1";
const TRADE_V2 = "trade-v2";
const TRADE_V3 = "trade-v3";
export const CRYPTO_POOL = "crypto-pool";
export const STABLE_POOL = "stable-pool";
export const ORDERLY_POOL = "stable-pool";
const notifications = {};

function pushSuccessNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function pushErrorNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data;
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  }

  return;
}

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};

function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return;
  }
  const { key } = position;

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key];
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return;
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return;
    }

    position.hasPendingChanges = true;
    position.pendingChanges = pendingChanges;
  }
}

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions = [];
  const positionsMap = {};
  if (!positionData) {
    return { positions, positionsMap };
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    }

    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    };

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }
    // console.log("?????",1 );

    let fundingFee = getFundingFee(position);

    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
    //TODO
    // position.totalFees = position.positionFee.add(position.fundingFee);
    position.totalFees = position.closingFee.add(position.fundingFee);

    position.pendingDelta = position.delta;

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral);

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      });

      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
      }

      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral);

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      });

      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta);

      netValue = netValue.sub(position.fundingFee);

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee);
      }

      position.netValue = netValue;
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    });

    positionsMap[key] = position;

    applyPendingChanges(position, pendingPositions);

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position);
    }
  }

  return { positions, positionsMap };
}

export function getPositionQuery(tokens, nativeTokenAddress) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
    indexTokens.push(getTokenAddress(token, nativeTokenAddress));
    isLong.push(true);
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token, nativeTokenAddress));
      isLong.push(false);
    }
  }

  return { collateralTokens, indexTokens, isLong };
}
let justOpenPositions = [];
let closePositionTempt = [];
export const ExchangeMerged = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    savedShouldDisableValidationForTesting,
    setTradeVersionState,
    setSavedSlippageAmount,
    savedSelectedDexes,
  } = props;
  const theme = useThemeContext();
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey("showBanner", true);
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey("bannerHidden", null);

  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [tradeVersion, setTradeVersion] = useState(TRADE_V2);

  const { active, library, account } = useWeb3React();
  // const account = "0x1B99b649e7b154C6F0efDCaf280F87269F9D4D04"; //FIX ME
  const { chainId } = useChainId();
  const currentAccount = account;
  /// V2 Data
  const token = useMemo(() => {
    return defaultChartToken?.split("/USD")?.[0];
  }, [defaultChartToken]);
  const [isShowConfirmModal, setIsShowConfirmModal] = useLocalStorageSerializeKey("orderly-show-confirm-modal", true);
  // console.log("????", parseValue(1, 30).sub(parseValue(0.5, 30)));
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const [defaultChartToken, setDefaultChartToken] = useLocalStorageSerializeKey(
    [chainId, "default-chart-token-v2"],
    "BTC/USD"
  );
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Short-Collateral-Address-V2",
    getTokenBySymbol(chainId, defaultCollateralSymbol).address
  );
  const [defaultPool, setDefaultPool] = useLocalStorageSerializeKey([chainId, "default-selected-pool"], CRYPTO_POOL);

  const readerYslpAddress = getContract(chainId, "ReaderYslp");
  const { data: userTokenBalances } = useSWR(
    active && [`Exchange:getUserBalances:${active}:${account}`, chainId, readerYslpAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderYslp),
    }
  );
  /// ------------------------------
  const [chooseOrdeBookPrice, setChooseOrderBookPrice] = useState(null);
  const hideBanner = () => {
    const hiddenLimit = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };
  const isPadScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 700px)" });
  useEffect(() => {
    if (new Date() > new Date("2021-11-30")) {
      setShowBanner(false);
    } else {
      if (bannerHidden && new Date(bannerHidden) > new Date()) {
        setShowBanner(false);
      } else {
        setBannerHidden(null);
        setShowBanner(true);
      }
    }
  }, [showBanner, bannerHidden, setBannerHidden, setShowBanner]);

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const readerAddress = getContract(chainId, "Reader");
  const usdyAddress = getContract(chainId, "USDY");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress);

  // const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "Exchange-token-selection-v3",
    defaultTokenSelection
  );
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "Trade-v1-Swap-option", LONG);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );
  const marketAsssets = useMarketAssets();
  const [orderlyMarkets] = useMarkets(MarketsType.ALL);
  // console.log("????", orderlyMarkets);

  const marketAsssetList = Object.keys(marketAsssets).map((key) => marketAsssets[key]);

  const marketPrices = useAssetsPrices();
  const marketTokensInfo = getMarketTokensInfo(marketAsssetList, marketPrices);
  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );
  useEffect(() => {
    if (!TRADE_ONLY_V1_TOKENS[chainId].includes(defaultChartToken) && defaultPool !== CRYPTO_POOL) {
      if (tradeVersion === TRADE_V1) {
        setTradeVersion(TRADE_V2);
      }
    } else {
      if (TRADE_V1_TOKENS[chainId].includes(defaultChartToken) && defaultPool === CRYPTO_POOL) {
        if (tradeVersion !== TRADE_V1) setTradeVersion(TRADE_V1);
        const tokenSymbol = defaultChartToken.split("/")[0];
        const toToken = getTokenBySymbol(chainId, tokenSymbol);
        const tmp = getTokenInfo(infoTokens, toToken.address);
        setChartToken(tmp);
        // console.log("?????", {tmp, chartToken});

        setToTokenAddress(swapOption, toToken.address);
      } else {
        if (TRADE_ONLY_V1_TOKENS[chainId].includes(defaultChartToken) && defaultPool !== CRYPTO_POOL) {
          if (tradeVersion !== TRADE_V1) setTradeVersion(TRADE_V1);
          const tokenSymbol = defaultChartToken.split("/")[0];
          const toToken = getTokenBySymbol(chainId, tokenSymbol);
          const tmp = getTokenInfo(infoTokens, toToken.address);
          setChartToken(tmp);
          setToTokenAddress(swapOption, toToken.address);
        } else {
          const tokenV2 = marketTokensInfo.find((x) => x.symbol === defaultChartToken);
          if (tokenV2) {
            if (tradeVersion !== TRADE_V2) setTradeVersion(TRADE_V2);
          } else if (tradeVersion !== TRADE_V3) setTradeVersion(TRADE_V3);
        }
      }
    }
  }, [chainId, defaultChartToken, defaultPool, marketTokensInfo]);
  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokens = getTokens(chainId);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });

  const { data: positionData, error: positionDataError } = useSWR(
    active && [active, chainId, readerAddress, "getPositions", vaultAddress, account],
    {
      fetcher: contractFetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    }
  );
  const positionsDataIsLoading = active && !positionData && !positionDataError;

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { data: usdySupply } = useSWR([`Exchange:usdySupply:${active}`, chainId, usdyAddress, "totalSupply"], {
    fetcher: contractFetcher(library, Token),
  });

  const orderBookAddress = getContract(chainId, "OrderBook");
  const routerAddress = getContract(chainId, "Router");
  const { data: orderBookApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, orderBookAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { data: positionRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, positionRouterAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens
  );

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId);
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`);
    document.title = title;
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress]);
  const flagOrdersEnabled = true;
  const [orders] = useAccountOrders(flagOrdersEnabled);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  const { positions: tradeV1, isLoading: loadingTradeHistoryV1 } = useUserTradeActivityV1(account, {
    pageSize: 1000,
    page: 1,
    type: "V1",
  });
  const { positions: ordersV1, isLoading: loadingOdersV1 } = useUserOpenOdersV1(account, {
    pageSize: 1000,
    page: 1,
    type: "V1",
  });
  const { positions: myTradesV2, isLoading: loadingTradeHistory } = useUserTradeActivity(account, {
    pageSize: 1000,
    page: 1,
  });
  const mergeList = useMemo(() => {
    let list = [];
    const listV3 =
      orderlyMarkets?.map((i) => ({
        ...i,
        tokenSymbol: i.symbol?.split("_")?.[1],
        symbol: i.symbol?.split("_")?.[1] + "/USD",
        askPrice: parseValue(i?.mark_price || 0, 30),
        decimals: i?.quote_dp,
        price24HChanged: i?.change,
      })) || [];
    list = mergelistAssetsVersions(listV3);
    return list;
  }, [orderlyMarkets]);
  const [orderlyPosition] = usePositionStream();
  const pairInfo = useQuery("/v1/public/info");
  const [ordersOrderLyData] = useOrderStream({
    status: OrderStatus.INCOMPLETE,
  });
  const ordersOrderLy = useMemo(() => {
    if (ordersOrderLyData) return [...ordersOrderLyData];
    return [];
  }, [ordersOrderLyData]);
  const { data: orderlyClosedPositionData } = usePrivateQuery("/v1/position_history?limit=1000");
  const { data: orderlyTradeActivitiesData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=FILLED"
  );
  const { data: orderlyTradeActivitiesCancelData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=CANCELLED"
  );
  const orderlyClosedPosition = useMemo(() => {
    if (orderlyClosedPositionData && orderlyClosedPositionData.length > 0) {
      return orderlyClosedPositionData
        ?.map((item) => ({
          ...item,
          closedAt: Math.floor(item.close_timestamp / 1000),
          isV3: true,
        }));
    }
    return [];
  }, [orderlyClosedPositionData]);
  const orderlyTradeActivities = useMemo(() => {
    if (
      (orderlyTradeActivitiesData && orderlyTradeActivitiesData.length > 0) ||
      (orderlyTradeActivitiesCancelData && orderlyTradeActivitiesCancelData.length > 0)
    ) {
      return [
        ...(orderlyTradeActivitiesData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.created_time / 1000),
        })) || []),
        ...(orderlyTradeActivitiesCancelData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.created_time / 1000),
        })) || []),
      ];
    }
    return [];
  }, [orderlyTradeActivitiesData, orderlyTradeActivitiesCancelData]);
  const tradeActivityMerged = useMemo(() => {
    return [...orderlyTradeActivities].sort((a, b) => b.createdAt - a.createdAt);
  }, [orderlyTradeActivities]);


  const mergedPosition = useMemo(() => {
    return [
      ...(orderlyPosition?.rows?.map((i) => ({ ...i, isV3: true, createdAt: Math.floor(i.timestamp / 1000) })) || []),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [orderlyPosition?.rows]);

  const mergedOrders = useMemo(() => {
    return [
      ...ordersOrderLy?.map((i) => ({
        ...i,
        isV3: true,
        createdAt: Math.floor(i.created_time / 1000),
        timestamp: i.timestamp,
      })),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [ordersOrderLy]);

  const mergedClosePositions = useMemo(() => {
    return [...orderlyClosedPosition].sort((a, b) => b.closedAt - a.closedAt);
  }, [orderlyClosedPosition]);

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;
      let message;
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD into ${tokenSymbol} ${longOrShortText}`;
      } else {
        message = `Increased ${tokenSymbol} ${longOrShortText}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onDecreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD from ${tokenSymbol} ${longOrShortText}.`;
      } else {
        message = `Decreased ${tokenSymbol} ${longOrShortText}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onCancelIncreasePosition(
      account,
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not increase ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;
      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account,
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not decrease ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);
  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });
  const [defaultChartTokenV2, setDefaultChartTokenV2] = useLocalStorageSerializeKey("default-chart-token", "BTC/USD");
  const [isProChart, setIsProChart] = useState(false);

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(chainId, library, cancelOrderIdList, {
          successMsg: `Orders cancelled.`,
          failMsg: `Cancel failed.`,
          sentMsg: `Cancel submitted.`,
          pendingTxns,
          setPendingTxns,
        });
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainId,
      library,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ]
  );

  const approveOrderBook = () => {
    setIsPluginApproving(true);
    return approvePlugin(chainId, orderBookAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg: `Enable orders sent.`,
      failMsg: `Enable orders failed.`,
    })
      .then(() => {
        setIsWaitingForPluginApproval(true);
      })
      .finally(() => {
        setIsPluginApproving(false);
      });
  };

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    setIsPositionRouterApproving(true);
    return approvePlugin(chainId, positionRouterAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true);
      })
      .finally(() => {
        setIsPositionRouterApproving(false);
      });
  };
  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trade";

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, CLOSED_POSITION, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-trade", LIST_SECTIONS[0]);
  const [posOrders, setPosOrders] = useState();
  const [orderPos, setOrderPos] = useState();
  useEffect(() => {
    if (listSection !== ORDERS) {
      setPosOrders(undefined);
    }
  }, [listSection]);
  useEffect(() => {
    if (listSection !== POSITIONS) {
      setOrderPos(undefined);
    }
  }, [listSection]);
  const LIST_SECTIONS_LABELS = {
    [ORDERS]: mergedOrders.length ? `Orders <span class="number-tag">${mergedOrders.length}</span>` : `Orders`,
    [POSITIONS]: mergedPosition.length
      ? `Positions <span class="number-tag">${mergedPosition.length}</span>`
      : `Positions`,
    [CLOSED_POSITION]: `Closed Positions`,
    [TRADES]: `Trade Activities`,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  if (!getToken(chainId, toTokenAddress)) {
    return null;
  }

  const chartV1Select = (token) => {
    setDefaultChartToken(token);
  };
  const chartV2Select = (token) => {
    setDefaultChartToken(token);
  };

  const renderCancelOrderButton = () => {
    if (cancelOrderIdList.length === 0) return;
    return (
      <button
        className="muted font-base cancel-order-btn"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        <Plural value={cancelOrderIdList.length} one="Cancel order" other="Cancel # orders" />
        {cancelOrderIdList.length === 1 ? "Cancel order" : `Cancel ${cancelOrderIdList.length} orders`}
      </button>
    );
  };

  const getListSection = () => {
    return (
      <div>
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="align-right Exchange-should-show-position-lines">
            {renderCancelOrderButton()}
            {!isProChart ? (
              <Checkbox
                isChecked={savedShouldShowPositionLines}
                setIsChecked={setSavedShouldShowPositionLines}
                className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
              >
                <div className="group-actions">
                  <span>Chart positions</span>
                </div>
              </Checkbox>
            ) : null}
          </div>
        </div>

        <div className="group-switch-mobile" onClick={() => setIsProChart(!isProChart)}>
          <div
            style={{
              color: !isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Basic
          </div>
          <span>/</span>
          <div
            style={{
              color: isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Pro
          </div>
        </div>

        {listSection === POSITIONS && (
          <PositionListMerged
            userTokenBalances={userTokenBalances}
            closePositionTempt={closePositionTempt}
            positions={orderPos || mergedPosition}
            setPendingTxns={setPendingTxns}
            loading={positionsDataIsLoading}
            chainId={chainId}
            library={library}
            account={account}
            active={active}
            marketTokensInfo={marketTokensInfo}
            marketAsssets={marketAsssets}
            nativeTokenAddress={nativeTokenAddress}
            orders={orders}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            positionsMap={positionsMap}
            pendingTxns={pendingTxns}
            infoTokens={infoTokens}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            positionRouterApproved={positionRouterApproved}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            approvePositionRouter={approvePositionRouter}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            approveOrderBook={approveOrderBook}
            isPluginApproving={isPluginApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            orderBookApproved={orderBookApproved}
            flagOrdersEnabled={flagOrdersEnabled}
            totalTokenWeights={totalTokenWeights}
            usdySupply={usdySupply}
            savedSlippageAmount={savedSlippageAmount}
            v1Orders={[]}
            v2Orders={[]}
            setPosOrders={setPosOrders}
            setListSection={setListSection}
          />
        )}
        {listSection === ORDERS && (
          <OrderList
            account={account}
            orders={posOrders || mergedOrders}
            loading={false}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            positionsMap={positionsMap}
            totalTokenWeights={totalTokenWeights}
            usdySupply={usdySupply}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            marketAsssets={marketAsssets}
            marketTokensInfo={marketTokensInfo}
            nativeTokenAddress={nativeTokenAddress}
            infoTokens={infoTokens}
            positions={[]}
            setOrderPos={setOrderPos}
            setListSection={setListSection}
            positionsV2={[]}
          />
        )}
        {listSection === CLOSED_POSITION && (
          <ClosedPositionList
            account={account}
            positions={mergedClosePositions}
            loading={false}
            chainId={chainId}
            marketAsssets={marketAsssets}
            marketTokensInfo={marketTokensInfo}
          />
        )}
        {listSection === TRADES && (
          <TradeActivityList
            positions={tradeActivityMerged}
            loading={loadingTradeHistory}
            chainId={chainId}
            marketAsssets={marketAsssets}
          />
        )}
      </div>
    );
  };

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
  };
  const onSelectChartToken = (token) => {
    setChartToken(token);
  };

  return (
    <div className="exchange-v2">
      <div className="Exchange page-layout Exchange-updated-ui exchange-v1 trade-v1">
        <div className={`Exchange-content ${showChart ? "chart-pad-show-content" : ""}`}>
          <div className="Exchange-left">
            <ExchangeTVChartV3
              isProChart={isProChart}
              chartToken={chartToken}
              setChartToken={setChartToken}
              swapOption={swapOption}
              chainId={chainId}
              positions={mergedPosition}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              orders={[[]]}
              marketPrices={marketPrices}
              marketAsssetList={marketTokensInfo}
              defaultChartToken={defaultChartToken}
              setDefaultChartToken={setDefaultChartToken}
              marketAsssets={marketAsssets}
              theme={theme}
              setTradeVersionState={setTradeVersionState}
              onSelectSymbolToken={chartV2Select}
              orderlyMarkets={orderlyMarkets}
              mergeList={mergeList}
              pairs={pairInfo?.data}
              setChooseOrderBookPrice={setChooseOrderBookPrice}
              ordersV3={ordersOrderLy}
            />
            <div className="Exchange-lists large">{getListSection()}</div>
          </div>
          <div className="Exchange-right">
            <SwapBoxWithOrderly
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              chainId={chainId}
              active={active}
              connectWallet={connectWallet}
              library={library}
              account={account}
              positionsMap={[]}
              swapOption={swapOption}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
              isPendingConfirmation={isPendingConfirmation}
              setIsPendingConfirmation={setIsPendingConfirmation}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
              savedSlippageAmount={savedSlippageAmount}
              setSavedSlippageAmount={setSavedSlippageAmount}
              savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
              chartToken={chartToken}
              setChartToken={setChartToken}
              userTokenBalances={userTokenBalances}
              marketTokenInfo={marketTokensInfo}
              defaultChartToken={defaultChartToken}
              justOpenPositions={justOpenPositions}
              savedSelectedDexes={savedSelectedDexes}
              setDefaultPool={setDefaultPool}
              defaultPool={defaultPool}
              mergeList={mergeList}
              pairInfo={pairInfo?.data}
              aggregated={orderlyPosition?.aggregated}
              isShowConfirmModal={isShowConfirmModal}
              setIsShowConfirmModal={setIsShowConfirmModal}
              chooseOrdeBookPrice={chooseOrdeBookPrice}
              setChooseOrderBookPrice={setChooseOrderBookPrice}
            />

            <div className="Exchange-wallet-tokens">
              <div className="Exchange-wallet-tokens-content">
                <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />
              </div>
            </div>
          </div>
          <div className="Exchange-lists small">{getListSection()}</div>
        </div>
        {isPadScreen && (
          <div className={`pad-chart-container ${showChart ? "chart-pad-open" : ""}`}>
            <div className="show-ui-content">
              <div className="index-chart">
                <img src={theme?.isLightTheme ? ChartPadLight : ChartPad} alt="" />{" "}
                {isMobileScreen ? "Chart" : "Index chart"}
              </div>
              <div className="chart-tick-container">
                {!isProChart && showChart ? (
                  <Checkbox
                    isChecked={savedShouldShowPositionLines}
                    setIsChecked={setSavedShouldShowPositionLines}
                    className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
                  >
                    <div className="group-actions">
                      <span>Chart positions</span>
                    </div>
                  </Checkbox>
                ) : null}
                <div className="view-chart" onClick={() => setShowChart(!showChart)}>
                  {showChart ? "Hide" : "Show"} chart
                  <img style={showChart ? { transform: "rotate(180deg)" } : {}} src={ArrowUp} alt="" />
                </div>
              </div>
            </div>
            <div style={!showChart ? { display: "none" } : {}}>
              <ExchangeTVChartPad
                isProChart={isProChart}
                chartToken={chartToken}
                setChartToken={setChartToken}
                fromTokenAddress={fromTokenAddress}
                toTokenAddress={toTokenAddress}
                infoTokens={infoTokens}
                swapOption={swapOption}
                chainId={chainId}
                positions={positions}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                orders={orders}
                setToTokenAddress={setToTokenAddress}
              />
            </div>
          </div>
        )}

        {/* <Footer /> */}
      </div>
    </div>
  );
});
