import cx from "classnames";
import { useAffiliateCodes, useUserReferralCodeV2 } from "domain/referrals";
import { toJpeg } from "html-to-image";
import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import shareBgImgV2 from "img/trade/Share_Position1.png";
import downloadImage from "lib/downloadImage";
import { helperToast } from "lib/helperToast";
import { decimalsRules, getAppBaseUrl, getBaseOrderlyAsset, getRootShareApiUrl, getTwitterIntentURL } from "lib/legacy";
import { limitDecimals } from "lib/numbers";
import useLoadImage from "lib/useLoadImage";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import SpinningLoader from "../Common/SpinningLoader";

import { commify } from "@ethersproject/units";
import { IconLogo } from "assets";
import Modal from "../Modal/Modal";
import "./PositionShare.scss";
import "./PositionShareTheme.scss";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 800, canvasHeight: 450, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref?.success && ref?.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}
function PositionClosedShareV3({ position, chainId, account, setIsPositionShareModalOpen, isPositionShareModalOpen }) {
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImgV2);
  const positionRef = useRef();
  const { userReferralCode } = useUserReferralCodeV2(chainId, account);
  const tokenSymbol = getBaseOrderlyAsset(position.symbol);
  const positionToShare = {
    ...position,
    // markPrice: markPrice,
    tokenSymbol: tokenSymbol,
  };

  const tweetLink = getTwitterIntentURL(
    `Latest $${tokenSymbol} trade on @NaviExSonic`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const isMobile = useMedia("(max-width: 800px)");
  useEffect(() => {
    (async function () {
      await handleUploadFile();
    })();
  }, [sharePositionBgImg, userAffiliateCode.success]);

  async function handleDownload() {
    // await handleUploadFile()
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  async function handleCopy() {
    // await handleUploadFile();
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userAffiliateCode);
    copyToClipboard(url);
    helperToast.success(`Link copied to clipboard.`);
  }

  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "ROI",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);

  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };
  async function handleUploadFile() {
    const element = positionRef.current;

    // console.log("????", { element, userReferralCode, sharePositionBgImg, positionToShare });
    if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
      // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
      // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
      const image = await toJpeg(element, config)
        .then(() => toJpeg(element, config))
        .then(() => toJpeg(element, config));
      try {
        const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
        setUploadedImageInfo(imageInfo);
      } catch (err) {
        setUploadedImageInfo(null);
        setUploadedImageError(`Image generation error, please refresh and try again.`);
      }
    }
  }

  return (
    <Modal
      className={`position-share-modal`}
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
        userReferralCode={userReferralCode}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="action-wrap">
        <div className="checkbox-list">
          <div className="checkboxs">
            {checkList.map((item) => {
              return (
                <div key={item.name} className="checkbox-item">
                  {item.checked ? (
                    <img className="img-check" alt="img" src={icChecked} onClick={() => handleCheck(item)} />
                  ) : (
                    <img className="img-check" src={icCheck} alt="img" onClick={() => handleCheck(item)} />
                  )}
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className="actions">
          <button
            disabled={!uploadedImageInfo}
            className={cx("App-button-option  btn-share", { disabled: !uploadedImageInfo })}
          >
            <a
              target="_blank"
              className={cx("tweet-link", { disabled: !uploadedImageInfo })}
              rel="noreferrer"
              href={tweetLink}
            >
              {/* <img src={tweetIc} alt="img" className="icon" /> */}
              {/* <img src={tweetIcDisabled} alt="disabled" className="icon" /> */}
              <span>Share</span>
            </a>
          </button>
          <button disabled={!uploadedImageInfo} className="App-button-option btn-second" onClick={handleDownload}>
            Download
          </button>{" "}
          <button disabled={!uploadedImageInfo} className="App-button-option btn-second" onClick={handleCopy}>
            Copy
          </button>
        </div>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
}) {
  const isTablet = useMedia("(max-width: 1023px)");
  const { tokenSymbol, realized_pnl } = position;
  const isLong = position.max_position_qty > 0 ? true : false;
  const homeURL = getAppBaseUrl();
  const maxPriceDecimals = useMemo(() => {
    let decimals = 1;
    const martkPrice = position?.avg_close_price;
    if (martkPrice) {
      decimals = martkPrice?.toString()?.split(".")?.[1]?.length || 1;
    }
    return decimals;
  }, [position?.avg_close_price]);
  return (
    <div className="relative">
      <div className="share-card-container">
        <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
          <IconLogo />
          <div className="info info-share-container">
            <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
            <div className="divider-title" />
            <div className="title">{tokenSymbol}/USDC</div>

            {/* {checkList[0].checked && <li>{formatAmount(parseValue(leverage, 30), 30, 2, true)}X&nbsp;</li>} */}
          </div>
          {checkList[1].checked && (
            <h3 className={`pnl ${realized_pnl < 0 ? "negative" : "positive"}`}>
              {realized_pnl < 0 ? "-" : realized_pnl > 0 ? "+" : ""}
              {limitDecimals(
                (Math.abs(position.realized_pnl) /
                  (Math.abs(position.closed_position_qty) * Math.abs(position.avg_close_price))) *
                  100,
                2
              )}
              %
            </h3>
          )}

          <div className="prices">
            {checkList[2].checked && (
              <div style={{ width: "fit-content", gap: "4px" }}>
                <div className="muted" style={{ whiteSpace: "nowrap" }}>
                  Entry price:
                </div>
                <div className="price" style={{ whiteSpace: "nowrap" }}>
                  $
                  {commify(
                    limitDecimals(
                      position.avg_open_price?.toString()?.replace("-", ""),
                      decimalsRules(position.avg_open_price)
                    )
                  )}
                </div>
              </div>
            )}
            {checkList[2].checked && (
              <div style={{ width: "fit-content", gap: "4px" }}>
                <div className="muted" style={{ whiteSpace: "nowrap" }}>
                  Mark price:
                </div>
                <div className="price" style={{ whiteSpace: "nowrap" }}>
                  ${commify(position.avg_close_price)?.toString()?.replace("-", "")}
                </div>
              </div>
            )}
            {/* {checkList[0].checked && (
              <div style={{ width: "fit-content" }}>
                <p className="muted" style={{ whiteSpace: "nowrap" }}>
                  Leverage:
                </p>
                <p className="price" style={{ whiteSpace: "nowrap" }}>
                  {formatAmount(parseValue(leverage, 30), 30, 2, true)}X
                </p>
              </div>
            )} */}
            <div className="referral-code">
              <div>
                <QRCodeSVG
                  size={isTablet ? 36 : 56}
                  value={userAffiliateCode?.code ? `${homeURL}/#/?ref=${userAffiliateCode.code}` : `${homeURL}`}
                />
              </div>
              <div className="referral-code-info">
                {/* {success && code ? (
                <>
                  <p className="label">Referral code</p>
                  <p className="code">{code}</p>
                </>
              ) : (
                <>
                  <p className="label">Trade now on</p>
                  <p className="code">Yummy.fi</p>
                </>
              )} */}
                <>
                  <p className="label">Trade now on</p>
                  <p className="code">Yummy.fi</p>
                </>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="referral-code-container">

        </div> */}
      </div>

      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <span>Generating shareable image...</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionClosedShareV3;
