import React from "react";

const DEPTH_LEVEL_MAP = {
  "0-1": [0.0001, 0.001, 0.01, 0.1],
  "1-10": [0.001, 0.01, 0.1, 1],
  "10-100": [0.01, 0.1, 1, 10],
  "100-1000": [0.01, 0.1, 1, 10],
  "1000-10000": [0.01, 0.1, 1, 10],
  "10000-100000": [0.1, 1, 10, 100],
  "100000+": [0.1, 1, 10, 100],
};

// Xác định range key theo giá
const getDepthRangeKey = (price) => {
  if (price < 1) return "0-1";
  if (price < 10) return "1-10";
  if (price < 100) return "10-100";
  if (price < 1000) return "100-1000";
  if (price < 10000) return "1000-10000";
  if (price < 100000) return "10000-100000";
  return "100000+";
};
export const useDepthLevels = (markPrice, symbol) => {
  const [currentRange, setCurrentRange] = React.useState(null);
  const [depthLevels, setDepthLevels] = React.useState([]);

  React.useEffect(() => {
      const rangeKey = getDepthRangeKey(markPrice);

      if (rangeKey !== currentRange) {
          setCurrentRange(rangeKey);

          const levels = DEPTH_LEVEL_MAP[rangeKey]?.map((value) => ({
              name: `${value}`,
              value: value,
          })) || [];

          setDepthLevels(levels);
      }
  }, [markPrice, currentRange, symbol]);

  return depthLevels;
};
