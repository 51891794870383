import styled from "styled-components";
import useBackHistory from "hooks/useBackHistory";
import { useParams } from "react-router-dom";
import cx from "classnames";

import Avatar from "components/Avatar";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl } from "config/chains";

import left_arrow from "img/left_arrow.svg";
import total_trading_volume from "img/analytics/v2/total_trading_volume.svg";
import total_pnl from "img/analytics/v2/total_pnl.svg";
import total_fee_paid from "img/analytics/v2/total_fee_paid.svg";
import left_arrowLight from "img/left_arrowLight.svg";
import total_trading_volumeLight from "img/analytics/v2/total_trading_volumeLight.svg";
import total_pnlLight from "img/analytics/v2/total_pnlLight.svg";
import total_fee_paidLight from "img/analytics/v2/total_fee_paidLight.svg";

import { useThemeContext } from "contexts/ThemeProvider";
import useTraderInfo from "hooks/useTraderInfo";
import { formatAmountInDollar } from "lib/numbers";
import { BigNumber } from "ethers";
import useTraderActions from "hooks/useTraderActions";
import { cloneElement, useCallback, useState } from "react";
import Orders from "./Account/Orders";
import OpenPositions from "./Account/OpenPositions";
import TradeHistories from "./Account/TradeHistories";
import ClosedPositions from "./Account/ClosedPositions";
import useMarketTokensInfo from "hooks/useMarketTokensInfo";
import Pagination from "./Pagination";
import { SkeletonLoadingTable } from "../helper/table";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import { useMediaQuery } from "react-responsive";
import LinkSquareIcon from "components/Icon/LinkSquareIcon";
import BackIcon from "components/Icon/BackIcon";
import moment from "moment";
import { SECONDS_PER_DAY } from "lib/legacy";
import DatePicker from "components/DatePicker";
import { getBeginOfDayInLocal } from "lib/dates";
import RefreshIcon from "components/Icon/RefreshIcon";

const TABS = [
  {
    label: "Open Positions",
    key: "openPositions",
  },
  {
    label: "Orders",
    key: "orders",
  },
  {
    label: "Closed Positions",
    key: "closedPositions",
  },
  {
    label: "Trade History",
    key: "tradeHistories",
  },
];

const DEFAULT_PAGINATION = {
  openPositions: {
    currentPage: 1,
    pageSize: 10,
  },
  closedPositions: {
    currentPage: 1,
    pageSize: 10,
  },
  orders: { currentPage: 1, pageSize: 10 },
  tradeHistories: { currentPage: 1, pageSize: 10 },
  lastDispatch: null,
};

//Only tradeHistories have filter by timestamp
//Empty object means no filter
const DEFAULT_TIMESTAMP = {
  tradeHistories: {},
  forceCall: 0,
};

const FILTER_BY_OPTIONS = [
  {
    label: "1 Day",
    value: moment().unix() - SECONDS_PER_DAY,
  },
  {
    label: "1 Week",
    value: moment().unix() - SECONDS_PER_DAY * 7,
  },
  {
    label: "1 Month",
    value: moment().unix() - SECONDS_PER_DAY * 30,
  },
  {
    label: "3 Months",
    value: moment().unix() - SECONDS_PER_DAY * 30 * 3,
  },
];

export default function Overview({ chainId, isValidChain }) {
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [timestamp, setTimestamp] = useState(DEFAULT_TIMESTAMP);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const { account } = useParams();

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { marketTokensInfo, assets } = useMarketTokensInfo();
  const back = useBackHistory("/analytic-v2/top-traders");
  const { data: traderInfo, isLoading: isTraderInfoLoading } = useTraderInfo(chainId, account);
  const { data: traderActionsData, isLoading: isTraderActionsDataLoading } = useTraderActions(
    chainId,
    account,
    pagination,
    timestamp
  );

  const { openPositions, closedPositions, orders, tradeHistories } = traderActionsData;

  const renderTabSection = () => {
    return {
      openPositions: <OpenPositions positions={openPositions.rows || []} />,
      orders: <Orders orders={orders.rows} />,
      closedPositions: <ClosedPositions positions={closedPositions.rows || []} />,
      tradeHistories: <TradeHistories histories={tradeHistories.rows || []} />,
    }[activeTab.key];
  };

  const handleChangePageSize = useCallback(
    (value) => {
      setPagination((prev) => {
        return {
          ...prev,
          lastDispatch: activeTab.key,
          [activeTab.key]: {
            currentPage: 1,
            pageSize: value,
          },
        };
      });
    },
    [activeTab.key]
  );

  const handlePrev = useCallback(() => {
    setPagination((prev) => {
      const prevActiveTab = prev[activeTab.key];
      return {
        ...prev,
        lastDispatch: activeTab.key,
        [activeTab.key]: {
          ...prevActiveTab,
          currentPage: prevActiveTab.currentPage - 1,
        },
      };
    });
  }, [activeTab.key]);

  const handleNext = useCallback(() => {
    setPagination((prev) => {
      const prevActiveTab = prev[activeTab.key];
      return {
        ...prev,
        lastDispatch: activeTab.key,
        [activeTab.key]: {
          ...prevActiveTab,
          currentPage: prevActiveTab.currentPage + 1,
        },
      };
    });
  }, [activeTab.key]);

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setPagination((prev) => {
      return {
        ...prev,
        lastDispatch: tab.key,
      };
    });
  };

  const handleSelectDate = (date, key) => {
    const dateUnix = getBeginOfDayInLocal(date);

    setTimestamp((prev) => ({
      ...prev,
      [activeTab.key]: {
        ...prev[activeTab.key],
        [key]: dateUnix,
      },
    }));
  };

  const dispatchFilterByDate = () => {
    setTimestamp((prev) => ({
      ...prev,
      forceCall: prev.forceCall + 1,
    }));
  };

  const refreshFilter = () => {
    setTimestamp((prev) => ({
      ...prev,
      [activeTab.key]: {},
      forceCall: prev.forceCall + 1,
    }));

    //set to default pagination
    setPagination((prev) => {
      return {
        ...prev,
        [activeTab.key]: { currentPage: 1, pageSize: 10 },
      };
    });
  };

  const handleChooseDuration = (value) => {
    setTimestamp((prev) => ({
      ...prev,
      [activeTab.key]: {
        from: value,
        to: moment().unix(),
      },
      forceCall: prev.forceCall + 1,
    }));

    //set to default pagination
    setPagination((prev) => {
      return {
        ...prev,
        [activeTab.key]: { ...prev[activeTab.key], currentPage: 1 },
      };
    });
  };

  const isEmpty = !isTraderActionsDataLoading && traderActionsData[activeTab.key]?.rows?.length === 0;

  return (
    <Wrapper className={lightThemeClassName}>
      <div className="breadcrumb">
        <div className="back-btn" onClick={back}>
          {/* <img src={isLightTheme ? left_arrowLight : left_arrow} alt="left_arrow" /> */}
          <BackIcon fill={isLightTheme && "#0D1A16"} />
        </div>
        <div className="account">
          <Avatar address={account} size="48px" chainId={chainId} />
          {isTablet ? account.slice(0, 6) + "..." + account.slice(account.length - 4, account.length) : account}
          <ExternalLink href={`${getExplorerUrl(chainId)}address/${account}`}>
            <LinkSquareIcon />
          </ExternalLink>
        </div>
      </div>
      <div className="summary">
        <div className="summary-box">
          <img src={isLightTheme ? total_trading_volumeLight : total_trading_volume} alt="total_trading_volume" />
          <div className="summary-box-detail">
            <div className="summary-box-title">Total Trading Volume</div>
            <div className="summary-box-value">
              {isTraderInfoLoading ? (
                <div className="skeleton-box" />
              ) : (
                <>{formatAmountInDollar(isValidChain ? traderInfo.volume : undefined, 30, 2, true)}</>
              )}
            </div>
          </div>
        </div>
        <div className="summary-box">
          <img src={isLightTheme ? total_pnlLight : total_pnl} alt="total_pnl" />
          <div className="summary-box-detail">
            <div className="summary-box-title">Total PnL</div>
            <div
              className={cx("summary-box-value", {
                positive: isValidChain && BigNumber.from(traderInfo.profitLoss || 0).gt(0),
                negative: isValidChain && BigNumber.from(traderInfo.profitLoss || 0).lt(0),
              })}
            >
              {isTraderInfoLoading ? (
                <div className="skeleton-box" />
              ) : (
                formatAmountInDollar(isValidChain ? traderInfo.profitLoss : undefined, 30, 2, true, {
                  showPlus: true,
                })
              )}
            </div>
          </div>
        </div>
        <div className="summary-box">
          <img src={isLightTheme ? total_fee_paidLight : total_fee_paid} alt="total_fee_paid" />
          <div className="summary-box-detail">
            <div className="summary-box-title">Total Fee Paid</div>
            <div className="summary-box-value">
              {isTraderInfoLoading ? (
                <div className="skeleton-box" />
              ) : (
                formatAmountInDollar(isValidChain ? traderInfo.fees : undefined, 30, 2, true)
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tabs">
        {TABS.map((tab) => {
          return (
            <div
              className={cx("tab", {
                active: activeTab.key === tab.key,
              })}
              onClick={() => handleActiveTab(tab)}
              key={tab.key}
            >
              {tab.label}{" "}
              {traderActionsData[tab.key].count && isValidChain ? `(${traderActionsData[tab.key].count})` : null}
            </div>
          );
        })}
      </div>
      {isValidChain && (
        <>
          <div className="tab-section">
            {DEFAULT_TIMESTAMP[activeTab.key] && (
              <div className="filter-time">
                <div className="duration">
                  {FILTER_BY_OPTIONS.map(({ label, value }) => (
                    <div
                      key={value}
                      onClick={() => handleChooseDuration(value)}
                      className={cx("duration-item", {
                        active: value === timestamp[activeTab.key].from,
                      })}
                    >
                      {label}
                    </div>
                  ))}
                </div>
                <div className="date-range">
                  <DatePicker
                    label="From:"
                    selected={timestamp[activeTab.key].from * 1000}
                    onChange={(date) => handleSelectDate(date, "from")}
                    placeholderText="MM-DD-YYYY"
                  />
                  <DatePicker
                    label="To:"
                    selected={timestamp[activeTab.key].to * 1000}
                    onChange={(date) => handleSelectDate(date, "to")}
                    placeholderText="MM-DD-YYYY"
                  />
                  <div className="action-btns">
                    <button className="search-btn" onClick={dispatchFilterByDate}>
                      Search
                    </button>
                    <button className="refresh-btn" onClick={refreshFilter}>
                      <RefreshIcon />
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!isTraderActionsDataLoading &&
              cloneElement(renderTabSection(), {
                assets,
                chainId,
                marketTokensInfo,
                isEmpty,
              })}
            {isTraderActionsDataLoading && <SkeletonLoadingTable height={isTablet ? 300 : 66.39} noHeading />}
          </div>
          {isEmpty && <NoItemFound />}
        </>
      )}
      {!isValidChain && <NoItemFound />}

      {!isEmpty && (
        <Pagination
          key={[activeTab.key, pagination[activeTab.key].pageSize]}
          className="pagination-analytics"
          currentPage={pagination[activeTab.key].currentPage}
          pageSize={pagination[activeTab.key].pageSize}
          onChangePageSize={handleChangePageSize}
          onPrev={handlePrev}
          onNext={handleNext}
          totalRows={traderActionsData[activeTab.key].count}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0;

  .breadcrumb {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .back-btn {
      display: flex;
      padding: var(--Border_radius, 4px);
      align-items: flex-start;
      gap: 10px;
      border-radius: var(--Base-Unit-XS-8, 8px);
      background: var(--Nature-2, #191919);
      cursor: pointer;

      &:hover {
        path {
          fill: #34f5ae;
        }
      }
    }

    .account {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */

      display: flex;
      align-items: center;
      gap: var(--Base-Unit-XS-8, 8px);

      a {
        svg {
          &:hover {
            path {
              fill: #4787e3;
            }
          }
        }
      }
    }
  }

  .summary {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .summary-box {
      display: flex;
      padding: 24px;
      align-items: center;
      gap: var(--Base-Unit-S-12, 12px);
      flex: 1 0 0;
      border-radius: 16px;
      background: var(--Nature-1, #191919);

      img {
        width: 32px;
        height: 32px;
      }
    }
    .summary-box-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Base-Unit-XS-8, 8px);

      .summary-box-title {
        color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }
      .summary-box-value {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%; /* 28px */

        .skeleton-box {
          width: 80px;
          height: 28px;
          display: flex;
        }
      }
    }
  }

  .tabs {
    display: flex;
    align-items: flex-start;
    margin: 24px 0 16px;
    overflow-y: auto;

    .tab {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-wrap: nowrap;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: #4787e3;
      }

      &.active {
        background: var(--HighLight, rgba(255, 255, 255, 0.1));
        color: var(--Primary, #1eb2fc);
      }
    }
  }

  .tab-section {
    .filter-time {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      width: 100%;
      margin-bottom: 16px;
      padding: 12px;
      background: #191919;
      border-radius: 16px;
      align-items: center;
      border: 1px solid transparent;

      .duration {
        display: flex;
        align-items: center;
        gap: 20px;

        .duration-item {
          cursor: pointer;

          &:hover,
          &.active {
            color: #1eb2fc;
          }

          &.active {
            pointer-events: none;
          }
        }
      }
    }

    .date-range {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      .action-btns {
        display: flex;
        align-items: center;

        .search-btn,
        .refresh-btn {
          color: var(--Primary, #1eb2fc);
          font-size: 14px;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          background: transparent;
          outline: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #03805b !important;

            svg {
              color: #1eb2fc;
            }
          }

          &:active {
            color: #03805b !important;

            svg {
              color: #1eb2fc;
            }
          }
        }
      }
    }
  }

  .table-state-loading {
    .table-state-loading-head {
      display: flex;
      padding: 0px 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      & > div.skeleton-box {
        height: 16.8px;
      }
    }

    .table-state-loading-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Base-Unit-XS-8, 8px);
      align-self: stretch;
      margin-top: 8px;

      & > div.skeleton-box {
        height: 66.28px;
        border-radius: 4px;
      }
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    margin: 0 auto;

    .summary {
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }

  &.theme--light {
    .breadcrumb {
      .back-btn {
        background: var(--Nature-2, #f2f5f7);
      }

      .account {
        color: #0d1a16;

        img[alt="share"] {
          opacity: 0.7;
        }
      }
    }

    .summary {
      .summary-box {
        background: var(--Nature-1, #fff);

        .summary-box-detail {
          .summary-box-title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }

          .summary-box-value {
            color: #0d1a16;
          }
        }
      }
    }

    .tabs {
      .tab {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        &.active {
          background: var(--HighLight, rgba(2, 178, 127, 0.15));
          color: var(--Primary, #02b27f);
        }

        &:hover {
          color: var(--Primary, #02b27f) !important;
        }
      }
    }

    .tab-section {
      .filter-time {
        background: #fff;
        border: 1px solid rgba(13, 26, 22, 0.07);

        .duration {
          .duration-item {
            &:hover,
            &.active {
              color: #02b27f;
            }
          }
        }
      }

      .date-range {
        .action-btns {
          .search-btn,
          .refresh-btn {
            color: #02b27f;

            &:hover {
              color: #03805b !important;

              svg {
                /* color: #02b27f !important; */

                path {
                  fill: #02b27f;
                }
              }
            }

            &:active {
              color: #03805b !important;

              svg {
                color: #02b27f;
              }
            }
          }
        }
      }

      .col-body {
        .risk.low {
          span {
            color: #02b27f !important;
          }
        }

        .risk.medium {
          span {
            color: #d49007 !important;
          }
        }

        .risk.high {
          span {
            color: #d62f44 !important;
          }
        }
      }
    }
  }
`;
