const SvgIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    className="w-12px h-12px"
    viewBox="0 0 16 16"
  >
    <path fill="currentColor" fillRule="evenodd" d="M7 16h2V9h7V7H9V0H7v7H0v2h7z" clipRule="evenodd"></path>
  </svg>
);

export default SvgIcon;
