import { useEffect, useMemo, useState } from "react";

import { useTPSLOrder } from "@orderly.network/hooks";
import { helperToast } from "lib/helperToast";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import styled from "styled-components";
import { preventSpecialCharacters } from "utils/helpers";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PercentageButtons from "./PercentageButtons";
import "./PositionSeller.css";
import { ANCHOR_PNL, ANCHOR_PRICE } from "./TPSLCardV3";

export default function AddTPSLOrderly(props) {
  const { isVisible, positionsV3, selectedPosition, setIsVisible, pairInfo } = props;
  const position = positionsV3.find((x) => x.symbol === selectedPosition?.symbol);
  useEffect(() => {
    if (!position) setIsVisible(false);
  }, [position]);
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);
  const isLong = position?.position_qty > 0;
  const currentPair = useMemo(() => {
    if (pairInfo && pairInfo?.length > 0 && position?.symbol) {
      return pairInfo.find((x) => x.symbol === position?.symbol);
    }
  }, [pairInfo, position?.symbol]);
  const maxQtyPercision = useMemo(() => {
    if (currentPair) {
      const { base_tick } = currentPair;
      const tickStr = base_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair]);
  const maxPricePercision = useMemo(() => {
    if (currentPair) {
      const { quote_tick } = currentPair;
      const tickStr = quote_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair]);
  const [algoOrder, { setValue, setValues, submit: submit, errors }] = useTPSLOrder(
    position || { symbol: "PERP_BTC_USDC" }
  );
  const [isConfirm, setIsConfirm] = useState(false);
  const posQty = Math.abs(position?.position_qty || 0);
  const markPrice = position?.mark_price || 0;
  const openPrice = position?.average_open_price || 0;

  const [takeProfitPrice, setTakeProfitPrice] = useState("");
  const [stopLossPrice, setStopLossPrice] = useState("");
  const [closeQuantity, setCloseQuantity] = useState("");

  const [tpAnchorInput, setTpAnchorInput] = useState(ANCHOR_PRICE);
  const [slAnchorInput, setSLAnchorInput] = useState(ANCHOR_PRICE);

  const [tpPnl, setTpPnl] = useState("");
  const [slPnl, setSlPnl] = useState("");

  useEffect(() => {
    if (tpAnchorInput === ANCHOR_PRICE) {
      if (Number(takeProfitPrice)) {
        if (isLong && takeProfitPrice > markPrice) {
          const offset = takeProfitPrice - openPrice;
          const roi = (offset * 100) / openPrice;
          setTpPnl(Number(limitDecimals(roi, 2)));
        } else if (!isLong && takeProfitPrice < markPrice) {
          const offset = openPrice - takeProfitPrice;
          const roi = (offset * 100) / openPrice;
          setTpPnl(Number(limitDecimals(roi, 2)));
        } else setTpPnl("");
      } else setTpPnl("");
    } else {
      if (tpPnl && Number(tpPnl)) {
        const offset = (openPrice * tpPnl) / 100;
        if (isLong) {
          if (offset) {
            setTakeProfitPrice(Number(limitDecimals(offset + openPrice, maxPricePercision)));
            setValue("tp_trigger_price", Number(limitDecimals(offset + openPrice, maxPricePercision)));
          } else {
            setTakeProfitPrice("");
            setValue("tp_trigger_price", undefined);
          }
        } else {
          if (offset) {
            setTakeProfitPrice(Number(limitDecimals(openPrice - offset, maxPricePercision)));
            setValue("tp_trigger_price", Number(limitDecimals(openPrice - offset, maxPricePercision)));
          } else {
            setTakeProfitPrice("");
            setValue("tp_trigger_price", undefined);
          }
        }
      } else {
        setTakeProfitPrice("");
        setValue("tp_trigger_price", undefined);
      }
    }
  }, [takeProfitPrice, tpPnl, tpAnchorInput]);
  useEffect(() => {
    if (slAnchorInput === ANCHOR_PRICE) {
      if (Number(stopLossPrice)) {
        if (isLong && stopLossPrice < markPrice) {
          const offset = openPrice - stopLossPrice;
          const roi = (offset * 100) / openPrice;
          setSlPnl(Number(limitDecimals(roi, 2)));
        } else if (!isLong && stopLossPrice > markPrice) {
          const offset = stopLossPrice - openPrice;
          const roi = (offset * 100) / openPrice;
          setSlPnl(Number(limitDecimals(roi, 2)));
        } else setSlPnl("");
      } else setSlPnl("");
    } else {
      if (slPnl && Number(slPnl)) {
        const offset = (openPrice * slPnl) / 100;
        if (isLong) {
          if (offset) {
            setStopLossPrice(Number(limitDecimals(openPrice - offset, maxPricePercision)));
            setValue("sl_trigger_price", Number(limitDecimals(openPrice - offset, maxPricePercision)));
          } else {
            setStopLossPrice("");
            setValue("sl_trigger_price", undefined);
          }
        } else {
          if (offset) {
            setStopLossPrice(Number(limitDecimals(openPrice + offset, maxPricePercision)));
            setValue("sl_trigger_price", Number(limitDecimals(offset + openPrice, maxPricePercision)));
          } else {
            setStopLossPrice("");
            setValue("sl_trigger_price", undefined);
          }
        }
      } else {
        setStopLossPrice("");
        setValue("sl_trigger_price", undefined);
      }
    }
  }, [stopLossPrice, slPnl, slAnchorInput]);
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = parseValue(posQty, 30).mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, maxQtyPercision, false));
    setValue("quantity", formatAmount(value, 30, maxQtyPercision, false));
  };
  const handleChangeQuantityInput = (e) => {
    const value = e.target.value;
    setCloseQuantity(value);
    if (value && Number(value)) {
      setValue("quantity", value);
    } else {
      setValue("quantity", 0);
    }
  };
  const onTriggerValueChangeTP = (evt) => {
    const value = evt.target.value;
    if (tpAnchorInput !== ANCHOR_PRICE) setTpAnchorInput(ANCHOR_PRICE);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        setTakeProfitPrice(evt.target.value || "");
        setValues({
          tp_trigger_price: evt.target.value,
        });
      }
    } else {
      setTakeProfitPrice(evt.target.value || "");
      if (!value && !stopLossPrice)
        setValues({
          tp_trigger_price: evt.target.value,
        });
      else
        setValues({
          tp_trigger_price: evt.target.value,
        });
    }
  };
  const onTriggerValueChangeSL = (evt) => {
    const value = evt.target.value;
    if (slAnchorInput !== ANCHOR_PRICE) setSLAnchorInput(ANCHOR_PRICE);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        // setValue("sl_trigger_price", evt.target.value);
        setValues({
          sl_trigger_price: evt.target.value,
        });
        setStopLossPrice(evt.target.value || "");
      }
    } else {
      if (!value && !stopLossPrice)
        setValues({
          sl_trigger_price: evt.target.value,
        });
      else
        setValues({
          sl_trigger_price: evt.target.value,
        });
      setStopLossPrice(evt.target.value || "");
    }
  };
  const onPnlValueChangeTP = (evt) => {
    const value = evt.target.value;
    if (tpAnchorInput !== ANCHOR_PNL) setTpAnchorInput(ANCHOR_PNL);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= 2) {
        // setValue("sl_trigger_price", evt.target.value);
        setValues({
          tp_ROI: evt.target.value,
        });
        setTpPnl(evt.target.value || "");
      }
    } else {
      if (!value && !takeProfitPrice)
        setValues({
          tp_ROI: evt.target.value,
        });
      else
        setValues({
          tp_ROI: evt.target.value,
        });

      setTpPnl(evt.target.value || "");
    }
  };
  const onPnlValueChangeSL = (evt) => {
    const value = evt.target.value;
    if (slAnchorInput !== ANCHOR_PNL) setSLAnchorInput(ANCHOR_PNL);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= 2) {
        setValues({
          sl_ROI: evt.target.value,
        });
        setSlPnl(evt.target.value || "");
      }
    } else {
      if (!value && !stopLossPrice)
        setValues({
          sl_ROI: evt.target.value,
        });
      else
        setValues({
          sl_ROI: evt.target.value,
        });
      setSlPnl(evt.target.value || "");
    }
  };
  const onHandleAddTPSL = async () => {
    try {
      setIsConfirm(true);
      await submit();
      helperToast.success(
        <div>
          Added TP/SL
          <br />
        </div>
      );
    } catch (error) {
      if (error?.message === "Too many TP/SL order in the same quantity.") {
      } else helperToast.error(error?.message || "Failed to add TP/SL");
    } finally {
      setIsConfirm(false);
      setIsVisible(false);
    }
  };
  const getPrimaryText = () => {
    if (closeQuantity === "") return isConfirm ? "Adding..." : "Add TP/SL";
    if (!Number(takeProfitPrice) && !Number(stopLossPrice)) {
      return "Enter trigger price";
    }
    if (!Number(closeQuantity)) {
      return "Enter quantity";
    }

    if (posQty && closeQuantity && closeQuantity > posQty) {
      return "Amount exceeds balance";
    }
    if (errors) {
      const messages = Object.values(errors)
        .map((item) => item.message)
        .filter((message) => message !== undefined);
      return messages?.[0];
    }
    return "Add TP/SL";
  };
  const isDisableButton = () => {
    if (isConfirm) return true;
    if (!Number(takeProfitPrice) && !Number(stopLossPrice)) {
      return true;
    }
    if (!Number(closeQuantity)) {
      return true;
    }

    if (posQty && closeQuantity && closeQuantity > posQty) {
      return true;
    }
    if (errors) {
      return true;
    }
    return false;
  };
  const estTakeProfit = useMemo(() => {
    const notional = Number(closeQuantity || 0) * (position?.average_open_price || 0);
    if (tpPnl) {
      return Number(notional * (Number(tpPnl) / 100));
    } else return 0;
  }, [tpPnl, closeQuantity]);
  const estStopLoss = useMemo(() => {
    const notional = Number(closeQuantity || 0) * (position?.average_open_price || 0);
    if (slPnl) {
      return Number(notional * (Number(slPnl) / 100)) * -1;
    } else return 0;
  }, [slPnl, closeQuantity]);
  return (
    <div className="PositionEditor">
      <Modal
        className={`PositionSeller-modal confirmation-modal`}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={"Add TP/SL"}
        allowContentTouchMove
      >
        <div className="order-title-line order-title-line-details">
          <div className="position-info-container">
            <StyledWrap className="head">
              <div className="header">
                <div className={`long-tag`} />
                {/* <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} /> */}
                <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
                <div className="position-info">
                  <div className="title">{tokenSymbol}/USDC</div>
                </div>
              </div>
            </StyledWrap>
          </div>
        </div>

        <div className="content-container" style={{ marginTop: "4px" }}>
          <Divider />
          <div className="PositionEditor-info-box">
            <ExchangeInfoRow label={`Avg. Open`}>
              {formatAmount(
                parseValue(position?.average_open_price, 30),
                30,
                decimalsRules(position?.average_open_price),
                true
              )}
            </ExchangeInfoRow>

            <ExchangeInfoRow label={`Quantity`}>
              {posQty} {tokenSymbol}
            </ExchangeInfoRow>

            <ExchangeInfoRow label={`Mark Price`}>
              {formatAmount(parseValue(position?.mark_price, 30), 30, decimalsRules(position?.mark_price), true)}
            </ExchangeInfoRow>
            <ExchangeInfoRow label={`Liq. Price`}>
              {formatAmount(
                parseValue(position?.est_liq_price || 0, 30),
                30,
                decimalsRules(position?.est_liq_price),
                true
              )}
            </ExchangeInfoRow>
          </div>
          <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
            <div className="Exchange-swap-section-bottom">
              <div className="input-label" style={{ fontSize: 12 }}>
                Quantity
              </div>
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={closeQuantity}
                  onChange={handleChangeQuantityInput}
                  onKeyDown={preventSpecialCharacters}
                />
              </div>
              <div className="PositionEditor-token-symbol" style={{ fontSize: 14 }}>
                {tokenSymbol}
              </div>
            </div>
          </div>
          {/* <PercentageButtons
            onChangePercentage={handleSelectPercentageCloseQuantity}
            balance={Number(formatAmount(parseValue(posQty), 30, 30, false))}
            value={closeQuantity}
          /> */}
          <PercentageButtons
            onChangePercentage={handleSelectPercentageCloseQuantity}
            balance={Number(posQty) || 0}
            value={Number(closeQuantity || 0)}
            decimals={6}
            fullBalance={parseValue(posQty, 10)}
            isUsd={true}
          />
          <div className="Exchange-double-input" style={{ marginTop: "16px" }}>
            <div className="Exchange-double-input-header">
              <span className="muted fz-12">Take Profit</span>
              <span className="muted fz-12">
                (Est. PnL{" "}
                <span className={`${estTakeProfit > 0 ? `positive` : estTakeProfit < 0 ? `negative` : ``}`}>
                  {estTakeProfit ? formatAmount(parseValue(estTakeProfit, 30), 30, 2) : "-"}
                </span>
                )
              </span>
            </div>
            <div className="Exchange-double-input-body">
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label muted fz-12">Price</div>
                <input
                  placeholder="0.00"
                  value={takeProfitPrice}
                  onChange={onTriggerValueChangeTP}
                  onKeyDown={preventSpecialCharacters}
                  type="number"
                />
              </div>
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label muted fz-12">Offset(%)</div>
                <input
                  placeholder="0.00"
                  type="number"
                  value={tpPnl}
                  onChange={onPnlValueChangeTP}
                  onKeyDown={preventSpecialCharacters}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-double-input" style={{ marginTop: "16px" }}>
            <div className="Exchange-double-input-header">
              <span className="muted fz-12">Stop Loss</span>
              <span className="muted fz-12">
                (Est. PnL{" "}
                <span className={`${estStopLoss > 0 ? `positive` : estStopLoss < 0 ? `negative` : ``}`}>
                  {estStopLoss ? formatAmount(parseValue(estStopLoss, 30), 30, 2, true) : "-"}
                </span>
                )
              </span>
            </div>
            <div className="Exchange-double-input-body">
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label muted fz-12">Price</div>
                <input
                  placeholder="0.00"
                  value={stopLossPrice}
                  onChange={onTriggerValueChangeSL}
                  onKeyDown={preventSpecialCharacters}
                  type="number"
                />
              </div>
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label muted fz-12">Offset(%)</div>
                <input
                  placeholder="0.00"
                  value={slPnl}
                  onChange={onPnlValueChangeSL}
                  onKeyDown={preventSpecialCharacters}
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="default-btn Exchange-swap-button" onClick={onHandleAddTPSL} disabled={isDisableButton()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
export const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 12px 0 12px 0;
`;
const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 12px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
