import { getServerUrl } from "config/backend";
import { switchNetwork } from "lib/wallets";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { BERACHAIN_BARTIO, DEFAULT_CHAIN_ID } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useYmyPrice, useTotalYmyStaked, useTotalYmySupplyAll } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { BASIS_POINTS_DIVISOR, YLP_DECIMALS, YMY_DECIMALS, PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { useThemeContext } from "contexts/ThemeProvider";
import useYMYFarmContractMulticall from "hooks/contracts/useYMYFarmContractMulticall";
import useYslpContract from "hooks/contracts/useYslpContract";
import useYslpApr from "hooks/useYslpApr";
import useInfoYslp from "hooks/useInfoYslp";
import useYslpPriceMultiChain from "hooks/useYslpPriceMultiChain";
import useTokensPrice from "hooks/useTokensPrice";
import useUsdPrice from "hooks/useUsdPrice";

import ylp40Icon from "img/ylp.svg";
import yslp40Icon from "img/yslp.svg";
import ymy40Icon from "img/gum.svg";

import useProcessedData from "hooks/useProcessedData";
import { caculateAPYHourly2 } from "lib/helper";
import { useMedia } from "react-use";

const ArrowIcon = ({ isLightTheme }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87521 15.6967C6.54977 16.0221 6.54977 16.5498 6.87521 16.8752C7.20065 17.2006 7.72828 17.2006 8.05372 16.8752L14.8807 10.0482L14.8807 15.1074C14.8807 15.5677 15.2538 15.9408 15.714 15.9408C16.1743 15.9408 16.5474 15.5677 16.5474 15.1074L16.5474 8.03638C16.5474 7.81536 16.4596 7.6034 16.3033 7.44712C16.147 7.29084 15.9351 7.20304 15.714 7.20304L8.64298 7.20304C8.18274 7.20304 7.80964 7.57614 7.80964 8.03638C7.80964 8.49661 8.18274 8.86971 8.64298 8.86971L13.7022 8.86971L6.87521 15.6967Z"
          fill={isLightTheme ? "#0D1A16" : "white"}
        />
      </g>
    </svg>
  );
};

const Tokens = ({ chainId, active, library, apy }) => {
  // const processedData = useAprContext();

  const processedData = useProcessedData(BERACHAIN_BARTIO);

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTabletOnly = useMedia("(min-width: 768px) and (max-width: 1023px)");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const { data: ylpInfo } = useSWR(getServerUrl(chainId, "/ylp-info"), {
    refreshInterval: 30000,
  });
  const { data: ylpInfoBARTIO } = useSWR(getServerUrl(BERACHAIN_BARTIO, "/ylp-info"), {
    refreshInterval: 30000,
  });

  // const { data: ylpInfoOP } = useSWR(getServerUrl(OP, "/ylp-info"), {
  //   refreshInterval: 30000,
  // });

  // const { data: ylpInfoArbitrum } = useSWR(getServerUrl(ARBITRUM, "/ylp-info"), {
  //   refreshInterval: 30000,
  // });
  // const { data: ylpInfoBase } = useSWR(getServerUrl(BASE, "/ylp-info"), {
  //   refreshInterval: 30000,
  // });

  let { bartio: totalYmySupplyBARTIO, total: totalYmySupply } = useTotalYmySupplyAll();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { ymyPriceFromBARTIO, ymyPriceFromOP, ymyPriceFromArbitrum, ymyPriceFromBase, ymyPrice } = useYmyPrice(
    chainId,
    undefined,
    active
  );

  let { bartio: bartioStakedYmy } = useTotalYmyStaked();

  let bartioStakedYmyUsd;
  if (bartioStakedYmy && ymyPriceFromArbitrum) {
    bartioStakedYmyUsd = bartioStakedYmy.mul(ymyPriceFromArbitrum).div(expandDecimals(1, YMY_DECIMALS));
  }

  let stakedYmySupplyUsd;
  if (ymyPriceFromBARTIO && bartioStakedYmy) {
    stakedYmySupplyUsd = bartioStakedYmy.mul(ymyPriceFromBARTIO).div(expandDecimals(1, YMY_DECIMALS));
  }

  let ylpPrice;
  let ylpSupply;
  let ylpMarketCap;
  // let ylpPriceFantomLegacy;
  let ylpPriceBARTIO;
  let ylpSupplyBARTIO;
  // let ylpMarketCapFantomLegacy;
  let ylpMarketCapBARTIO;
  let totalYlpSupply;
  if (ylpInfo) {
    ylpSupply = bigNumberify(ylpInfo?.totalSupply || "0");

    ylpPrice = bigNumberify(ylpInfo?.price || "0");
    ylpMarketCap = ylpPrice.mul(ylpSupply).div(expandDecimals(1, YLP_DECIMALS));
  }
  if (ylpInfoBARTIO) {
    ylpSupplyBARTIO = bigNumberify(ylpInfoBARTIO?.totalSupply || "0");

    ylpPriceBARTIO = bigNumberify(ylpInfoBARTIO?.price || "0");
    ylpMarketCapBARTIO = ylpPriceBARTIO.mul(ylpSupplyBARTIO).div(expandDecimals(1, YLP_DECIMALS));
  }

  if (ylpSupplyBARTIO) {
    totalYlpSupply = ylpSupplyBARTIO;
  }
  if (ylpMarketCapBARTIO) {
    ylpMarketCap = ylpMarketCapBARTIO;
    // .add(ylpMarketCapBARTIOLegacy)
    // .add(ylpMarketCapBase);
  }
  let adjustedUsdySupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdyAmount) {
      adjustedUsdySupply = adjustedUsdySupply.add(tokenInfo.usdyAmount);
    }
  }

  let stableYlp = 0;
  let totalYlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdyAmount && adjustedUsdySupply && adjustedUsdySupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdyAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdySupply);
      if (tokenInfo.isStable) {
        stableYlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalYlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalYlp > 0 ? ((stableYlp * 100) / totalYlp).toFixed(2) : "0.0";

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  const yslpPrices = useYslpPriceMultiChain();
  const yslpPrice = yslpPrices[chainId] || yslpPrices[DEFAULT_CHAIN_ID];

  const { totalSupply: totalSupplyYslpBARTIO, decimals: yslpDecimalBARTIO } = useYslpContract(
    BERACHAIN_BARTIO,
    active,
    PLACEHOLDER_ACCOUNT
  );

  // const { totalSupply: totalSupplyYslpOp, decimals: yslpDecimalOp } = useYslpContract(OP, active, PLACEHOLDER_ACCOUNT);
  // const totalSupplyYslp = totalSupplyYslpBARTIO && totalSupplyYslpOp ? totalSupplyYslpBARTIO.add(totalSupplyYslpOp) : undefined;
  const { totalSupply: totalSupplyYslp } = useInfoYslp(chainId);

  // const {
  //   ylpPoolInfo: [totalStakedBARTIO],
  //   poolRewardsPerSec: poolRewardsPerSecBARTIO,
  // } = useYMYFarmContractMulticall(BERACHAIN_BARTIO, active, PLACEHOLDER_ACCOUNT, library);

  // const {
  //   ylpPoolInfo: [totalStakedOp],
  //   poolRewardsPerSec: poolRewardsPerSecOp,
  // } = useYMYFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);

  // const totalStaked = {
  //   [BERACHAIN_BARTIO]: useUsdPrice(totalStakedBARTIO, yslpPrice, 30),
  //   // [OP]: useUsdPrice(totalStakedOp, yslpPrice, 30),
  // };
  // const totalStakedAll = totalStaked[BERACHAIN_BARTIO] ? totalStaked[BERACHAIN_BARTIO] : undefined;

  // const rewardsPriceBARTIO = useTokensPrice({
  //   ymyPrice: ymyPriceFromBARTIO,
  //   BERACHAIN_BARTIO,
  // });

  // const aprBARTIO = useYslpApr({
  //   yslpPrice: yslpPrices[BERACHAIN_BARTIO],
  //   rewardsPrice: rewardsPriceBARTIO,
  //   totalStaked: totalStakedBARTIO,
  //   poolRewardsPerSec: poolRewardsPerSecBARTIO,
  // });

  //YMY
  const ymyAprBARTIO = parseFloat(formatAmount(processedData[BERACHAIN_BARTIO]?.ymyAprTotal, 2)) / 100;
  const ymyApyBARTIO = caculateAPYHourly2(ymyAprBARTIO) * 100;

  //YLP
  const ylpAprBARTIO = parseFloat(formatAmount(processedData[BERACHAIN_BARTIO]?.ylpAprTotal, 2)) / 100;
  const ylpApyBARTIO = caculateAPYHourly2(ylpAprBARTIO) * 100;

  let { total: totalYmyStaked } = useTotalYmyStaked();

  const totalYmystake = ymyPrice ? ymyPrice.mul(totalYmyStaked || 0).div(parseValue(1, USD_DECIMALS)) : 0;
  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Tokens</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={ymy40Icon} alt="YMY Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">YMY</div>
                <div className="token-desc">YMY Finance Governance Token</div>
              </div>
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div className="label"> APR </div>
            <div className="apr-wrap">
              {processedData?.ymyAprTotalWithBoost ? (
                <span>{formatKeyAmount(processedData, "ymyAprTotalWithBoost", 2, 2, true)}%</span>
              ) : (
                <div className="skeleton-box" style={{ width: "60px" }} />
              )}
            </div>
          </TokenAPR>{" "}
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value`}>
              {totalYmySupply && processedData?.ymySupplyUsd ? (
                <>
                  {"$" + formatAmount(totalYmySupply, YMY_DECIMALS, 0, true) + " YMY"}
                  <div className="value-dollar">${formatKeyAmount(processedData, "ymySupplyUsd", 30, 2, true)} </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {totalYmyStaked && totalYmystake ? (
                <>
                  {formatAmount(totalYmyStaked, 18, 0, true)} YMY
                  <div className="value-dollar">${formatNumber(formatAmount(totalYmystake, 18, 2), 2)}</div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <BuyLink to="/earn" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={ylp40Icon} alt="ylp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  YLP{" "}
                  {/* <ExternalLink href="/#">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink> */}
                </div>
                <div className="token-desc">YMY Liquidity Pool Token</div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>

          <TokenAPR>
            <div className="label"> APY</div>{" "}
            <div className="apr-wrap">
              {processedData?.ylpAprTotal ? (
                <span>{formatKeyAmount(processedData, "ylpAprTotal", 2, 2, true)}%</span>
              ) : (
                <div className="skeleton-box" style={{ width: "60px" }} />
              )}
            </div>
          </TokenAPR>
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value`}>
              {processedData?.ylpSupply && processedData?.ylpSupplyUsd ? (
                <>
                  {formatAmount(processedData?.ylpSupply, YLP_DECIMALS, 0, true)} YLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "ylpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {processedData?.ylpSupply && processedData?.ylpSupplyUsd ? (
                <>
                  {formatKeyAmount(processedData, "ylpSupply", 18, 0, true)} YLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "ylpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>

          <BuyLink to="/earn/ylp" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg className="golden">
              <img src={yslp40Icon} alt="yslp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">YSLP </div>
                <div className="token-desc">YMY Stable Liquidity Pool Token</div>

                {/* <div className="token-price">
                  Price:{" "}
                  {!yslpPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {yslpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(yslpPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on BARTIO`}
                            value={formatAmount(yslpPrices[FANTOM], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(yslpPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div> */}
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div className="label">APR </div>
            <div className="apr-wrap">
              <span>
                {processedData?.yslpAprTotal ? (
                  <>{formatKeyAmount(processedData, "yslpAprTotal", 2, 2, true)}%</>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </span>
            </div>
          </TokenAPR>
          <TokenCardInfo>
            <div className="label">
              <span>Supply</span>
            </div>
            <div className={`value `}>
              {totalSupplyYslp && processedData?.yslpSupplyUsd ? (
                <>
                  {formatAmount(totalSupplyYslp, yslpDecimalBARTIO, 0, true)} YSLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "yslpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          <TokenCardInfo>
            <div className="label">
              <span>Total Staked</span>
            </div>
            <div className="value">
              {processedData?.yslpSupply && processedData?.yslpSupplyUsd ? (
                <>
                  {formatKeyAmount(processedData, "yslpSupply", 18, 0, true)} YSLP
                  <div className="value-dollar">
                    ${formatNumber(formatKeyAmount(processedData, "yslpSupplyUsd", USD_DECIMALS, 2), 2)}
                  </div>
                </>
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo>
          {/* <TokenCardInfo>
            <div className="label">
              <span>Market Cap</span>
            </div>
            <div className="value">
              {yslpMarketCap ? (
                <TooltipComponent
                  position="right-bottom"
                  className="nowrap"
                  handle={`$${formatAmount(yslpMarketCap, YLP_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        label={t`Marketcap on BARTIO`}
                        value={formatAmount(yslpMarketCapBARTIO, YLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={t`Marketcap on Optimism`}
                        value={formatAmount(yslpMarketCapOp, YLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <div className="divider"></div>
                      <StatsTooltipRow
                        label={t`Total`}
                        value={formatAmount(yslpMarketCap, YLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                    </>
                  )}
                />
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo> */}
          <BuyLink to="/earn/yslp" className="btn-token default-btn" network={BERACHAIN_BARTIO}>
            Deposit
          </BuyLink>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  flex: 1;
  .apr-wrap {
    display: flex;
    flex-direction: column;

    color: var(--Text-Text_Primary, #fff);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    & > span {
      color: var(--main-primary, #7ee55c);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      display: flex;
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    }
  }

  > .row {
    width: 100%;

    gap: 8px;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;

      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #1eb2fc;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #1eb2fc !important;

        box-shadow: none !important;
        border: 1px solid #1eb2fc !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    display: flex;
    gap: 4px;
    .value-dollar {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--Text-Text_Secondary, #919499);
    }
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .token-symbol {
    color: var(--Text-Text_Primary, #fff);
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; /* 22.4px */

    display: flex;
    align-items: center;
    gap: var(--Border_radius, 4px);

    & > a {
      &:hover {
        svg {
          g {
            opacity: 1;
          }
          path {
            fill: #1eb2fc;
          }
        }
      }
    }
  }
  .token-desc {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .token-price {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    .Tooltip-handle {
      color: var(--Primary, #1eb2fc);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  /* .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: #fff;
  } */
`;

const TokenImg = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
`;

const CardTitle = styled.div`
  width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 250px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  /* position: absolute;
  top: 0;
  left: 24px;
  right: 24px; */

  /* @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  } */
`;

const TokenCard = styled.div`
  height: 94px;
  padding: 24px;
  background-color: #18191a;
  border-radius: 16px;
  gap: 24px;
  position: relative;
  display: flex;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    height: fit-content;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    gap: 12px;
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @media (max-width: 1023px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Base-Unit-XS-8, 8px);

  &:not(:first-child) {
    margin-top: 48px;

    @media screen and (max-width: 767px) {
      margin-top: 48px;
    }
  }

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 4px;
  }

  div {
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
`;

const Wrapper = styled.div`
  .btn-token {
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Button-Background, #1eb2fc);
    display: flex;
    color: #fff;
    width: 100px;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    align-items: center;

    text-decoration: none !important;

    @media screen and (max-width: 700px) {
      width: 100%;
      margin-top: 8px;
    }
  }
  margin: 0 0 48px;

  .lp {
    margin-top: 16px;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #191b2e 0%, #191b2e 100%);
    position: relative;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    & > div.lp-col {
      &.tablet {
        display: none;
      }

      .default-btn {
        color: #000;
        font-weight: 700;
      }

      &:has(.lp-symbol) {
        display: flex;
        align-items: center;
        gap: 24px;

        .lp-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);
          width: 178px;
        }

        .lp-symbol {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);
          color: var(--Text-Text_Primary, #fff);
          font-size: 16px;
          font-weight: 700;
          line-height: 120%; /* 22.4px */
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          span {
            color: var(--Primary, #1eb2fc);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }
        }
      }

      &:not(:has(.default-btn, img)) {
        flex: 1;

        &:has(.lp-sumbol) {
        }

        .lp-col-key {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .lp-col-value {
          color: var(--Text-Text_Primary, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */

          &.green {
            color: var(--Primary, #1eb2fc);
          }

          &:has(span) {
            display: flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
            align-self: stretch;
          }

          span {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & > div.lp-col {
        &.tablet {
          display: block;
          grid-column: 3/5;
          margin-left: auto;
        }

        &:has(.lp-symbol) {
          grid-column: 1/3;
        }

        &:not(.tablet):has(.default-btn) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 12px;
      padding: 16px;

      & > div.lp-col {
        grid-column: unset !important;

        &:has(.lp-symbol) {
          margin-bottom: 12px;
        }

        &.tablet {
          display: none;
        }

        &:not(.tablet):has(.default-btn) {
          display: block;

          .default-btn {
            width: 100%;
          }
        }

        &:has(.lp-col-key, .lp-col-value) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0;
    margin-bottom: 48px;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    ${TokenCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      ${TokenImg} {
        width: 40px;
        height: 40px;
      }

      ${TokenInfo} {
        .token-symbol {
          color: var(--Text-Text_Primary, #0d1a16);

          & > a {
            &:hover {
              svg {
                g {
                  opacity: 1;
                }
                path {
                  fill: #02b27f;
                }
              }
            }
          }
        }

        .token-desc,
        .token-price {
          color: #919499 !important;
        }
      }

      ${TokenAPR} {
        border-color: var(--Border, rgba(13, 26, 22, 0.07)) !important;

        .btn-buy.border-btn {
          color: var(--Text-Text_Primary, #0d1a16) !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

          &:hover {
            color: #02b27f !important;
            border-color: #02b27f !important;
          }
        }
      }

      ${TokenCardInfo} {
        .label span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .lp {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .lp-info {
        .lp-symbol {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: var(--Primary, #02b27f) !important;
          }
        }
      }

      .lp-col-key {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }

      .lp-col-value {
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &.green {
          color: var(--Primary, #02b27f) !important;
        }

        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
  }
`;

const StyleButton = styled(Link)`
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Button-Background, #1eb2fc);
  display: flex;
  width: 100px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;
export default Tokens;
