import { CHART_HEIGHT } from "lib/helper";
import { ACTIVE_CHAIN_IDS_V2 } from "lib/legacy";
import moment from "moment";
import { FC, useMemo } from "react";
import { useMedia } from "react-use";
import styled from "styled-components";
import VolumeChart from "../VolumeChart";

interface Props {
  chainId: number;
  dailyInfoData: any;
}

const StatBarCharts: FC<Props> = ({ chainId, dailyInfoData }) => {
  const isMobile = useMedia("(max-width:600px)");
  const isTablet = useMedia("(max-width:1000px)");
  const isValidChain = ACTIVE_CHAIN_IDS_V2.includes(chainId);

  const dataCharts = useMemo(() => {
    if (dailyInfoData && isValidChain) {
      const volumes: any[] = [];
      const openInterest: any[] = [];
      const users: any[] = [];
      const tradesPerUser: any[] = [];
      const realizedPnL: any[] = [];
      const fees: any[] = [];
      const yslpPrices: any[] = [];
      const yslpTvls: any[] = [];

      dailyInfoData.forEach(
        ({
          volume,
          volumeCumulative,
          fees: _fees,
          feesCumulative,
          longOpenInterest,
          shortOpenInterest,
          returningUsers,
          userCumulative,
          newUsers,
          tradePerUser,
          realizedPnl,
          dailyPnl,
          liquidation,
          yslpPrice,
          yslpTvl,
          timestamp: _timestamp,
        }) => {
          const timestamp = moment(_timestamp * 1000).format("MM-DD");
          volumes.push({
            volume: +volume,
            volumeCumulative: +volumeCumulative,
            timestamp,
          });
          openInterest.push({
            longOpenInterest: +longOpenInterest,
            shortOpenInterest: +shortOpenInterest,
            total: +(Number(longOpenInterest) + Number(shortOpenInterest)),
            timestamp,
          });
          users.push({
            returningUsers: +returningUsers,
            userCumulative: +userCumulative,
            newUsers: +newUsers,
            timestamp,
          });
          tradesPerUser.push({ tradePerUser: +tradePerUser, timestamp });
          realizedPnL.push({
            realizedPnl: +realizedPnl,
            dailyPnl: +dailyPnl,
            liquidation: +liquidation,
            timestamp,
          });
          fees.push({
            fees: +_fees,
            feesCumulative: +feesCumulative,
            timestamp,
          });
          yslpPrices.push({
            yslpPrice: +yslpPrice,
            timestamp,
          });
          yslpTvls.push({
            yslpTvl: +yslpTvl,
            timestamp,
          });
        }
      );

      return {
        volumes: {
          title: "Volume",
          data: volumes,
          //bars
          barKeys: ["volume"],
          barColors: ["#999FDE"],
          barYAxisId: ["right"],
          //lines
          lineKeys: ["volumeCumulative"],
          lineColors: ["#FD9037"],
          lineYAxisId: ["left"],
          isSwapToUsd: true,
          yaxisWidth: [40, 40],
        },
        openInterest: {
          title: "Open interest",
          data: openInterest,
          //bars
          barKeys: ["longOpenInterest", "shortOpenInterest"],
          barColors: ["#95DE64", "#F7686E"],
          barYAxisId: ["left", "left"],
          isSwapToUsd: true,
          yaxisWidth: [35, 10],
        },
        users: {
          title: "Users",
          data: users,
          //bars
          barKeys: ["returningUsers", "newUsers"],
          barColors: ["#999FDE", "#95DE64"],
          barYAxisId: ["left", "left"],
          //lines
          lineKeys: ["userCumulative"],
          lineYAxisId: ["right"],
          lineColors: ["#FD9037"],
          yaxisWidth: [30, 30],
        },
        tradesPerUser: {
          title: "Trades per user",
          data: tradesPerUser,
          //lines
          lineKeys: ["tradePerUser"],
          lineColors: ["#FD9037"],
          lineYAxisId: ["left"],
          yaxisWidth: [16, 10],
        },
        realizedPnL: {
          title: "Realized PnL",
          data: realizedPnL,
          //bars
          barKeys: ["realizedPnl", "liquidation"],
          barColors: ["#999FDE", "#95DE64"],
          barYAxisId: ["left", "left"],
          //lines
          lineKeys: ["dailyPnl"],
          lineColors: ["#FD9037"],
          lineYAxisId: ["left"],
          isSwapToUsd: true,
          yaxisWidth: [32, 10],
        },
        fees: {
          title: "Fees",
          data: fees,
          //bars
          barKeys: ["fees"],
          barColors: ["#999FDE"],
          barYAxisId: ["left"],
          //lines
          lineKeys: ["feesCumulative"],
          lineColors: ["#FD9037"],
          lineYAxisId: ["right"],
          isSwapToUsd: true,
          yaxisWidth: [32, 32],
        },
        yslpPrice: {
          title: "YSLP price",
          data: yslpPrices,
          //lines
          lineKeys: ["yslpPrice"],
          lineColors: ["#95DE64"],
          lineYAxisId: ["left"],
          isSwapToUsd: true,
          yaxisWidth: [28, 10],
        },
        yslpTvl: {
          title: "YSLP TVL",
          data: yslpTvls,
          //bars
          barKeys: ["yslpTvl"],
          barColors: ["#95DE64"],
          barYAxisId: ["left"],
          isSwapToUsd: true,
          yaxisWidth: [36, 10],
        },
      };
    }

    return {
      volumes: {
        title: "Volume",
        data: null,
        isSwapToUsd: true,
      },
      openInterest: {
        title: "Open interest",
        data: null,
        isSwapToUsd: true,
      },
      users: {
        title: "Users",
        data: null,
      },
      tradesPerUser: {
        title: "Trades per user",
        data: null,
      },
      realizedPnL: {
        title: "Realized PnL",
        data: null,
        isSwapToUsd: true,
      },
      fees: {
        title: "Fees",
        data: null,
        isSwapToUsd: true,
      },
      yslpPrice: {
        title: "YSLP price",
        data: null,
        isSwapToUsd: true,
      },
      yslpTvl: {
        title: "YSLP TVL",
        data: null,
        isSwapToUsd: true,
      },
    };
  }, [dailyInfoData, chainId]);

  return (
    <ColumnChartContainer>
      {Object.entries(dataCharts).map(
        ([
          key,
          { title, data, barKeys, lineKeys, barColors, lineColors, barYAxisId, lineYAxisId, isSwapToUsd, yaxisWidth },
        ]) => {
          return (
            <div className="chart-cell" key={key}>
              <VolumeChart
                title={title}
                data={data}
                loading={false}
                chartHeight={CHART_HEIGHT}
                yaxisWidth={yaxisWidth}
                barSize={isMobile ? 8 : isTablet ? 15 : 20}
                barKeys={barKeys}
                lineKeys={lineKeys}
                barColors={barColors}
                lineColors={lineColors}
                barYAxisId={barYAxisId}
                lineYAxisId={lineYAxisId}
                isSwapToUsd={isSwapToUsd}
              />
            </div>
          );
        }
      )}
    </ColumnChartContainer>
  );
};

const ColumnChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;

  .chart-cell {
    padding: 24px;
    position: relative;
    background: transparent;
    border-radius: 16px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    .chart-header {
      .chart-name {
        margin-top: 0;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        font-size: 16px;
        a {
          margin-left: 8px;
        }
      }
    }
  }

  .recharts-tooltip-wrapper .recharts-default-tooltip {
    background-color: #353651 !important;
    box-shadow: 0 3px 6px #0000005c;
    border: 1px solid #484b6e !important;
    border-radius: 4px;
  }
  .chart-header {
    display: flex;
    justify-content: space-between;
  }
  .chart-control-checkbox {
    width: fit-content;
    background: #333;
    color: #aaa;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 8px;
    padding: 8px 7px;

    font-weight: 700;

    border-radius: 4px;
    transition: background-color 0.1s, color 0.1s;
    font-size: 14px;
  }
  .chart-control-checkbox.active {
    background: #609ade;
    color: #fff;
  }

  .recharts-legend-item-text {
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .recharts-legend-item {
    padding-bottom: 0;
  }
  .bar-chart-wrapper {
    position: relative;
    min-height: 275px;
  }
  .no-record-chart {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 600px) {
    gap: 16px;
    margin-bottom: 16px;
    overflow: auto;
    .chart-cell {
      padding: 16px;
    }
  }
`;

export default StatBarCharts;
