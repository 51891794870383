import { Menu } from "@headlessui/react";
import { useWeb3React } from "@web3-react/core";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import settingsIcon from "img/ic_settings_16.svg";
// import accountReferralIcon from "img/ic_account_referral.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import icSetting from "img/icons/ic-setting.svg";
import switchIcon from "img/icons/ic-switch.svg";
import { defaultLocale } from "lib/i18n";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./NetworkDropdown.css";
export default function UserDropdown(props) {
  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const { account } = useWeb3React();

  return (
    <>
      <DesktopDropdown
        currentLanguage={currentLanguage}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        {...props}
      />
    </>
  );
}

function DesktopDropdown({ openSettings, isLightMode }) {
  const { toggleTheme } = useThemeContext() as any;
  return (
    <StyledContainer
    // className="App-header-user-address"
    // style={{
    //   marginLeft: 12,
    // }}
    >
      <Menu>
        <Menu.Button as="div" className="network-dropdown">
          <img src={icSetting} alt="setting" />
          {/* <img src={isLightMode ? DefaultAvatarLight : DefaultAvatar} alt="Avatar" /> */}
        </Menu.Button>

        <Menu.Items
          as="div"
          className="menu-items network-dropdown-items"
          style={{
            padding: 0,
          }}
        >
          <div className="menu-items-wrap">
            {/* <div className="label">Account</div> */}

            <Menu.Item>
              <div className="network-dropdown-menu-item menu-item" onClick={openSettings}>
                <div className="menu-item-group">
                  <div className="menu-item-icon">
                    <img className="network-dropdown-icon" src={settingsIcon} alt="" />
                  </div>
                  <span className="network-dropdown-item-label">
                    <span>Settings</span>
                  </span>
                </div>
              </div>
            </Menu.Item>
            {/* <Menu.Item>
              <Link to="/portfolio" className="network-dropdown-menu-item menu-item">
                <div className="menu-item-group">
                  <div className="menu-item-icon">
                    <img className="network-dropdown-icon" src={switchIcon} alt="" />
                  </div>
                  <span className="network-dropdown-item-label">
                    <span>Transfer Account</span>
                  </span>
                </div>
              </Link>
            </Menu.Item> */}
          </div>
          {/* <div className="divider" />
          <div className="menu-items-wrap">
            <div className="label">Theme</div>
            <div className="network-dropdown-menu-item menu-item" onClick={() => toggleTheme("dark")}>
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img className="network-dropdown-icon" src={isLightMode ? dark_modeLight : dark_mode} alt="" />
                </div>
                <span className="network-dropdown-item-label">
                  <span>Dark mode</span>
                  <div
                    className={cx("radio-input", {
                      active: !isLightMode,
                    })}
                  ></div>
                </span>
              </div>
            </div>
            <div className="network-dropdown-menu-item menu-item" onClick={() => toggleTheme("light")}>
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img className="network-dropdown-icon" src={isLightMode ? light_modeLight : light_mode} alt="" />
                </div>
                <span className="network-dropdown-item-label">
                  <span>Light mode</span>
                  <div
                    className={cx("radio-input", {
                      active: isLightMode,
                    })}
                  ></div>
                </span>
              </div>
            </div>
          </div> */}
        </Menu.Items>
      </Menu>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  .network-dropdown {
    display: flex;
    height: 40px;
    padding: var(--Base-Unit-XS-8, 8px);
    width: 40px;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 4px;
    background: var(--Nature-1, #18191a);
    &:hover {
      filter: brightness(1.5);
    }
    border: 1px solid #2B3139;
  }
`;
