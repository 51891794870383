import FooterLanding from "components/FooterLanding";
import Financial from "pages/Landing/Financial";
import Hero from "pages/Landing/Hero";
import Integrated from "pages/Landing/Integrated";
import TopMarkets from "pages/Landing/TopMarkets";

const Landing = () => {
  return (
    <>
      <Hero />
      <Financial />
      <Integrated />
      <TopMarkets />
      <FooterLanding />
    </>
  );
};

export default Landing;
