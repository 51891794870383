import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { decimalsRules, getBaseOrderlyAsset, importImage, PRECISION } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import moment from "moment";
import editIcon from "img/trade/edit.svg";
import { useMemo, useState } from "react";
import OrderlyConfirmaton from "./OrderlyConfirmaton";
import { commify } from "@ethersproject/units";
import { is } from "date-fns/locale";
const OrderItemV3 = ({
  selectedOder,
  order,
  orders,
  handleCancelTrigger,
  orderlyPosition,
  isShowConfirmModal,
  handlEditTPSL,
}) => {
  const tokenSymbol = getBaseOrderlyAsset(order?.symbol);
  const isLong = order?.side === "BUY" ? true : false;
  const [isConfirm, setShowConfirm] = useState(false);
  const position = useMemo(() => {
    return orderlyPosition?.find((x) => x.symbol === order?.symbol);
  }, [orderlyPosition, order]);
  const getOrderlyType = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      let isTP,
        isSL = false;
      if (order?.child_orders?.[0]?.trigger_price) {
        isTP = true;
      }
      if (order?.child_orders?.[1]?.trigger_price) {
        isSL = true;
      }
      if (isTP && isSL) return "TP/SL";
      else if (isTP) return "Take profit";
      else return "Stop loss";
    }
    if (isLong) {
      if (order?.side === "BUY") {
        if (order?.type === "MARKET") return "Stop Market";
        if (order?.algo_type && order?.algo_type !== "BRACKET") return "Stop Limit";
        return order?.type === "POST_ONLY"
          ? "Post Only"
          : order?.type === "IOC"
          ? "IOC"
          : order?.type === "FOK"
          ? "FOK"
          : "Limit";
      } else {
        if (order?.algo_type === "STOP_LOSS") return "Stop Loss";
        return "Take Profit";
      }
    } else {
      if (order?.side === "SELL") {
        if (order?.type === "MARKET") return "Stop Market";
        if (order?.algo_type && order?.algo_type !== "BRACKET") return "Stop Limit";
        return order?.type === "POST_ONLY"
          ? "Post Only"
          : order?.type === "IOC"
          ? "IOC"
          : order?.type === "FOK"
          ? "FOK"
          : "Limit";
      } else {
        if (order?.type === "LIMIT" && !order?.algo_type)
          return order?.type === "POST_ONLY"
            ? "Post Only"
            : order?.type === "IOC"
            ? "IOC"
            : order?.type === "FOK"
            ? "FOK"
            : "Limit";
        if (order?.algo_type === "STOP_LOSS") return "Stop Loss";
        return "Take Profit";
      }
    }
  };
  const getPrice = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      return "Market";
    } else {
      const type = getOrderlyType();
      if (type !== "Stop Market")
        return formatAmount(
          parseValue(order?.price || order?.trigger_price || 0, 30),
          30,
          decimalsRules(order?.price || order?.trigger_price),
          true
        );
      return "Market";
    }
  };
  const getTriggerPrice = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      const child_orders = order?.child_orders || [];
      let isTP, isSL;
      let tp, sl;
      if (child_orders?.length > 0) {
        tp = child_orders?.[0];
        sl = child_orders?.[1];
        if (tp?.trigger_price) isTP = true;
        if (sl?.trigger_price) isSL = true;
      }
      if (isTP && isSL)
        return (
          <div>
            <div>
              <span className="positive">TP </span>
              {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
            </div>
            <div style={{ marginTop: 4 }}>
              <span className="negative">SL </span>
              {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
            </div>
          </div>
        );
      else if (isTP)
        return (
          <>
            <span>
              {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
            </span>
          </>
        );
      else
        return (
          <>
            <span>
              {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
            </span>
          </>
        );
    } else {
      const type = getOrderlyType();
      if (type !== "Stop Limit" && type !== "Stop Market") return "-";
      return formatAmount(parseValue(order?.trigger_price || 0, 30), 30, decimalsRules(order?.trigger_price), true);
    }
  };

  const getNotional = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      if (Math.abs(order?.quantity) === 0) return "Entire Position";
      return formatAmount(parseValue((order?.quantity || 0) * position?.average_open_price, 30), 30, 2, true);
    }
    if (order?.type === "MARKET") return "-";
    return (
      <span>
        {commify(limitDecimals((order?.price || order?.trigger_price || 0) * order?.quantity, 2))}{" "}
        <span className="muted">USDC</span>
      </span>
    );
  };
  const getTPSL = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      return "-";
    } else {
      if (order?.child_orders && order?.child_orders?.length > 0) {
        const child = order?.child_orders?.[0];
        const child_orders = child?.child_orders || [];
        let isTP, isSL;
        let tp, sl;
        if (child_orders?.length > 0) {
          tp = child_orders?.[0];
          sl = child_orders?.[1];
          if (tp?.trigger_price) isTP = true;
          if (sl?.trigger_price) isSL = true;
        }
        if (isTP && isSL)
          return (
            <div>
              <div>
                <span className="positive">TP </span>
                {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
              </div>
              <div style={{ marginTop: 4 }}>
                <span className="negative">SL </span>
                {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
              </div>
            </div>
          );
        else if (isTP)
          return (
            <>
              <span>
                <span className="positive">TP </span>
                {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
              </span>
            </>
          );
        else if (isSL)
          return (
            <>
              <span>
                <span className="negative">SL </span>
                {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
              </span>
            </>
          );
        else return "-";
      }
      return "-";
    }
  };
  const getReduceOnly = () => {
    if (order?.algo_type?.includes("TP_SL")) {
      return "Yes";
    }
    return "No";
  };
  const onConfirm = () => {
    handleCancelTrigger(order);
  };
  const getFilledQuantity = useMemo(() => {
    if (order?.algo_type?.includes("TP_SL")) {
      if (Math.abs(order?.quantity) === 0) return "Entire Position";
    }
    return `${order?.total_executed_quantity}/${Math.abs(order?.quantity)}`;
  }, [order]);
  return (
    <tr
      className="table-content"
      // onClick={() => handleSelectedPosition(completePosition)}
    >
      <td style={{ width: "200px" }}>
        <div style={{ paddingLeft: "10px" }}>
          <div className={`${order.side === "BUY" ? `long-tag` : `short-tag`}`} />
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />
          <div className="tk_info">
            <div className="Exchange-list-title fz-12">{tokenSymbol}/USDC</div>
            <div style={{ height: "4px" }}></div>
            <div className="Exchange-list-info-label">
              <span className="cancel-order-txt">{getOrderlyType()}</span>
            </div>
          </div>
        </div>
      </td>
      <td style={{ width: "150px" }} className="fz-12">{getFilledQuantity}</td>
      <td>
        <div className="fz-12">{getPrice()}</div>
      </td>
      <td>
        <div className="fz-12">{getTriggerPrice()}</div>
      </td>
      {/* <td>
        <div>{getOrderlyType()}</div>
      </td> */}

      {/* <td>{getIncreaseDecreaseOrder()}</td> */}

      <td className="fz-12">{getTPSL()}</td>
      <td>
        <div className="fz-12">{getNotional()}</div>
      </td>
      <td className="fz-12">{getReduceOnly()}</td>
      {/* <td>No</td> */}
      <td style={{ width: "30px" }} className="fz-12">
        <div className="close-container">
          <button
            className="Exchange-list-action bold fz-12"
            onClick={(e) => {
              e.stopPropagation();
              if (isShowConfirmModal) setShowConfirm(true);
              else handleCancelTrigger(order);
            }}
            style={{ marginRight: 0 }}
            // disabled={position?.size.eq(0)}
          >
            <span style={{ color: "#1EB2FC", fontSize: 12 }}>Cancel</span>
          </button>
          <button
            className="PositionDropdown-dots-icon"
            onClick={() => {
              handlEditTPSL(order, order?.algo_type?.includes("TP_SL") ? "TP_SL" : "Order");
            }}
          >
            <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
          </button>
        </div>
      </td>
      {isConfirm && (
        <OrderlyConfirmaton isVisible={isConfirm} setIsVisible={setShowConfirm} onConfirm={onConfirm}>
          Do you want to cancel this order?
        </OrderlyConfirmaton>
      )}
    </tr>
  );
};
export default OrderItemV3;
