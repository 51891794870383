import styled from "styled-components";

const Comparison = () => {
  return (
    <Wrapper>
      <div className="Comparison-head">
        <span>Market</span>
        <span>WOOFi Pro</span>
        <span>Binance</span>
        <span>OKX</span>
        <span>Bybit</span>
        <span>dYdX</span>
        <span>Bitget</span>
        <span>Kucoin</span>
      </div>
      <div className="Comparison-body">
        {[...Array(10)].map((_, index) => (
          <div className="Comparison-bodyRow" key={index}>
            <span>SEI-PERP</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #1e1e1e;
  border-radius: 16px;

  .Comparison-head {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 48px;
    align-items: center;

    span {
      padding: 0 8px;
    }
  }

  .Comparison-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .Comparison-bodyRow {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    border-top: 1px solid #2c2c2c;
    align-items: center;
    height: 48px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
    }

    span {
      padding: 0 8px;
    }
  }
`;

export default Comparison;
