import { Bar } from "charting_library";
import { BigNumber } from "ethers";
import { formatAmount, parseValue } from "lib/numbers";

export function getObjectKeyFromValue(value, object) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function formatTimeInBar(bar: Bar) {
  return {
    ...bar,
    time: bar.time * 1000,
  };
}
export const mergePaths = (data) => {
  const paths = data?.paths || [];
  const totalFrom = data?.totalFrom || "";
  const tokens = data?.tokens || [];
  if (paths.length === 0) return [];
  const pathResults: any = [];
  const objPathMerge = {};
  for (let i = 0; i < paths.length; i++) {
    const { swaps } = paths[i];

    if (!objPathMerge[swaps.length]) {
      objPathMerge[swaps.length] = [];
      objPathMerge[swaps.length].push({ ...paths[i], id: i });
    } else {
      objPathMerge[swaps.length].push({ ...paths[i], id: i });
    }
  }
  // console.log("??? test 1" ,objPathMerge );

  for (const [key, value] of Object.entries(objPathMerge)) {
    if (Number(key) > 0) {
      const arrayData: any = value;
      const usedIDS: any = [];
      // console.log("test2-1", arrayData);

      for (let i = 0; i < arrayData.length; i++) {
        const path: any = arrayData[i];
        // console.log("???", {usedIDS, id: path.id} );

        if (!usedIDS.includes(path.id)) {
          usedIDS.push(path.id);
          const { swaps } = path;
          let filterArray = arrayData;
          for (let j = 0; j < swaps.length; j++) {
            const swap = swaps[j];
            filterArray = filterArray.filter((x) => !usedIDS.includes(x.id) && swap.to === x.swaps[j].to);
          }
          for (let j = 0; j < filterArray.length; j++) {
            if (!usedIDS.includes(filterArray[j].id)) usedIDS.push(filterArray[j].id);
          }
          const merge: any = [...filterArray, path];
          const data: any = {};
          let percent = BigNumber.from(0);
          const maxLength = merge[0].swaps.length;
          for (let j = 0; j < merge.length; j++) {
            percent = percent.add(BigNumber.from(merge[j].amountFrom));
          }
          const dexesPaths: any = [];

          for (let j = 0; j < maxLength; j++) {
            const dexes: any = [];
            for (let z = 0; z < merge.length; z++) {
              if (dexes.length === 0) {
                dexes.push({
                  symbol: tokens[merge[z].swaps[j].to]?.symbol,
                  address: tokens[merge[z].swaps[j].to]?.address,
                  dex: merge[z].swaps[j].dex,
                  percent: BigNumber.from(merge[z].amountFrom).mul(BigNumber.from(100000)).div(percent),
                });
              } else {
                const index = dexes.findIndex((x) => x.dex === merge[z].swaps[j].dex);
                if (index >= 0) {
                  const dataOverride = {
                    dex: merge[z].swaps[j].dex,
                    symbol: tokens[merge[z].swaps[j].to]?.symbol,
                    address: tokens[merge[z].swaps[j].to]?.address,
                    percent: BigNumber.from(merge[z].amountFrom)
                      .mul(BigNumber.from(100000))
                      .div(percent)
                      .add(dexes[index].percent),
                  };
                  dexes[index] = dataOverride;
                } else {
                  dexes.push({
                    symbol: tokens[merge[z].swaps[j].to]?.symbol,
                    address: tokens[merge[z].swaps[j].to]?.address,
                    dex: merge[z].swaps[j].dex,
                    percent: BigNumber.from(merge[z].amountFrom).mul(BigNumber.from(100000)).div(percent),
                  });
                }
              }
            }
            for (let z = 0; z < dexes.length; z++) {
              dexes[z].percent = Math.round(Number(formatAmount(dexes[z].percent, 3, 3)));
            }
            dexesPaths.push(dexes);
          }
          data.totalPercent = formatAmount(percent.mul(BigNumber.from(100000)).div(BigNumber.from(totalFrom)), 3, 0);
          data.paths = dexesPaths;
          pathResults.push(data);
        }
      }
    }
  }

  return pathResults;
};
export const TEST_MERGE_ROUTE_DATA = {
  from: "0x695921034f0387eac4e11620ee91b1b15a6a09fe",
  to: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
  totalFrom: "3000000000000000000",
  totalTo: "6950045721",
  totalGas: 443000,
  gasPrice: "51625646746",
  paths: [
    {
      amountFrom: "900000000000000000",
      amountTo: "2088067250",
      gas: 180000,
      swaps: [
        {
          from: "0x695921034f0387eac4e11620ee91b1b15a6a09fe",
          to: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
          amountFrom: "900000000000000000",
          amountTo: "2092540500",
          pool: "0x7e9e9d925846b110b025041cfdf9942f2c401f4f",
          dex: "yummy",
        },
        {
          from: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
          to: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
          amountFrom: "2092540500",
          amountTo: "2088067250",
          pool: "0xe728af1d484e36ae03d0d58e4e1565d28bf414f6",
          dex: "solidlyv3",
        },
      ],
    },
    {
      amountFrom: "2100000000000000000",
      amountTo: "4865455232",
      gas: 138000,
      swaps: [
        {
          from: "0x695921034f0387eac4e11620ee91b1b15a6a09fe",
          to: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
          amountFrom: "2100000000000000000",
          amountTo: "4882594500",
          pool: "0x7e9e9d925846b110b025041cfdf9942f2c401f4f",
          dex: "yummy",
        },
        {
          from: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
          to: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
          amountFrom: "4882594500",
          amountTo: "4865455232",
          pool: "0xdb0f33978b5366191c42bb9a7b7d886cb714df65",
          swapFee: 0.0003,
          dex: "velocimeter",
        },
      ],
    },
  ],
  tokens: {
    "0x1b6382dbdea11d97f24495c9a90b7c88469134a4": {
      address: "0x1b6382dbdea11d97f24495c9a90b7c88469134a4",
      symbol: "axlUSDC",
      name: "Axelar Wrapped USDC",
      decimals: 6,
      price: 0.9958172314989825,
    },
    "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf": {
      address: "0x28a92dde19d9989f39a49905d7c9c2fac7799bdf",
      symbol: "USDC",
      name: "LayerZero USD Coin",
      decimals: 6,
      price: 1,
    },
    "0x695921034f0387eac4e11620ee91b1b15a6a09fe": {
      address: "0x695921034f0387eac4e11620ee91b1b15a6a09fe",
      symbol: "WETH",
      name: "LayerZero Wrapped Ether",
      decimals: 18,
      price: 2319.349868581038,
    },
  },
};
