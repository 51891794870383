import { useChainIdWithDefault } from "lib/chains";
import styled from "styled-components";

import Footer from "components/Footer/Footer";
import { SUPPORTED_V2_CHAINS } from "config/chains";
import banner from "img/leaderboard/Banner.png";
import { TopTradersTab } from "pages/AnalyticsYummy/components";
import "styles/AnalyticV1.scss";
import { StyledAnalyticsContent } from "./styles/AnalyticsV3.styled";
export default function AnalyticsV3() {
  // const { chainId } = useChainId();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const isValidChain = SUPPORTED_V2_CHAINS.includes(chainId);

  return (
    <div>
      <Wrapper>
        <HeadWraper className="container">
          <Title>
            <div className="title-head">Leaderboard</div>

            <div className="desc">Trading stats of top Yummy traders.</div>
          </Title>
          <img src={banner} alt="banner" className="banner" />
        </HeadWraper>

        <StyledAnalyticsContent>
          <TopTradersTab chainId={chainId} isValidChain={isValidChain} />
        </StyledAnalyticsContent>
      </Wrapper>
      <Footer />
    </div>
  );
}
const Wrapper = styled.div`
  margin-top: 48px;

  .container {
    max-width: 100%;
    width: 1368px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
const Title = styled.div`
  .desc {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #848e9c;
  }
  .title-head {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
`;
const HeadWraper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    .banner {
      width: 100%;
    }
  }

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
      padding-inline: 16px;
    }
  }
`;
