import TradeActivity from "components/ExchangeV2/TradeActivity";
import TradeActivityV1 from "components/ExchangeV2/TradeActivityV1";
import TradeActivityV3 from "components/ExchangeV2/TradeActivityV3";
import { useMemo, useState } from "react";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import TradeActivityItemMobileV3 from "./TradeActivityItemMobileV3";
import TradeActivityItemV3 from "./TradeActivityItemV3";

const TradeActivityList = ({ positions, loading, chainId, marketAsssets }) => {
  const [page, setPage] = useState(1);

  const [isTradeActivityVisible, setIsTradeActivityVisible] = useState(false);
  const [isTradeActivityVisibleV3, setIsTradeActivityVisibleV3] = useState(false);
  const [isTradeActivityVisibleV1, setIsTradeActivityVisibleV1] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();

  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisible(true);
  };
  const handleSelectedPositionV1 = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisibleV1(true);
  };
  const handleSelectedPositionV3 = (position) => {
    setSelectedPosition(position);
    setIsTradeActivityVisibleV3(true);
  };
  return (
    <div className="PositionsList">
      {isTradeActivityVisibleV3 && (
        <TradeActivityV3
          isVisible={isTradeActivityVisibleV3}
          setIsVisible={setIsTradeActivityVisibleV3}
          position={selectedPosition}
        />
      )}
      {isTradeActivityVisible && (
        <TradeActivity
          isVisible={isTradeActivityVisible}
          setIsVisible={setIsTradeActivityVisible}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      {isTradeActivityVisibleV1 && (
        <TradeActivityV1
          isVisible={isTradeActivityVisibleV1}
          setIsVisible={setIsTradeActivityVisibleV1}
          position={selectedPosition}
          chainId={chainId}
        />
      )}
      <table className={`Exchange-list large App-box ${positions.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          {filterPositions.length > 0 && (
            <tr className="Exchange-list-header">
              <th>
                <span>Symbol</span>
              </th>
              <th>
                <span>Avg. Price</span>
              </th>
              <th>
                <span>Trigger Price</span>
              </th>
              <th>
                <span>Filled/Quantity</span>
              </th>
              <th>
                <span>Fees</span>
              </th>
              <th>
                <span>Reduce Only</span>
              </th>
              <th>
                <span>Post Only</span>
              </th>
              <th>
                Status
              </th>
              <th>Time</th>
            </tr>
          )}

          {positions.length === 0 && !loading && (
            <tr className="no-position">
              <td colSpan="15">
                <div className="no-data-record">No trades yet</div>
              </td>
            </tr>
          )}
          {filterPositions.length > 0 &&
            filterPositions.map((position, index) => {
              return (
                <TradeActivityItemV3
                  key={index}
                  position={position}
                  handleSelectedPosition={handleSelectedPositionV3}
                />
              );
            })}
        </tbody>
      </table>
      <div className="Exchange-list small">
        {positions.length === 0 && loading && (
          <div className="Exchange-empty-positions-list-note App-card">
            <div className="skeleton-box" style={{ height: "25px" }} />
          </div>
        )}
        {positions.length === 0 && <div className="no-data-record">No order history</div>}
        {filterPositions.length > 0 &&
          filterPositions.map((position, index) => {
            return (
              <TradeActivityItemMobileV3
                key={index}
                position={position}
                handleSelectedPosition={handleSelectedPositionV3}
              />
            );
          })}
      </div>
      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default TradeActivityList;
