import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import ReaderYslp from "abis/ReaderYslp.json";
import Token from "abis/Token.json";
import "antd/dist/reset.css";
import { ethers } from "ethers";
import useScrollToTop from "lib/useScrollToTop";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWR, { SWRConfig } from "swr";

import { Route, HashRouter as Router, Switch, useHistory, useLocation } from "react-router-dom";

import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  getAppBaseUrl,
  isDevelopment,
  isHomeSite,
  isMobileDevice,
  REFERRAL_CODE_QUERY_PARAM,
} from "lib/legacy";
import Dashboard from "pages/Dashboard/Dashboard";
import { Exchange } from "pages/Exchange/Exchange";
import { ExchangeV2 } from "pages/ExchangeV2/Exchange";
import Home from "pages/Home/Home";
import Referrals from "pages/Referrals/Referrals";
import VaultFantom from "pages/Vault";
import VaultARB from "pages/Vault/indexARB";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

import Checkbox from "components/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "styles/Font.css";
import "styles/Input.css";
import "styles/Shared.css";
import "./App.scss";
import "./AppTheme.scss";

import SEO from "components/Common/SEO";
import EventToastContainer from "components/EventToast/EventToastContainer";
import { decodeReferralCode, encodeReferralCode } from "domain/referrals";
import coinbaseImg from "img/coinbaseWallet.png";
import icLink from "img/icons/redirect-green.svg";
import icLinkLight from "img/icons/redirect-greenLight.svg";
import metamaskImg from "img/metamask.svg";
import walletConnectImg from "img/walletconnect-circle-blue.svg";
import useRouteQuery from "lib/useRouteQuery";

import PositionRouter from "abis/PositionRouter.json";
import VaultV2 from "abis/VaultV2.json";
import VaultV2b from "abis/VaultV2b.json";
import { RedirectPopupModal } from "components/ModalViews/RedirectModal";
import { getContract } from "config/contracts";
import { REDIRECT_POPUP_TIMESTAMP_KEY } from "config/ui";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { useLocalStorage } from "react-use";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import CircleLoader from "components/CircleLoader/CircleLoader";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Header } from "components/Header/Header";
import WrongChainModal from "components/Modal/WrongNetworkModal";
import {
  ARBITRUM,
  FANTOM,
  getExplorerUrl,
  MICROSWAP_SUPPORTED_CHAIN_IDS,
  OP,
  SUPPORT_MERGED_ANALYTICS_CHAIN_IDS,
} from "config/chains";
import {
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  REFERRAL_CODE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
} from "config/localStorage";
import StatsProvider from "contexts/StatsProvider";
import StatsProviderV2 from "contexts/StatsProviderV2";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { defaultLocale, dynamicActivate } from "lib/i18n";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import {
  activateInjectedProvider,
  clearWalletConnectData,
  clearWalletLinkData,
  getInjectedHandler,
  getWalletConnectV2Handler,
  hasCoinBaseWalletExtension,
  hasMetaMaskWalletExtension,
  useEagerConnect,
  useInactiveListener,
} from "lib/wallets";
import AnalyticsV2 from "pages/AnalyticsV2/index.tsx";
import AnalyticsYummy from "pages/AnalyticsYummy/index.tsx";

import AccountOverview from "pages/AccountOverview";

import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { DEFAULT_MICROSWAP_SELECTED_LIQ_SOURCE } from "config/dex";
import AprProvider from "contexts/AprProvider";
import ThemeProvider, { useThemeContext } from "contexts/ThemeProvider";
import { contractFetcher } from "lib/contracts";
import BuyYmy from "pages/Buy/BuyYmy";
import Earn from "pages/Earn";
import Yslp from "pages/Earn/Yslp";
import Ylp from "pages/Earn/Ylp";
import Faucet from "pages/Faucet";
import { SwapV2 } from "pages/SwapV2/SwapV2";
import { useWebsocketProvider, WebsocketContextProvider } from "./WebsocketContextProvider";
import { ExchangeMerged } from "pages/ExchangeMerged/ExchangeMerged";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import CompleteAccountTransfer from "pages/CompleteAccountTransfer/CompleteAccountTransfer";
import AnalyticsV3 from "../pages/AnalyticsV3";
import AnalyticsSplits from "../pages/AnalyticsV3/AnalyticsSplits";
import { OrderlyConfigProvider } from "@orderly.network/hooks";
import { DefaultEVMWalletAdapter } from "@orderly.network/default-evm-adapter";
import { EthersProvider } from "@orderly.network/web3-provider-ethers";
import OrderlyNetworkConnectionWrapper from "App/OrderlyNetworkConnectionWrapper";
import Portfolio from "pages/Portfolio";
import { ExchangeOrderly } from "pages/ExchangeMerged/ExchangeOrderly";
import Landing from "pages/Landing";

import PortfolioOrderly from "pages/PortfolioOrderly";
import Markets from "pages/Markets";
// if ("ethereum" in window) {
//   window.ethereum.autoRefreshOnNetworkChange = false;
// }
if (window.ethereum !== undefined) {
  (window.ethereum || {}).autoRefreshOnNetworkChange = false;
}

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  return library;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

const DEFAULT_LIST_SLIPPAGE = [0.1, 0.5, 1, 2];
function FullApp() {
  const isHome = isHomeSite();
  const exchangeRef = useRef();
  const { connector, library, deactivate, activate, active, account } = useWeb3React();
  const { chainId } = useChainId();

  const location = useLocation();
  const history = useHistory();
  const readerYslpAddress = getContract(chainId, "ReaderYslp");

  const [tradeVersion, setTradeVersion] = useState(() => {
    if (window.localStorage.getItem('["trade-version"]')) {
      return JSON.parse(window.localStorage.getItem('["trade-version"]'));
    }
    return "V2";
  });
  // useEventToast();
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, chainId]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);

  // useHandleUnsupportedNetwork();

  const query = useRouteQuery();

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM);
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search);
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM);
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(REFERRAL_CODE_QUERY_PARAM)) {
          queryParams.delete(REFERRAL_CODE_QUERY_PARAM);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [query, history, location]);

  const disconnectAccount = useCallback(() => {
    // only works with WalletConnect
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    deactivate();
  }, [deactivate]);

  const disconnectAccountAndCloseSettings = () => {
    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const connectInjectedWallet = getInjectedHandler(activate, deactivate);
  const activateWalletConnectV2 = useCallback(() => {
    getWalletConnectV2Handler(activate, deactivate, setActivatingConnector, setWalletModalVisible)();
  }, [activate, deactivate]);

  const attemptActivateWallet = useCallback(
    (providerName) => {
      localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
      localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
      activateInjectedProvider(providerName);
      connectInjectedWallet();
    },
    [connectInjectedWallet]
  );

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = useCallback(() => {
    if (!hasMetaMaskWalletExtension()) {
      helperToast.error(
        <div>
          <span>MetaMask not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use YUMMY with its built-in
              browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using YUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("MetaMask");
  }, [attemptActivateWallet, userOnMobileDevice]);

  const activateCoinBase = useCallback(() => {
    if (!hasCoinBaseWalletExtension()) {
      helperToast.error(
        <div>
          <span>Coinbase Wallet not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink>, and use YUMMY
              with its built-in browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink> to start using
              YUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("CoinBase");
  }, [attemptActivateWallet, userOnMobileDevice]);

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false);
  const [redirectPopupTimestamp, setRedirectPopupTimestamp] = useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY);

  const [selectedToPage, setSelectedToPage] = useState("");
  const connectWallet = () => setWalletModalVisible(true);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [savedSelectedDexes, setSavedSelectedDexes] = useLocalStorageSerializeKey(
    [chainId, "micro-swap-selected-dexes"],
    DEFAULT_MICROSWAP_SELECTED_LIQ_SOURCE[MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : FANTOM]
  );
  const [temptLiqSource, setTemptLiqSource] = useState(savedSelectedDexes);
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(true);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );
  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );
  const handleChooseLiqSource = (dexId) => {
    let result = `${temptLiqSource}`;
    if (temptLiqSource.includes(`${dexId},`)) {
      result = temptLiqSource.replace(`${dexId},`, "");
    } else result += `${dexId},`;
    setTemptLiqSource(result);
  };
  const openSettings = () => {
    // const slippage = savedSlippageAmount;
    // setSlippageAmount(slippage / 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    // setShowPnlAfterFees(savedShowPnlAfterFees);
    setShowPnlAfterFees(true);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting);
    setIsSettingsVisible(true);
  };
  useEffect(() => {
    if (!isSettingsVisible) {
      setTemptLiqSource(savedSelectedDexes);
    } else {
      const slippage = savedSlippageAmount / 100;
      setSlippageAmount(slippage);
    }
  }, [isSettingsVisible]);
  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    setSavedSelectedDexes(temptLiqSource);
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const baseUrl = getAppBaseUrl();
  let appRedirectUrl = baseUrl + selectedToPage;
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode);
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`;
    }
  }

  const [pendingTxns, setPendingTxns] = useState([]);

  const showRedirectModal = (to) => {
    // setRedirectModalVisible(true);
    setSelectedToPage(to);
  };

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await library.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <span>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </span>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <span>View</span>
                </ExternalLink>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [library, pendingTxns, chainId]);

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const { wsProvider } = useWebsocketProvider();

  useEffect(() => {
    const wsVaultAbi = chainId === ARBITRUM ? VaultV2.abi : VaultV2b.abi;
    // const wsProvider = getWsProvider(active, chainId);

    if (!wsProvider) {
      return;
    }

    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [active, chainId, vaultAddress, positionRouterAddress]);

  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    !walletModalVisible && setSelectedWallet(null);
  }, [selectedWallet, walletModalVisible]);

  const wallets = useMemo(() => {
    return [
      {
        className: "MetaMask-btn",
        onClick: activateMetaMask,
        logo: metamaskImg,
        title: "MetaMask",
      },
      {
        className: "CoinbaseWallet-btn",
        onClick: activateCoinBase,
        logo: coinbaseImg,
        title: "Coinbase Wallet",
      },
      {
        className: "WalletConnect-btn",
        onClick: activateWalletConnectV2,
        logo: walletConnectImg,
        title: "WalletConnect",
      },
    ];
  }, [activateCoinBase, activateMetaMask, activateWalletConnectV2]);
  let notiTitle = "";
  let notiDes = "";
  switch (chainId) {
    case FANTOM: {
      notiTitle = "Withdraw your esYMY in v1!";
      notiDes = (
        <div>
          After August 1st 12:00 PM (UTC), ALL esYMY vesting on v1 will become unwithdrawable. Please{" "}
          <a target="_self" href="https://legacy.yummy.fi/#/earn-v2/vest">
            withdraw
          </a>{" "}
          all esYMY and transfer them to v2 now.
        </div>
      );
      break;
    }
    case OP: {
      notiTitle = "Migrate your YMY now!";
      notiDes = (
        <div>
          The{" "}
          <a target="_self" href="https://optimistic.etherscan.io/address/0x47536f17f4ff30e64a96a7555826b8f9e66ec468">
            multiYMY{" "}
          </a>{" "}
          will be abandoned, and migration support will not be available from August 9th, 00:00 AM UTC. Please complete
          your{" "}
          <a target="_self" href=" https://app.yummy.fi/#/migrate">
            migration
          </a>{" "}
          before the deadline.
        </div>
      );
      break;
    }

    default:
      break;
  }

  const [hiddenNotiState, setHiddenNotiState] = useState(false);

  const handleCloseNoti = () => {
    setHiddenNotiState(true);
  };
  return (
    <>
      <OrderlyNetworkConnectionWrapper>
        <div className={`App ${lightThemeClassName}`}>
          <WrongChainModal />
          <div className="App-content">
            {Date.now() < 1692180000000 && notiTitle && notiDes && !hiddenNotiState && location?.pathname !== "/" ? (
              <Notice>
                <span>
                  <strong>EsYMY migration period:</strong> August 2nd - August 16th (14 days). Complete{" "}
                  <ExternalLink href="https://app.yummy.fi/#/earn-v2/vest">
                    <span>migration</span>
                  </ExternalLink>{" "}
                  before the deadline. Affects rewarded esYMY on Fantom V1 and bonus esYMY from minting NFTs on
                  Optimism.
                </span>
                <MdClose fontSize={20} className="Modal-close-icon" onClick={handleCloseNoti} />
              </Notice>
            ) : null}
            <Header
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              setWalletModalVisible={setWalletModalVisible}
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
              tradeVersion={tradeVersion}
              account={account}
              library={library}
              savedSlippageAmount={savedSlippageAmount}
              savedSelectedDexes={savedSelectedDexes}
            />
            {isHome && (
              <Switch>
                <Route exact path="/">
                  <AprProvider active={active}>
                    <Landing />
                  </AprProvider>
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            )}

            {!isHome && (
              <Switch>
                <Route exact path="/">
                  <ExchangeOrderly
                    ref={exchangeRef}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    connectWallet={connectWallet}
                    setSavedSlippageAmount={setSavedSlippageAmount}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                    savedSelectedDexes={savedSelectedDexes}
                  />
                </Route>
                <Route exact path="/trade">
                  <ExchangeOrderly
                    ref={exchangeRef}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    savedShouldShowPositionLines={savedShouldShowPositionLines}
                    setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                    connectWallet={connectWallet}
                    setSavedSlippageAmount={setSavedSlippageAmount}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                    savedSelectedDexes={savedSelectedDexes}
                  />
                </Route>
                {/* <Route exact path="/markets">
                  <Markets />
                </Route> */}
                <Route exact path="/leaderboard">
                  <AnalyticsV3 setPendingTxns={setPendingTxns} />
                </Route>
                <Route exact path="/portfolio">
                  <PortfolioOrderly
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    connectWallet={connectWallet}
                    savedSlippageAmount={savedSlippageAmount}
                    savedSelectedDexes={savedSelectedDexes}
                    savedIsPnlInLeverage={savedIsPnlInLeverage}
                    savedShowPnlAfterFees={savedShowPnlAfterFees}
                  />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            )}
          </div>
        </div>

        <ToastContainer
          limit={1}
          transition={Zoom}
          position="bottom-right"
          autoClose={7000}
          newestOnTop={false}
          closeOnClick={false}
          draggable={false}
          pauseOnHover
          theme={isLightTheme ? "light" : "dark"}
        />
        <EventToastContainer />
        <RedirectPopupModal
          redirectModalVisible={redirectModalVisible}
          setRedirectModalVisible={setRedirectModalVisible}
          appRedirectUrl={appRedirectUrl}
          setRedirectPopupTimestamp={setRedirectPopupTimestamp}
          setShouldHideRedirectModal={setShouldHideRedirectModal}
          shouldHideRedirectModal={shouldHideRedirectModal}
        />
        <Modal
          className="Connect-wallet-modal-v2"
          isVisible={walletModalVisible}
          setIsVisible={setWalletModalVisible}
          label={`Connect Wallet`}
        >
          <div className="wallet-desc">
            Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase
            securely. Never share them with anyone.
          </div>
          <div className="buttons">
            {wallets.map((wallet) => {
              return (
                <button
                  key={wallet.title}
                  className={`Wallet-btn ${wallet.className}`}
                  onClick={() => {
                    setSelectedWallet(wallet.title);
                    wallet.onClick();
                  }}
                >
                  <img width={36} height={36} src={wallet.logo} alt={wallet.title} />
                  <div
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>{wallet.title}</span>
                    {selectedWallet === wallet.title && (
                      <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "12px" }}>
                        Please confirm in {wallet.title}
                      </div>
                    )}
                  </div>
                  {selectedWallet === wallet.title && <CircleLoader />}
                </button>
              );
            })}
          </div>
          <ExternalLink
            href="https://docs.yummy.fi/trading#adding-a-wallet"
            target="_blank"
            className="learn-to-connect"
          >
            Learn how to Connect Wallet <img src={isLightTheme ? icLinkLight : icLink} alt="icLink" />
          </ExternalLink>
        </Modal>
        <Modal
          className="App-settings"
          isVisible={isSettingsVisible}
          setIsVisible={setIsSettingsVisible}
          label={`Settings`}
        >
          <div className="settings-row-container">
            <div className="setting-title">Allowed Slippage</div>
            <div className="allowed-slippage">
              {DEFAULT_LIST_SLIPPAGE.map((item) => (
                <button
                  className={`slippage-tab ${item === Number(slippageAmount) ? "slippage-tab--active" : ""}`}
                  key={item}
                  disabled={item === Number(slippageAmount)}
                  onClick={() => setSlippageAmount(item)}
                >
                  {item}%
                </button>
              ))}
              <div className="App-slippage-tolerance-input-container">
                <input
                  type="number"
                  className="App-slippage-tolerance-input"
                  min="0"
                  placeholder="Custom"
                  value={slippageAmount}
                  onChange={(e) => setSlippageAmount(e.target.value)}
                />
                <div className="App-slippage-tolerance-input-percent">%</div>
              </div>
            </div>
          </div>
          <button
            style={{ marginTop: "24px" }}
            className="default-btn Exchange-swap-button"
            onClick={saveAndCloseSettings}
          >
            <span>Save</span>
          </button>
        </Modal>
      </OrderlyNetworkConnectionWrapper>
    </>
  );
}

function App() {
  useScrollToTop();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);
  return (
    <SWRConfig value={{ refreshInterval: 10000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <OrderlyConfigProvider
          brokerId="yummy"
          networkId="mainnet"
          brokerName="Yummy"
          walletAdapters={[new DefaultEVMWalletAdapter(new EthersProvider())]}
        >
          <WebsocketContextProvider>
            <SEO>
              <Router>
                <I18nProvider i18n={i18n}>
                  <ThemeProvider>
                    <FullApp />
                  </ThemeProvider>
                </I18nProvider>
              </Router>
            </SEO>
          </WebsocketContextProvider>
        </OrderlyConfigProvider>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

const Notice = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .Modal-close-icon {
    min-width: 20px;
    position: absolute;
    right: 16px;
    top: 8px;
  }
  .noti-container {
    display: flex;
    align-items: center;
    .noti-title {
      font-weight: 700;
    }
  }

  a {
    color: #231f20;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    text-decoration-line: underline;
  }
  padding: 10px 24px;

  gap: 10px;

  background: #ffdf76;
  color: rgba(35, 31, 32, 1);

  @media screen and (max-width: 1024px) {
    padding: 10px 16px;
    justify-content: flex-start;
    .noti-container {
      align-items: flex-start;
      flex-direction: column;
      .noti-title {
        margin-bottom: 8px;
      }
    }
  }
`;

export default App;
