import { getContract } from "config/contracts";
import { getNormalizedAxlTokenSymbol } from "config/tokens";
import { getChartPricesFromStats } from "domain/pricesV3";
import { ethers } from "ethers";
import { getServerBaseUrl } from "config/backend";
import { getTokenSymbolFromString } from "domain/tokens";
import { parseValue } from "lib/numbers";

export const getTokenChartPrice = async (chainId: number, symbol: string, period: string, apiBaseUrl) => {
  let prices = [];
  const _symbol = getNormalizedAxlTokenSymbol(symbol);
  try {
    prices = await getChartPricesFromStats(chainId, _symbol, period, apiBaseUrl);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex, "Switching to graph chainlink data");
    try {
      // prices = await getChainlinkChartPricesFromGraph(_symbol, period);
    } catch (ex2) {
      // eslint-disable-next-line no-console
      console.warn("getChainlinkChartPricesFromGraph failed", ex2);
    }
  }
  return prices;
};

export async function getCurrentPriceOfToken(symbol: string) {
  try {
    // const indexPricesUrl = getServerUrl(chainId, "/prices");
    const indexPricesUrl = `https://api.orderly.org/v1/public/futures/PERP_${symbol}_USDC`;
    const response = await fetch(indexPricesUrl);
    const { data } = await response.json();
    return parseValue(data.mark_price || 0, 30);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return;
  }
}
