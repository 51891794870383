import styled from "styled-components";
import Tab from "components/Tab/Tab";
import { useMemo, useState } from "react";

import MarketsTab from "pages/Markets/MarketsTab";
import FundingTab from "pages/Markets/FundingTab";
import { useQuery } from "@orderly.network/hooks";

const MARKETS = "Markets";
const FUNDING = "Funding";

const OPTION = [MARKETS, FUNDING];
const OPTION_LABELS = {
  [MARKETS]: MARKETS,
  [FUNDING]: FUNDING,
};

const Markets = () => {
  const [option, setOption] = useState(MARKETS);

  const onOptionChange = (option) => {
    setOption(option);
  };
  const marketsInfo = useQuery("/v1/public/info");
  const markets = useQuery("/v1/public/futures");

  const marketList = useMemo(() => {
    if (marketsInfo?.data && markets?.data) {
      let arrMarkets = [];
      for (let i = 0; i < markets?.data.length; i++) {
        const info = marketsInfo?.data?.find((x) => x.symbol === markets?.data[i]?.symbol);
        if (info) {
          arrMarkets.push({
            ...info,
            ...markets?.data[i],
          });
        }
      }
      return arrMarkets;
    }
    return [];
  }, [marketsInfo?.data, markets?.data]);
  const renderedComponent = {
    [MARKETS]: <MarketsTab marketList={marketList}/>,
    [FUNDING]: <FundingTab />,
  }[option];

  return (
    <Wrapper>
      <Tab1 options={OPTION} option={option} optionLabels={OPTION_LABELS} onChange={onOptionChange} />
      {renderedComponent}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 24px;
`;

const Tab1 = styled(Tab)`
  grid-template-columns: 1fr 1fr;
  height: 44px;
  text-align: center;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  border-radius: 4px;
  background: var(--natural-1, #2e3033);
  user-select: none;
  overflow: hidden;
  box-sizing: content-box;
  margin-bottom: 16px;

  * {
    user-select: none;
  }

  > div {
    height: 44px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    border-radius: 10px;

    color: var(--Text-Text_Secondary, #919499);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }

  .active {
    border: 2px solid #41454d;
    color: var(--Text-Text_Secondary, #fff);
  }
`;

export default Markets;
