import { useEffect, useMemo, useState } from "react";

import { commify } from "@ethersproject/units";
import { useOrderEntry } from "@orderly.network/hooks";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import PercentageButtons from "components/ExchangeV2/PercentageButtons";
import RowsDropdown, { RowsDropdownHandler } from "components/ExchangeV2/RowsDropdown";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import { helperToast } from "lib/helperToast";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import styled from "styled-components";
import Modal from "../Modal/Modal";
import TokenTopInfo from "./TokenTopInfo";
import { OrderSide } from "@orderly.network/types";
export default function OrderEditorV3(props) {
  const { positions, updateOrder, updateAlgoOrder, pairInfo, isVisible, setIsVisible, selectedOrder, orders } = props;
  // console.log("?????", {selectedOrder, orders});

  const order = selectedOrder?.order_id
    ? orders.find((x) => x.order_id === selectedOrder?.order_id)
    : orders.find((x) => x.algo_order_id === selectedOrder?.algo_order_id);
  useEffect(() => {
    if (!order) setIsVisible(false);
  }, [order]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const position = positions.find((x) => x.symbol === order?.symbol);
  const isLong = order?.side === "BUY" ? true : false;
  const isTP = order?.algo_type === "TAKE_PROFIT" || order?.algo_type === "STOP_LOSS";
  const tokenSymbol = getBaseOrderlyAsset(order?.symbol);
  const [triggerPriceValue, setTriggerPriceValue] = useState(0);
  const [stopLimitPrice, setStopLimitPrice] = useState(0);
  const [closeQuantity, setCloseQuantity] = useState(0);

  const { maxQty } = useOrderEntry(order?.symbol || "PERP_BTC_USDC", {
    initialOrder: {
      side: isLong ? OrderSide.BUY : OrderSide.SELL,
    },
  });
  const currentPair = useMemo(() => {
    if (!order) return;
    if (pairInfo && pairInfo?.length > 0 && order?.symbol) {
      return pairInfo.find((x) => x.symbol === order?.symbol);
    }
  }, [pairInfo]);
  useEffect(() => {
    if (!order) setIsVisible(false);
  }, [order]);
  const handleSelectPercentage = (percentage) => {
    const maxQuantity = (order?.quantity || 0) + (maxQty || 0);
    if (!maxQuantity || parseValue(maxQuantity || 0, 30).eq(0)) {
      return;
    }
    const maxColateral = parseValue(maxQuantity || 0, 30)
      .mul(parseValue(percentage, 30))
      .div(parseValue(1, 30));
    setCloseQuantity(formatAmount(maxColateral, 30, maxQtyPercision, false));
    // setValue("order_quantity", Number(formatAmount(maxColateral, 30, maxQtyPercision, false)));

    // setFromValue(formatAmount(userTokenBalances?.ysusdBalance.sub(tempFee), 30, 6, false));
  };

  const maxQtyPercision = useMemo(() => {
    if (!order) return 2;
    if (currentPair) {
      const { base_tick } = currentPair;
      const tickStr = base_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair, order]);
  const maxPricePercision = useMemo(() => {
    if (!order) return 2;
    if (currentPair) {
      const { quote_tick } = currentPair;
      const tickStr = quote_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair, order]);
  const getOrderlyType = useMemo(() => {
    if (order?.algo_type?.includes("TP_SL")) {
      let isTP,
        isSL = false;
      if (order?.child_orders?.[0]?.trigger_price) {
        isTP = true;
      }
      if (order?.child_orders?.[1]?.trigger_price) {
        isSL = true;
      }
      if (isTP && isSL) return "TP/SL";
      else if (isTP) return "Take profit";
      else return "Stop loss";
    }
    if (isLong) {
      if (order?.side === "BUY") {
        if (order?.type === "MARKET") return "Stop Market";
        if (order?.algo_type && order?.algo_type !== "BRACKET") return "Stop Limit";
        return order?.type === "POST_ONLY"
          ? "Post Only"
          : order?.type === "IOC"
          ? "IOC"
          : order?.type === "FOK"
          ? "FOK"
          : "Limit";
      } else {
        if (order?.algo_type === "STOP_LOSS") return "Stop Loss";
        return "Take Profit";
      }
    } else {
      if (order?.side === "SELL") {
        if (order?.type === "MARKET") return "Stop Market";
        if (order?.algo_type && order?.algo_type !== "BRACKET") return "Stop Limit";
        return order?.type === "POST_ONLY"
          ? "Post Only"
          : order?.type === "IOC"
          ? "IOC"
          : order?.type === "FOK"
          ? "FOK"
          : "Limit";
      } else {
        if (order?.type === "LIMIT" && !order?.algo_type)
          return order?.type === "POST_ONLY"
            ? "Post Only"
            : order?.type === "IOC"
            ? "IOC"
            : order?.type === "FOK"
            ? "FOK"
            : "Limit";
        if (order?.algo_type === "STOP_LOSS") return "Stop Loss";
        return "Take Profit";
      }
    }
  }, [order]);
  const getIncreaseDecreaseOrder = () => {
    if (!order) return "-";
    if (order?.algo_type?.includes("TP_SL")) {
      return `Decrease ${
        order?.algo_type === "TP_SL" ? Math.abs(order?.quantity) : Math.abs(position?.position_qty)
      } ${tokenSymbol}`;
    }
    if (isLong) {
      if (order?.side === "BUY") {
        return `Increase ${order?.quantity} ${tokenSymbol}`;
      } else {
        return `Decrease ${order?.quantity} ${tokenSymbol}`;
      }
    } else {
      if (order?.side === "SELL") {
        return `Increase ${order?.quantity} ${tokenSymbol}`;
      } else {
        return `Decrease ${order?.quantity} ${tokenSymbol}`;
      }
    }
  };

  const isIncrease = getIncreaseDecreaseOrder().includes("Increase");
  useEffect(() => {
    const orderType = getOrderlyType;
    setCloseQuantity(order?.quantity);
    if (["Limit", "Post Only", "IOC", "FOK"].includes(getOrderlyType)) {
      setTriggerPriceValue(order?.price);
    } else if (orderType === "Stop Market") {
      setTriggerPriceValue(order?.trigger_price);
    } else {
      setTriggerPriceValue(order?.trigger_price);
      setStopLimitPrice(order?.price);
    }
  }, []);
  const triggerPrice = useMemo(() => {
    if (!order) return "-";
    const orderType = getOrderlyType;
    if (["Limit", "Post Only", "IOC", "FOK"].includes(orderType)) return order?.price;
    else return order?.trigger_price;
  }, [order]);
  const stopPrice = useMemo(() => {
    if (!order) return "-";
    return order?.price;
  }, [order]);
  const priceRange = useMemo(() => {
    if (!order) return 0;
    return Number(limitDecimals(isLong ? order?.mark_price * 1.03 : order?.mark_price * 0.97, maxPricePercision));
  }, [order?.mark_price]);
  const priceScope = useMemo(() => {
    if (!order) return 0;
    return Number(limitDecimals(isLong ? order?.mark_price * 0.4 : order?.mark_price * 1.6, maxPricePercision));
  }, [order?.mark_price]);

  const priceRangeStopLimit = useMemo(() => {
    if (!order) return 0;
    return Number(
      limitDecimals(
        isLong ? (triggerPriceValue || order.trigger_price) * 1.03 : (triggerPriceValue || order.trigger_price) * 0.97,
        maxPricePercision
      )
    );
  }, [order]);
  const priceScopeStopLimit = useMemo(() => {
    if (!order) return;
    return Number(
      limitDecimals(
        isLong ? (triggerPriceValue || order.trigger_price) * 0.4 : (triggerPriceValue || order.trigger_price) * 1.6,
        maxPricePercision
      )
    );
  }, [order]);

  const onClickPrimary = async () => {
    setIsSubmitting(true);
    try {
      const orderType = getOrderlyType;
      if (["Post Only", "IOC", "FOK", "Limit"].includes(orderType)) {
        if (order?.algo_order_id) {
          await updateAlgoOrder(order?.algo_order_id, {
            symbol: order?.symbol,
            side: order?.side,
            order_type: order?.type,
            order_quantity: closeQuantity,
            order_price: triggerPriceValue,
          });
        } else {
          await updateOrder(order?.order_id, {
            symbol: order?.symbol,
            side: order?.side,
            order_type: order?.type,
            order_quantity: closeQuantity,
            order_price: triggerPriceValue,
          });
        }
      } else if (orderType === "Stop Market") {
        await updateAlgoOrder(order?.algo_order_id, {
          symbol: order?.symbol,
          side: order?.side,
          order_type: order?.type,
          order_quantity: closeQuantity,
          trigger_price: triggerPriceValue,
        });
      } else {
        await updateAlgoOrder(order?.algo_order_id, {
          symbol: order?.symbol,
          side: order?.side,
          order_type: order?.type,
          order_quantity: closeQuantity,
          trigger_price: triggerPriceValue,
          order_price: stopLimitPrice,
        });
      }
      helperToast.success(
        <div>
          Updated Order.
          <br />
        </div>
      );
      setIsVisible(false);
    } catch (e) {
      helperToast.error(
        <div>
          {e?.message || "Update Order failed."}
          <br />
        </div>
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onTriggerPriceChange = (evt) => {
    const value = evt.target.value;
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        setTriggerPriceValue(evt.target.value || "");
      }
    } else {
      setTriggerPriceValue(evt.target.value || "");
    }
  };
  const onTriggerStopLimitPriceChange = (evt) => {
    const value = evt.target.value;
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        setStopLimitPrice(evt.target.value || "");
      }
    } else {
      setStopLimitPrice(evt.target.value || "");
    }
  };

  const getError = () => {
    if (!triggerPriceValue || !Number(triggerPriceValue)) return "Enter Price";

    if (!closeQuantity || !Number(closeQuantity)) return "Enter Quantity";
    if (getOrderlyType === "Stop Limit" && !stopLimitPrice) return "Enter Limit Price";
    if (totalUSD < 10.0022) return "Min Order: 10.0022 USDC";
    if (Number(order?.quantity + (maxQty || 0)) < Number(closeQuantity))
      return `Quantity must be less than ${commify(
        limitDecimals(Number(order?.quantity + (maxQty || 0)), maxQtyPercision)
      )}`;
    if (["Limit", "Post Only", "IOC", "FOK", "Stop Market"].includes(getOrderlyType)) {
      if (isLong) {
        if (Number(triggerPriceValue) < priceScope || Number(triggerPriceValue) > priceRange)
          return `Trigger Price range: ${commify(limitDecimals(priceScope, decimalsRules(priceScope)))}-${commify(
            limitDecimals(priceRange, decimalsRules(priceRange))
          )}`;
      } else {
        if (Number(triggerPriceValue) > priceScope || Number(triggerPriceValue) < priceRange)
          return `Trigger Price range: ${commify(limitDecimals(priceRange, decimalsRules(priceRange)))}-${commify(
            limitDecimals(priceScope, decimalsRules(priceScope))
          )}`;
      }
    } else {
      if (isLong) {
        if (Number(stopLimitPrice) < priceScopeStopLimit || Number(stopLimitPrice) > priceRangeStopLimit)
          return `Limit Price range: ${commify(
            limitDecimals(priceScopeStopLimit, decimalsRules(priceScopeStopLimit))
          )}-${commify(limitDecimals(priceRangeStopLimit, decimalsRules(priceRangeStopLimit)))}`;
      } else {
        if (Number(stopLimitPrice) > priceScopeStopLimit || Number(stopLimitPrice) < priceRangeStopLimit)
          return `Limit Price range: ${commify(
            limitDecimals(priceScopeStopLimit, decimalsRules(priceScopeStopLimit))
          )}-${commify(limitDecimals(priceRangeStopLimit, decimalsRules(priceRangeStopLimit)))}`;
      }
    }
  };

  const isPrimaryEnabled = () => {
    if (!triggerPriceValue || !Number(triggerPriceValue) || !closeQuantity || !Number(closeQuantity)) return false;
    if (getOrderlyType === "Stop Limit" && !stopLimitPrice) return false;
    if (totalUSD < 10.0022) return false;
    if (Number(order?.quantity + (maxQty || 0)) < Number(closeQuantity)) return false;
    if (["Limit", "Post Only", "IOC", "FOK", "Stop Market"].includes(getOrderlyType)) {
      if (isLong) {
        if (Number(triggerPriceValue) < priceScope || Number(triggerPriceValue) > priceRange) return false;
      } else {
        if (Number(triggerPriceValue) > priceScope || Number(triggerPriceValue) < priceRange) return false;
      }
    } else {
      if (isLong) {
        if (Number(stopLimitPrice) < priceScopeStopLimit || Number(stopLimitPrice) > priceRangeStopLimit) return false;
      } else {
        if (Number(stopLimitPrice) > priceScopeStopLimit || Number(stopLimitPrice) < priceRangeStopLimit) return false;
      }
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (isSubmitting) {
      return `Editing...`;
    }
    return `Edit Order`;
  };

  const onFromValueChange = (e) => {
    const value = e.target.value;
    if (value.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxQtyPercision) {
        setCloseQuantity(value);
      } else {
        const newValueStr = value.split(".");
        let newValue = newValueStr[0];
        for (let i = 0; i < maxQtyPercision; i++) {
          newValue += newValueStr[1][i];
        }
        setCloseQuantity(newValue);
      }
    } else {
      setCloseQuantity(value);
    }
  };
  const totalUSD = useMemo(() => {
    if (!order) return 0;
    const orderType = getOrderlyType;
    if (["Limit", "Stop Limit", "Post Only", "IOC", "FOK", "Stop Market"].includes(orderType)) {
      return (closeQuantity || 0) * (triggerPriceValue || 0);
    }
    return (closeQuantity || 0) * (stopLimitPrice || 0);
  }, [order, getOrderlyType, closeQuantity, stopLimitPrice, triggerPriceValue]);
  const totalUSDOrigin = useMemo(() => {
    if (!order) return 0;
    const orderType = getOrderlyType;
    if (["Limit", "Stop Limit", "Post Only", "IOC", "FOK"].includes(orderType)) {
      return (order?.quantity || 0) * (order?.price || 0);
    }
    return (order?.quantity || 0) * (order?.trigger_price || 0);
  }, [order]);
  if (!order) return;
  return (
    <Modal
      isVisible={isVisible}
      className="PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1"
      setIsVisible={() => setIsVisible(false)}
      label={`Edit Order`}
      allowContentTouchMove
    >
      <div className="order-title-line order-title-line-details">
        <div className="position-info-container">
          <StyledWrap className="head">
            <div className="header">
              <div className={`${order.side === "BUY" ? `long-tag` : `short-tag`}`} />
              <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
              <div className="position-info">
                <div className="title">{tokenSymbol}/USDC</div>
                <span className="cancel-order-txt">{getOrderlyType}</span>
              </div>
            </div>
          </StyledWrap>
        </div>
      </div>
      <div className="content-container">
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-bottom">
            <div className="input-label">
              {["Limit", "Post Only", "IOC", "FOK"].includes(getOrderlyType) ? "Limit Price" : "Stop Price"}
            </div>
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={triggerPriceValue}
                onChange={onTriggerPriceChange}
              />
            </div>
            <div className="PositionEditor-token-symbol">USD</div>
          </div>
        </div>
        {getOrderlyType === "Stop Limit" && (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-bottom">
              <div className="input-label">Limit Price</div>
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={stopLimitPrice}
                  onChange={onTriggerStopLimitPriceChange}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
        )}
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-bottom">
            <div className="input-label">Quantity</div>
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                // disabled={true}
                placeholder="0.0"
                className="Exchange-swap-input"
                value={closeQuantity}
                onChange={onFromValueChange}
              />
            </div>
            <div className="PositionEditor-token-symbol">{tokenSymbol}</div>
          </div>
        </div>
        <PercentageButtons
          onChangePercentage={handleSelectPercentage}
          balance={(order?.quantity || 0) + (maxQty || 0)}
          value={closeQuantity || 0}
          decimals={6}
          fullBalance={parseValue((order?.quantity || 0) + (maxQty || 0), 10)}
          isUsd={true}
        />
        <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
          <div className="square-container square-fee-container">
            <RowsDropdown show={true} handler={<RowsDropdownHandler>Order</RowsDropdownHandler>}>
              <ExchangeInfoRow
                label={`${["Limit", "Post Only", "IOC", "FOK"].includes(getOrderlyType) ? "Limit" : "Stop"} Price`}
              >
                {triggerPriceValue && Number(triggerPriceValue) !== Number(triggerPrice) ? (
                  <>
                    <span style={{ textDecoration: "line-through", color: "#828899" }}>
                      {commify(limitDecimals(triggerPrice, decimalsRules(triggerPrice)))}
                    </span>
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#828899"} />
                    </span>{" "}
                    <div>
                      <span style={{ marginLeft: "4px" }}>
                        {commify(limitDecimals(triggerPriceValue, decimalsRules(triggerPriceValue)))}
                      </span>
                    </div>{" "}
                  </>
                ) : (
                  <span>{commify(limitDecimals(triggerPrice, decimalsRules(triggerPrice)))}</span>
                )}
              </ExchangeInfoRow>
              {getOrderlyType === "Stop Limit" && (
                <ExchangeInfoRow label={`Limit Price`}>
                  {stopLimitPrice && Number(stopLimitPrice) !== Number(stopPrice) ? (
                    <>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        {commify(limitDecimals(stopPrice, maxPricePercision))}
                      </span>
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      <div>
                        <span style={{ marginLeft: "4px" }}>
                          {commify(limitDecimals(stopLimitPrice, maxPricePercision))}
                        </span>
                      </div>{" "}
                    </>
                  ) : (
                    <span>{commify(limitDecimals(stopPrice, maxPricePercision))}</span>
                  )}
                </ExchangeInfoRow>
              )}
              <ExchangeInfoRow label={`Order Qty`}>
                {closeQuantity && Number(closeQuantity) !== Number(order?.quantity) ? (
                  <>
                    <span style={{ textDecoration: "line-through", color: "#828899" }}>
                      {Math.abs(order?.quantity)}
                    </span>
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#828899"} />
                    </span>{" "}
                    <div>
                      <span style={{ marginLeft: "4px" }}>{closeQuantity}</span>
                    </div>{" "}
                  </>
                ) : (
                  <span>{Math.abs(order?.quantity)}</span>
                )}
                <span style={{ marginLeft: "4px" }}>{tokenSymbol}</span>
              </ExchangeInfoRow>
              {/* <ExchangeInfoRow label={`Total USDC`}>
                {Number(limitDecimals(totalUSDOrigin, 2)) !== Number(limitDecimals(totalUSD, 2)) ? (
                  <>
                    <span style={{ textDecoration: "line-through", color: "#828899" }}>
                      {limitDecimals(totalUSDOrigin, 2)}
                    </span>
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      <GreenArraowIcon fill={"#828899"} />
                    </span>{" "}
                    <div>
                      <span style={{ marginLeft: "4px" }}>{limitDecimals(totalUSD, 2)}</span>
                    </div>{" "}
                  </>
                ) : (
                  <span>{limitDecimals(totalUSDOrigin, 2)}</span>
                )}
                <span style={{ marginLeft: "4px" }}>USDC</span>
              </ExchangeInfoRow> */}
            </RowsDropdown>
          </div>
        </div>
      </div>
      <div className="Exchange-swap-button-container">
        <button
          className="App-cta Exchange-swap-button Exchange-list-modal-button"
          onClick={onClickPrimary}
          disabled={!isPrimaryEnabled()}
        >
          {getPrimaryText()}
        </button>
      </div>
    </Modal>
  );
}

const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 8px 0 16px 0;
`;
export const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 12px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
