import styled from "styled-components";

import btc from "img/home/ic_btc.svg";
import chart from "img/home/chart.svg";
import { useMarkets } from "@orderly.network/hooks";
import { MarketsType } from "@orderly.network/hooks";

const TopMarkets = () => {
  const [orderlyMarkets] = useMarkets(MarketsType.ALL);
  const marketAsssetList = orderlyMarkets?.sort((a, b) => b?.["24h_amount"] - a?.["24h_amount"]);
  const top6Markets = marketAsssetList?.slice(0, 6);
  return (
    <Wrapper>
      <h1>Top Markets On Yummy</h1>

      <div className="TopMarkets-body">
        {top6Markets?.map((market, index) => (
          <div className="TopMarkets-bodyItem" key={index}>
            <div className="TopMarkets-bodyItemToken">
              <img
                src={`https://oss.orderly.network/static/symbol_logo/${market?.["symbol"].split("_")[1]}.png`}
                alt="Token"
              />
              <span>
                {market?.["symbol"].split("_")[1]}/{market?.["symbol"].split("_")[2]}
              </span>
            </div>
            <div className="TopMarkets-bodyItemPrice">
              <span>${market?.["mark_price"]}</span>
              <div>{(market?.["change"] * 100).toFixed(2)}%</div>
            </div>
          </div>
        ))}
      </div>

      <button onClick={() => window.open("https://app.yummy.fi/#/trade", "_blank")} className="discover-btn">
        Discover More Markets
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;

  display: flex;
  padding: 72px 24px 120px 24px;
  flex-direction: column;
  align-items: stretch;
  gap: 48px;

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    text-align: center;
  }

  .discover-btn {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Border-Radius-Medium, 4px);
    border: 1px solid var(--Border, #2B3139);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    background: transparent;
    outline: none;

    align-self: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .TopMarkets-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .TopMarkets-bodyItem {
    display: flex;
    padding: 32px 24px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;

    border-radius: 8px;
    border: 1px solid var(--Border, #2B3139);

    background-image: url(${chart});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .TopMarkets-bodyItemToken {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
    }

    span {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }

  .TopMarkets-bodyItemPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;

    span {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }

    div {
      display: flex;
      align-items: center;

      color: var(--Functional-Green, #40ffb7);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 48px 24px 120px 24px;
    gap: 16px;

    .TopMarkets-body {
    }

    .TopMarkets-bodyItem {
    }

    .TopMarkets-bodyItemToken {
      flex-direction: column;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .TopMarkets-bodyItemPrice {
    }
  }

  @media screen and (max-width: 767px) {
    padding: 48px 16px 120px 16px;

    h1 {
      font-size: 24px;
    }

    .TopMarkets-body {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }

    .TopMarkets-bodyItem {
      padding: 16px;
      align-items: center;
    }

    .TopMarkets-bodyItemToken {
      flex-direction: row;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .TopMarkets-bodyItemPrice {
    }
  }
`;

export default TopMarkets;
