const SvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="161" height="24" fill="none" viewBox="0 0 161 24">
    <g clipPath="url(#clip0_22608_15086)">
      <path stroke="url(#paint0_linear_22608_15086)" d="M80.5 23.5V12h79V0"></path>
      <path stroke="url(#paint1_linear_22608_15086)" d="M80.5 23.5V12h-79V0"></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_22608_15086"
        x1="159.5"
        x2="80.5"
        y1="11.75"
        y2="11.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1EDFEC" stopOpacity="0.2"></stop>
        <stop offset="1" stopColor="#1EDFEC"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_22608_15086"
        x1="1.5"
        x2="80.5"
        y1="11.75"
        y2="11.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1EDFEC" stopOpacity="0.2"></stop>
        <stop offset="1" stopColor="#1EDFEC"></stop>
      </linearGradient>
      <clipPath id="clip0_22608_15086">
        <path fill="#fff" d="M160.5 24V0H.5v24z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default SvgIcon;
