import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import {
  decimalsRules,
  getBaseOrderlyAsset,
  getMaxLeverageOrderly,
  getPositionSizeOrderly,
  hashStringOrderly,
  importImage,
} from "lib/legacy";
import { formatAmount, limitDecimals, parseValue, usdFormatter } from "lib/numbers";
import moment from "moment";
import styled from "styled-components";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import { useOrderEntry } from "@orderly.network/hooks";
import { OrderSide, OrderType } from "@orderly.network/types";
import { helperToast } from "lib/helperToast";
import { useEffect, useMemo, useState } from "react";
import { commify } from "@ethersproject/units";

export const MAPPED_ACTION_TYPES = {
  STOP_LOSS: "Stop Loss",
  TAKE_PROFIT: "Take Profit",
  TRAILING_STOP: "Trailing Stop",
};

export default function PositionDetailsV3(props) {
  const {
    onConfirm,
    isVisible,
    positions,
    selectedPosition,
    setIsVisible,
    hideClose,
    account,
    orders,
    handleCancelRelatedOrders,
    feeTier,
  } = props;
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const position = positions.find((x) => x.symbol === selectedPosition?.symbol);
  useEffect(() => {
    if (!position) setIsVisible(false);
  }, [position]);
  const [isConfirm, setIsConfirm] = useState(false);
  const {
    submit,
    setValue,
    formattedOrder,
    metaState: { dirty, errors, submitted },
  } = useOrderEntry(position?.symbol || "PERP_BTC_USDC", {
    initialOrder: {
      side: position?.position_qty > 0 ? OrderSide.SELL : OrderSide.BUY,
      order_type: OrderType.MARKET,
      order_quantity: String(Math.abs(position?.position_qty)),
      reduce_only: true,
    },
  });
  // console.log("??????", {errors, formattedOrder})
  const maxPriceDecimals = useMemo(() => {
    let decimals = 1;
    const martkPrice = position?.mark_price;
    if (martkPrice) {
      decimals = martkPrice?.toString()?.split(".")?.[1]?.length || 1;
    }
    return decimals;
  }, [position?.mark_price]);
  if (!position) return;
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);
  const isLong = position?.position_qty > 0;

  // console.log('????', position);

  const handleSubmitClosePosition = async () => {
    const textQty = `${formattedOrder ? formattedOrder?.order_quantity : "-"} ${tokenSymbol}`;

    try {
      const symbol = position?.symbol;
      setIsConfirm(true);
      await submit();
      setIsVisible(false);
      helperToast.success(
        <div>
          Closed order {textQty}
          <br />
        </div>
      );
      handleCancelRelatedOrders && handleCancelRelatedOrders(symbol, isLong);
    } catch (error) {
      helperToast.error(error?.message || `Failed to close order ${textQty}`);
    } finally {
      setIsConfirm(false);
    }
  };
  return (
    <Wrapper className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Open Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container" style={{ width: "100%" }}>
              {" "}
              <StyledWrap className="head">
                <div className="header">
                  <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} />
                  <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
                  <div className="position-info">
                    <div className="title">{tokenSymbol}/USDC</div>
                  </div>
                </div>
              </StyledWrap>
            </div>
          </div>{" "}
          <Wrap className="content-container">
            {" "}
            <Divider />
            <div className="order-details-container">
              <RowsDropdown
                handler={<ExchangeInfoRow label={<div className="title">Position Details</div>}></ExchangeInfoRow>}
                show
              >
                <ExchangeInfoRow label={`Unrealized PnL`}>
                  <div
                    className={cx({ positive: position?.unrealized_pnl > 0, negative: position?.unrealized_pnl < 0 })}
                  >
                    {position?.unrealized_pnl > 0 ? "+" : "-"}
                    {formatAmount(parseValue(position?.unrealized_pnl || 0, 30), 30, 2, true)
                      .replace("-", "")
                      .replace("-", "")}{" "}
                    <span className="muted">USDC</span>
                    &nbsp;(
                    {position?.unrealized_pnl > 0 ? "+" : "-"}
                    {commify(limitDecimals((position?.unrealized_pnl_ROI || 0) * 100, 2)).replace("-", "")}
                    %)
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Notional`}>
                  {position?.notional ? formatAmount(parseValue(position?.notional || 0, 30), 30, 2, true) : "-"}{" "}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Quantity`}>
                  {Math.abs(position?.position_qty)} {tokenSymbol}
                </ExchangeInfoRow>

                <ExchangeInfoRow label={`Margin`}>
                  {" "}
                  {formatAmount(parseValue(position?.mm || 0, 30), 30, 2, true)}{" "}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  {" "}
                  {formatAmount(
                    parseValue(position?.mark_price || 0, 30),
                    30,
                    decimalsRules(position?.mark_price),
                    true
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Open Price`}>
                  {formatAmount(
                    parseValue(position?.average_open_price || 0, 30),
                    30,
                    decimalsRules(position?.average_open_price),
                    true
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  {position?.est_liq_price
                    ? formatAmount(
                        parseValue(position?.est_liq_price || 0, 30),
                        30,
                        decimalsRules(position?.est_liq_price),
                        true
                      )
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  {feeTier ? commify(limitDecimals(Math.abs(position?.notional || 0) * (feeTier / 10000), 4)) : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Time Opened`}>
                  {moment(new Date(position?.timestamp)).format(dateFormat)}&nbsp;
                  {moment(new Date(position?.timestamp)).format(timeFormat)}
                </ExchangeInfoRow>
              </RowsDropdown>
            </div>
          </Wrap>
          {!hideClose && (
            <div className="Exchange-swap-button-container">
              <button
                className="App-cta Exchange-swap-button"
                onClick={handleSubmitClosePosition}
                // disabled={(position && !position?.liquidationPrice) || isConfirm || isDisableCondition()}
              >
                {isConfirm ? "Closing..." : "Close Position"}
              </button>
            </div>
          )}
        </Modal>
      )}
    </Wrapper>
  );
}

const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 12px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
const Wrap = styled.div`
  @media screen and (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
`;
const Wrapper = styled.div`
  .postion-history-ic {
    cursor: pointer;
    &:hover {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(354deg) brightness(106%)
        contrast(101%);
    }
  }
  .Exchange-swap-button-container {
    margin-top: -8px;
  }
  .order-title-line .position-info-container {
    gap: 8px;
  }

  .content-container {
    padding-top: 0 !important;
    .title {
      font-weight: 700;
      color: #fff;
    }
  }
  .Exchange-info-label {
    margin-bottom: 0 !important;
  }

  .square-fee-container {
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Nature-1, #12141a);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* &:not(:last-child) {
      margin-bottom: 8px;
    } */
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background: var(--Border, rgba(55, 63, 92, 0.5));
  &.toggle-margin {
    margin: -8px 0 0 0;
  }
  &.medium {
    margin: 12px 0;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
