import axios from "axios";

import { useEffect, useState } from "react";

function useTopTrader(chainId) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const source = axios.CancelToken.source();

    setTimeout(() => {}, 3000);

    setIsLoading(true);
    async function getTraders() {
      try {
        const { data: response, status } = await axios(`https://api.yummy.fi/stats/bartio/orderly/leaderboard`, {
          params: {
            page: currentPage,
            pageSize: rowsPerPage,
            order: `${"perp_volume%DESC"}`,
          },
          cancelToken: source.token,
        });

        if (status === 200) {
          setData(response.data || {});
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request canceled", error.message);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getTraders();
    const timerId = setInterval(getTraders, 30000);

    return () => {
      source.cancel();
      clearInterval(timerId);
    };
  }, [chainId, currentPage, rowsPerPage]);

  return {
    isLoading,
    data,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
  };
}

export default useTopTrader;
