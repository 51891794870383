import { Spin } from "antd";
import { getPositionNetValue } from "components/ExchangeV2/PositionShare";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import {
  calculateRiskLevelOrderly,
  decimalsRules,
  getBaseOrderlyAsset,
  getLiqRisk,
  getMaxLeverageOrderly,
  getPositionSizeOrderly,
  getUnrealizedPnl,
  hashStringOrderly,
  importImage,
  USD_DECIMALS,
} from "lib/legacy";
import { formatAmount, limitDecimals, parseValue, usdFormatter } from "lib/numbers";
import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { renderRisk } from "lib/helper";
import moment from "moment";
import { useThemeContext } from "contexts/ThemeProvider";
import ic_chain from "img/trade/chains.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import PositionDropdown from "components/ExchangeV2/PositionDropdown";
import editIcon from "img/trade/edit.svg";
import ic_ArrowDown from "img/trade/arrow-red.svg";
import ic_ArrowUp from "img/trade/arrow-up-green.svg";
import PositionDropdownV3 from "components/ExchangeV2/PositionDropdownV3";
import { useMemo } from "react";
import { commify } from "@ethersproject/units";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#FFA722" }} spin />;
const PositionItemV3 = (props) => {
  const {
    setSelectedPosition,
    setIsPositionShareModalOpen,
    position,
    account,
    handleSelectedPosition,
    handleSelectAddTPSL,
    handleSelectClosePosition,
    aggregated,
  } = props;
  const theme = useThemeContext();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);

  const hasIdString = `${account}-${position?.position_qty > 0 ? `LONG` : `SHORT`}-${tokenSymbol}-${
    position?.timestamp
  }`;
  const id = hashStringOrderly(hasIdString);
  const leverage =
    position?.notional && aggregated?.total_collateral_value
      ? limitDecimals(position?.notional / aggregated?.total_collateral_value, 2)
      : null;
  const posSize = getPositionSizeOrderly(position);
  // const { liquidationPrice, riskLevel } = calculateRiskLevelOrderly(position);
  // console.log("????", aggregated);

  const risk =
    position?.est_liq_price && position?.mark_price
      ? Math.abs(position?.mark_price - position?.est_liq_price) / position?.mark_price
      : null;
  const maxPriceDecimals = useMemo(() => {
    let decimals = 1;
    const martkPrice = position?.mark_price;
    if (martkPrice) {
      decimals = martkPrice?.toString()?.split(".")?.[1]?.length || 1;
    }
    return decimals;
  }, [position?.mark_price]);
  return (
    <tr className="table-content clickable" onClick={() => handleSelectedPosition(position)}>
      <td className="clickable" style={{ width: "200px" }}>
        <div style={{ paddingLeft: "10px" }}>
          <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} />
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title fz-12">{tokenSymbol}/USDC</div>{" "}
            {/* <div style={{ height: "4px" }}></div>
            <div className="Exchange-list-info-label">
            </div> */}
          </div>
        </div>
      </td>
      <td>
        <div className="fz-12">{Math.abs(position?.position_qty)}</div>
      </td>
      <td style={{ minWidth: "80px" }} className="fz-12">
        {formatAmount(parseValue(position?.mark_price || 0, 30), 30, decimalsRules(position?.mark_price), true)}
      </td>
      <td style={{ minWidth: "80px" }} className="fz-12">
        {formatAmount(
          parseValue(position?.average_open_price || 0, 30),
          30,
          decimalsRules(position?.average_open_price),
          true
        )}
      </td>

      <td style={{ minWidth: "80px" }} className="fz-12">
        {position?.est_liq_price
          ? formatAmount(parseValue(position?.est_liq_price || 0, 30), 30, decimalsRules(position?.est_liq_price), true)
          : "-"}
      </td>
      <td style={{ width: 200 }} className="fz-12">
        <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
          <div
            className={cx("Exchange-list-info-label profit-loss-label", {
              positive: position?.unrealized_pnl > 0,
              negative: position?.unrealized_pnl < 0,
            })}
            style={{ fontSize: "12px", minWidth: "100px" }}
          >
            <span>
              <span
                style={{ fontSize: "12px", fontWeight: 700 }}
                className={cx("", {
                  positive: position?.unrealized_pnl > 0,
                  negative: position?.unrealized_pnl < 0,
                })}
              >
                {" "}
                {position?.unrealized_pnl === 0 ? null : position?.unrealized_pnl < 0 ? "-" : ""}
                {`${formatAmount(parseValue(position?.unrealized_pnl || 0, 30), 30, 2, true).replace("-", "")}`}{" "}
                <span className="muted">USDC</span>
              </span>
              <span
                className={cx("", {
                  positive: position?.unrealized_pnl > 0,
                  negative: position?.unrealized_pnl < 0,
                })}
                style={{ fontSize: "12px", fontWeight: 500 }}
              >
                ({commify(limitDecimals((position?.unrealized_pnl_ROI || 0) * 100, 2)).replace("-", "")}
                %)
              </span>
            </span>
          </div>
        </div>
      </td>
      <td style={{ minWidth: "80px" }} className="fz-12">
        {position?.notional ? formatAmount(parseValue(position?.notional || 0, 30), 30, 2, true) : "-"}{" "}
        <span className="muted">USDC</span>
      </td>
      <td style={{ minWidth: "80px" }} className="fz-12">
        {position?.mm ? formatAmount(parseValue(position?.mm || 0, 30), 30, 2, true) : "-"}{" "}
        <span className="muted">USDC</span>
      </td>

      {/* <td>
        <div className="fz-12 bold">
          {position?.notional ? "$" + formatAmount(parseValue(position?.notional || 0, 30), 30, 2, true) : "-"}
        </div>
        <div style={{ height: "4px" }}></div>
        <div className="muted fz-12 ">
          {Math.abs(position?.position_qty)} {tokenSymbol}
        </div>
      </td>
      <td>
        <div className="fz-12 bold">
          ${position?.mm ? formatAmount(parseValue(position?.mm || 0, 30), 30, 2, true) : "-"}
        </div>
        <div style={{ height: "4px" }}></div>
        <div className="muted fz-12 ">(Cross)</div>
      </td>
      <td>
        <div style={{ fontWeight: "bold" }}>
          {risk && position?.unrealized_pnl < 0
            ? renderRisk(parseValue(risk || 0, 30), false)
            : renderRisk(parseValue(0, 30), false)}
        </div>
      </td>
      <td className="clickable">
        <div style={{ minWidth: "90px" }}>
          <div className="fz-12 bold">
            $
            {position?.est_liq_price
              ? formatAmount(
                  parseValue(position?.est_liq_price || 0, 30),
                  30,
                  decimalsRules(position?.est_liq_price),
                  true
                )
              : "-"}
          </div>
          <div style={{ height: "4px" }}></div>
          <div className="muted fz-12 ">
            ${formatAmount(parseValue(position?.mark_price || 0, 30), 30, decimalsRules(position?.mark_price), true)}
          </div>
        </div>
      </td>
      <td style={{ minWidth: "80px" }} className="fz-12 bold">
        $
        {formatAmount(
          parseValue(position?.average_open_price || 0, 30),
          30,
          decimalsRules(position?.average_open_price),
          true
        )}
      </td> */}
      <td style={{ width: "20px" }}>
        <div className="close-container">
          <button
            style={{ color: "#1EB2FC", fontSize: "12px", marginRight: "0px" }}
            className="Exchange-list-action bold"
            onClick={(e) => {
              e.stopPropagation();
              handleSelectClosePosition(position);
            }}
          >
            Close
          </button>
          <PositionDropdownV3
            position={position}
            handleShare={() => {
              setSelectedPosition(position);
              setIsPositionShareModalOpen(true);
            }}
            handleAddTPSL={() => {
              handleSelectAddTPSL(position);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default PositionItemV3;
