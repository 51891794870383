import cx from "classnames";
import { getTokenSymbolFromString } from "domain/tokens";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { availableNetworksForChart } from "components/TVChartContainer/constants";
import { getTokens } from "config/tokens";
import { formatDateTime } from "lib/dates";
import { CHART_PERIODS, decimalsRules, LONG, SHORT, SWAP, USD_DECIMALS } from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, formatNumber, limitDecimals, parseValue, usdFormatter } from "lib/numbers";
import { useMediaQuery } from "react-responsive";
// import Tooltip from "components/Tooltip/Tooltip";
import { useMarketTradeStream, useTickerStream } from "@orderly.network/hooks";
import { Tooltip } from "antd";
import { IconArrow } from "assets";
import TVChartContainerV3 from "components/TVChartContainer/TVChartContainerV3";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import useOrderlyOpenInterest from "hooks/useOrderlyOpenInterest";
import useOrderlyVolume from "hooks/useOrderlyVolume";
import infoIcon from "img/icons/ic-info.svg";
import { useMedia } from "react-use";
import ChartTokenSelectorV3 from "./ChartTokenSelectorV3";
import OrderlyOrderBook from "./OrderlyOrderBook";
import Countdown from "react-countdown";
const PRICE_LINE_TEXT_WIDTH = 15;

const timezoneOffset = -new Date().getTimezoneOffset() * 60;

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdy && toToken.isUsdy) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdy) {
    return toToken;
  }
  if (toToken.isUsdy) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "4h";
const DEFAULT_V3_PERIOD = "4h";

const getSeriesOptions = () => ({
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
  lineColor: "#5472cc",
  topColor: "rgba(49, 69, 131, 0.4)",
  bottomColor: "rgba(42, 64, 103, 0.0)",
  lineWidth: 2,
  priceLineColor: "#3a3e5e",
  downColor: "#fa3c58",
  wickDownColor: "#fa3c58",
  upColor: "#0ECB81",
  wickUpColor: "#0ECB81",
  borderVisible: false,
});

const getChartOptions = (width, height) => ({
  width,
  height,
  layout: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    textColor: "#ccc",
    fontFamily: "Relative",
  },
  localization: {
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
    timeFormatter: (businessDayOrTimestamp) => {
      return formatDateTime(businessDayOrTimestamp - timezoneOffset);
    },
  },
  grid: {
    vertLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
    horzLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
  timeScale: {
    rightOffset: 5,
    borderVisible: false,
    barSpacing: 5,
    timeVisible: true,
    fixLeftEdge: true,
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
  priceScale: {
    borderVisible: false,
  },
  crosshair: {
    horzLine: {
      color: "#aaa",
    },
    vertLine: {
      color: "#aaa",
    },
    mode: 0,
  },
});
export const countdownRenderer = (countdownProps) => {
  const { days, hours, minutes, seconds } = countdownProps;
  // const d = String(days);
  const h = String(hours);
  const m = String(minutes);
  const s = String(seconds);
  return <span>{`${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`}</span>;
};

export default function ExchangeTVChartV3(props) {
  const {
    mobilePosition,
    setMobilePosition,
    setSwapOption,
    isProChart,
    swapOption,
    infoTokens,
    chainId,
    positions,
    savedShouldShowPositionLines,
    orders,
    defaultChartToken,
    setDefaultChartToken,
    marketAsssets,
    onSelectSymbolToken,
    library,
    mergeList,
    pairs,
    setChooseOrderBookPrice,
    ordersV3,
    trades,
  } = props;

  const theme = useThemeContext();
  const [currentChart, setCurrentChart] = useState();
  const [currentSeries, setCurrentSeries] = useState();
  const [tradeVersion, setTradeVersion] = useLocalStorageSerializeKey(["trade-version"], "V2");
  const VersionTab = ["V2", "V1"];
  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-v3-period"], DEFAULT_V3_PERIOD);
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_V3_PERIOD;
  }
  const token = useMemo(() => {
    return defaultChartToken?.split("/USD")?.[0];
  }, [defaultChartToken]);

  const orderlySymbol = useMemo(() => {
    if (token) return `PERP_${token}_USDC`;
    return null;
  }, [token]);
  const volumeData = useOrderlyVolume(orderlySymbol);
  const stream = useTickerStream(orderlySymbol);

  const [hoveredCandlestick, setHoveredCandlestick] = useState();

  const [showScrollButtons, setShowScrollButtons] = useState({
    left: false,
    right: true,
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const contentRef = React.useRef(null);
  const itemRefs = React.useRef([]);

  const { data: pairInfo } = useOrderlyOpenInterest();

  const onpenInterestData = useMemo(() => {
    if (!pairInfo) {
      return { long: 0, short: 0 };
    }
    const pair = pairInfo?.find((x) => x.symbol === orderlySymbol);
    if (pair)
      return {
        long: pair.long_oi,
        short: Math.abs(pair.short_oi),
      };
    return {
      long: 0,
      short: 0,
    };
  }, [pairInfo]);

  const ref = useRef(null);
  const chartRef = useRef(null);

  const isMobile = useMedia("(max-width: 767px)");
  const isPad = useMedia("(max-width: 1250px)");

  const [chartInited, setChartInited] = useState(false);

  const onSelectSymbolTokenChart = (token) => {
    onSelectSymbolToken && onSelectSymbolToken(token);
  };
  const scaleChart = useCallback(() => {
    const from = Date.now() / 1000 - (7 * 24 * CHART_PERIODS[period]) / 2 + timezoneOffset;
    const to = Date.now() / 1000 + timezoneOffset;
    currentChart.timeScale().setVisibleRange({ from, to });
  }, [currentChart, period]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const lastPrice = useMemo(() => {
    if (trades.length > 0) {
      return trades[0].price;
    }
  }, [trades]);
  useEffect(() => {
    const container = contentRef.current;

    const checkScroll = () => {
      if (container) {
        const { scrollLeft, scrollWidth, clientWidth } = container;
        setShowScrollButtons({
          left: scrollLeft > 10,
          right: Math.ceil(scrollLeft) < scrollWidth - clientWidth - 10,
        });
      }
    };

    container?.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", checkScroll);

    checkScroll();

    return () => {
      container?.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", checkScroll);
    };
  }, []);

  const handleScroll = (direction) => {
    const container = contentRef.current;
    if (!container) return;

    let nextIndex;
    if (direction === "left") {
      nextIndex = Math.max(0, currentIndex - 2);
    } else {
      nextIndex = Math.min(itemRefs.current.length - 2, currentIndex + 2);
    }

    const targetItem = itemRefs.current[nextIndex];
    if (targetItem) {
      targetItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      setCurrentIndex(nextIndex);
    }
  };

  useEffect(() => {
    const container = contentRef.current;
    if (!container) return;

    const handleScrollUpdate = () => {
      const scrollLeft = container.scrollLeft;
      const containerWidth = container.clientWidth;

      let minDistance = Infinity;
      let nearestIndex = 0;

      itemRefs.current.forEach((item, index) => {
        if (item) {
          const itemLeft = item.offsetLeft - container.offsetLeft;
          const distance = Math.abs(scrollLeft - itemLeft);
          if (distance < minDistance) {
            minDistance = distance;
            nearestIndex = index;
          }
        }
      });

      setCurrentIndex(nearestIndex);
    };

    container.addEventListener("scroll", handleScrollUpdate);
    return () => container.removeEventListener("scroll", handleScrollUpdate);
  }, []);

  useEffect(() => {
    if (!currentChart) {
      return;
    }
    const resizeChart = () => {
      currentChart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
    };
    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, [currentChart]);

  useEffect(() => {
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(hash.split("?")[1]);
    const symbolParam = queryParams.get("symbol");

    if (symbolParam) {
      setDefaultChartToken(symbolParam);
      queryParams.delete("symbol");
      const newHash = `#/trade?${queryParams.toString()}`;
      window.history.replaceState(null, "", newHash);
    }
  }, []);

  return (
    <Wrapper
      style={{
        height: "526px",
      }}
      className="ExchangeChart tv"
      ref={ref}
    >
      <div className="ExchangeChart-top App-box App-box-border">
        <div className="ExchangeChart-top-inner">
          <div className="ExchangeChart-title-container">
            <div className="ExchangeChart-title">
              <ChartTokenSelectorV3
                className="chart-token-selector"
                defaultChartToken={defaultChartToken}
                marketAsssetList={mergeList}
                theme={theme}
                onSelectSymbolToken={onSelectSymbolTokenChart}
              />
            </div>
            {mobilePosition ? (
              <button style={{ width: 150 }} className="border-btn" onClick={() => setMobilePosition(undefined)}>
                View Chart
              </button>
            ) : (
              <div className="ExchangeChart-buttons">
                <button
                  onClick={() => {
                    setMobilePosition(SHORT);
                    setSwapOption(SHORT);
                  }}
                >
                  Short
                </button>
                <button
                  onClick={() => {
                    setMobilePosition(LONG);
                    setSwapOption(LONG);
                  }}
                >
                  Long
                </button>
              </div>
            )}
          </div>
          <StyledLine />
          <ScrollContainer showLeft={showScrollButtons.left} showRight={showScrollButtons.right}>
            <ScrollContent>
              <ScrollButton className="left" show={showScrollButtons.left} onClick={() => handleScroll("left")}>
                <IconArrow />
              </ScrollButton>
              <div className="exchange-chart-info-container">
                <ScrollContent className="scroll-content" ref={contentRef}>
                  <ChartItem ref={(el) => (itemRefs.current[0] = el)}>
                    <div className="info-main-price">
                      <div className="ExchangeChart-additional-info ExchangeChart-additional-info1">
                        <div className="last-price">
                          {!lastPrice ? (
                            <div className="skeleton-box" style={{ width: "85px", height: "30px" }} />
                          ) : null}
                          {lastPrice ? (
                            <div style={{ fontSize: 16 }}>
                              {formatAmount(
                                parseValue(lastPrice, USD_DECIMALS),
                                USD_DECIMALS,
                                decimalsRules(lastPrice),
                                true
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[1] = el)}>
                    <div className="ExchangeChart-additional-info">
                      <div className="ExchangeChart-info-label">
                        <span>24h Change</span>
                      </div>
                      <div
                        style={{
                          fontWeight: 700,
                        }}
                        className={cx("changed-24h", {
                          positive: Number(stream?.["24h_change"]) > 0,
                          negative: Number(stream?.["24h_change"]) < 0,
                        })}
                      >
                        {!stream?.["24h_change"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                        {stream?.change ? `${((stream.change ?? 0) * 100).toFixed(2)}%` : null}
                      </div>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[2] = el)}>
                    <div className="ExchangeChart-additional-info">
                      <div className="ExchangeChart-info-label">
                        <span>Market Price</span>
                      </div>
                      <div
                        style={{
                          fontWeight: 700,
                        }}
                        className={cx("changed-24h", {})}
                      >
                        {!stream?.["mark_price"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                        {stream?.["mark_price"]
                          ? `${formatAmount(
                              parseValue(stream.mark_price, USD_DECIMALS),
                              USD_DECIMALS,
                              decimalsRules(stream?.mark_price),
                              2
                            )}`
                          : null}
                      </div>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[3] = el)}>
                    <div className="ExchangeChart-additional-info">
                      <div className="ExchangeChart-info-label">
                        <span>Index Price</span>
                      </div>
                      <div
                        style={{
                          fontWeight: 700,
                        }}
                        className={cx("changed-24h", {})}
                      >
                        {!stream?.["index_price"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                        {stream?.["index_price"]
                          ? `${formatAmount(
                              parseValue(stream.index_price, USD_DECIMALS),
                              USD_DECIMALS,
                              decimalsRules(stream?.index_price),
                              2
                            )}`
                          : null}
                      </div>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[4] = el)}>
                    <div className="ExchangeChart-additional-info funding">
                      <div className="ExchangeChart-info-label">
                        <Tooltip
                          placement={isPad ? "bottomRight" : "bottom"}
                          overlayClassName={`custom-popup-antd-tooltip ${
                            theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                          }`}
                          autoAdjustOverflow={isPad ? true : false}
                          className={`custom-antd-tooltip`}
                          title={
                            // stream?.["last_funding_rate"]
                            //   ? stream?.["last_funding_rate"] < 0
                            //     ? "Negative value means that shorts are paying longs"
                            //     : "Positive value means that longs are paying shorts"
                            //   : ""
                            "Funding rates are payments between traders who are long and short. When positive, long positions pay short positions funding. When negative, short positions pay long positions."
                          }
                        >
                          Funding Rate
                          <img src={infoIcon} alt="info icon" />
                        </Tooltip>
                      </div>
                      <StyledTooltip>
                        <span
                          className={`${
                            stream?.["est_funding_rate"]
                              ? stream?.["est_funding_rate"] > 0
                                ? "positive"
                                : "negative"
                              : ""
                          }`}
                        >
                          {!stream?.["est_funding_rate"] ? (
                            <div className="skeleton-box" style={{ width: "60px" }} />
                          ) : (
                            <div>
                              {/* {currentMarketPrices?.fundingRate == 0 ? null : (
                          <img
                            src={currentMarketPrices?.fundingRate.includes("-") ? ArrowDownIcon : ArrowUpIcon}
                            alt="up arrow"
                          />
                        )}{" "} */}
                              {`${limitDecimals(stream?.["est_funding_rate"] * 100, 5)}%/H`}
                            </div>
                          )}
                        </span>
                      </StyledTooltip>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[5] = el)}>
                    <div className="ExchangeChart-additional-info rate">
                      <div className="ExchangeChart-info-label">Next Funding</div>
                      <div>
                        {stream?.next_funding_time ? (
                          <Countdown date={new Date(stream.next_funding_time)} renderer={countdownRenderer} />
                        ) : (
                          <div className="skeleton-box" style={{ width: "60px" }} />
                        )}
                      </div>
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[6] = el)}>
                    <div className="ExchangeChart-additional-info">
                      <div className="ExchangeChart-info-label">
                        <Tooltip
                          placement={isPad ? "topRight" : "topRight"}
                          overlayClassName={`custom-popup-antd-tooltip ${
                            theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                          }`}
                          autoAdjustOverflow={isPad ? true : false}
                          className={`custom-antd-tooltip`}
                          title={"24 hour total trading volume on the Orderly Network."}
                        >
                          Volume 24h <img src={infoIcon} alt="info icon" />
                        </Tooltip>
                      </div>
                      {stream?.["24h_amount"] ? (
                        <div>
                          {stream?.["24h_amount"] === 0 ? "0" : formatNumber(stream?.["24h_amount"], 2)}{" "}
                          <span className="muted">USDC</span>
                        </div>
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </div>
                  </ChartItem>
                  <ChartItem ref={(el) => (itemRefs.current[7] = el)}>
                    <div className="ExchangeChart-additional-info rate">
                      <div className="ExchangeChart-info-label">Open Interest</div>
                      <StyledTooltip>
                        <>
                          {!stream?.open_interest || !stream?.mark_price ? (
                            <div className="skeleton-box" style={{ width: "60px" }} />
                          ) : (
                            <Flex>
                              <div>
                                <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                  {formatNumber(
                                    formatAmount(
                                      parseValue(stream?.open_interest * (stream?.mark_price || 1), 30),
                                      30,
                                      6,
                                      false
                                    ),
                                    2
                                  )}{" "}
                                  <span className="muted">USDC</span>
                                </span>
                              </div>
                            </Flex>
                          )}
                        </>
                      </StyledTooltip>
                    </div>
                  </ChartItem>
                </ScrollContent>
              </div>

              <ScrollButton className="right" show={showScrollButtons.right} onClick={() => handleScroll("right")}>
                <IconArrow />
              </ScrollButton>
            </ScrollContent>
          </ScrollContainer>
          <div className="info-mobile exchange-chart-info-container">
            <div className="info-main-price">
              <div className="ExchangeChart-additional-info">
                <div className="last-price">
                  {!stream ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                  {stream?.mark_price ? (
                    <Tooltip
                      placement={isPad ? "topRight" : "top"}
                      overlayClassName={`custom-popup-antd-tooltip ${
                        theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                      }`}
                      autoAdjustOverflow={isPad ? true : false}
                      className={`custom-antd-tooltip`}
                      title={"Price for the computation of unrealized PnL and liquidation."}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                        {"$" + usdFormatter.format(stream?.mark_price)} <img src={infoIcon} alt="info icon" />
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
                <div className="ExchangeChart-additional-info">
                  <div
                    style={{
                      fontWeight: 500,
                    }}
                    className={cx("changed-24h", {
                      positive: Number(stream?.["24h_change"]) > 0,
                      negative: Number(stream?.["24h_change"]) < 0,
                    })}
                  >
                    {!stream?.["24h_change"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                    {/* {stream?.["24h_change"] ? (Number(stream["24h_change"]) < 0 ? "-" : "") : null} */}
                    {stream?.change ? `${((stream.change ?? 0) * 100).toFixed(2)}%` : null}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24h High</span>
              </div>
              <div
                style={{
                  fontWeight: 700,
                }}
              >
                {!stream && <div className="skeleton-box" style={{ width: "60px" }} />}
                {stream?.["24h_high"] && "$" + limitDecimals(stream?.["24h_high"])}
              </div>
            </div>
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24h Low</span>
              </div>
              <div
                style={{
                  fontWeight: 700,
                }}
              >
                {!stream && <div className="skeleton-box" style={{ width: "60px" }} />}
                {stream?.["24h_low"] && "$" + limitDecimals(stream?.["24h_low"])}
              </div>
            </div> */}

            <>
              <div className="ExchangeChart-additional-info rate">
                <div className="last-price">
                  {!stream ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                  {stream?.mark_price ? (
                    <Tooltip
                      placement={isPad ? "topRight" : "top"}
                      overlayClassName={`custom-popup-antd-tooltip ${
                        theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                      }`}
                      autoAdjustOverflow={isPad ? true : false}
                      className={`custom-antd-tooltip`}
                      title={"Price for the computation of unrealized PnL and liquidation."}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                        {formatAmount(
                          parseValue(stream?.mark_price || 0, 30),
                          30,
                          decimalsRules(stream?.mark_price),
                          true
                        )}{" "}
                        <img src={infoIcon} alt="info icon" />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
                <div className="ExchangeChart-additional-info">
                  <div
                    style={{
                      fontWeight: 500,
                    }}
                    className={cx({
                      positive: Number(stream?.["24h_change"]) > 0,
                      negative: Number(stream?.["24h_change"]) < 0,
                    })}
                  >
                    {!stream?.["24h_change"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                    {/* {stream?.["24h_change"] ? (Number(stream["24h_change"]) < 0 ? "-" : "") : null} */}
                    {stream?.change ? `${((stream.change ?? 0) * 100).toFixed(2)}%` : null}
                  </div>
                </div>
              </div>

              <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">
                  <span>Mark price</span>
                </div>
                <StyledTooltip>
                  <>
                    {!stream ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        {!stream?.["mark_price"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                        {stream?.["mark_price"]
                          ? `${formatAmount(
                              parseValue(stream.mark_price, USD_DECIMALS),
                              USD_DECIMALS,
                              decimalsRules(stream?.mark_price),
                              2
                            )}`
                          : null}
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>

              <div className="ExchangeChart-additional-info">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "topRight" : "topRight"}
                    overlayClassName={`custom-popup-antd-tooltip ${
                      theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                    }`}
                    autoAdjustOverflow={isPad ? true : false}
                    className={`custom-antd-tooltip`}
                    title={"24 hour total trading volume on the Orderly Network."}
                  >
                    Volume 24h <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                {stream?.["24h_amount"] ? (
                  <div>
                    {stream?.["24h_amount"] === 0 ? "0" : formatNumber(stream?.["24h_amount"], 2)}{" "}
                    <span className="muted">USDC</span>
                  </div>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </div>
              <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">
                  <span>Index price</span>
                </div>
                <StyledTooltip>
                  <>
                    {!stream ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        {!stream?.["index_price"] ? <div className="skeleton-box" style={{ width: "60px" }} /> : null}
                        {stream?.["index_price"] ? `${usdFormatter.format(stream?.index_price)}` : null}
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>
              <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">Open Interest</div>
                <StyledTooltip>
                  <>
                    {!stream?.open_interest || !stream?.mark_price ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        <span>
                          {formatNumber(
                            formatAmount(
                              parseValue((stream?.open_interest || 0) * (stream?.mark_price || 1), 30),
                              30,
                              6,
                              false
                            ),
                            2
                          )}{" "}
                          <span className="muted">USDC</span>
                        </span>
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>
              <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">
                  <span>Funding / Countdown</span>
                </div>
                <StyledTooltip>
                  <>
                    {!stream ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        {!stream?.["est_funding_rate"] || !stream?.next_funding_time ? (
                          <div className="skeleton-box" style={{ width: "60px" }} />
                        ) : (
                          <div>
                            {`${limitDecimals(stream?.["est_funding_rate"] * 100, 5)}%`}/
                            {<Countdown date={new Date(stream.next_funding_time)} renderer={countdownRenderer} />}
                          </div>
                        )}
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>
              {/* <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">
                  <span>Next Funding</span>
                </div>
                {stream?.next_funding_time ? (
                  <Countdown date={new Date(stream.next_funding_time)} renderer={countdownRenderer} />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </div> */}

              {/* <div className="ExchangeChart-additional-info rate">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "bottomRight" : "bottom"}
                    autoAdjustOverflow={isPad ? true : false}
                    overlayClassName={`custom-popup-antd-tooltip ${
                      theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                    }`}
                    className="custom-antd-tooltip"
                    title={<div>Borrow rate per hour is determined per asset by using each asset's historical volatility</div>}
                  >
                    Borrow Rate <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                <StyledTooltip>
                  <>
                    <Flex>
                      <div>
                        <span style={{ color: "rgba(255, 255, 255, 0.6)", marginRight: "4px" }} className="orr">
                          (L){" "}
                        </span>
                        <span>-%</span>
                      </div>
                      <div>
                        <span style={{ color: "rgba(255, 255, 255, 0.6)", marginRight: "4px" }} className="orr">
                          (S){" "}
                        </span>
                        <span>-%</span>
                      </div>
                    </Flex>
                  </>
                </StyledTooltip>
              </div> */}
            </>
          </div>
        </div>
        {/* <div className="trade-version">
          <div className="trade-version-container">
            {VersionTab.map((item) => (
              <Link key={item} to={`${item === "V2" ? "/trade" : "/v1"}`}>
                <div className={`version-tab ${item === "V2" ? "version-tab-active" : ""}`}>
                  {item}
                  {item === "V2" && <img className="star" alt="" src={icStar} />}
                </div>
              </Link>
            ))}
          </div>
        </div> */}
      </div>

      {true && (
        <div className="ExchangeChart-bottom App-box App-box-border">
          <div className="orderly-chart-container">
            {availableNetworksForChart.includes(chainId) && chainId ? (
              <TVChartContainerV3
                chartLines={[]}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                // symbol={chartToken.symbol}
                symbol={getTokenSymbolFromString(defaultChartToken)}
                chainId={chainId}
                onSelectToken={() => {}}
                period={period}
                setPeriod={setPeriod}
                marketPrices={BigNumber.from(0)}
                theme={theme}
                markPrice={stream?.mark_price || 0}
                pairInfo={pairs}
              />
            ) : (
              <p className="ExchangeChart-error">Sorry, chart is not supported on this network yet.</p>
            )}
            <OrderlyOrderBook
              symbol={orderlySymbol}
              chartSymbol={token}
              markPrice={stream?.["mark_price"]}
              setChooseOrderBookPrice={setChooseOrderBookPrice}
              ordersV3={ordersV3}
              trades={trades}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const StyledLine = styled.div`
  background: var(--Border, #2B3139);
  width: 1px;
  height: 44px;
  @media screen and (max-width: 1350px) {
    display: none;
  }
`;

const StyledTooltip = styled.div`
  div.Tooltip-popup:has(.customTooltip) {
    width: fit-content;
  }
`;

const Wrapper = styled.div`
  .ExchangeChart-top {
    @media screen and (max-width: 767px) {
      padding: 0px 16px !important;
    }
  }

  .info-mobile {
    @media screen and (min-width: 1110px) {
      display: none;
    }
  }

  .ExchangeChart-title-container {
    @media screen and (min-width: 1110px) {
      height: 60px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 4px;
  /* flex-wrap: wrap; */
  div {
    display: flex;
    width: fit-content !important;
  }

  @media screen and (max-width: 1700px) {
    gap: 4px;
    align-items: flex-start;
    /* flex-wrap: wrap; */
  }

  /* @media screen and (min-width: 1110px) {
    flex-direction: column;
  } */

  /* @media screen and (max-width: 1600px) {
    flex-direction: column;
  } */
`;

const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    pointer-events: none;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::before {
    left: 43px;
    left: 0;
    background: linear-gradient(to right, #12141b, transparent);
    opacity: ${({ showLeft }) => (showLeft ? 1 : 0)};
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, #12141b, transparent);
    opacity: ${({ showRight }) => (showRight ? 1 : 0)};
  }

  @media screen and (max-width: 1110px) {
    display: none;
  }
`;

const ScrollContent = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 24px;
  width: 100%;
  scroll-snap-type: x mandatory;
  padding: 0 4px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.scroll-content {
    align-items: center;
  }
`;
const ScrollButton = styled.button`
  position: absolute;
  z-index: 999999;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  top: 0;
  bottom: 0;
  margin: auto;
  background: transparent;

  &.left {
    left: 4px;
    transform: rotate(180deg);
  }

  &.right {
    right: 4px;
  }
`;
const ChartItem = styled.div.attrs({ tabIndex: 0 })`
  /* flex: 1 0 auto; */
  scroll-snap-align: start;
`;
