import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import { importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import cx from "classnames";
import shareIcon from "img/trade/share.svg";
import moment from "moment";
export function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z(secs % 60) + "s";
}
const ClosedPositionItemV2 = (props) => {
  const { position,
    marketAsssets,
    marketTokensInfo,
    setPositionToShare,
    setIsPositionShareModalOpen,
    handleSelectedPosition
  } = props
  const symbol = marketAsssets?.[position.tokenId]?.symbol;
  const leverage = BigNumber.from(position?.totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.totalCollateral || 0));
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const duration = secondsToHMS(position.closedAt - position.createdAt);
  const roiPrefix = position.totalROI.includes("-") ? "-" : "+";
  const rPnLPrifix = position.realisedPnl.includes("-") ? "-" : "+";
  const isLoss = position.realisedPnl.includes("-");
  const pnlPrefix = position.realisedPnl.includes("-") ? "-" : "+";
  const posQty = BigNumber.from(position?.totalSize || 0)
    .mul(parseValue("1", 30))
    .div(BigNumber.from(position?.averagePrice || 0));

  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const completePosition = {
    // positionNetValue,
    ...position,
    duration,
    paidFees: BigNumber.from(position.positionFee || 0)
      .add(BigNumber.from(position.fundingFee || 0))
      .add(BigNumber.from(position.borrowFee || 0)),
    positionNetValue: {
      prefix: roiPrefix,
      percentPnl: parseValue(limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", ""), 30),
      pnl: position.realisedPnl,
    },
    rPnLPrifix,
    leverage: formatAmount(leverage, 30, 2, true),
    symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
    quantity: posQty,
    currentMarket,
  };
  return (
    <tr
      className="table-content clickable"
      onClick={() => handleSelectedPosition(completePosition)}
    >
      <td className="clickable">
        <div>
          <img
            src={importImage("ic_" + getTokenSymbolFromString(symbol)?.toLowerCase() + "_24.svg")}
            alt="tk"
          />{" "}
          <div className="tk_info">
            <div className="Exchange-list-title">
              {symbol} <span className="muted">#{position.posId}</span>
            </div>
            <div className="Exchange-list-info-label">
              <span
                className={cx({ bold: true, positive: position.isLong, negative: !position.isLong })}
                style={{ color: position.isLong ? "#C1FF99" : "#E43E53" }}
              >
                {position.isLong ? `LONG` : `SHORT`}
              </span>
              {leverage && (
                <span
                  className="leverage-box"
                  style={{
                    color: "#FFC700",
                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                    height: 16,
                    display: "inline-block",
                    opacity: 1,
                    borderRadius: "4px",
                    padding: "0 4px",
                    lineHeight: "15.8px",
                    fontWeight: 700,
                    marginLeft: "4px",
                  }}
                >
                  {formatAmount(leverage, 30, 2, true)}X
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
      </td>
      <td>
        <div>{moment(new Date(position.createdAt * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">
          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
        </div>
      </td>
      <td>
        <div>{moment(new Date(position.closedAt * 1000)).format(dateFormat)}</div>
        <div className="muted fz-12 bold">{moment(new Date(position.closedAt * 1000)).format(timeFormat)}</div>
      </td>
      <td>
        <div className="">{duration}</div>
      </td>
      <td className="clickable">
        <div
          className={`${!isLoss ? "isProfit" : "isLoss"} ${!isLoss ? "positive" : "negative"}`}
          style={{
            color: position.realisedPnl.includes("-") ? "#E43E53" : "#C1FF99 ",
          }}
        >
          {pnlPrefix}${formatAmount(position.realisedPnl, 30, 2, true).replace("-", "")}
        </div>
        <div className="muted fz-12 bold">
          {roiPrefix}
          {limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", "")}%
        </div>
      </td>
      <td className="clickable">
        ${formatAmount(position.averagePrice, 30, currentMarket?.decimals, true)}
      </td>
      <td className="clickable">
        ${formatAmount(position.markPrice, 30, currentMarket?.decimals, true)}
      </td>

      <td>
        <div> ${formatAmount(position.totalSize, 30, 2, true)}</div>
        <div className="muted muted fz-12 bold">
          {" "}
          {formatAmount(posQty, 30, 4, true)} {getTokenSymbolFromString(symbol)}
        </div>
      </td>
      <td style={{ width: "30px" }}>
        <button
          className="PositionDropdown-dots-icon"
          onClick={(e) => {
            e.stopPropagation();
            setPositionToShare(completePosition);
            setIsPositionShareModalOpen(true);
          }}
        >
          <img src={shareIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
        </button>
      </td>
    </tr>
  );
}
export default ClosedPositionItemV2;
