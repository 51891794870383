import { ARBITRUM, AVALANCHE, BASE, BERACHAIN_BARTIO, FANTOM, OP, SONIC } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/ymy-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/ymy-io/ymy-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/ymy-io/ymy-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-fantom-referrals/version/latest",
  },
  [BERACHAIN_BARTIO]: {
    stats: "https://api.yummy.fi/subgraph/bartio/query/stats",
    referrals: "https://api.yummy.fi/subgraph/bartio/query/referrals",
    positions: "https://api.yummy.fi/subgraph/bartio/query/middleware",
  },
  [SONIC]: {
    stats: "https://api.yummy.fi/subgraph/sonic/query/stats",
    referrals: "https://api.yummy.fi/subgraph/sonic/query/referrals",
    positions: "https://api.yummy.fi/subgraph/sonic/query/middleware",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
