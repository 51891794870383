import { BigNumber, BigNumberish, ethers } from "ethers";
import Numeral from "numeral";
export function bigNumberify(n: BigNumberish) {
  try {
    return BigNumber.from(n);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export function expandDecimals(n: BigNumberish, decimals: number): BigNumber {
  // @ts-ignore
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

export const trimZeroDecimals = (amount: string) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};
export function formatNumber(n, displayDecimal = 2) {
  if (n === "--") return "-";
  if (!n) return "0";
  if (typeof n === "string") n = parseFloat(n);
  if (n === 0) return "0.00";
  if (!n) return n;
  const order = Math.floor(Math.log10(n) / 3);
  if (order < 0) {
    return n.toFixed(displayDecimal);
  }

  const units = ["", "K", "M", "B", "T", "Q"];
  const num = n / 1000 ** order;
  return units[order] !== undefined ? +num.toFixed(displayDecimal) + units[order] : "∞";
}
export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  if (!defaultValue) {
    defaultValue = "-";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  if (displayDecimals !== 0) {
    amountStr = limitDecimals(amountStr, displayDecimals);
    amountStr = padDecimals(amountStr, displayDecimals);
  } else {
    amountStr = Number(amountStr).toFixed(0);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatAmountInDollar = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  options?: {
    showPlus?: boolean;
    autoDisplayDecimals?: boolean;
  }
) => {
  const { showPlus, autoDisplayDecimals } = options || {};

  if (amount === undefined || amount.toString().length === 0) {
    return "$--";
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);

  if (autoDisplayDecimals) {
    displayDecimals = Number(amountStr) >= 10 ? 2 : 4;
  }

  if (displayDecimals !== 0) {
    amountStr = limitDecimals(amountStr, displayDecimals);
    amountStr = padDecimals(amountStr, displayDecimals);
  } else {
    amountStr = Number(amountStr).toFixed(0);
  }
  if (useCommas) {
    amountStr = numberWithCommas(amountStr);
  }

  amountStr = "$" + amountStr;

  if (amountStr.includes("-")) {
    amountStr = "-" + amountStr.replace("-", "");
  } else if (showPlus && amountStr !== "$0.00") {
    amountStr = "+" + amountStr;
  }
  return amountStr;
};

export const formatKeyAmount = (
  map: any,
  key: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key]) {
    return "--";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};
export const formatAddKeysAmount = (
  map: any,
  key1: string,
  key2: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key1] || !map[key2]) {
    return "...";
  }

  return formatAmount(map[key1].add(map[key2]), tokenDecimals, displayDecimals, useCommas);
};
export const formatKeyAmountFixed = (
  map: any,
  key: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key]) {
    return "...";
  }

  const amountStr = Number(formatAmountFree(map[key], tokenDecimals, displayDecimals + 1)).toFixed(displayDecimals);

  if (useCommas) {
    numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatArrayAmount = (
  arr: any[],
  index: number,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean
) => {
  if (!arr || !arr[index]) {
    return "...";
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas);
};

export const formatAmountFree = (amount: BigNumberish, tokenDecimals: number, displayDecimals?: number) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value: string | number, tokenDecimals: number) => {
  const pValue = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(pValue)) {
    return undefined;
  }

  value = limitDecimals(value, tokenDecimals);
  const amount = ethers.utils.parseUnits(value, tokenDecimals);
  return bigNumberify(amount);
};

export function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return "...";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function getDisplayDecimalByAmount(x: BigNumberish, tokenDecimals: number) {
  //amount < 10 => displayDecimal = 4
  if (x && tokenDecimals) return Number(ethers.utils.formatUnits(x.toString(), tokenDecimals)) < 10 ? 4 : 2;
  else return 2;
}

export function formatNumberWithNegative(n, displayDecimal = 2) {
  if (Number(n) == 0) return "0";
  if (!n || isNaN(parseFloat(n))) return "--";
  if (typeof n === "string") n = parseFloat(n);
  let isNagative = false;

  if (n < 0) {
    n = Math.abs(n);
    isNagative = true;
  }

  if (!n) return n;
  const order = Math.floor(Math.log10(n) / 3);
  if (order < 0) {
    return `${isNagative ? "-" : ""}${n.toFixed(displayDecimal)}`;
  }

  const units = ["", "K", "M", "B", "T", "Q"];
  const num = n / 1000 ** order;
  return units[order] !== undefined ? `${isNagative ? "-" : ""}${+num.toFixed(displayDecimal) + units[order]}` : "∞";
}

export function isNegative(n: string | number) {
  if (typeof n === "string") {
    return n.indexOf("-") === 0;
  }
  return n < 0;
}
export function numberFormat(
  number,
  decimals = 0,
  thousandsSep = ",",
  decPoint = ".",
  trailingZeros = false
): string | undefined {
  if (typeof number === "undefined") return;
  if (+number < 0.1 && (decimals === null || decimals > 13)) {
    return number;
  }
  // Strip all characters but numerical ones.
  const numerical = `${number}`.replace(/[^0-9+\-Ee.]/g, "");
  const n = !isFinite(+numerical) ? 0 : +numerical;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
  const dec = typeof decPoint === "undefined" ? "." : decPoint;
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
  };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  let s = (decimals === null ? `${n}` : prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    if (trailingZeros) {
      // 1.123 with decimals = 5 => 1.12300
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
  }
  return s[1] ? s.join(dec) : s[0];
}
export const toKInChart = (num: string, unit?: string) => {
  if (parseFloat(num) < 0.0000001) return `< ${unit ?? ""}0.0000001`;
  if (parseFloat(num) >= 0.1) return (unit ?? "") + Numeral(num).format("0.[00]a");
  return (unit ?? "") + Numeral(num).format("0.[0000000]a");
};
export const getDifferentValues = (
  chartData: any,
  hoverValue: number | null
): {
  chartColor?: string;
  different: number | string;
  differentPercent: number | string;
} => {
  if (chartData && chartData.length > 0) {
    const firstValue = chartData[0].value;
    const lastValue = chartData[chartData.length - 1].value;
    const differentValue = hoverValue !== null ? hoverValue - lastValue : lastValue - firstValue;
    const compareValue = hoverValue !== null ? lastValue : firstValue;
    return {
      // // chartColor: lastValue - firstValue >= 0 ? '#EE8066' : '#ff556c',
      // chartColor: '#EE8066',
      chartColor: "#1EB2FC",
      different: differentValue.toPrecision(6),
      differentPercent: compareValue === 0 ? 100 : ((differentValue / compareValue) * 100).toFixed(2),
    };
  }
  return {
    chartColor: "#1EB2FC",
    different: 0,
    differentPercent: 0,
  };
};
export const convertSmallENumber = (scientificNumber, decimals = 18) => {
  var num = parseFloat(scientificNumber);
  return num.toFixed(decimals).replace(/0+$/, "");
};
export const usdFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 });
