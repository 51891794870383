import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import YMYAprTooltip from "components/Stake/YMYAprTooltip";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import Tooltip from "components/Tooltip/Tooltip";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useTotalYmyStaked, useTotalEsYmyStaked } from "domain/legacy";
import { ethers } from "ethers";
import TokenIcon from "img/earn/ymy_coin.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import {
  formatAddKeysAmount,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  parseValue,
} from "lib/numbers";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import "./Earn.scss";
import "./EarnTheme.scss";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import RewardRouter from "abis/RewardRouter.json";
import Modal from "components/Modal/Modal";
import { useThemeContext } from "contexts/ThemeProvider";
const { AddressZero } = ethers.constants;
function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esYMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esYMY amount includes the esYMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esYMY to YMY later, you will be required to deposit a reserve amount of YMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusYmyInFeeYmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusYmyInFeeYmy &&
    bonusYmyInFeeYmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusYmyInFeeYmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusYmyInFeeYmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusYmyInFeeYmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
const YMYandESYMY = (props) => {
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();
  const {
    setPendingTxns,
    bonusYmyInFeeYmy,
    processedData,
    multiplierPointsAmount,
    connectWallet,
    maxUnstakeableYmy,
    vestingData,
    ymyPrice,
    stakedYmySupplyUsd,
    stakedEsYmySupplyUsd,
    hasMultiplierPoints,
    totalYmySupply,
    totalSupplyUsd,
    esYmySupplyUsd,
    esYmySupply,
  } = props;
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");
  let {
    op: opYmyStaked,
    // oplegacy: opYmyStakedLegacy,
    fantom: fantomYmyStaked,
    // fantomlegacy: fantomYmyStakedLegacy,
    total: totalYmyStaked,
    arbitrum: arbitrumYmyStaked,
    base: baseYmyStaked,
  } = useTotalYmyStaked();
  let {
    op: opEsYmyStaked,
    fantom: fantomEsYmyStaked,
    // fantomlegacy: fantomEsYmyStakedLegacy,
    arbitrum: arbitrumEsYmyStaked,
    base: baseEsYmyStaked,
    total: totalEsYmyStaked,
  } = useTotalEsYmyStaked();

  const { account, active, library } = useWeb3React();
  const isYmyTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");

  const esYmyAddress = getContract(chainId, "ES_YMY");
  const ymyAddress = getContract(chainId, "YMY");
  const showStakeYmyModal = () => {
    if (!isYmyTransferEnabled) {
      helperToast.error(`YMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake YMY`);
    setStakeModalMaxAmount(processedData.ymyBalance);
    setStakeValue("");
    setStakingTokenSymbol("YMY");
    setStakingTokenAddress(ymyAddress);
    setStakingFarmAddress(stakedYmyTrackerAddress);
    setStakeMethodName("stakeYmy");
  };

  const showStakeEsYmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esYMY`);
    setStakeModalMaxAmount(processedData.esYmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esYMY");
    setStakingTokenAddress(esYmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsYmy");
  };
  const showUnstakeEsYmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esYMY`);
    let maxAmount = processedData.esYmyInStakedYmy;
    if (
      processedData.esYmyInStakedYmy &&
      vestingData &&
      vestingData.ymyVesterPairAmount.gt(0) &&
      maxUnstakeableYmy &&
      maxUnstakeableYmy.lt(processedData.esYmyInStakedYmy)
    ) {
      maxAmount = maxUnstakeableYmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.ymyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esYMY");
    setUnstakeMethodName("unstakeEsYmy");
  };
  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const showUnstakeYmyModal = () => {
    if (!isYmyTransferEnabled) {
      helperToast.error(`YMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake YMY`);
    let maxAmount = processedData.ymyInStakedYmy;
    if (
      processedData.ymyInStakedYmy &&
      vestingData &&
      vestingData.ymyVesterPairAmount.gt(0) &&
      maxUnstakeableYmy &&
      maxUnstakeableYmy.lt(processedData.ymyInStakedYmy)
    ) {
      maxAmount = maxUnstakeableYmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.ymyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("YMY");
    setUnstakeMethodName("unstakeYmy");
  };
  return (
    <div className={`earn-content-container ${lightThemeClassName}`}>
      <div className="tab-content-title">YMY & esYMY</div>
      <div className="tab-content-desc">Earn rewards by staking YMY and esYMY tokens.</div>
      <div className="tab-main-content">
        <div className="main-content left-content">
          <div className="left-box-content main-content right-content">
            <div className="right-content-border-box">
              <div className="box-main-price">
                <div className="price-info">
                  <div className="title">YMY price</div>
                  <div className="value">
                    {ymyPrice ? (
                      "$" + formatAmount(ymyPrice, USD_DECIMALS, 3, true)
                    ) : (
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    )}
                  </div>
                </div>
                <img src={TokenIcon} alt="ymy-tokens" />
              </div>
              <div className="buy-btn-container">
                <Link className="default-btn" to="/buy_ymy">
                  <span>Buy YMY</span>
                </Link>
              </div>
              <div className="box-devider">
                <div className="divider-title">YMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.ymyAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "ymyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <YMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalYmyStaked ? (
                    // formatAmount(totalYmyStaked, 18, 0, true) +
                    // " YMY" +
                    // ` ($${formatAmount(stakedYmySupplyUsd, USD_DECIMALS, 2, true)})`
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalYmyStaked, 18, 0, true)} YMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opYmyStaked}
                            // opLegacyValue={opYmyStakedLegacy}
                            arbValue={arbitrumYmyStaked}
                            baseValue={baseYmyStaked}
                            fantomValue={fantomYmyStaked}
                            // fantomLegacyValue={fantomYmyStakedLegacy}
                            total={totalYmyStaked}
                            decimalsForConversion={18}
                            symbol="YMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedYmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {totalYmySupply ? (
                    <>
                      {formatAmount(totalYmySupply, 18, 0, true)} YMY{" "}
                      <span>${formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100.00%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <span>
                          Boost your rewards with Multiplier Points.&nbsp;
                          <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">
                            More info
                          </ExternalLink>
                          .
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Boost Percentage</div>
                <div className="value">
                  {processedData.boostBasisPoints ? (
                    <Tooltip
                      handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            You are earning{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                            </span>{" "}
                            more {nativeTokenSymbol} rewards using{" "}
                            <span style={{ fontWeight: 700, color: "white" }}>
                              {formatAmount(processedData.bnYmyInFeeYmy, 18, 4, 2, true)}
                            </span>{" "}
                            Staked Multiplier Points.
                            <br />
                            <div style={{ marginTop: "8px" }}>
                              <span>Use the "Compound" button to stake your Multiplier Points.</span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-devider">
                <div className="divider-title">esYMY APR</div>
                <div className="divider-line" />
              </div>
              <div className="box-apr">
                {processedData.ymyAprTotalWithBoost ? (
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "ymyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <YMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
              <div className="box-token-info">
                <div className="title">Total Staked</div>
                <div className="value">
                  {totalEsYmyStaked ? (
                    <>
                      <Tooltip
                        position="right-bottom"
                        className="nowrap"
                        handle={<>{formatAmount(totalEsYmyStaked, 18, 0, true)} esYMY</>}
                        renderContent={() => (
                          <StatsTooltip
                            showDollar={false}
                            title={`Staked`}
                            opValue={opEsYmyStaked}
                            fantomValue={fantomEsYmyStaked}
                            // fantomLegacyValue={fantomEsYmyStakedLegacy}
                            arbValue={arbitrumEsYmyStaked}
                            baseValue={baseEsYmyStaked}
                            total={totalEsYmyStaked}
                            decimalsForConversion={18}
                            symbol="esYMY"
                          />
                        )}
                      />
                      <span>${formatAmount(stakedEsYmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">Total Supply</div>
                <div className="value">
                  {esYmySupply ? (
                    <>
                      {formatAmount(esYmySupply, 18, 0, true)} esYMY
                      <span>${formatAmount(esYmySupplyUsd, USD_DECIMALS, 0, true)}</span>
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
              <div className="box-token-info">
                <div className="title">MPs APR</div>
                <div className="value">
                  <Tooltip
                    handle={`100%`}
                    position="right-bottom"
                    renderContent={() => (
                      <YMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">YMY</div>
            </div>
            <div className="box-desc">Stake YMY to earn {nativeTokenSymbol}, esYMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData?.ymyBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "ymyBalance", 18, 2, true)} YMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "ymyBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.ymyInStakedYmy ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "ymyInStakedYmy", 18, 2, true)} YMY{" "}
                      <span>${formatKeyAmount(processedData, "ymyInStakedYmyUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeYmyModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeYmyModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">esYMY</div>
            </div>
            <div className="box-desc">Stake esYMY to earn {nativeTokenSymbol}, esYMY, and MPs.</div>
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  {processedData.esYmyBalance ? (
                    <div className="value">
                      {formatKeyAmountFixed(processedData, "esYmyBalance", 18, 2, true)} esYMY{" "}
                      <span>${formatKeyAmountFixed(processedData, "esYmyBalanceUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  {processedData?.esYmyInStakedYmy ? (
                    <div className="value">
                      {formatKeyAmount(processedData, "esYmyInStakedYmy", 18, 2, true)} esYMY{" "}
                      <span>${formatKeyAmount(processedData, "esYmyInStakedYmyUsd", USD_DECIMALS, 2, true)}</span>
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => showStakeEsYmyModal()} className="App-button-option App-card-option">
                    <span>Stake</span>
                  </button>
                )}
                {account && (
                  <button
                    onClick={() => showUnstakeEsYmyModal()}
                    className="App-button-option App-card-option border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-content left-content right-content">
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">Pending rewards</div>
              {/* <div className="text-button-green">
                <Link to="/earn-v2/">Claim page</Link>
              </div> */}
            </div>
            <div className="green-value green-pending">
              $
              {formatAddKeysAmount(
                processedData,
                "feeYmyTrackerRewardsUsd",
                "stakedYmyTrackerRewardsUsd",
                USD_DECIMALS,
                2,
                true
              )}
            </div>
            <div className="rewards-container  pending-rewards-content">
              <div className="reward-info">
                <div className="title">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                {processedData?.feeYmyTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "feeYmyTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "feeYmyTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
              <div className="reward-info">
                <div className="title">esYMY</div>
                {processedData?.stakedYmyTrackerRewards ? (
                  <div className="value">
                    {formatKeyAmount(processedData, "stakedYmyTrackerRewards", 18, 4, true)}{" "}
                    <span>${formatKeyAmount(processedData, "stakedYmyTrackerRewardsUsd", USD_DECIMALS, 2, true)}</span>
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className={`btns no-chain`}>
              <Link to="/earn-v2/" className="border-btn full">
                Claim page
              </Link>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusYmyInFeeYmy={bonusYmyInFeeYmy}
      />
    </div>
  );
};
export default YMYandESYMY;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;
