import { LoadingOutlined } from "@ant-design/icons";
import { commify } from "@ethersproject/units";
import { useOrderEntry } from "@orderly.network/hooks";
import { OrderSide, OrderType } from "@orderly.network/types";
import cx from "classnames";
import { helperToast } from "lib/helperToast";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { preventSpecialCharacters } from "utils/helpers";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PercentageButtons from "./PercentageButtons";
import "./PositionSeller.css";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "" : "") + n;
  }
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return sign + z((secs / 3600) | 0) + "h:" + z(((secs % 3600) / 60) | 0) + "m:" + z((secs % 60).toFixed(0)) + "s";
}
export default function ClosePositionV3(props) {
  const { isVisible, positions, selectedPosition, setIsVisible, feeTier, handleCancelRelatedOrders, pairInfo } = props;
  const [closeAmount, setCloseAmount] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const position = positions.find((x) => x.symbol === selectedPosition?.symbol);
  useEffect(() => {
    if (!position) setIsVisible(false);
  }, [position]);
  const currentPair = useMemo(() => {
    if (pairInfo && pairInfo?.length > 0 && position?.symbol) {
      return pairInfo.find((x) => x.symbol === position?.symbol);
    }
  }, [pairInfo, position?.symbol]);
  const maxQtyPercision = useMemo(() => {
    if (currentPair) {
      const { base_tick } = currentPair;
      const tickStr = base_tick.toString();
      if (tickStr.includes("e-")) return tickStr.split("e-")[1];
      if (!tickStr.includes(".")) return 0;
      return tickStr.split(".")[1].length;
    }
    return 1;
  }, [currentPair]);
  const {
    submit,
    setValue,
    formattedOrder,
    metaState: { errors },
  } = useOrderEntry(position?.symbol, {
    initialOrder: {
      side: position?.position_qty > 0 ? OrderSide.SELL : OrderSide.BUY,
      order_type: OrderType.MARKET,
      order_quantity: String(closeAmount || 0),
      reduce_only: true,
    },
  });
  if (!position) return;
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);
  const isLong = position?.position_qty > 0;

  const handleSelectPercentageCloseAmount = (percentage) => {
    const value = posQty.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseAmount(formatAmount(value, 30, maxQtyPercision, false));
    setValue("order_quantity", formatAmount(value, 30, maxQtyPercision, false));
  };

  const posQty = parseValue(Math.abs(position?.position_qty), 30);

  const onClossePosition = async () => {
    const textQty = `${formattedOrder ? formattedOrder?.order_quantity : "-"} ${tokenSymbol}`;

    try {
      const symbol = position?.symbol;
      setIsConfirm(true);
      await submit();
      setIsVisible(false);
      helperToast.success(
        <div>
          Closed order {textQty}
          <br />
        </div>
      );
      if (closeAmount && closeAmount >= position?.position_qty) {
        handleCancelRelatedOrders && handleCancelRelatedOrders(symbol, isLong);
      }
    } catch (error) {
      helperToast.error(error?.message || `Failed to close order ${textQty}`);
    } finally {
      setIsConfirm(false);
    }
  };

  const getClosePositionError = () => {
    if (!closeAmount || !Number(closeAmount)) {
      return "Enter an amount";
    }

    if (posQty && parseValue(closeAmount, 30).gt(posQty)) {
      return "Amount exceeds balance";
    }
    if (errors) {
      const messages = Object.values(errors)
        .map((item) => item.message)
        .filter((message) => message !== undefined);
      return messages?.[0];
    }
    return null;
  };
  const getPrimaryText = () => {
    return getClosePositionError() ?? (isConfirm ? "Closing..." : "Close Position");
  };
  const isDisableCondition = () => {
    return false;
  };
  const handleCloseAmount = (e) => {
    const value = e.target.value;
    setCloseAmount(value);
    setValue("order_quantity", value);
  };

  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Close Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <StyledWrap className="head">
                <div className="header">
                  <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} />
                  <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
                  <div className="position-info">
                    <div className="title">{tokenSymbol}/USDC</div>
                  </div>
                </div>
              </StyledWrap>
            </div>
          </div>
          <div className="content-container with-margin-mobile" style={{ marginTop: "16px" }}>
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "1px" }}>
              {/* <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Amount</span>
                </div>
                <div className="right-balance" style={{ marginLeft: "auto" }}>
                  <span>Max: {Math.abs(position?.position_qty)}</span>
                </div>
              </div> */}
              <div className="Exchange-swap-section-bottom">
                <div className="input-label fz-12">Quantity</div>
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    onKeyDown={preventSpecialCharacters}
                    value={closeAmount}
                    onChange={handleCloseAmount}
                  />
                </div>
                <div className="PositionEditor-token-symbol" style={{ fontSize: 14 }}>
                  {tokenSymbol}
                </div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseAmount}
              balance={Number(formatAmount(posQty, 30, 6, false))}
              value={closeAmount}
            />
            <div className="order-details-container" style={{ marginTop: "16px" }}>
              <div className="square-container">
                <ExchangeInfoRow label={`Unrealized PnL`}>
                  <div
                    className={cx({ positive: position?.unrealized_pnl > 0, negative: position?.unrealized_pnl < 0 })}
                  >
                    {position?.unrealized_pnl > 0 ? "+" : "-"}
                    {formatAmount(parseValue(position?.unrealized_pnl || 0, 30), 30, 2, true)
                      .replace("-", "")
                      .replace("-", "")}{" "}
                    <span className="muted">USDC</span>
                    &nbsp;(
                    {commify(limitDecimals((position?.unrealized_pnl_ROI || 0) * 100, 2)).replace("-", "")}
                    %)
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Quantity`}>
                  {Math.abs(position?.position_qty)} {tokenSymbol}
                </ExchangeInfoRow>

                <ExchangeInfoRow label={`Margin`}>
                  {" "}
                  {formatAmount(parseValue(position?.mm || 0, 30), 30, 2, true)}{" "}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Notional`}>
                  {limitDecimals(position?.notional || 0, 2)}{" "}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>

                <ExchangeInfoRow label={`Mark Price`}>
                  {" "}
                  {formatAmount(
                    parseValue(position?.mark_price || 0, 30),
                    30,
                    decimalsRules(position?.mark_price),
                    true
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Open Price`}>
                  {formatAmount(
                    parseValue(position?.average_open_price || 0, 30),
                    30,
                    decimalsRules(position?.average_open_price),
                    true
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  {position?.est_liq_price
                    ? formatAmount(
                        parseValue(position?.est_liq_price || 0, 30),
                        30,
                        decimalsRules(position?.est_liq_price),
                        true
                      )
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  {feeTier
                    ? commify(
                        limitDecimals(
                          Math.abs(position?.position_qty || 0) * (position?.mark_price || 0) * (feeTier / 10000),
                          4
                        )
                      )
                    : "-"}
                  <span style={{marginLeft: 4}} className="muted">USDC</span>
                </ExchangeInfoRow>
                {/* <Divider /> */}

                {/* <ExchangeInfoRow label={`PnL (ROI)`} isPositive={undefined}></ExchangeInfoRow> */}
                {/* <ExchangeInfoRow label={`Receive`}>--</ExchangeInfoRow> */}
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={onClossePosition}
              disabled={!!getClosePositionError() || isConfirm || isDisableCondition()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

const LeverageTag = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 8px 0 16px 0;
`;
const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 12px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
