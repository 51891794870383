import { useWeb3React } from "@web3-react/core";
import connectWalletImg from "img/ic_wallet_24.svg";
import { useCallback, useEffect, useState } from "react";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";

import cx from "classnames";
import {
  BERACHAIN_BARTIO,
  SUPPORTED_CHAIN_ID_DEPOSIT,
  SUPPORTED_V2_CHAINS,
  getChainName,
  getConstant,
} from "config/chains";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { getAccountUrl, isDevelopment } from "lib/legacy";
import { switchNetwork } from "lib/wallets";
import { useHistory } from "react-router-dom";
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import "./Header.scss";

import { useThemeContext } from "contexts/ThemeProvider";
import useProviderBalance from "hooks/useProviderBalance";
import useSwitchChain from "hooks/useSwitchChain";
import BalanceManager from "components/BalanceManager";
import ComboDepositModal from "components/ComboDepositModal";
import DepositModalOrderly from "components/DepositModalOrderly";
import WithdrawModalOrderly from "components/WithdrawModalOrderly";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  tradeVersion: any;
  savedSlippageAmount?: any;
  savedSelectedDexes?: string;
};

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
  tradeVersion,
  savedSlippageAmount,
  savedSelectedDexes,
}: Props) {
  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();
  const [isDepositModalVisible, setIsDepositModalVisible] = useState<boolean>(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState<boolean>(false);

  const history = useHistory();
  const isLanding = false;
  const isTrade = history.location.pathname === "/trade";
  const showConnectionOptions = !isLanding;

  const networkOptions = [
    // {
    //   label: getChainName(FANTOM),
    //   value: FANTOM,
    //   icon: "ic_ftm_24.svg",
    //   color: "#264f79",
    // },
    {
      label: getChainName(BERACHAIN_BARTIO),
      value: BERACHAIN_BARTIO,
      icon: "ic_bera_24.png",
      color: "#E841424D",
    },
  ];
  if (isDevelopment()) {
    // networkOptions.push({
    //   label: getChainName(ARBITRUM_TESTNET),
    //   value: ARBITRUM_TESTNET,
    //   icon: "ic_arbitrum_24.svg",
    //   color: "#264f79",
    // });
  }

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  const [depositValue, setDepositValue] = useState("");
  const ethBalance = useProviderBalance();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const { toggleTheme, isLightTheme } = useThemeContext() as any;

  if (!active || !account) {
    return (
      <div className="App-header-user">
        <div className={cx("App-header-trade-link", { "homepage-header": isLanding })}>
          {isLanding ? (
            <a className="default-btn" href="https://app.yummy.fi/#/trade">
              <span>Launch App</span>
            </a>
          ) : (
            <>
              {!isTrade && (
                <a className="default-btn" href="https://app.yummy.fi/#/trade">
                  <span>Trade</span>
                </a>
              )}
            </>
            // <HeaderLink
            //   className="default-btn"
            //   to="/trade"
            //   redirectPopupTimestamp={redirectPopupTimestamp}
            //   showRedirectModal={showRedirectModal}
            // >
            //   <span>Trade</span>
            // </HeaderLink>
          )}
        </div>

        {showConnectionOptions ? (
          <>
            <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={connectWalletImg}>
              {small ? <span>Connect</span> : <span>Connect Wallet</span>}
            </ConnectWalletButton>
            <NetworkDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
              isLightMode={isLightTheme}
            />
            {/* <UserDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            /> */}
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  const handleDeposit = () => {
    setIsDepositModalVisible(true);
  };
  const handleWithdraw = () => {
    setIsWithdrawModalVisible(true);
  };
  return (
    <>
      {isDepositModalVisible && (
        <DepositModalOrderly isVisible={isDepositModalVisible} setIsVisible={setIsDepositModalVisible} />
      )}
      {isWithdrawModalVisible && (
        <WithdrawModalOrderly isVisible={isWithdrawModalVisible} setIsVisible={setIsWithdrawModalVisible} />
      )}
      <div className="App-header-user">
        {/* <button className="default-btn" onClick={toggleTheme} style={{ marginRight: "10px" }}>
          <span>Toggle Theme</span>
        </button> */}
        {
          <div className="App-header-trade-link">
            {isLanding ? (
              <a className="default-btn" href="https://app.yummy.fi/#/trade">
                <span>Launch App</span>
              </a>
            ) : (
              <>
                {/* {!isTrade && (
                  <a className="default-btn" href={chainId === FANTOM ?  '/#/trade' : '/#/v1'}>
                    <span>Trade</span>
                  </a>
                )} */}
              </>
            )}
          </div>
        }
        {!isLanding && SUPPORTED_V2_CHAINS.includes(chainId) && (
          <>
            <BalanceManager handlDeposit={handleDeposit} handleWithdraw={handleWithdraw} />
            {/* <div className="App-header-balance-container">
              <img src={exchangeIcon} alt="icon" />
              <div className="App-header-usd-balance">${formatAmount(ysusdBalance || 0, 30, 2, true)}</div>
              <button className="App-header-add-eth" onClick={handleDeposit}>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div> */}
          </>
        )}
        {/* {isLanding ? (
          <div className="App-header-trade-link">
            <a className="default-btn" href="https://app.yummy.fi/#/trade">
              <span>Trade</span>
            </a>
          </div>
        ) : (
          <>
            <div className="App-header-balance-container">
              <div className="App-header-usd-balance">${formatAmount(ysusdBalance, 30, 4, true)}</div>
              <div className="App-header-eth-balance-container">
                <span className="App-header-eth-icon">
                  <img src={importImage("ic_" + nativeTokenSymbol.toLowerCase() + "_24.svg")} alt="" />
                </span>
                <span className="App-header-eth-balance">{formatAmount(ethBalance, 18, 4, true)}</span>
              </div>
              <button className="App-header-add-eth" onClick={() => setIsDepositModalVisible(true)}>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
                    fill="#18191A"
                  />
                </svg>
              </button>
            </div>
          </>
        )} */}

        {showConnectionOptions ? (
          <>
            <div className="App-header-user-address">
              <AddressDropdown
                account={account}
                accountUrl={accountUrl}
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                isLightMode={isLightTheme}
              />
            </div>
            <NetworkDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
              isLightMode={isLightTheme}
            />
            {/* <UserDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            /> */}
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    </>
  );
}
