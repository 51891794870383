import Modal from "components/Modal/Modal";
import styled from "styled-components";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount } from "lib/numbers";
import { BASIS_POINTS_DIVISOR, importImage, USD_DECIMALS, USDY_DECIMALS } from "lib/legacy";
import { getWhitelistedTokens } from "config/tokens";

import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_YSLP } from "config/chains";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";

import VaultV2 from "abis/VaultV2.json";
import { contractFetcher } from "lib/contracts";
import { getTokenInfo } from "domain/tokens";
import { Link } from "react-router-dom";

export const PoolCompositionYlpModal = ({ isVisible, setIsVisible, infoTokens }) => {
  const { active, library } = useWeb3React();

  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_YSLP,
    isUseDefaultChain: false,
  });
  const vaultAddress = getContract(chainId, "Vault");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const { data: totalTokenWeights } = useSWR(
    [`BuySellYlp:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  let adjustedUsdySupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdyAmount) {
      adjustedUsdySupply = adjustedUsdySupply.add(tokenInfo.usdyAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdyAmount ||
      !adjustedUsdySupply ||
      adjustedUsdySupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdyAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdySupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return weightText;
  };

  return (
    <Modal1
      className="medium-modal Bootstrap-Modal hasDivider"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Pool Composition"
    >
      <div className="PoolModal-Table">
        <div className="PoolModal-TableHead">
          <span>Token</span>
          <span>Weight</span>
          <span>TVL</span>
        </div>
        {visibleTokens.map((token, index) => {
          const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");
          const tokenInfo = getTokenInfo(infoTokens, token.address);

          let amountLeftToDeposit = bigNumberify(0);
          if (tokenInfo.maxUsdyAmount && tokenInfo.maxUsdyAmount.gt(0)) {
            amountLeftToDeposit = tokenInfo.maxUsdyAmount
              .sub(tokenInfo.usdyAmount)
              .mul(expandDecimals(1, USD_DECIMALS))
              .div(expandDecimals(1, USDY_DECIMALS));
          }
          if (amountLeftToDeposit.lt(0)) {
            amountLeftToDeposit = bigNumberify(0);
          }

          return (
            <div className="PoolModal-TableRow" key={index}>
              <span>
                <img src={tokenImage} alt="usdc" width="40px" /> {token.symbol}
              </span>
              <span>
                {getWeightText(tokenInfo) !== "..." ? (
                  getWeightText(tokenInfo)
                ) : (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </span>
              <span>${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}</span>
            </div>
          );
        })}
      </div>
      <div className="PoolModal-Actions">
        <Link to="/earn/ylp" className="default-btn">
          Get & Stake
        </Link>
      </div>
    </Modal1>
  );
};

const Modal1 = styled(Modal)`
  .PoolModal-Table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Base-Unit-S-12, 12px);
    align-self: stretch;
  }

  .PoolModal-TableHead {
    display: grid;
    grid-template-columns: 90px 1fr 1fr;
    gap: 20px;
    align-self: stretch;

    & > span {
      color: var(--Text-Text_Secondary, #919499);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  .PoolModal-TableRow {
    display: grid;
    grid-template-columns: 90px 1fr 1fr;
    align-items: center;
    gap: 20px;
    align-self: stretch;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      /* width: calc(100% / 3); */

      &:first-child {
        display: flex;
        align-items: center;
        gap: var(--Base-Unit-XS-8, 8px);
        flex: 1 0 0;
      }
    }
  }

  .PoolModal-Actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 24px;
  }
`;
