import { commify } from "@ethersproject/units";
import cx from "classnames";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import { useMemo } from "react";

const TradeActivityItemMobileV3 = (props) => {
  const { position, handleSelectedPosition } = props;
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);
  const isLong = position?.side === "BUY";
  const getPriceExecute = () => {
    if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED") return "-";
    if (position?.algo_type?.includes("TP_SL")) {
      const tp = position?.child_orders?.[0]?.average_executed_price;
      const sl = position?.child_orders?.[1]?.average_executed_price;
      if (tp)
        return (
          <span>
            <span className="positive">TP </span>
            {formatAmount(parseValue(tp || 0, 30), 30, decimalsRules(tp), true)}
          </span>
        );
      else if (sl)
        return (
          <span>
            <span className="negative">SL </span>
            {formatAmount(parseValue(sl || 0, 30), 30, decimalsRules(sl), true)}
          </span>
        );
    } else if (position?.average_executed_price)
      return formatAmount(
        parseValue(position?.average_executed_price || 0, 30),
        30,
        decimalsRules(position?.average_executed_price),
        true
      );
    return "-";
  };
  const getPriceTrigger = () => {
    if (position?.algo_type?.includes("TP_SL") || position?.algo_type?.includes("BRACKET")) {
      if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED") {
        let isTP, isSL;
        let tp, sl;
        let child_orders = position?.child_orders || [];
        if (child_orders.length > 0 && child_orders.length < 2) {
          child_orders = child_orders[0]?.child_orders;
        }
        if (child_orders?.length > 0) {
          tp = child_orders?.[0];
          sl = child_orders?.[1];
          if (tp?.trigger_price) isTP = true;
          if (sl?.trigger_price) isSL = true;
          if (isTP && isSL)
            return (
              <div>
                <div>
                  <span className="positive">TP </span>
                  {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
                </div>
                <div style={{ marginTop: 4 }}>
                  <span className="negative">SL </span>
                  {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
                </div>
              </div>
            );
          else if (isTP)
            return (
              <>
                <span>
                  <span className=""></span>
                  {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
                </span>
              </>
            );
          else if (isSL)
            return (
              <>
                <span>
                  <span className=""></span>
                  {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
                </span>
              </>
            );
        }
      } else {
        // const child = position?.child_orders?.find((x) => x?.status === "FILLED");
        // if (child)
        //   return formatAmount(
        //     parseValue(child?.trigger_price || child?.trigger_trade_price || 0, 30),
        //     30,
        //     decimalsRules(child?.trigger_price),
        //     true
        //   );
      }
    } else {
      if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED")
        if (position?.price || position?.trigger_trade_price || position?.trigger_price)
          return formatAmount(
            parseValue(position?.price || position?.trigger_trade_price || position?.trigger_price || 0, 30),
            30,
            decimalsRules(position?.price || position?.trigger_trade_price || position?.trigger_price),
            true
          );
    }
    return "-";
  };
  const getOrderlyType = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      let isTP,
        isSL = false;
      if (position?.child_orders?.[0]?.trigger_price) {
        isTP = true;
      }
      if (position?.child_orders?.[1]?.trigger_price) {
        isSL = true;
      }
      if (isTP && isSL) return "TP/SL";
      else if (isTP) return "Take profit";
      else return "Stop loss";
    }
    if (position?.type === "MARKET" && position?.algo_type && position?.algo_type !== "BRACKET") return "Stop Market";
    if (position?.algo_type && position?.algo_type !== "BRACKET") return "Stop Limit";
    return position?.type === "POST_ONLY"
      ? "Post Only"
      : position?.type === "IOC"
      ? "IOC"
      : position?.type === "FOK"
      ? "FOK"
      : position?.type === "LIMIT"
      ? "Limit"
      : "Market";
  };
  const getFilledQuantity = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") {
        const child = position?.child_orders.find((x) => x?.visible_quantity);
        if (child) return `${child?.total_executed_quantity}/${child?.visible_quantity}`;
        else return "Entire position";
      }
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return `${child?.total_executed_quantity}/${child?.quantity}`;
    } else {
      // if (position?.status === "CANCELLED") return "-";
      return `${position?.total_executed_quantity}/${position?.quantity}`;
    }
  };
  const getReduceOnly = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      return "Yes";
    }
    if (position.reduce_only) return "Yes";
    return "No";
  };
  const getFees = useMemo(() => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") return "-";
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return child.total_fee;
    } else return position.total_fee;
  }, [position]);
  return (
    <div
      className="App-card"
      style={{ opacity: position?.algo_status === "CANCELLED" || position.status === "CANCELLED" ? 0.6 : 1 }}
    >
      <div className="App-card-title">
        <div className={`${isLong === "BUY" ? `long-tag` : `short-tag`}`} style={{ top: 13 }} />
        <span className="Exchange-list-title" style={{ paddingLeft: "4px" }}>
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="" /> {tokenSymbol}/USDC{" "}
          <span className="cancel-order-txt">{getOrderlyType()}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Price</span>
          </div>
          <div>
            <div>{getPriceExecute()}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Trigger Price</span>
          </div>
          <div>
            <div>{getPriceTrigger()}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Filled/Quantity</span>
          </div>
          <div>
            <div>{getFilledQuantity()}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Fees</span>
          </div>
          <div>
            {commify(limitDecimals(getFees || 0, 2)).replace("-", "")} <span className="muted">USDC</span>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Reduce Only</span>
          </div>
          <div>
            <div>{getReduceOnly()}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Post Only</span>
          </div>
          <div>
            <div>{position.type === "POST_ONLY" ? "Yes" : "No"}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Status</span>
          </div>
          <div>
            <div>
              {" "}
              {position?.algo_status === "CANCELLED" || position.status === "CANCELLED"
                ? "Cancelled"
                : ["FILLED"]?.includes(position?.status || position.algo_status)
                ? "Filled"
                : "Pending"}
            </div>
          </div>
        </div>

        <div className="App-card-row">
          <div className="label">
            <span>Time</span>
          </div>
          <div>
            <div>{moment(new Date(position?.updated_time)).format(dateFormat)}</div>
            <div className="muted fz-12">{moment(new Date(position?.updated_time)).format(timeFormat)}</div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(position)}
          // disabled={position?.size == 0}
        >
          <span>View</span>
        </button>
      </div>
    </div>
  );
};
export default TradeActivityItemMobileV3;
