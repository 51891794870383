import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import { ARBITRUM } from "config/chains";
import { helperToast } from "lib/helperToast";
import { useDebounce } from "lib/useDebounce";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  getCodeError,
  getReferralCodeTakenStatus,
  getSampleReferrarStat,
  getTierIdDisplay,
  tierRebateInfo,
} from "./referralsHelper";

import Modal from "../Modal/Modal";

function AddAffiliateCode({
  isReferralCodeAvailable,
  referralsData,
  handleCreateReferralCode,
  active,
  connectWallet,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const { referrerTierInfo } = referralsData || {
    referrerTierInfo: {},
  };

  const tierId = referrerTierInfo?.tierId;

  return (
    <>
      {!isReferralCodeAvailable ? (
        <div className="referral-card section-center mt-medium">
          <h2 className="title">
            <span>Referral Code</span>
          </h2>
          <p className="sub-title">
            <span>
              Looks like you don't have a referral code to share.
              <br /> Create one now and start earning rebates!
            </span>
          </p>

          <div
            onClick={() => setIsVisible(true)}
            className="default-btn"
            style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
          >
            Generate Code
          </div>
        </div>
      ) : (
        <div className="referral-card section-center mt-medium">
          <div
            style={{
              margin: 0,
            }}
            className="sub-title"
          >
            Your Referral Code
          </div>
          <div
            style={{
              margin: "4px 0 24px 0",
            }}
            className="your-code-percentage"
          >
            {referralsData?.codes?.length}
          </div>
          <div className="line" />

          <div
            style={{
              margin: "48px 0 0 0",
            }}
            className="sub-title"
          >
            Your Rebate Rate
          </div>
          <div className="your-code-percentage">{referrerTierInfo && `${tierRebateInfo[tierId]}%`}</div>
          <div
            style={{
              marginTop: 0,
            }}
            className="sub-title"
          >
            {referrerTierInfo && `Tier ${getTierIdDisplay(tierId)}`}
          </div>

          <div onClick={() => setIsVisible(true)} className="modal-ref-code default-btn"
          style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
            Create New Code
          </div>
        </div>
      )}

      <Modal
        isVisible={isVisible}
        setIsVisible={() => setIsVisible(false)}
        label="Create Referral Code"
        allowContentTouchMove
      >
        <div className="card-action">
          <div className="card-action">
            {active ? (
              <AffiliateCodeForm
                handleCreateReferralCode={handleCreateReferralCode}
                recentlyAddedCodes={recentlyAddedCodes}
                setRecentlyAddedCodes={setRecentlyAddedCodes}
              />
            ) : (
              <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
                <span>Connect Wallet</span>
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export function AffiliateCodeForm({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
  callAfterSuccess,
}) {
  const [referralCode, setReferralCode] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef("");
  const [referralCodeCheckStatus, setReferralCodeCheckStatus] = useState("ok");
  const debouncedReferralCode = useDebounce(referralCode, 300);
  const { account, chainId } = useWeb3React();
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    let cancelled = false;
    const checkCodeTakenStatus = async () => {
      if (error) {
        setReferralCodeCheckStatus("ok");
        return;
      }
      const { status: takenStatus } = await getReferralCodeTakenStatus(account, debouncedReferralCode, chainId);
      // ignore the result if the referral code to check has changed
      if (cancelled) {
        return;
      }
      if (takenStatus === "none") {
        setReferralCodeCheckStatus("ok");
      } else {
        setReferralCodeCheckStatus("taken");
      }
    };
    setReferralCodeCheckStatus("checking");
    checkCodeTakenStatus();
    return () => {
      cancelled = true;
    };
  }, [account, debouncedReferralCode, error, chainId]);

  function getButtonError() {
    if (!debouncedReferralCode) {
      return `Enter a code`;
    }
    if (referralCodeCheckStatus === "taken") {
      return `Code already taken`;
    }
    if (referralCodeCheckStatus === "checking") {
      return `Checking code...`;
    }

    return false;
  }

  const buttonError = getButtonError();

  function getPrimaryText() {
    if (buttonError) {
      return buttonError;
    }

    if (isProcessing) {
      return `Creating...`;
    }

    return `Create`;
  }
  function isPrimaryEnabled() {
    if (buttonError) {
      return false;
    }
    if (error || isProcessing) {
      return false;
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsProcessing(true);
    const { status: takenStatus, info: takenInfo } = await getReferralCodeTakenStatus(account, referralCode, chainId);
    if (["all", "current", "other"].includes(takenStatus)) {
      setIsProcessing(false);
    }

    if (takenStatus === "none" || takenStatus === "other") {
      const ownerOnOtherNetwork = takenInfo[chainId === ARBITRUM ? "ownerAvax" : "ownerArbitrum"];
      try {
        const tx = await handleCreateReferralCode(referralCode);
        if (callAfterSuccess) {
          callAfterSuccess();
        }
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          recentlyAddedCodes.push(getSampleReferrarStat(referralCode, ownerOnOtherNetwork, account));

          helperToast.success(`Referral code created!`);
          setRecentlyAddedCodes(recentlyAddedCodes);
          setReferralCode("");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        ref={inputRef}
        value={referralCode}
        disabled={isProcessing}
        className={cx("text-input", { "mb-sm": !error })}
        placeholder={`Enter a code`}
        onChange={({ target }) => {
          const { value } = target;
          setReferralCode(value);
          setError(getCodeError(value));
        }}
      />
      {error && <p className="error">{error}</p>}
      <button className="App-cta Exchange-swap-button" type="submit" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </button>
    </form>
  );
}

export const StyledInput = styled.input`
  border-radius: 4px;

  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #ffffff;

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }
`;

export default AddAffiliateCode;
