const SvgIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height="2"
    fill="none"
    className="w-12px"
    viewBox="0 0 16 2"
  >
    <path fill="currentColor" d="M16 0v2H0V0z"></path>
  </svg>
);

export default SvgIcon;
