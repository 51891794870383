import Modal from "../Modal/Modal";
export default function OrderlyConfirmaton(props) {
  const { isVisible, setIsVisible, onConfirm } = props;
  return (
    <div className="PositionEditor">
      <Modal
        className={`PositionSeller-modal confirmation-modal add-to-position-modal add-to-position-modal1`}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={"Cancel Order"}
        allowContentTouchMove
        maxWidth="480px"
      >
        <div className="content-container">
          <div>{props.children}</div>
        </div>
        <div className="Exchange-swap-button-container update-levrage-button-container">
          <button className="border-btn" onClick={() => setIsVisible(false)}>
            Cancel
          </button>
          <button className="default-btn Exchange-swap-button" onClick={() => {
            setIsVisible(false)
            onConfirm && onConfirm()
          }}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
}
