import styled from "styled-components";

import Yummy_Hero from "img/home/Gummy_Hero.png";
import pyth from "img/home/pyth.svg";
import orderly from "img/home/orderly.svg";
import twitter from "img/ic_twitter.svg";
import discord from "img/ic_discord.svg";
import telegram from "img/ic_telegram.svg";
import medium from "img/ic_medium.svg";
import { ImageBannerBg, ImageBannerBgTl, ImageBannerBgMb } from "assets";

const Hero = () => {
  const listSocial = [
    {
      icon: discord,
      link: "https://discord.com/invite/3GzTNxYJEm",
    },
    {
      icon: twitter,
      link: "https://x.com/Yummy_Fi",
    },
    {
      icon: medium,
      link: "https://medium.com/@Yummy_Fi",
    },
    {
      icon: telegram,
      link: "https://t.me/yummyfi",
    },
  ];

  return (
    <Wrapper>
      <div className="Hero-wrapper hidden-in-tablet">
        <div className="Hero-left">
          <div className="Hero-head">
            <h1>Smarter Margin. Onchain Freedom. All on Berachain.</h1>
            <div className="Hero-desc">
              Unlock powerful perpetual trading with flexible margin modes and deep liquidity - built for the next-gen
              DeFi user.
            </div>
          </div>
          <div className="Hero-info">
            <div className="Hero-actions">
              <button onClick={() => window.open("https://app.yummy.fi/#/trade", "_blank")} className="default-btn">
                Start Trading
              </button>
              <div className="Hero-powered">
                Powered by{" "}
                <a href="https://www.pyth.network/" target="_blank" rel="noreferrer">
                  <img src={pyth} alt="Powered by" />
                </a>
                <div className="divider" />
                <a href="https://orderly.network/" target="_blank" rel="noreferrer">
                  <img src={orderly} alt="Powered by" />
                </a>
              </div>
            </div>
            <div className="Hero-values">
              <div className="Hero-value">
                Total Trading Volume
                <span>$--</span>
              </div>
              <div className="Hero-value">
                Total Trades
                <span>--</span>
              </div>
              <div className="Hero-value">
                Total Fees
                <span>$--</span>
              </div>
            </div>
          </div>

          <div className="Hero-partners">
            {listSocial.map((item, index) => (
              <a href={item.link} target="_blank" rel="noreferrer" className="Hero-partner" key={index}>
                <img src={item.icon} alt="Powered by" />
              </a>
            ))}
          </div>
        </div>
        <div className="Hero-right">
          <img src={Yummy_Hero} alt="Hero" />
        </div>
      </div>
      <div className="Hero-wrapper show-in-tablet">
        <div>
          <div className="hero-top-tablet">
            <div className="Hero-left">
              <div className="Hero-head">
                <h1>Smarter Margin. Onchain Freedom. All on Berachain.</h1>
                <div className="Hero-desc">
                  Unlock powerful perpetual trading with flexible margin modes and deep liquidity - built for the
                  next-gen DeFi user.
                </div>
              </div>
              <div className="Hero-actions">
                <button onClick={() => window.open("https://app.yummy.fi/#/trade", "_blank")} className="default-btn">
                  Start Trading
                </button>
                <div className="Hero-powered">
                  Powered by{" "}
                  <img onClick={() => window.open("https://pyth.network/", "_blank")} src={pyth} alt="Powered by" />
                  <div className="divider" />
                  <img
                    onClick={() => window.open("https://orderly.network/", "_blank")}
                    src={orderly}
                    alt="Powered by"
                  />
                </div>
              </div>
            </div>
            <div className="Hero-right">
              <img src={Yummy_Hero} alt="Hero" />
            </div>
          </div>
          <div className="hero-bottom-tablet">
            <div className="Hero-values">
              <div className="Hero-value">
                Total Trading Volume
                <span>$--</span>
              </div>
              <div className="Hero-value">
                Total Trades
                <span>--</span>
              </div>
              <div className="Hero-value">
                Total Fees
                <span>$--</span>
              </div>
            </div>

            <div className="Hero-partners">
              {listSocial.map((item, index) => (
                <a href={item.link} target="_blank" rel="noreferrer" className="Hero-partner" key={index}>
                  <img src={item.icon} alt="Powered by" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-image: url(${ImageBannerBg});
  background-size: cover;
  background-position: 70% center;
  background-repeat: no-repeat;

  .Hero-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1368px;
    padding: 0 24px;
    margin: 0 auto;
    padding-top: 110px;
    padding-bottom: 48px;
  }

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    width: 78%;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  .Hero-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 24px 0;

    @media screen and (max-width: 1023px) {
      gap: 24px;
    }
  }

  .Hero-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .Hero-desc {
    color: var(--Text-Text_Secondary, #797b80);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    width: 76%;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  .Hero-info {
    margin-top: 24px;
    width: 100%;
  }

  .Hero-actions {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
      font-weight: 500;
    }
  }

  .Hero-powered {
    display: flex;
    height: 40px;
    padding: 0px 24px;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    background: linear-gradient(270deg, rgba(1, 84, 128, 0.06) 0%, rgba(1, 84, 128, 0.3) 100%);

    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    .divider {
      background: rgba(255, 255, 255, 0.1);
      width: 1px;
      align-self: stretch;
      height: 100%;
      margin: 0;
    }

    img {
      cursor: pointer;
    }
  }

  .Hero-values {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    padding-left: 24px;
    position: relative;
    margin: 48px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background: #1edfec;
      box-shadow: 4px 0px 8px 0px rgba(30, 223, 236, 0.5);
    }

    @media screen and (max-width: 1023px) {
      flex: 1;
      padding: 8px 0 8px 16px;
      margin: 0;
    }

    @media screen and (max-width: 767px) {
      padding-left: 9px;
      padding: 0px;
      margin: 0;
      margin-bottom: 24px;
    }
  }

  .Hero-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    color: var(--Text-Text_Secondary, #848e9c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    span {
      color: #1edfec;
      font-size: 36px;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }

    @media screen and (max-width: 1023px) {
      flex: 1;
    }
  }

  .Hero-partners {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }

  .Hero-partner {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 4px;
    background: linear-gradient(270deg, rgba(1, 84, 128, 0.06) 0%, rgba(1, 84, 128, 0.3) 100%);
    cursor: pointer;

    &:hover {
      border-radius: 4px;
      /* border: 2px solid rgba(255, 255, 255, 0.1); */
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
      background: linear-gradient(270deg, rgba(1, 84, 128, 0.2) 0%, #015480 100%);
    }

    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      aspect-ratio: 1/1;
    }
  }

  .Hero-right {
    img {
      width: 500px;
      height: 500px;
      flex-shrink: 0;
      aspect-ratio: 1/1;
    }
  }

  @media screen and (max-width: 1023px) {
    gap: 24px;
    align-items: center;
    background-position: 85% center;
    background-image: url(${ImageBannerBgTl});

    h1 {
      font-size: 24px;
      line-height: 150%;
    }

    .Hero-wrapper {
      align-items: center;
    }

    .Hero-left {
      padding: 0;
    }

    .Hero-head {
    }

    .Hero-desc {
    }

    .Hero-actions {
    }

    .Hero-powered {
    }

    .Hero-values {
    }

    .Hero-value {
      span {
        font-size: 24px;
      }
    }

    .Hero-partners {
    }

    .Hero-partner {
    }

    .Hero-right {
      img {
        width: 188px;
        height: 188px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-position: 90% center;

    h1 {
      width: 100%;
    }

    .Hero-wrapper {
      padding-inline: 16px;
      padding-bottom: 48px;
    }

    .Hero-left {
      align-self: center;
      width: 100%;
      padding: 0;
    }

    .Hero-head {
    }

    .Hero-desc {
      width: 100%;
    }

    .Hero-info {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 0;
    }

    .Hero-actions {
      flex-direction: column;

      button {
        width: 100%;
      }
    }

    .Hero-powered {
      width: 100%;
    }

    .Hero-values {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      padding-left: 9px;
    }

    .Hero-value {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex-direction: row;
      width: 100%;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }

    .Hero-partners {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }

    .Hero-partner {
    }

    .Hero-right {
      display: none;
    }
  }

  .hidden-in-tablet {
    display: flex;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      display: none;
    }
  }

  .show-in-tablet {
    display: none;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      display: flex;
    }
  }

  .hero-top-tablet {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .hero-bottom-tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin-top: 24px;
  }

  @media screen and (max-width: 500px) {
    background-size: contain;
    background-position: unset;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 45%, rgba(0, 0, 0, 0)), url(${ImageBannerBgMb});
  }
`;

export default Hero;
