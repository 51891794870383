import styled from "styled-components";
import iconWarning from "img/trade/ic-warning.svg";
import iconError from "img/trade/ic-error.svg";

const MessageComponent = (props) => {
  const { type, children } = { ...props };
  const Icons = {
    warning: <img src={iconWarning} alt="warning" />,
    error: <img src={iconError} alt="error" />,
  };

  if (type === "warning") {
    return (
      <MessageContent className="warning">
        <div className="icon">{Icons["warning"]}</div>
        <div className="msg-content">{children}</div>
      </MessageContent>
    );
  }
  if (type === "error") {
    return (
      <MessageContent className="error">
        <div className="icon">{Icons["error"]}</div>
        <div className="msg-content">{children}</div>
      </MessageContent>
    );
  }
};
export default MessageComponent;
const MessageContent = styled.div`
  background: #18191a;
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  .Confirmation-box-info,
  .Confirmation-box-info-warning {
    margin: 0px;
    text-align: left;
  }
  .msg-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  &.error {
    .msg-content {
      color: var(--Functional-Red, #E43E53);
    }
  }
  &.warning {
    .msg-content {
      color: var(--Functional-Orange, #fed74c);
    }
  }
`;
