import { BASIS_POINTS_DIVISOR, decimalsRules, LIMIT, STOP_LIMIT, STOP_MARKET } from "lib/legacy";
import { useEffect, useRef, useState } from "react";
import "./ConfirmationBox.css";

import Modal from "../Modal/Modal";

import { LoadingOutlined } from "@ant-design/icons";
import { helperToast } from "lib/helperToast";
import { formatAmount, parseValue } from "lib/numbers";
import { useMedia } from "react-use";
import styled from "styled-components";
import ExchangeInfoRow from "./ExchangeInfoRow";
import { StyledWrap } from "components/Exchange/OrderEditorV3";
import { Divider } from "./AddTPSL";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;

const DEFAULT_LIST_SLIPPAGE = [0.1, 0.5, 1, 2];

export default function ConfirmationBoxV3(props) {
  const {
    isSwap,
    isLong,
    orderOption,
    onConfirmationClick,
    setIsConfirming,
    leverage,
    spread,
    openPosFee,
    markPrice,
    posQty,
    posSize,
    liqPrice,
    isSubmitting,
    isMarketOrder,
    isPendingConfirmation,
    takeProfitTriggerPrice,
    stopLossTriggerPrice,
    isTakeProfit,
    isStopLoss,
    savedSlippageAmount,
    setSavedSlippageAmount,
    chartSymbol,
    isLimitOrder,
    limitPrice,
    isStopOrder,
    stopPrice,
    isStopLimitOrder,
    currentMarket,
    collateral,
    totalUSD,
    takeProfitPrice,
    stopLossPrice,
  } = props;
  const slippage = parseInt(savedSlippageAmount);

  const [slippageAmount, setSlippageAmount] = useState((slippage / BASIS_POINTS_DIVISOR) * 100);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };
  const isPad = useMedia("(max-width: 1350px)");

  useEffect(() => {
    if (isSettingsVisible) {
      const slippage = savedSlippageAmount / 100;
      setSlippageAmount(slippage);
    }
  }, [isSettingsVisible]);

  const openSettings = () => {
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const renderMarginSection = () => {
    return (
      <>
        <div>
          <div className="order-details-container">
            <div className="square-container">
              <DivStyled>
                <ExchangeInfoRow label={`Total USD`}>{totalUSD} USDC</ExchangeInfoRow>
                <ExchangeInfoRow label={`Position Quantity`}>
                  {posQty} {chartSymbol}
                </ExchangeInfoRow>
              </DivStyled>
              {/* {collateral && <ExchangeInfoRow label={`Collateral`}>${collateral}</ExchangeInfoRow>} */}
              <DividerStyled />
              {!isLimitOrder && !isStopOrder && !isStopLimitOrder && (
                <ExchangeInfoRow label={`Entry Price`}>Market</ExchangeInfoRow>
              )}{" "}
              {(isStopOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Stop Price`}>
                  ${formatAmount(parseValue(stopPrice, 30), 30, decimalsRules(stopPrice), true)}
                </ExchangeInfoRow>
              )}
              {(isLimitOrder || isStopLimitOrder) && (
                <ExchangeInfoRow label={`Limit Price`}>
                  ${formatAmount(parseValue(limitPrice, 30), 30, decimalsRules(limitPrice), true)}
                </ExchangeInfoRow>
              )}
              <ExchangeInfoRow label={`Liq. Price`}>
                {liqPrice ? "$" + formatAmount(parseValue(liqPrice, 30), 30, decimalsRules(liqPrice), true) : "-"}
              </ExchangeInfoRow>
              {takeProfitPrice && (
                <ExchangeInfoRow label={`TP Price`}>
                  ${formatAmount(parseValue(takeProfitPrice, 30), 30, decimalsRules(takeProfitPrice), true)}
                </ExchangeInfoRow>
              )}
              {stopLossPrice && (
                <ExchangeInfoRow label={`SL Price`}>
                  ${formatAmount(parseValue(stopLossPrice, 30), 30, decimalsRules(stopLossPrice), true)}
                </ExchangeInfoRow>
              )}
              {/* {isTakeProfit && (
                <ExchangeInfoRow label={`Take Profit Price`}>
                  ${formatAmount(parseValue(takeProfitTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )}
              {isStopLoss && (
                <ExchangeInfoRow label={`Stop Loss Price`}>
                  ${formatAmount(parseValue(stopLossTriggerPrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
              )} */}
              {/* <ExchangeInfoRow label={`Fees`}>
                <Tooltip
                  handle={<span>${formatAmount(openPosFee, 10, 3, true)}</span>}
                  position={"right-top"}
                  className="fit-content nowrap"
                  handleClassName={cx("plain", "")}
                  renderContent={() => {
                    return (
                      <span>
                        Position Fee (0.08% of position size):{" "}
                        <span style={{ color: "#fff", fontWeight: 700 }}>
                          {" "}
                          ${openPosFee ? +formatAmount(openPosFee, 10, 3, true) : "--"}{" "}
                        </span>
                      </span>
                    );
                  }}
                />{" "}

              </ExchangeInfoRow> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const getTitle = () => {
    if (!isMarketOrder) {
      if (orderOption === LIMIT) return `Confirm Limit Order`;
      if (orderOption === STOP_LIMIT) return `Confirm Stop Limit`;
      if (orderOption === STOP_MARKET) return `Confirm Stop Market`;
    }
    return isLong ? `Confirm Long` : `Confirm Short`;
  };

  const getPrimaryText = () => {
    if (!isPendingConfirmation) {
      const action = isMarketOrder ? (isLong ? `Long` : `Short`) : `Create Order`;
      return action;
    }
    if (isMarketOrder) {
      if (isLong) {
        return `Longing...`;
      }
      return `Shorting...`;
    } else return "Creating...";
  };
  const isPrimaryEnabled = () => {
    return !isPendingConfirmation && !isSubmitting;
  };
  const handleConfirmClick = async () => {
    // const slippage = parseFloat(slippageAmount);
    // if (isNaN(slippage)) {
    //   helperToast.error(`Invalid slippage value`);
    //   return;
    // }
    // if (slippage > 5) {
    //   helperToast.error(`Slippage should be less than 5%`);
    //   return;
    // }
    // const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    // if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
    //   helperToast.error(`Max slippage precision is 0.01%`);
    //   return;
    // }
    // await setSavedSlippageAmount(basisPoints);
    onConfirmationClick && onConfirmationClick();
  };
  return (
    <Wrap className="Confirmation-box">
      <Modal
        isVisible={true}
        setIsVisible={() => setIsConfirming(false)}
        label={`Confirm Order`}
        allowContentTouchMove
        className={`confirmation-modal`}
      >
        <div className="order-title-line order-title-line-details">
          <div className="position-info-container">
            {/* <div className="position-info">
              <img className="small" src={`https://oss.orderly.network/static/symbol_logo/${chartSymbol}.png`} alt="" />
              <div className="title">{chartSymbol}</div>
            </div>
            <div className="position-tags">
              <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
              <div className="side">{orderOption}</div>
            </div> */}
            <StyledWrap className="head">
              <div className="header">
                <div className={`${isLong ? `long-tag` : `short-tag`}`} />
                <img src={`https://oss.orderly.network/static/symbol_logo/${chartSymbol}.png`} alt="tk" />{" "}
                <div className="position-info">
                  <div className="title">
                    {chartSymbol}/USDC <span className="cancel-order-txt">{orderOption}</span>
                  </div>
                </div>
              </div>
            </StyledWrap>
          </div>
        </div>
        <div className="content-container mobile-padding-top">
          <Divider />

          {renderMarginSection()}
        </div>
        <div className="Confirmation-box-row">
          <button
            onClick={handleConfirmClick}
            className={`App-cta Confirmation-box-button`}
            // disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </Wrap>
  );
}
const Wrap = styled.div`
  @media screen and (max-width: 700px) {
    .confirmation-modal .Modal-content .Modal-body .content-container.mobile-padding-top {
      padding-top: 0 !important;
    }
  }
`;
const DivStyled = styled.div`
  border-radius: 16px;
  background: var(--Nature-1, #12141a);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  margin-bottom: 16px;
  > div {
    flex: 1;
    display: flex !important;
    flex-direction: column;
    .Exchange-info-label {
      margin-right: 0;
      margin-bottom: 8px;
    }
    .align-right {
      justify-content: flex-start;
    }
  }
`;

const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: 4px 0 12px;
  background: var(--Border, rgba(55, 63, 92, 0.5));
`;
