import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useYmyPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import arbIcon from "img/ic_arb_24.svg";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  YLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import ymyIcon from "img/ic_ymy_custom.svg";
import YMY_FTM from "img/YMY-FTM.svg";
import YMY_ETH from "img/YMY-ETH.svg";
import tokensIcon from "img/tokens.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import YlpManager from "abis/YlpManager.json";
import VaultStrategyYMY from "abis/VaultStrategyYMY.json";
import VaultStrategyYLP from "abis/VaultStrategyYLP.json";
import VaultStrategyYMY_WFTM_OLD from "abis/VaultStrategyYMY_WFTM_OLD.json";
import VaultStrategyYMY_WFTM from "abis/VaultStrategyYMY_WFTM.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment, { now } from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useChainId } from "lib/chains";
import { FANTOM, OP } from "config/chains";
import { Trans } from "@lingui/react";
import RouterTab from "components/RouterTab";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";

export const YMY_VAULT = "YMY vault";
export const YLP_VAULT = "YLP vault";
export const YMY_WFTM_OLD_VAULT = "YMY_WFTM_OLD vault";
export const YMY_WFTM_VAULT = "YMY_WFTM vault";
export const YMY_WETH_VAULT = "YMY_WETH vault";

const TAB_ICONS = {
  [YMY_VAULT]: ymyIcon,
  [YLP_VAULT]: tokensIcon,
  [YMY_WFTM_OLD_VAULT]: YMY_FTM,
  [YMY_WFTM_VAULT]: YMY_FTM,
  [YMY_WETH_VAULT]: YMY_ETH,
};

const StyledTab = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 22.5px;
  background: #11121b;
  border-radius: 16px;
  width: fit-content;
  display: inline-flex;
  padding: 8px;

  @media (max-width: 768px) {
    width: 100%;

    display: flex !important;
    flex-wrap: wrap;

    height: fit-content;

    .Tab-option {
      width: 50%;

      padding: 8px !important;
    }
  }

  > div {
    height: 45px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    &:nth-child(2) {
      img {
        width: 36px;
      }
    }
  }
`;

export default function Vault(props) {
  const isMobile = useMedia("(max-width: 600px)");
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();

  const [activeTab, setActiveTab] = useState(chainId !== OP ? YMY_VAULT : YMY_WETH_VAULT);
  const TAB_OPTION_LABELS = {
    [YMY_VAULT]: `YMY`,
    [YLP_VAULT]: `YLP`,
    [YMY_WFTM_OLD_VAULT]: `YMY-WFTM V1`,
    [YMY_WFTM_VAULT]: `YMY-WFTM`,
    [YMY_WETH_VAULT]: `YMY-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsYMYWFTM, setStatsYMYWFTM] = useState({
    price: 0,
    apr: 10,
  });

  const fetchStatsYMYWFTM = async () => {
    const res = await axios.get(`https://api.yummy.fi/api/tokens/info?symbols=YMY/FTM&chain=FANTOM`, {});

    if (res && res.data) {
      const { price } = res.data["YMY/FTM"];

      setStatsYMYWFTM({
        ...statsYMYWFTM,
        price,
      });
    }
  };

  useEffect(() => {
    fetchStatsYMYWFTM();

    const interval = setInterval(async () => {
      fetchStatsYMYWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const TAB_OPTIONS = chainId !== OP ? [YMY_VAULT, YLP_VAULT] : [YMY_WETH_VAULT];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultYMYAddress = getContract(chainId, "VaultYMY");
  const vaultYLPAddress = getContract(chainId, "VaultYLP");

  const currentVaultAddress =
    activeTab === YMY_VAULT
      ? vaultYMYAddress
      : activeTab === YLP_VAULT
      ? vaultYLPAddress
      : activeTab === YMY_WFTM_VAULT;

  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const ymyAddress = getContract(chainId, "YMY");
  const esYmyAddress = getContract(chainId, "ES_YMY");
  const bnYmyAddress = getContract(chainId, "BN_YMY");
  const ylpAddress = getContract(chainId, "YLP");
  const usdyAddress = getContract(chainId, "USDY");

  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");
  const bonusYmyTrackerAddress = getContract(chainId, "BonusYmyTracker");
  const feeYmyTrackerAddress = getContract(chainId, "FeeYmyTracker");

  const stakedYlpTrackerAddress = getContract(chainId, "StakedYlpTracker");
  const feeYlpTrackerAddress = getContract(chainId, "FeeYlpTracker");

  const ylpManagerAddress = getContract(chainId, "YlpManager");

  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const ylpVesterAddress = getContract(chainId, "YlpVester");

  const vesterAddresses = [ymyVesterAddress, ylpVesterAddress];

  const walletTokens = [ymyAddress, esYmyAddress, ylpAddress, stakedYmyTrackerAddress];
  const depositTokens = [
    ymyAddress,
    esYmyAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    bnYmyAddress,
    ylpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedYmyTrackerAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    stakedYlpTrackerAddress,
    feeYlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesESYMY } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0xc55E86aF2F11e912a48e47f82a51eA782e082ed2" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesYLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x703Dc0F50fEB31F040F92158A2dd571DB1A472B2" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedYmySupply } = useSWR(
    [`StakeV2:stakedYmySupply:${active}`, chainId, ymyAddress, "balanceOf", stakedYmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  //YMY
  const { data: strategyAddressYMY } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultYMYAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestYMY } = useSWR(
    [`StakeV2:lastHarvestYMY:${active}`, chainId, strategyAddressYMY, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyYMY),
    }
  );
  const { data: balanceOfYMY } = useSWR(
    () => [`StakeV2:balanceOfYMY:${active}:${account}`, chainId, vaultYMYAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareYMY } = useSWR(
    () => [`StakeV2:getPricePerFullShareYMY:${active}:${account}`, chainId, vaultYMYAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyYMY } = useSWR([`StakeV2:totalSupplyYMY:${active}`, chainId, vaultYMYAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  //YLP
  const { data: strategyAddressYLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultYLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestYLP } = useSWR(
    [`StakeV2:lastHarvestYLP:${active}`, chainId, strategyAddressYLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyYLP),
    }
  );
  const { data: balanceOfYLP } = useSWR(
    [`StakeV2:balanceOfYLP:${active}:${account}`, chainId, vaultYLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareYLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareYLP:${active}:${account}`, chainId, vaultYLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyYLP } = useSWR([`StakeV2:totalSupplyYLP:${active}`, chainId, vaultYLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  // YMY_WFTM

  // const { data: strategyAddressYMY_WFTM } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultYMY_WFTMAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestYMY_WFTM } = useSWR(
  //   [`StakeV2:lastHarvestYMY:${active}`, chainId, strategyAddressYMY_WFTM, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyYMY_WFTM),
  //   }
  // );

  // YMY_WFTM_OLD

  // const { data: strategyAddressYMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultYMY_WFTM_OLDAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestYMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:lastHarvestYMY:${active}`, chainId, strategyAddressYMY_WFTM_OLD, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyYMY_WFTM),
  //   }
  // );

  const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { ymyPrice } = useYmyPrice(chainId, {}, active);

  let ymySupply;
  if (totalSupplies && totalSupplies[1]) {
    ymySupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);

  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESYMY = getDepositBalanceData(depositBalancesESYMY);
  const depositBalanceDataYLP = getDepositBalanceData(depositBalancesYLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const amountTokenDeposit =
    activeTab === YMY_VAULT
      ? formatAmountFree(balanceOfYMY, 18)
      : activeTab === YLP_VAULT
      ? formatAmountFree(balanceOfYLP, 18)
      : activeTab === YMY_WFTM_VAULT;
  const rateWithdraw =
    activeTab === YMY_VAULT
      ? formatAmountFree(pricePerFullShareYMY, 18)
      : activeTab === YLP_VAULT
      ? formatAmountFree(pricePerFullShareYLP, 18)
      : activeTab === YMY_WFTM_VAULT;
  const amountTVL =
    activeTab === YMY_VAULT
      ? formatAmount(totalSupplyYMY, 18)
      : activeTab === YLP_VAULT
      ? formatAmount(totalSupplyYLP, 18)
      : activeTab === YMY_WFTM_VAULT;
  const amountLastHarvest =
    activeTab === YMY_VAULT
      ? lastHarvestYMY
        ? moment(Number(lastHarvestYMY?.toString() || "") * 1000).fromNow()
        : "0"
      : activeTab === YLP_VAULT
      ? lastHarvestYLP
        ? moment(Number(lastHarvestYLP?.toString() || "") * 1000).fromNow()
        : "0"
      : // : lastHarvestYMY_WFTM ? moment(Number(lastHarvestYMY_WFTM?.toString() || "") * 1000).fromNow() : "0"; // 3 vault
      lastHarvestYLP
      ? moment(Number(lastHarvestYLP?.toString() || "") * 1000).fromNow()
      : "0"; // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 4080) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedYmySupply,
    ymyPrice,
    ymySupply
  );

  let nameToken = "ymyBalance";

  switch (activeTab) {
    case YMY_VAULT:
      nameToken = "ymyBalance";
      break;

    case YLP_VAULT:
      nameToken = "ylpBalance";
      break;

    case YMY_WFTM_OLD_VAULT:
      nameToken = "ymy_wftmBalance";
      break;

    case YMY_WFTM_VAULT:
      nameToken = "ymy_wftmBalance";
      break;

    case YMY_WETH_VAULT:
      nameToken = "ymy_wethBalance";
      break;

    default:
      nameToken = "ymyBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);

  if (processedData && vestingData && processedData?.ylpBalance) {
    if (activeTab === YLP_VAULT) {
      balance = formatAmountFree(processedData?.ylpBalance.sub(vestingData?.ylpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const priceToken =
    activeTab === YMY_VAULT
      ? Number(formatAmount(ymyPrice, USD_DECIMALS))
      : activeTab === YLP_VAULT
      ? Number(formatKeyAmount(processedData, "ylpPrice", USD_DECIMALS, 3, true))
      : statsYMYWFTM.price;

  const esYmyInStakedESYMY =
    depositBalanceDataESYMY &&
    ymyPrice &&
    depositBalanceDataESYMY["esYmyInStakedYmy"].mul(ymyPrice).div(expandDecimals(1, YLP_DECIMALS));

  const esYmyInStakedYLP =
    depositBalanceDataYLP &&
    ymyPrice &&
    depositBalanceDataYLP["esYmyInStakedYmy"].mul(ymyPrice).div(expandDecimals(1, YLP_DECIMALS));

  const aprFarm = Number(
    formatKeyAmount(
      processedData,
      activeTab === YMY_VAULT ? "ymyAprForNativeToken" : "ylpAprForNativeToken",
      2,
      2,
      true
    )
  );

  const aprFarmEsYMY = Number(formatKeyAmount(processedData, "ymyAprForNativeToken", 2, 2, true));

  const totalStakedWithOutEsYMY = Number(amountTVL * priceToken);
  const totalEsYMY = Number(formatAmount(activeTab === YMY_VAULT ? esYmyInStakedESYMY : esYmyInStakedYLP, 30, 2));

  const aprVault = aprFarm + aprFarmEsYMY * (totalEsYMY / totalStakedWithOutEsYMY);

  const apr = [YMY_WFTM_OLD_VAULT, YMY_WFTM_VAULT].includes(activeTab) ? statsYMYWFTM.apr : aprVault;

  const apy = !isNaN(formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2)
    : "--";

  const totalApr = [YMY_WFTM_OLD_VAULT, YMY_WFTM_VAULT].includes(activeTab)
    ? statsYMYWFTM.apr
    : formatKeyAmount(processedData, activeTab === YMY_VAULT ? "ymyAprTotal" : "ylpAprTotal", 2, 2, true); // 3 vault

  const totalApy = !isNaN(formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2)
    : "--";

  const [daily, setDaily] = useState("0.0000");
  const [dailyFTM, setDailyFTM] = useState("0.0000");
  const [dailyEsYMY, setDailyEsYMY] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(caculateAPYDaily(Number(totalApr), 1), 4));
      setDailyFTM(formatNumber(caculateAPYDaily(Number(apr), 1), 4));
      setDailyEsYMY(formatNumber(caculateAPYDaily(Number(totalApr), 1) - caculateAPYDaily(Number(apr), 1), 4));
    }
  }, [totalApy, totalApr]);

  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div>TVL</div>
            {[YMY_WFTM_OLD_VAULT, YMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">${priceToken === 0 ? "--" : formatNumber(amountTVL * priceToken, 2)}</span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken ? (
                    formatNumber(
                      amountTVL * priceToken +
                        Number(formatAmount(activeTab === YMY_VAULT ? esYmyInStakedESYMY : esYmyInStakedYLP, 30)),
                      2
                    )
                  ) : (
                    <div className="skeleton-box" />
                  )
                }`}
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === YMY_VAULT ? "YMY" : "YLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked esYMY`}
                        value={formatNumber(
                          Number(formatAmount(activeTab === YMY_VAULT ? esYmyInStakedESYMY : esYmyInStakedYLP, 30, 2)),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            <div>APY</div>
            {[YMY_WFTM_OLD_VAULT, YMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">{activeTab === YMY_WFTM_OLD_VAULT ? 0 : totalApy}%</span>
            ) : totalApy !== "--" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {totalApy}%
                  </span>
                }
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Escrowed YMY APY`}
                        value={`${formatNumber(totalApy - apy, 2)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow label={`FTM APY`} value={`${formatNumber(apy, 2)}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="Daily">
            <div>Daily</div>
            {[YMY_WFTM_OLD_VAULT, YMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="Daily-value">{activeTab === YMY_WFTM_OLD_VAULT ? 0 : daily}%</span>
            ) : daily !== "0.0000" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {daily}%
                  </span>
                }
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow label={`Daily Escrowed YMY`} value={`${dailyEsYMY}%`} showDollar={false} />
                      <StatsTooltipRow label={`Daily FTM`} value={`${dailyFTM}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <Wrapper className={`Vault default-container page-layout ${lightThemeClassName}`}>
      <div
        style={{
          maxWidth: "100%",
        }}
        className="section-title-block"
      >
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div
            style={{
              marginBottom: 8,
            }}
            className="Page-title"
          >
            <div>
              Vault <img alt="" src={chainId === FANTOM ? ftmIcon : chainId === OP ? opIcon : arbIcon} />
            </div>
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.6)",
            }}
            className="description"
          >
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </div>
        </div>
      </div>
      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />

      {activeTab === YMY_WFTM_OLD_VAULT && (
        <div className="old_vault">
          We have deployed a new contract to V2. Please move ALL of your LP Tokens to the new YMY-WFTM vault.
        </div>
      )}

      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "100px", height: "25px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}
      <Footer />
    </Wrapper>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  /* .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  } */
`;

const Wrapper = styled.div`
  max-width: unset;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    /* padding-bottom: 0 !important; */
    min-height: unset !important;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .old_vault {
    margin-bottom: 24px;

    padding: 12px 24px;
    gap: 10px;

    background: #E43E53;
    border-radius: 4px;
  }
  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

    & > .Tab.block {
      .Tab-option {
        color: var(--Text-Text_Primary, #0d1a16);

        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .section-title-content {
      .description {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }

    .Tab.block {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      border-radius: 16px;

      /* .Tab-option {
        &:hover,
        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
          color: var(--Text-Text_Primary, #0d1a16);
        }
      } */
    }

    ${StyledVaultInfoSection} {
      background-color: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
    }
  }
`;
