import React, { memo, useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import ModalWithPortal from "components/Modal/ModalWithPortal";
import { formatAmount, limitDecimals, numberFormat } from "lib/numbers";
import "./index.scss";
import { useAccount, useChains, useDeposit } from "@orderly.network/hooks";
import { AccountStatusEnum } from "@orderly.network/types";
import usdc from "img/ic_usdc.e_24.svg";
import { helperToast } from "lib/helperToast";
import { BigNumber } from "ethers";

const DepositModalOrderly = (props) => {
  const { isVisible, setIsVisible } = props;

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    account,
    state: { status },
  } = useAccount();

  const [chains] = useChains("mainnet");

  const token = useMemo(
    () => chains.find((item) => item.network_infos.chain_id === account.chainId)?.token_infos[0],
    [chains, account.chainId]
  );
  // console.log("?????", token);

  const {
    deposit,
    approve,
    allowance,
    setQuantity,
    balance: depositBalance,
    depositFee,
  } = useDeposit({
    address: token?.address,
    decimals: token?.decimals,
    srcToken: token?.symbol,
    srcChainId: Number(account.chainId),
  });
  const { account: accountOrderly, state } = useAccount();
  const [registering, setRegistering] = useState(false);
  const [creating, setCreating] = useState(false);
  useEffect(() => {
    if (!amount || Number(amount) <= 0) return;
    setQuantity(amount);
  }, [amount, setQuantity]);

  const balance = useMemo(
    () => (status >= AccountStatusEnum.Connected ? depositBalance : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, depositBalance, account.chainId]
  );
  const isRegistered = state.status >= AccountStatusEnum.SignedIn;
  const hasOrderlyKey = state.status >= AccountStatusEnum.EnableTrading;
  const onMax = () => {
    setAmount(depositBalance);
  };
  const registerAccount = async () => {
    try {
      setRegistering(true);
      await accountOrderly.createAccount();

      helperToast.success("Registration complete!");
    } catch (error) {
      helperToast.error("Registration failed!");
    } finally {
      setRegistering(false);
    }
  };

  const createKeyAccount = async () => {
    try {
      setCreating(true);
      await accountOrderly.createOrderlyKey(365);

      helperToast.success("Key registration complete!");
    } catch (error) {
      helperToast.error("Key registration failed!");
    } finally {
      setCreating(false);
    }
  };
  const onChangeAmount = (e) => {
    const value = e.target.value;

    setAmount(value);
  };

  //actions
  const handleApprove = async () => {
    try {
      setLoading(true);

      await approve(amount);
    } catch (error) {
      helperToast.error("Approval failed!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeposit = async () => {
    try {
      setLoading(true);
      await deposit();
      helperToast.success("Deposit successfully");
      setIsVisible(false);
    } catch (error) {
      helperToast.error("Deposit failed");
    } finally {
      setLoading(false);
    }
  };

  //validation
  const canDeposit =
    //amount > 0
    amount &&
    Number(amount) > 0 &&
    //exceeds balance
    Number(amount) <= balance &&
    //loading
    !loading;

  const getButtonContent = () => {
    if (loading) return "Depositing...";
    if (!amount || Number(amount) < 0) return "Enter an amount";
    if (Number(amount) > balance) return "Exceeds balance";

    return "Deposit";
  };

  return (
    <div className="modal-deposit-container">
      <ModalWithPortal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label="Orderly deposit"
        className={`hasDivider non-scrollable  Collateral-modal-deposit`}
      >
        <span className="Modal-description">
          Funds are deposited from your standard wallet into the Orderly balance to serve as collateral for trading with
          Orderly pool.
        </span>

        <ModalContent>
          <div className="Exchange-swap-section exchange-section-first">
            <div className="Exchange-swap-section-top">
              <div className="muted align-right clickable" onClick={onMax}>
                <span>Balance: {numberFormat(balance, 6)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={amount}
                  onChange={onChangeAmount}
                />
                <div className="Exchange-swap-max" onClick={onMax}>
                  <span>MAX</span>
                </div>
              </div>
              <div className="Exchange-swap-token">
                <img src={usdc} alt="USDC" />
                {token?.display_name}
              </div>
            </div>
          </div>
          <div className="unsettle-container">
            <div className="unsettle-label">Deposit fee:</div>
            <div>{depositFee ? formatAmount(BigNumber.from(depositFee), 18, 4, true) : "-"} BERA</div>
          </div>
        </ModalContent>
        {isRegistered && hasOrderlyKey ? (
          Number(allowance) < parseFloat(amount) ? (
            <button className="default-btn full" onClick={handleApprove} disabled={loading}>
              {loading ? "Approving..." : "Approve"}
            </button>
          ) : (
            <button className="default-btn full" disabled={!canDeposit} onClick={handleDeposit}>
              {getButtonContent()}
            </button>
          )
        ) : !isRegistered ? (
          <button className={`App-cta Exchange-swap-button`} onClick={registerAccount} disabled={registering}>
            {registering ? "Registering..." : "Register"}
          </button>
        ) : isRegistered && !hasOrderlyKey ? (
          <button className={`App-cta Exchange-swap-button`} onClick={createKeyAccount} disabled={creating}>
            {creating ? "Enabling..." : "Enable Trading"}
          </button>
        ) : null}
      </ModalWithPortal>
    </div>
  );
};

const ModalContent = styled.div`
  .Exchange-swap-section-top {
    grid-template-columns: auto;
  }

  /* .Exchange-swap-input-container {
    & > div {
      padding: 2px 12px;
      color: #080715;
      border-radius: 4px;
      z-index: 1;
      font-size: var(--font-sm);
      background: #1EB2FC;
      height: 24px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }
    }
  } */

  .Exchange-swap-token {
    font-weight: 700;
    line-height: 140%;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 12px;
  }
`;

export default memo(DepositModalOrderly);
