import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import styled from "styled-components";

import "./RouterTab.scss";

import Vest from "pages/Vest";

import ylp from "img/routerTab/ylp.svg";
import ymy_esymy from "img/routerTab/ymy_esymy.svg";
import overview from "img/routerTab/overview.svg";
import transfer_account from "img/routerTab/transfer_account.svg";
import yslp from "img/routerTab/yslp.svg";
import vest from "img/routerTab/vest.svg";
import ylpLight from "img/routerTab/ylpLight.svg";
import ymy_esymyLight from "img/routerTab/ymy_esymyLight.svg";
import overviewLight from "img/routerTab/overviewLight.svg";
import transfer_accountLight from "img/routerTab/transfer_accountLight.svg";
import yslpLight from "img/routerTab/yslpLight.svg";
import vestLight from "img/routerTab/vestLight.svg";

import YlpManager from "abis/YlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import Footer from "components/Footer/Footer";
import { getServerUrl } from "config/backend";
import { ARBITRUM, BASE, BERACHAIN_BARTIO, FANTOM, FANTOM_TESTNET, OP, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useYmyPrice, useTotalYmyStaked, useTotalEsYmyStaked } from "domain/legacy";
import { BigNumber, ethers } from "ethers";
import { contractFetcher } from "lib/contracts";
import {
  ESYMY_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  YMY_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import EarnYLP from "pages/Stake/EarnYLP";
import YMYandESYMY from "pages/Stake/YMYandESYMY";
import EarnOverview from "pages/Stake/Overview";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import bg from "img/earn/bg.svg";
import bgLight from "img/earn/bg-light.png";
import Yslp from "pages/Stake/Yslp";
import moment from "moment";
import useTokensPrice from "hooks/useTokensPrice";
import EarnOverviewV2 from "pages/Stake/OverviewV2";
import { useThemeContext } from "contexts/ThemeProvider";

const { AddressZero } = ethers.constants;
const EarnTabs = [
  {
    name: "Overview",
    params: null,
    img: overview,
    imgLight: overviewLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "YSLP",
    params: "yslp",
    img: yslp,
    imgLight: yslpLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
    newTag: true,
  },
  {
    name: "YMY & esYMY",
    params: "ymy-esymy",
    img: ymy_esymy,
    imgLight: ymy_esymyLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "YLP",
    params: "ylp",
    img: ylp,
    imgLight: ylpLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "Vest",
    params: "vest",
    img: vest,
    imgLight: vestLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE],
  },
  // {
  //   name: "Vault",
  //   params: "vault",
  //   img: vault,
  //   chains: [FANTOM, ARBITRUM] //TODO legacy
  // },
  {
    name: "Transfer account",
    params: "transfer-account",
    img: transfer_account,
    imgLight: transfer_accountLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
];

const now = moment.utc().valueOf() / 1000;

export default function RouterTab({ setPendingTxns, connectWallet }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  let match = useRouteMatch();
  const location = useLocation();
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const ymyAddress = getContract(chainId, "YMY");
  const esYmyAddress = getContract(chainId, "ES_YMY");
  const bnYmyAddress = getContract(chainId, "BN_YMY");
  const ylpAddress = getContract(chainId, "YLP");
  const yslpAddress = getContract(chainId, "YSLP");
  const usdyAddress = getContract(chainId, "USDY");

  const stakedYmyTrackerAddress = getContract(chainId, "StakedYmyTracker");
  const bonusYmyTrackerAddress = getContract(chainId, "BonusYmyTracker");
  const feeYmyTrackerAddress = getContract(chainId, "FeeYmyTracker");

  const stakedYlpTrackerAddress = getContract(chainId, "StakedYlpTracker");
  const feeYlpTrackerAddress = getContract(chainId, "FeeYlpTracker");

  const stakedYslpTrackerAddress = getContract(chainId, "StakedYslpTracker");
  const feeYslpTrackerAddress = getContract(chainId, "FeeYslpTracker");

  const ylpManagerAddress = getContract(chainId, "YlpManager");

  const stakedYmyDistributorAddress = getContract(chainId, "StakedYmyDistributor");
  const stakedYlpDistributorAddress = getContract(chainId, "StakedYlpDistributor");
  const stakedYslpDistributorAddress = getContract(chainId, "StakedYslpDistributor");

  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const ylpVesterAddress = getContract(chainId, "YlpVester");
  const yslpVesterAddress = getContract(chainId, "YslpVester");

  const vesterAddresses = [ymyVesterAddress, ylpVesterAddress, yslpVesterAddress];

  const excludedEsYmyAccounts = [stakedYmyDistributorAddress, stakedYlpDistributorAddress, stakedYslpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [ymyAddress, esYmyAddress, ylpAddress, stakedYmyTrackerAddress, yslpAddress];
  const depositTokens = [
    ymyAddress,
    esYmyAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    bnYmyAddress,
    ylpAddress,
    yslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedYmyTrackerAddress,
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYmyTrackerAddress,
    feeYlpTrackerAddress,
    feeYslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedYmyTrackerAddress,
    bonusYmyTrackerAddress,
    feeYmyTrackerAddress,
    stakedYlpTrackerAddress,
    feeYlpTrackerAddress,
    stakedYslpTrackerAddress,
    feeYslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedYmySupply } = useSWR(
    [
      `StakeV2:stakedYmySupply:${active}:${stakedYmyTrackerAddress}`,
      chainId,
      ymyAddress,
      "balanceOf",
      stakedYmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: esYmySupply } = useSWR(
    [
      `StakeV2:esYmySupply:${active}:${excludedEsYmyAccounts}:${esYmyAddress}`,
      chainId,
      readerAddress,
      "getTokenSupply",
      esYmyAddress,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsYmyAccounts]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress];

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  const { ymyPrice, ymyPriceFromFantom } = useYmyPrice(
    chainId,
    { arbitrum: chainId === FANTOM ? library : undefined },
    active
  );

  // const { totalYmySupply, totalSupply } = useYMYInfo();
  let totalYmySupply, totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalYmySupply = totalSupplies[1];
    totalSupply = totalSupplies[1];
  }
  // const ymyPrice = BigNumber.from("1000000000000000000000000000000")

  // let { total: totalYmySupply } = useTotalYmySupply();
  // let totalYmySupply = BigNumber.from("10000000000000000000000000");

  let { op: opYmyStaked, fantom: fantomYmyStaked, total: totalYmyStaked } = useTotalYmyStaked();
  let { op: opEsYmyStaked, fantom: fantomEsYmyStaked, total: totalEsYmyStaked } = useTotalEsYmyStaked();

  // const ymySupplyUrl = getServerUrl(chainId, "/ymy_supply");
  // const { data: ymySupply } = useSWR([ymySupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  let ymySupply = totalSupply;

  // const isYmyTransferEnabled = true;

  let esYmySupplyUsd;
  if (esYmySupply && ymyPrice) {
    esYmySupplyUsd = esYmySupply.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedYmySupply,
    ymyPrice,
    ymySupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusYmyTrackerRewards && processedData.bnYmyInFeeYmy) {
    multiplierPointsAmount = processedData.bonusYmyTrackerRewards.add(processedData.bnYmyInFeeYmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnYmyInFeeYmy && processedData.bonusYmyInFeeYmy) {
    totalRewardTokens = processedData.bnYmyInFeeYmy.add(processedData.bonusYmyInFeeYmy);
  }

  let totalRewardTokensAndYlp;
  if (totalRewardTokens && processedData && processedData.ylpBalance) {
    totalRewardTokensAndYlp = totalRewardTokens.add(processedData.ylpBalance);
  }

  const bonusYmyInFeeYmy = processedData ? processedData.bonusYmyInFeeYmy : undefined;

  let stakedYmySupplyUsd;
  if (!totalYmyStaked.isZero() && ymyPrice) {
    stakedYmySupplyUsd = totalYmyStaked.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let stakedEsYmySupplyUsd;
  if (!totalEsYmyStaked.isZero() && ymyPrice) {
    stakedEsYmySupplyUsd = totalEsYmyStaked.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalYmySupply && !totalYmySupply.isZero() && ymyPrice) {
    totalSupplyUsd = totalYmySupply.mul(ymyPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableYmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.ymyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusYmyInFeeYmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.ymyVesterPairAmount);
    const stakedTokens = processedData.bonusYmyInFeeYmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableYmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }
  let earnMsg;
  if (totalRewardTokensAndYlp && totalRewardTokensAndYlp.gt(0)) {
    let ymyAmountStr;
    if (processedData.ymyInStakedYmy && processedData.ymyInStakedYmy.gt(0)) {
      ymyAmountStr = formatAmount(processedData.ymyInStakedYmy, 18, 2, true) + " YMY";
    }
    let esYmyAmountStr;
    if (processedData.esYmyInStakedYmy && processedData.esYmyInStakedYmy.gt(0)) {
      esYmyAmountStr = formatAmount(processedData.esYmyInStakedYmy, 18, 2, true) + " esYMY";
    }
    let mpAmountStr;
    if (processedData.bonusYmyInFeeYmy && processedData.bnYmyInFeeYmy.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnYmyInFeeYmy, 18, 2, true) + " MP";
    }
    let ylpStr;
    if (processedData.ylpBalance && processedData.ylpBalance.gt(0)) {
      ylpStr = formatAmount(processedData.ylpBalance, 18, 2, true) + " YLP";
    }
    const amountStr = [ymyAmountStr, esYmyAmountStr, mpAmountStr, ylpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <span>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndYlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </span>
      </div>
    );
  }

  //YSLP only for FANTOM
  const rewardsPrice = useTokensPrice({
    ymyPrice: ymyPriceFromFantom,
    chainId,
  });

  return (
    <Wrapper
      style={
        isLightTheme
          ? { backgroundImage: `url(${bgLight})`, backgroundSize: "contain", backgroundRepeat: "no-repeat" }
          : {}
      }
      className={`RouterTab ${lightThemeClassName}`}
    >
      <div className="total-wrapper-content">
        <div className="router">
          {EarnTabs.filter((x) => x.chains.includes(chainId)).map((item) => (
            <div
              key={item.params}
              className={`earn-tab ${
                (item.params && location.pathname.includes(item.params)) ||
                (!item.params && location.pathname === "/earn-v2/")
                  ? "earn-tab-active"
                  : ""
              } ${item.newTag ? "new-tag" : ""}`}
            >
              <Link to={`/earn-v2/${item.params || ""}`}>
                <img src={isLightTheme ? item.imgLight : item.img} alt={item.params} />
                <span>{item.name}</span>
              </Link>
            </div>
          ))}
        </div>
        <Switch>
          <Route path={`/earn-v2/ymy-esymy`} exact>
            <YMYandESYMY
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              ymyPrice={ymyPrice}
              stakedYmySupplyUsd={stakedYmySupplyUsd}
              stakedEsYmySupplyUsd={stakedEsYmySupplyUsd}
              totalYmySupply={totalYmySupply}
              totalSupplyUsd={totalSupplyUsd}
              esYmySupplyUsd={esYmySupplyUsd}
              esYmySupply={esYmySupply}
              hasMultiplierPoints={hasMultiplierPoints}
              vestingData={vestingData}
              maxUnstakeableYmy={maxUnstakeableYmy}
              multiplierPointsAmount={multiplierPointsAmount}
              bonusYmyInFeeYmy={bonusYmyInFeeYmy}
            />
          </Route>
          <Route path={`/earn-v2/ylp`} exact>
            <EarnYLP setPendingTxns={setPendingTxns} connectWallet={connectWallet} processedData={processedData} />
          </Route>
          <Route path={`/earn-v2/vest`} exact>
            <Vest
              totalRewardTokens={totalRewardTokens}
              vestingData={vestingData}
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
            />
          </Route>
          <Route path={match.path} exact>
            {/* <EarnOverview
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
            /> */}
            <EarnOverviewV2
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
              totalYmySupply={totalYmySupply}
              stakedYmySupplyUsd={stakedYmySupplyUsd}
              totalSupplyUsd={totalSupplyUsd}
              stakedEsYmySupplyUsd={stakedEsYmySupplyUsd}
              esYmySupplyUsd={esYmySupplyUsd}
              esYmySupply={esYmySupply}
              rewardsPrice={rewardsPrice}
            />
          </Route>

          <Route path={`/earn-v2/yslp`} exact>
            <Yslp
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              // isBootstrapPhase={isBootstrapPhase}
              // endTimeBootstrapPhase={endTimeBootstrapPhase}
              rewardsPrice={rewardsPrice}
            />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  min-height: 900px;

  background-image: url(${bg});
  background-repeat: no-repeat;
  padding-bottom: 286px;
  background-size: contain;

  .earn-tab {
    opacity: 0.6 !important;

    a span {
      font-weight: 700;
    }

    &.new-tag {
      display: flex;
      align-items: center;
      gap: var(--base-unit-xs-8, 8px);
      position: relative;

      &::after {
        content: "New";
        display: flex;
        padding: 2px var(--Border_radius, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--base-unit-xs-8, 8px);
        border-radius: var(--Border_radius, 4px);
        border: 1px solid var(--Warning, #ffdf76);
        color: var(--Warning, #ffdf76);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%; /* 16.8px */

        @media screen and (max-width: 767px) {
          position: absolute;
          right: -0.953px;
          top: -4px;
          transform: rotate(29.671deg);
        }
      }
    }
  }
  .earn-tab-active {
    span {
      font-weight: 700;
    }
    opacity: 1 !important;
  }
  @media (max-width: 1024px) {
    /* min-height: 1200px; */
    background-size: initial;
    gap: 0;
  }

  @media (max-width: 600px) {
    gap: 0;
  }

  .total-wrapper-content {
    display: flex;

    gap: 24px;

    width: 1320px;
    /* height: fit-content; */

    /* padding: 0 24px; */

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 24px;
    }

    @media screen and (max-width: 767px) {
      gap: 40px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
    }

    .router {
      margin-top: 65px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 163px;

      a {
        cursor: pointer;
        display: flex;
        align-items: center;

        gap: 10px;

        font-weight: 700;
        font-size: 14px;

        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }

      .earn-tab-active {
        a {
          color: white !important;
        }
      }

      @media (max-width: 1023px) {
        margin-top: 64px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
        align-items: center;

        width: 100%;
        height: 95px;
        background: #191b2e;
        padding: 12px;
        margin-top: 0px;
        gap: unset;
        justify-content: space-between;
        .earn-tab {
          padding: 12px;
          opacity: 1 !important;
        }
        .earn-tab-active {
          span {
            font-weight: 700;
          }
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
        }
        div {
          flex: 1;
          a {
            display: flex;

            flex-direction: column;
            gap: 6px;
            opacity: 1;

            img {
              width: 32px;
              height: 32px;
            }

            font-weight: 500;
            font-size: 8px;

            color: #ffffff;

            &:nth-child(2) {
              span {
                max-width: 30px;
              }
            }

            span {
              max-width: 38px;

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 2048px) {
    height: 100%;
  }
`;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;
