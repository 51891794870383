import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDY_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
  importImage,
  TAKE_PROFIT,
  STOP_LOSS,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  limitDecimals,
  parseValue,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PercentageButtons from "./PercentageButtons";
import TakeProfitLongTriggerButton from "./TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "./TakeProfitShortTriggerButton";

const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function EditStopLoss(props) {
  const {
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    marketTokensInfo,
    onConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [fromValue, setFromValue] = useState("");
  const [triggerPriceInput, setTriggerPriceInput] = useState(position?.markPrice || "");
  const [closeQuantity, setCloseQuantity] = useState(0);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const { positionNetValue } = position;
  const handleSelectPercentageTriggerPrice = (percentage) => {
    setTriggerPriceInput(percentage);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, 30, false));
  };
  const size = position.size;
  const collateral = position.collateral;
  const quantity = position.posQty;
  useEffect(() => {
    // console.log("???", formatAmount(position.triggerPrice, 30, 30, false));
    setFromValue(formatAmount(position.triggerPrice, 30, 30, false));
    const percent = Number(position.trigger.amountPercent) / 100000;
    const closeQuantity = position.posQty.mul(parseValue(percent, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(closeQuantity, 30, 30, false));
  }, []);
  const handleTakeProfitLongPercent = (percentage) => {
    const value = position.markPrice.add(parseValue(percentage, 30).mul(position.markPrice).div(parseValue(1, 30)));
    setFromValue(formatAmount(value, 30, 6, false));
    // setTPSLTrigerPriceValue(value);
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const value = position.markPrice.sub(parseValue(percentage, 30).mul(position.markPrice).div(parseValue(1, 30)));
    setFromValue(formatAmount(value, 30, 6, false));
    // setTPSLTrigerPriceValue(value);
  };
  const newSize = closeQuantity ? parseValue(closeQuantity, 30).mul(position.markPrice).div(parseValue(1, 30)) : null;
  const pnlWithoutFee =
    newSize && fromValue
      ? position.isLong
        ? parseValue(fromValue, 30).sub(position.averagePrice).mul(newSize).div(position.averagePrice)
        : position.averagePrice.sub(parseValue(fromValue, 30)).mul(newSize).div(position.averagePrice)
      : null;
  const handleConfirm = () => {
    // debugger
    const orderId = position.trigger.id.split("-")[1];
    const _isTP = position.trigger.isTP;
    const percent =
      Number(formatAmount(parseValue(closeQuantity, 30).mul(parseValue(1, 30)).div(quantity), 30, 2, false)) * 100000;
    const price = parseValue(fromValue, 30);
    // console.log("?????", { position, orderId, _isTP, price, percent });
    onConfirm && onConfirm(position, orderId, _isTP, price, percent);
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className="PositionSeller-modal confirmation-modal"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Edit Take Profit"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="title">{position.symbol}</div>
            <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
              {position?.isLong ? "LONG" : "SHORT"}
            </div>
            <div className="side">{position?.positionType === "Market Order" ? "Market" : position?.positionType}</div>
          </div>
          <div className="content-container">
            <div className="Exchange-swap-section" style={{ marginBottom: "8px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Trigger Price
                    </span>
                  </div>
                </div>
                <div className="muted align-right clickable">
                  <span>Mark: ${limitDecimals(currentMarket.askPrice, 2)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={(e) => setFromValue(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {position.isLong ? (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(position.averagePrice, 30, 6, false))}
                value={fromValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(position.markPrice, 6, 30, false))}
                value={fromValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )}
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div className="muted align-right clickable">
                  <span>Max: {formatAmount(quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={(e) => setCloseQuantity(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(quantity, 30, 30, false))}
              value={closeQuantity}
            />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <div style={{ fontSize: 14 }}>Stop Loss</div>
                <ExchangeInfoRow label={`Trigger Price`}>
                  {fromValue ? (position.isLong ? "≤ " : "≥ ") : ""}$
                  {fromValue ? formatAmount(parseValue(fromValue, 30), 30, 2, true) : "--"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`SL Qty`}>
                  {" "}
                  {fromValue ? formatAmount(parseValue(closeQuantity, 30), 30, 4, true) : "--"} {position.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Est. PnL`} isPositive={pnlWithoutFee && pnlWithoutFee.gt(0)}>
                  {pnlWithoutFee ? (pnlWithoutFee.gt(0) ? "+" : "-") : ""}$
                  {pnlWithoutFee ? formatAmount(pnlWithoutFee, 30, 2, true).replace("-", "") : "--"}
                </ExchangeInfoRow>
                <div className="divider" />
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(position.averagePrice, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>${formatAmount(position.markPrice, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>${formatAmount(size, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={handleConfirm}>
              Edit Stop Loss
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
