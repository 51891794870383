import { useMemo, useState } from "react";
// import AddTPSLV1 from "components/ExchangeV2/AddTPSLV1";
import { commify } from "@ethersproject/units";
import AddTPSLOrderly from "components/ExchangeV2/AddTPSLOrderly";
import ClosePositionV3 from "components/ExchangeV2/ClosePositionV3";
import EditTPSLOrderly from "components/ExchangeV2/EditTPSLOrderly";
import PositionDetailsV3 from "components/ExchangeV2/PositionDetailsV3";
import PositionShareV3 from "components/ExchangeV2/PositionShareV3";
import { limitDecimals } from "lib/numbers";
import styled from "styled-components";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import PositionItemMobileV3 from "./PositionItemMobileV3";
import PositionItemV3 from "./PositionItemV3";
import { min } from "date-fns";

const PositionListMerged = ({ positions, loading, account, accountInfo, pairInfo, aggregated, chainId }) => {
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [page, setPage] = useState(1);
  // console.log("aggregated", aggregated);

  const [isDetailsPositionV3, setIsDetailsPositionV3] = useState(false);
  const [isPositionShareModalOpenV3, setIsPositionShareModalOpenV3] = useState(false);
  const [isAddTPSLV3, setIsAddTPSLV3] = useState(false);
  const [isClosePositionV3, setIsClosePositionV3] = useState(false);

  const handleCancelRelatedOrders = async (symbol, isLong) => {};

  const filterPositions = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  // V3 logic
  const handleSelectedPositionV3 = (position) => {
    setSelectedPosition(position);
    setIsDetailsPositionV3(true);
  };
  const handleSelectAddTPSLV3 = (position) => {
    setSelectedPosition(position);
    setIsAddTPSLV3(true);
  };
  const handleClosePositionV3 = (position) => {
    setSelectedPosition(position);
    setIsClosePositionV3(true);
  };

  ///-----------------------------------

  return (
    <>
      {account && (
        <ContainertWrap className="item-exchange">
          <ContentWrap style={{ minWidth: "100px" }}>
            <div className="muted fz-12">Unreal. PnL</div>
            <div
              className={`value  fz-12 ${
                aggregated?.unrealPnL > 0 ? "positive" : aggregated?.unrealPnL < 0 ? "negative" : ""
              }`}
            >
              <span>
                {aggregated?.unrealPnL ? commify(limitDecimals(aggregated?.unrealPnL, 2)) : "0.00"}{" "}
                <span style={{ fontSize: "12px", fontWeight: 500 }}>
                  (
                  {aggregated?.unrealPnL
                    ? commify(limitDecimals((aggregated?.unrealPnL || 0) * 100, 2).replace("-", "")) + "%"
                    : "0.00%"}
                  )
                </span>
              </span>
            </div>
          </ContentWrap>
          <ContentWrap>
            <div className="muted fz-12">Notional</div>
            <div className={`value fz-12`}>
              {aggregated?.notional ? commify(limitDecimals(aggregated?.notional, 2)) : "0.00"}
            </div>
          </ContentWrap>
        </ContainertWrap>
      )}
      <div className="PositionsList">
        {isClosePositionV3 && (
          <ClosePositionV3
            isVisible={isClosePositionV3}
            setIsVisible={setIsClosePositionV3}
            positions={positions}
            isConfirm={() => {}}
            account={account}
            selectedPosition={selectedPosition}
            handleCancelRelatedOrders={handleCancelRelatedOrders}
            feeTier={accountInfo?.futures_taker_fee_rate}
            pairInfo={pairInfo}
          />
        )}
        {isDetailsPositionV3 && (
          <PositionDetailsV3
            isVisible={isDetailsPositionV3}
            setIsVisible={setIsDetailsPositionV3}
            positions={positions}
            isConfirm={() => {}}
            account={account}
            selectedPosition={selectedPosition}
            handleCancelRelatedOrders={handleCancelRelatedOrders}
            feeTier={accountInfo?.futures_taker_fee_rate}
          />
        )}
        {/* {isAddTPSLV3 && (
          <AddTPSLV3
            isVisible={isAddTPSLV3}
            setIsVisible={setIsAddTPSLV3}
            positionsV3={positions}
            isConfirm={() => {}}
            account={account}
            selectedPosition={selectedPosition}
            pairInfo={pairInfo}
          />
        )} */}
        {isAddTPSLV3 && (
          <AddTPSLOrderly
            isVisible={isAddTPSLV3}
            setIsVisible={setIsAddTPSLV3}
            positionsV3={positions}
            isConfirm={() => {}}
            account={account}
            selectedPosition={selectedPosition}
            pairInfo={pairInfo}
          />
        )}
        <EditTPSLOrderly isVisible={false} setIsVisible={() => {}} />
        {isPositionShareModalOpenV3 && (
          <PositionShareV3
            setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
            isPositionShareModalOpen={isPositionShareModalOpenV3}
            selectedPosition={selectedPosition}
            chainId={chainId}
            account={account}
            positions={positions?.filter((x) => x.isV3)}
            aggregated={aggregated}
          />
        )}

        {loading && (
          <>
            <div className="skeleton-box" style={{ height: "56.19px" }} />
            <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
            <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          </>
        )}

        {!loading && positions && (
          <table className={`Exchange-list large App-box ${positions.length === 0 ? "no-array-length" : ""}`}>
            <tbody>
              {positions.length > 0 && (
                <tr className="Exchange-list-header">
                  <th>
                    <span>Symbol</span>
                  </th>
                  <th>
                    <span>Quantity</span>
                  </th>
                  <th>
                    <span>Mark Price</span>
                  </th>
                  <th>
                    <span>Avg. Open</span>
                  </th>
                  <th>
                    <span>Liq. Price</span>
                  </th>
                  <th>
                    <span>Unreal. PnL</span>
                  </th>
                  <th>
                    <span>Notional</span>
                  </th>
                  <th>
                    <span>Margin</span>
                  </th>
                  <th></th>
                </tr>
              )}

              {positions.length === 0 && !loading && (
                <tr className="no-position">
                  <td colSpan="15">
                    <div className="no-data-record">No open positions</div>
                  </td>
                </tr>
              )}

              {filterPositions.length > 0 &&
                filterPositions.map((position, index) => {
                  return (
                    <PositionItemV3
                      position={position}
                      key={index}
                      account={account}
                      handleSelectedPosition={handleSelectedPositionV3}
                      handleSelectAddTPSL={handleSelectAddTPSLV3}
                      handleSelectClosePosition={handleClosePositionV3}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
                      setSelectedPosition={setSelectedPosition}
                      aggregated={aggregated}
                    />
                  );
                })}
            </tbody>
          </table>
        )}
        {positions && !loading && (
          <div className="Exchange-list small">
            <div>
              {positions.length === 0 && loading && (
                <div className="Exchange-empty-positions-list-note App-card">
                  <div className="skeleton-box" style={{ height: "25px" }} />
                </div>
              )}
              {positions.length === 0 && <div className="no-data-record">No open positions</div>}
              {filterPositions.length > 0 &&
                filterPositions.map((position, index) => {
                  return (
                    <PositionItemMobileV3
                      position={position}
                      key={index}
                      account={account}
                      handleSelectedPosition={handleSelectedPositionV3}
                      handleSelectAddTPSL={handleSelectAddTPSLV3}
                      handleSelectClosePosition={handleClosePositionV3}
                      setIsPositionShareModalOpen={setIsPositionShareModalOpenV3}
                      setSelectedPosition={setSelectedPosition}
                      aggregated={aggregated}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
          <PaginationV2
            rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
            rows={positions.length}
            currentPage={page}
            setCurrentPage={setPage}
          />
        )}
      </div>
    </>
  );
};
export default PositionListMerged;
const ContainertWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 16px;
  @media screen and (min-width: 1356px) {
    margin-bottom: 32px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .value {
    font-size: 14px;
    font-weight: bold;
  }
`;
