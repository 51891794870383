import { Menu } from "@headlessui/react";
import cx from "classnames";
import triangleIcon from "img/triangle.svg";
import "./ChartTokenSelector.css";

import SelectTokenChart from "./SelectTokenChart";
export default function ChartTokenSelectorV3(props) {
  const { marketAsssetList, defaultChartToken, theme, onSelectSymbolToken } = props;

  const onSelect = async (token) => {
    onSelectSymbolToken(token?.symbol || token?.tokenSymbol);
  };
  const tokenImage = `https://oss.orderly.network/static/symbol_logo/${defaultChartToken?.split("/USD")[0]}.png`;
  return (
    <Menu>
      <Menu.Button as="div" disabled={false}>
        <button
          className={cx("App-cta small transparent chart-token-selector", {
            "default-cursor": false,
          })}
        >
          <div className="max-leverage-container">
            <img alt="" src={tokenImage} className="token-selected" />
            <span className="chart-token-selector--current">{defaultChartToken}</span>

            <img alt="" src={triangleIcon} className="arrow-chart" />
          </div>
        </button>
      </Menu.Button>
      <div className="chart-token-menu">
        <Menu.Items as="div" className="menu-items chart-token-menu-items">
          <SelectTokenChart tokens={marketAsssetList} onSelect={onSelect} current={defaultChartToken} theme={theme} />
        </Menu.Items>
      </div>
    </Menu>
  );
}
