import { useOrderStream } from "@orderly.network/hooks";
import { OrderStatus } from "@orderly.network/types";
import OrderEditorV3 from "components/Exchange/OrderEditorV3";
import EditTPSLV3 from "components/ExchangeV2/EditTPSLV3";
import { helperToast } from "lib/helperToast";
import { useMemo, useState } from "react";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "../../hooks/useUserPosition";
import PaginationV2 from "../../pages/AnalyticsV3/components/PaginationV2";
import OrderItemMobileV3 from "./OrderItemMobileV3";
import OrderItemV3 from "./OrderItemV3";
const OrderList = ({ orders, loading, positions, isShowConfirmModal, pairInfo, ordersV3 }) => {
  const [page, setPage] = useState(1);
  const [isEditTPSLV3ModalOpen, setIsEditTPSLV3ModalOpen] = useState(false);
  const [isEditOrderV3ModalOpen, setIsEditOrderV3ModalOpen] = useState(false);

  const [selectedPosition, setSelectedPosition] = useState(undefined);

  const [, { cancelOrder, cancelAlgoOrder, updateOrder, updateAlgoOrder }] = useOrderStream({
    status: OrderStatus.INCOMPLETE,
  });
  const filterOrders = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return orders?.slice(startIndex, endIndex) || [];
  }, [orders, page]);

  const handleCancelTriggerV3 = async (order) => {
    try {
      if (order.algo_order_id) {
        await cancelAlgoOrder(order.algo_order_id, order.symbol);
      } else {
        await cancelOrder(order.order_id, order.symbol);
      }
      helperToast.success(
        <div>
          Cancelled order successfully
          <br />
        </div>
      );
    } catch {
      helperToast.error(
        <div>
          Cancelled order failed
          <br />
        </div>
      );
    }
  };

  const handlEditTPSLV3 = (order, type) => {
    setSelectedPosition(order);
    if (type === "TP_SL") {
      setIsEditTPSLV3ModalOpen(true);
    } else setIsEditOrderV3ModalOpen(true);
  };

  return (
    <div className="PositionsList">
      {isEditOrderV3ModalOpen && (
        <OrderEditorV3
          isVisible={isEditOrderV3ModalOpen}
          setIsVisible={setIsEditOrderV3ModalOpen}
          positions={positions}
          pairInfo={pairInfo}
          selectedOrder={selectedPosition}
          updateAlgoOrder={updateAlgoOrder}
          updateOrder={updateOrder}
          orders={orders}
        />
      )}
      {isEditTPSLV3ModalOpen && (
        <EditTPSLV3
          isVisible={isEditTPSLV3ModalOpen}
          setIsVisible={setIsEditTPSLV3ModalOpen}
          positionsV3={positions}
          pairInfo={pairInfo}
          order={selectedPosition}
        />
      )}
      <table className={`Exchange-list large App-box ${orders.length === 0 ? "no-array-length" : ""}`}>
        <tbody>
          {filterOrders.length > 0 && (
            <tr className="Exchange-list-header">
              <th>
                <span>Symbol</span>
              </th>
              <th>
                <span>Filled/Quantity</span>
              </th>
              <th>
                <span>Price</span>
              </th>

              <th>
                <span>Trigger</span>
              </th>
              <th>
                <span>TP/SL</span>
              </th>
              <th>
                <span>Notional</span>
              </th>
              <th>
                <span>Reduce Only</span>
              </th>
              {/* <th>
                <span>Hidden</span>
              </th> */}
              <th></th>
            </tr>
          )}
          {orders.length === 0 && (
            <tr className="no-position">
              <td colSpan="15">
                <div className="no-data-record">No pending orders</div>
              </td>
            </tr>
          )}
          {filterOrders.length > 0 &&
            filterOrders.map((order, index) => {
              return (
                <OrderItemV3
                  key={index}
                  orders={orders}
                  order={order}
                  orderlyPosition={positions}
                  handleCancelTrigger={handleCancelTriggerV3}
                  isShowConfirmModal={isShowConfirmModal}
                  handlEditTPSL={handlEditTPSLV3}
                />
              );
            })}
        </tbody>
      </table>
      <div className="Exchange-list small">
        {positions.length === 0 && loading && (
          <div className="Exchange-empty-positions-list-note App-card">
            <div className="skeleton-box" style={{ height: "25px" }} />
          </div>
        )}
        {filterOrders.length === 0 && <div className="no-data-record">No open orders</div>}
        {filterOrders.length > 0 &&
          filterOrders.map((order, index) => {
            return (
              <OrderItemMobileV3
                key={index}
                orders={orders}
                order={order}
                orderlyPosition={positions}
                handleCancelTrigger={handleCancelTriggerV3}
                isShowConfirmModal={isShowConfirmModal}
                handlEditTPSL={handlEditTPSLV3}
              />
            );
          })}
      </div>
      {orders && orders.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={orders.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
};
export default OrderList;
