import Tab from "components/Tab/Tab";
import { formatAmount, parseValue } from "lib/numbers";
import { useMemo, useState } from "react";
import styled from "styled-components";

const ALL = "All markets";
const NEW = "New listings";

const OPTION = [ALL, NEW];
const OPTION_LABELS = {
  [ALL]: ALL,
  [NEW]: NEW,
};

const MarketsTab = ({ marketList }) => {
  const [option, setOption] = useState(ALL);

  const onOptionChange = (option) => {
    setOption(option);
  };
  const totalVolume24h = useMemo(() => {
    let total = 0;
    if (marketList.length > 0) {
      for (let i = 0; i < marketList.length; i++) {
        const market = marketList[i];
        total += market?.["24h_amount"];
      }
    }
    return total;
  }, [marketList]);
  const totalOpenInterest = useMemo(() => {
    let total = 0;
    if (marketList.length > 0) {
      for (let i = 0; i < marketList.length; i++) {
        const market = marketList[i];
        total += market?.["open_interest"];
      }
    }
    return total;
  }, [marketList]);
  return (
    <Wrapper>
      <div className="MarketTab-stats">
        <div className="MarketTab-statsTotal">
          <div className="MarketTab-statsTotalBox">
            24h Volume
            <span>${formatAmount(parseValue(totalVolume24h, 8), 8, 2, true)}</span>
          </div>
          <div className="MarketTab-statsTotalBox">
            Open Interest
            <span>${formatAmount(parseValue(totalOpenInterest, 8), 8, 2, true)}</span>
          </div>
          <div className="MarketTab-statsTotalBox">
            24h volume
            <span>$100,000</span>
          </div>
        </div>
        <div className="MarketTab-statsList">
          <span>New listings</span>
          <div className="MarketTab-statsListRows">
            {[...Array(5)].map((_, index) => (
              <div className="MarketTab-statsListRow" key={index}>
                <span>Wal</span>
                <span>$0.5555</span>
                <span>-0.11%</span>
              </div>
            ))}
          </div>
        </div>
        <div className="MarketTab-statsList">
          <span>New listings</span>
          <div className="MarketTab-statsListRows">
            {[...Array(5)].map((_, index) => (
              <div className="MarketTab-statsListRow" key={index}>
                <span>Wal</span>
                <span>$0.5555</span>
                <span>-0.11%</span>
              </div>
            ))}
          </div>
        </div>
        <div className="MarketTab-statsList">
          <span>New listings</span>
          <div className="MarketTab-statsListRows">
            {[...Array(5)].map((_, index) => (
              <div className="MarketTab-statsListRow" key={index}>
                <span>Wal</span>
                <span>$0.5555</span>
                <span>-0.11%</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="MarketTab-list">
        <div className="MarketTab-head">
          <Tab1 options={OPTION} option={option} optionLabels={OPTION_LABELS} onChange={onOptionChange} />
          <input placeholder="Search market" />
        </div>
        <div className="MarketTab-listHead">
          <span>Market</span>
          <span>Price</span>
          <span>24h change</span>
          <span>24h volume</span>
          <span>Open interest</span>
          <span>8h funding</span>
        </div>
        <div className="MarketTab-listBody">
          {[...Array(5)].map((_, index) => (
            <div className="MarketTab-listBodyRow" key={index}>
              <span>ETH-PERP</span>
              <span>$0.5555</span>
              <span>-0.11%</span>
              <span>$100,000</span>
              <span>$100,000</span>
              <span>$100,000</span>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* Desktop styles (default) */
  .MarketTab-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
  }

  .MarketTab-statsTotal {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: stretch;
    gap: 12px;
  }

  .MarketTab-statsTotalBox {
    background-color: #131519;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 12px;

    & > span {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .MarketTab-statsList {
    background-color: #131519;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    padding-block: 16px 8px;

    & > span {
      padding: 0 16px;
      font-weight: 500;
    }
  }

  .MarketTab-statsListRows {
    flex: 1;
  }

  .MarketTab-statsListRow {
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .MarketTab-list {
    margin-top: 12px;

    background-color: #131519;
    padding: 24px;
    border-radius: 24px;
  }

  .MarketTab-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      height: 32px;
      font-size: 14px;
      padding: 0 12px;
      border-radius: 4px;
      background-color: #2e3033;
      color: #fff;
      border: 1px solid #2e3033;
    }
  }

  .MarketTab-listHead {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 12px 16px;
  }

  .MarketTab-listBody {
  }

  .MarketTab-listBodyRow {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 12px 16px;
    border-top: 1px solid #2e3033;

    &:hover {
      background-color: #1a1c20;
      cursor: pointer;
    }
  }

  /* Tablet styles */
  @media (max-width: 1023px) {
    .MarketTab-stats {
    }

    .MarketTab-statsTotal {
    }

    .MarketTab-statsTotalBox {
    }

    .MarketTab-statsList {
    }

    .MarketTab-statsListRows {
    }

    .MarketTab-statsListRow {
    }

    .MarketTab-list {
    }

    .MarketTab-listHead {
    }

    .MarketTab-listBody {
    }

    .MarketTab-listBodyRow {
    }
  }

  /* Mobile styles */
  @media (max-width: 767px) {
    .MarketTab-stats {
    }

    .MarketTab-statsTotal {
    }

    .MarketTab-statsTotalBox {
    }

    .MarketTab-statsList {
    }

    .MarketTab-statsListRows {
    }

    .MarketTab-statsListRow {
    }

    .MarketTab-list {
    }

    .MarketTab-listHead {
    }

    .MarketTab-listBody {
    }

    .MarketTab-listBodyRow {
    }
  }
`;

const Tab1 = styled(Tab)`
  grid-template-columns: 1fr 1fr;
  height: 44px;
  text-align: center;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  border-radius: 4px;
  background: var(--natural-1, #2e3033);
  user-select: none;
  overflow: hidden;
  box-sizing: content-box;

  * {
    user-select: none;
  }

  > div {
    height: 44px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    border-radius: 10px;

    color: var(--Text-Text_Secondary, #919499);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }

  .active {
    border: 2px solid #41454d;
    color: var(--Text-Text_Secondary, #fff);
  }
`;

export default MarketsTab;
