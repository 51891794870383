import React, { useEffect, useMemo, useState } from "react";
import { t, Trans } from "@lingui/macro";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./SwapBox.css";
import { LEVERAGE_ORDER_OPTIONS_V2, STOP_MARKET, SWAP_OPTIONS_V2 } from "lib/legacy";
import cx from "classnames";
import useSWR from "swr";
import { BigNumber, ethers } from "ethers";
import { getTokenSymbolFromString } from "domain/tokens";
import styled from "styled-components";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  LIMIT,
  LONG,
  MARKET,
  SHORT,
  STOP,
  SWAP,
  USD_DECIMALS,
  LEVERAGE_MARKS,
  STOP_LIMIT,
} from "lib/legacy";
import { getContract } from "config/contracts";
import TransactionModal from "components/DepositModal";
import triangle_down from "img/trade/triangle_down.svg";
import Tab from "../Tab/Tab";
import ConfirmationBox from "./ConfirmationBox";
import SettingsManager from "abis/SettingsManager.json";

import longImg from "img/trade/long_active.svg";
import longLightImg from "img/trade/long-light.svg";
import shortLightImg from "img/trade/short-light.svg";
import shortImg from "img/trade/short_active.svg";

import longInactiveImg from "img/trade/long_inactive.svg";
import shortInactiveImg from "img/trade/short_inactive.svg";

import swapImg from "img/swap.svg";

import { useUserReferralCode, useUserReferralCodeV2 } from "domain/referrals";
import { contractFetcher } from "lib/contracts";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { TOKEN_SHOWS_DECIMALS, TRADE_V1_TOKENS } from "config/tokens";
import PercentageButtons from "./PercentageButtons";
import TakeProfitCard from "./TakeProfitCard";
import StopLossCard from "./StopLossCard";
import { preventSpecialCharacters } from "utils/helpers";
import Tooltip from "components/Tooltip/Tooltip";
import { useThemeContext } from "contexts/ThemeProvider";
import useSwitchChain from "../../hooks/useSwitchChain";
import { useMedia } from "react-use";
import { CRYPTO_POOL, ORDERLY_POOL, STABLE_POOL } from "pages/ExchangeMerged/ExchangeMerged";
import { Select } from "antd";
const abiDecoder = require("abi-decoder");
window.Buffer = window.Buffer || require("buffer").Buffer;

const { AddressZero } = ethers.constants;

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(1)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
var timeOutOpenPosition = null;
export default function SwapBoxV3(props) {
  const {
    active,
    library,
    account,
    swapOption,
    setSwapOption,
    setPendingTxns,
    tokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    savedSlippageAmount,
    userTokenBalances,
    marketTokenInfo,
    defaultChartToken,
    setSavedSlippageAmount,
    justOpenPositions,
    savedSelectedDexes,
    defaultPool,
    setDefaultPool,
  } = props;
  const theme = useThemeContext();
  const isMobile = useMedia("(max-width: 767px)");

  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const { userReferralCode, userReferralInviter } = useUserReferralCodeV2(chainId, account);
  const settingsManagerAddress = getContract(chainId, "SettingsManager");

  const chartSymbol = getTokenSymbolFromString(defaultChartToken) || "";
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [isStopLoss, setIsStopLoss] = useState(false);

  const [takeProfitTriggerPrice, setTakeProfitTriggerPrice] = useState("0");
  const [stopLossTriggerPrice, setStopLossTriggerPrice] = useState("0");
  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  const [takeProfitCloseQuantity, setTakeProfitCloseQuantity] = useState(100);
  const [stopLossCloseQuantity, setStopLossCloseQuantity] = useState(100);

  const [isShowStopLoss, setIsShowStopLoss] = useState(false);
  const [isShowTakeProfit, setIsShowTakeProfit] = useState(false);
  const [switchChain] = useSwitchChain(chainId);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const currentMarket = marketTokenInfo?.find((x) => x.symbol === defaultChartToken);

  const maxLeverage = currentMarket?.maxLeverage ? Number(currentMarket?.maxLeverage) / BASIS_POINTS_DIVISOR : 100;
  const { localStorageCodeOwner: code } = useUserReferralCode(library, chainId, account);
  // console.log("???", code);
  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;
  const assetId = currentMarket?.id;
  const SWAP_ICONS = {
    [LONG]: isLong ? (theme.isLightTheme ? longLightImg : longImg) : longInactiveImg,
    [SHORT]: isShort ? (theme.isLightTheme ? shortLightImg : shortImg) : shortInactiveImg,
    [SWAP]: swapImg,
  };
  const { data: tradingFee } = useSWR(
    assetId && [`Exchange:tradingFee:${[active, assetId, isLong]}`, chainId, settingsManagerAddress, "tradingFee"],
    {
      fetcher: contractFetcher(library, SettingsManager, [assetId, isLong]),
    }
  );

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Trade-v2-Exchange-swap-leverage-option"],
    "2"
  );
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, "trade-v2-Exchange-swap-leverage-slider-enabled"],
    true
  );

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Trade-v2-Order-option"], MARKET);
  // if (!flagOrdersEnabled) {
  //   orderOption = MARKET;
  // }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const onChangeLeverage = (value) => {
    if (value && value.includes(".")) {
      var arr = value.split(".");
      if (arr[1].length <= 1) setLeverageOption(value);
    } else {
      setLeverageOption(value);
    }
  };
  const isMarketOrder = orderOption === MARKET;
  const isLimitOrder = orderOption === LIMIT;
  const isStopLimitOrder = orderOption === STOP_LIMIT;
  const orderOptions = LEVERAGE_ORDER_OPTIONS_V2;
  const orderOptionLabels = {
    [STOP_MARKET]: `Stop Market`,
    [MARKET]: `Market`,
    [LIMIT]: `Limit`,
    [STOP_LIMIT]: "Stop Limit",
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [triggerStopPriceValue, setTriggerStopPriceValue] = useState("");
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };
  const onTriggerStopPriceChange = (evt) => {
    setTriggerStopPriceValue(evt.target.value || "");
  };

  const onTriggerRatioChange = (evt) => {
    setTriggerRatioValue(evt.target.value || "");
  };

  const getError = () => {
    switch (orderOption) {
      case LIMIT:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter limit price"];
        }
        break;
      case STOP_MARKET:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter stop price"];
        }
        break;
      case STOP_LIMIT:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter stop price"];
        }
        if (!triggerStopPriceValue || !Number(triggerStopPriceValue)) {
          return ["Enter limit price"];
        }
        break;
      default:
        break;
    }
  };

  const getMarketError = () => {
    return true;
  };

  const isPrimaryEnabled = () => {
    return true;
  };

  const getPrimaryText = () => {
    return `Short`;
  };

  const onFromValueChange = (e) => {
    setFromValue(e.target.value);
  };

  let referralCode = ethers.constants.HashZero;
  if (userReferralCode) {
    referralCode = userReferralCode;
  }

  const increasePosition = async () => {
    setIsSubmitting(true);
  };

  const handleDeposit = () => {
    setIsDepositModalVisible(true);
  };

  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === STOP) {
      setOrderOption(MARKET);
    }
    setFromValue("");
    setToValue("");
    setTriggerPriceValue("");
    // setTriggerRatioValue("");
  };

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }
    setIsPendingConfirmation(true);
    increasePosition();
  };

  const onClickPrimary = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const isStopOrder = orderOption === STOP_MARKET;
  const showFromAndToSection = true;
  const showTriggerPriceSection = !isSwap && !isMarketOrder;
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder;

  const handleSelectPercentage = (percentage) => {
    if (!userTokenBalances?.ysusdBalance || userTokenBalances?.ysusdBalance.eq(0)) {
      return;
    }
    const temptValue = userTokenBalances?.ysusdBalance.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    const tempFee = temptValue.mul(parseValue(leverageOption, 30)).mul(8).div(parseValue(10000, 30));
    if (temptValue.add(tempFee).lt(userTokenBalances?.ysusdBalance)) {
      setFromValue(formatAmount(temptValue, 30, 6, false));
    } else {
      const maxColateral = userTokenBalances?.ysusdBalance
        .mul(parseValue(1, 30))
        .div(parseValue(1 + (leverageOption * 8) / 10000, 30));
      setFromValue(formatAmount(maxColateral, 30, 6, false));
    }

    // setFromValue(formatAmount(userTokenBalances?.ysusdBalance.sub(tempFee), 30, 6, false));
  };
  const onSliderChange = (sliderValue) => {
    if (fromValue && fromValue !== 0) {
      const temptValue = parseValue(fromValue, 30);
      const tempFee = temptValue.mul(parseValue(sliderValue, 30)).mul(8).div(parseValue(10000, 30));
      if (temptValue.add(tempFee).gt(userTokenBalances?.ysusdBalance)) {
        const maxColateral = userTokenBalances?.ysusdBalance
          .mul(parseValue(1, 30))
          .div(parseValue(1 + (sliderValue * 8) / 10000, 30));
        setFromValue(formatAmount(maxColateral, 30, 6, false));
      }
    }
    setLeverageOption(sliderValue);
  };
  const SWAP_LABELS = {
    [LONG]: `Long`,
    [SHORT]: `Short`,
    [SWAP]: `SWAP`,
  };
  const { data: openInterestPerAssetPerSide } = useSWR(
    currentMarket?.id && [
      "ExChange:openInterestPerAssetPerSide",
      chainId,
      settingsManagerAddress,
      "openInterestPerAssetPerSide",
      currentMarket?.id,
      isLong,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxOpenInterestPerAssetPerSide } = useSWR(
    currentMarket?.id && [
      "ExChange:maxOpenInterestPerAssetPerSide",
      chainId,
      settingsManagerAddress,
      "maxOpenInterestPerAssetPerSide",
      currentMarket?.id,
      isLong,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1.1 || val > maxLeverage) {
      setLeverageOption(1.1);
      return;
    }
    setLeverageOption(target.value);
  };

  return (
    <div className={`Exchange-swap-box ${swapOption === LONG ? "box-trade-long" : "box-trade-short"}`}>
      <div className="swap-box-v2">
        <Tab
          icons={SWAP_ICONS}
          options={SWAP_OPTIONS_V2}
          optionLabels={SWAP_LABELS}
          option={swapOption}
          onChange={onSwapOptionChange}
          className={`Exchange-swap-option-tabs ${isLong ? "tab-active-long" : "tab-active-short"}`}
          isBoldFontWeight={true}
        />
      </div>
      <div
        className={`Exchange-swap-box-inner App-box-highlight box-trade-v2
        `}
      >
        <TextBox>
          <div className="pool-source-container">
            <div className="muted title">Liquidity Source</div>
            {TRADE_V1_TOKENS[chainId].includes(chartSymbol + "/USD") ? (
              <StyledSelect
                showArrow
                suffixIcon={<img className="triangle_down" src={triangle_down} alt="" />}
                onChange={(option) => {
                  setDefaultPool(option);
                }}
                defaultValue={defaultPool}
                placement="bottomRight"
                style={{
                  width: "fit-content",
                }}
                options={[
                  {
                    value: CRYPTO_POOL,
                    label: "Crypto pool",
                  },
                  {
                    value: STABLE_POOL,
                    label: "Stable pool",
                  },
                  {
                    value: ORDERLY_POOL,
                    label: "Orderly pool",
                  },
                ]}
                className="transparent-ant-select-selection-item"
              />
            ) : (
              <Select
                showArrow
                suffixIcon={<img className="triangle_down" src={triangle_down} alt="" />}
                onChange={(option) => {
                  setDefaultPool(option);
                }}
                defaultValue={STABLE_POOL}
                placement="bottomRight"
                style={{
                  width: "fit-content",
                }}
                options={[
                  {
                    value: ORDERLY_POOL,
                    label: "Orderly pool",
                  },
                ]}
                className="transparent-ant-select-selection-item"
              />
            )}
          </div>
          <div className="pool-source-container">
            <div className="muted title">Collateral Used</div>
            <div className="value">
              Exchange balance{" "}
              {account && (
                <button className="App-header-add-eth" onClick={handleDeposit}>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
                      fill="#000"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </TextBox>
        <div>
          {
            <Tab
              options={orderOptions}
              optionLabels={orderOptionLabels}
              className="Exchange-swap-order-type-tabs"
              type="inline"
              option={orderOption}
              onChange={onOrderOptionChange}
            />
          }
        </div>
        {showFromAndToSection && (
          <React.Fragment>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="">
                  <Tooltip
                    handle={`Collateral`}
                    position="left-top"
                    renderContent={() => {
                      return (
                        <span>
                          When you click on the 100% option, your max collateral amount will be estimated based on your
                          free collateral and Open pos. fees
                        </span>
                      );
                    }}
                  />
                </div>
                <div className="muted align-right clickable">
                  <span>
                    Orderly balance: $
                    {userTokenBalances?.ysusdBalance
                      ? formatAmount(userTokenBalances?.ysusdBalance, USD_DECIMALS, 2, true)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    autoFocus
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                    onKeyDown={preventSpecialCharacters}
                  />
                  <div className="usd-txt">USD</div>
                </div>
              </div>
            </div>
            {<span className="validate-error">{}</span>}
            <PercentageButtons
              onChangePercentage={handleSelectPercentage}
              balance={Number(formatAmount(userTokenBalances?.ysusdBalance || 0, USD_DECIMALS, 6, false)) || 0}
              value={Number(fromValue || 0)}
              decimals={6}
              fullBalance={userTokenBalances?.ysusdBalance}
              isUsd={true}
            />

            {showTriggerRatioSection && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>Price</span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input small"
                      value={triggerRatioValue}
                      onChange={onTriggerRatioChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                </div>
              </div>
            )}
            {showTriggerPriceSection && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{orderOption === LIMIT ? "Limit Price" : "Stop Price"}</span>
                  </div>
                  <div className="muted align-right clickable" onClick={() => {}}>
                    <span>Mark: ${formatAmount(0, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken], true)}</span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={triggerPriceValue}
                      onChange={onTriggerPriceChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                  <div className="PositionEditor-token-symbol">USD</div>
                </div>
              </div>
            )}
            {orderOption === STOP_LIMIT && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{"Limit Price"}</span>
                  </div>
                  <div
                    className="muted align-right clickable"
                    onClick={() => {
                      setTriggerStopPriceValue(
                        formatAmountFree(0, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken])
                      );
                    }}
                  >
                    <span>Mark: ${formatAmount(0, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken], true)}</span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={triggerStopPriceValue}
                      onChange={onTriggerStopPriceChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                  <div className="PositionEditor-token-symbol">USD</div>
                </div>
              </div>
            )}
            <div className="exchange-info-container">
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Pos. Size</Trans>
                </div>
                <div className="bold">{null ? "$" + formatAmount(0, 5, 3, true) : "-"}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Pos. Qty</Trans>
                </div>
                <div className="bold">
                  {null ? formatAmount(0, 5, 4, true) : "-"} {0 ? chartSymbol : ""}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Entry Price</Trans>
                </div>
                <div className="bold">${formatAmount(0, USD_DECIMALS, currentMarket?.decimals, true)}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Liq. Price</Trans>
                </div>
                <div className="bold">
                  {null ? "$" + formatAmount(0, USD_DECIMALS, currentMarket?.decimals, true) : "-"}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <div>Fees </div>
                </div>
                <div className="bold exchange-fee ">
                  {null ? "$" + formatAmount(0, 10, 3, true) : "-"}{" "}
                  {/* <span style={{ fontWeight: 400, color: "#807E7C" }}>
                    ({tradingFee ? formatAmount(tradingFee, 3) : "0"}%)
                  </span>{" "} */}
                  <Tooltip
                    handle={``}
                    position={isMobile ? "right-top" : "left-top"}
                    className="fit-content excep"
                    renderContent={() => {
                      return (
                        <span>
                          Position Fee (0.08% of position size):{" "}
                          <span style={{ color: "#fff", fontWeight: 700 }}>
                            {" "}
                            {null ? "$" + formatAmount(0, 10, 3, true) : "-"}{" "}
                          </span>
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              {/* <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <div>Fee Discount (--%)</div>
                </div>

                <div className="align-right bold">~$--</div>
              </div> */}
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Spread</Trans>
                </div>
                <div className="bold">{null ? formatAmount(0, 30, 3, true) : "--"}%</div>
              </div>
            </div>
            <div className="divider" />
          </React.Fragment>
        )}
        <div className="Exchange-leverage-box">
          <div className="bold">Leverage slider</div>
          {isLeverageSliderEnabled && (
            <div className="Exchange-leverage-slider-container">
              <div className="percentahe-value">
                {/* <span>{leverageOption}</span> */}
                <input
                  className="percentahe-input"
                  value={leverageOption}
                  type="number"
                  onChange={({ target }) => onChangeLeverage(target.value)}
                  onBlur={handleBlurLeverage}
                  onKeyDown={preventSpecialCharacters}
                />
                <span>x</span>
              </div>
              <div
                className={cx("Exchange-leverage-slider", "App-slider", {
                  // positive: isLong,
                  // negative: isShort,
                  normalSlider: true,
                })}
              >
                <Slider
                  min={1.0}
                  max={maxLeverage}
                  step={0.5}
                  marks={LEVERAGE_MARKS(chainId, maxLeverage)}
                  handle={leverageSliderHandle}
                  onChange={(value) => onSliderChange(value)}
                  defaultValue={leverageOption}
                  value={leverageOption}
                />
              </div>
            </div>
          )}
          <div className="divider" />
          <TakeProfitCard
            isLong={isLong}
            marketSymbol={chartSymbol}
            marketPrice={BigNumber.from(1)}
            posQty={BigNumber.from(1)}
            onActiveTakeProfit={(val) => setIsTakeProfit(val)}
            onChaneTriggerPrice={(val) => setTakeProfitTriggerPrice(val)}
            onChangeCloseQuantity={(val) => setTakeProfitCloseQuantity(val)}
            show={isShowTakeProfit}
            setShow={setIsShowTakeProfit}
            takeProfitTriggerError={null}
          />
          <div className="divider" />
          <StopLossCard
            isLong={isLong}
            marketSymbol={chartSymbol}
            marketPrice={BigNumber.from(1)}
            posQty={BigNumber.from(1)}
            liquidPrice={BigNumber.from(1)}
            onActiveStopLoss={(val) => setIsStopLoss(val)}
            onChaneTriggerPrice={(val) => setStopLossTriggerPrice(val)}
            onChangeCloseQuantity={(val) => setStopLossCloseQuantity(val)}
            triggerPrice={stopLossTriggerPrice}
            setTriggerPrices={setStopLossTriggerPrice}
            closeQuantity={stopLossCloseQuantity}
            setCloseQuantity={setStopLossCloseQuantity}
            show={isShowStopLoss}
            setShow={setIsShowStopLoss}
            stopLossTriggerError={"null"}
          />
          <div className="divider" />
          <div className="Exchange-swap-button-container">
            {/* {chainId === FANTOM ? (
              <button
                className={`App-cta Exchange-swap-button ${isShort ? "short-btn-v2" : "default-btn"}`}
                onClick={onClickPrimary}
                disabled={
                  !isPrimaryEnabled() ||
                  (active &&
                    ((isStopLoss && !!stopLossTriggerError) ||
                      (isTakeProfit && !!takeProfitTriggerError) ||
                      !!getErrorCollateral() ||
                      !Number(fromValue)))
                }
              >
                {getPrimaryText()}
              </button>
            ) : (
              <button
                className={`App-cta Exchange-swap-button default-btn`}
                onClick={() => switchNetwork(FANTOM, active)}
              >
                Switch to FANTOM
              </button>
            )} */}
            <button
              className={`App-cta Exchange-swap-button ${isShort ? "short-btn-v2" : "long-btn-v2"}`}
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isLong={isLong}
          collateral={fromValue}
          orderOption={orderOption}
          posSize={BigNumber.from(1)}
          posQty={BigNumber.from(1)}
          markPrice={BigNumber.from(1)}
          openPosFee={BigNumber.from(1)}
          liqPrice={BigNumber.from(1)}
          feeDiscount={BigNumber.from(1)}
          onConfirmationClick={onConfirmationClick}
          spread={BigNumber.from(1)}
          setIsConfirming={setIsConfirming}
          chainId={chainId}
          leverage={leverageOption}
          isPendingConfirmation={isPendingConfirmation}
          isMarketOrder={isMarketOrder}
          isSubmitting={isSubmitting}
          isTakeProfit={isTakeProfit}
          isStopLoss={isStopLoss}
          takeProfitTriggerPrice={takeProfitTriggerPrice}
          stopLossTriggerPrice={stopLossTriggerPrice}
          savedSlippageAmount={savedSlippageAmount}
          setSavedSlippageAmount={setSavedSlippageAmount}
          chartSymbol={chartSymbol}
          isLimitOrder={isLimitOrder}
          limitPrice={isStopOrder || isStopLimitOrder ? triggerStopPriceValue : triggerPriceValue}
          isStopOrder={isStopOrder}
          stopPrice={triggerPriceValue}
          isStopLimitOrder={isStopLimitOrder}
          currentMarket={currentMarket}
        />
      )}
      <TransactionModal
        isVisible={isDepositModalVisible}
        setIsVisible={setIsDepositModalVisible}
        library={library}
        chainId={chainId}
        savedSlippageAmount={savedSlippageAmount}
        savedSelectedDexes={savedSelectedDexes}
      ></TransactionModal>
    </div>
  );
}

const TextBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  span {
    font-weight: 500;
    color: #999794;
    margin-right: 4px;
  }
  .txt-deposit {
    color: #7ee55c;
    cursor: pointer;
  }
  .txt-collateral {
    display: flex;
  }
  margin-bottom: 16px;
`;
const StyledSelect = styled(Select)`
  margin-top: 2px;
  border: none !important;
  .ant-select-selector {
    border: none !important;
  }
`;
