import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import { ethers } from "ethers";
import ChartPadLight from "img/chart-pad-light.svg";
import ChartPad from "img/icons/chart-pad.svg";
import ArrowUp from "img/icons/icon-up.svg";
import { CLOSED_POSITION, LONG, mergelistAssetsVersions } from "lib/legacy";
import { forwardRef, useEffect, useMemo, useState } from "react";

import Checkbox from "components/Checkbox/Checkbox";
import Tab from "components/Tab/Tab";

import { useThemeContext } from "contexts/ThemeProvider";
import { useChainId } from "lib/chains";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, formatNumber, limitDecimals, parseValue } from "lib/numbers";
import { useMediaQuery } from "react-responsive";
import "./Exchange.css";
import "./Exchange.scss";

import {
  MarketsType,
  useAccountInfo,
  useCollateral,
  useMarkets,
  useMarketTradeStream,
  useOrderStream,
  usePositionStream,
  usePrivateQuery,
  useQuery,
} from "@orderly.network/hooks";
import { OrderStatus } from "@orderly.network/types";
import ExchangeTVChartV3 from "components/ExchangeV2/ExchangeTVChartV3";
import SwapBoxWithOrderly from "components/ExchangeV2/SwapBoxWithOrderly";
import ClosedPositionList from "components/TradeMerged/ClosedPositionList";
import OrderList from "components/TradeMerged/OrderList";
import PositionListMerged from "components/TradeMerged/PositionList";
import TradeActivityList from "components/TradeMerged/TradeActivityList";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import Tooltip from "components/Tooltip/Tooltip";
import { renderRisk } from "lib/helper";
import classNames from "classnames";
export const CRYPTO_POOL = "crypto-pool";
export const STABLE_POOL = "stable-pool";
export const ORDERLY_POOL = "stable-pool";

export const ExchangeOrderly = forwardRef((props, ref) => {
  const {
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    setTradeVersionState,
  } = props;
  const theme = useThemeContext();
  const [showChart, setShowChart] = useState(false);

  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const [mobilePosition, setMobilePosition] = useState();
  const [defaultChartToken, setDefaultChartToken] = useLocalStorageSerializeKey(
    [chainId, "default-orderly-token"],
    "BTC/USDC"
  );
  const [isShowConfirmModal, setIsShowConfirmModal] = useLocalStorageSerializeKey("orderly-show-confirm-modal", true);

  const [chooseOrdeBookPrice, setChooseOrderBookPrice] = useState(null);
  const isPadScreen = useMediaQuery({ query: "(max-width: 1350px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 700px)" });

  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "orderly-Swap-option", LONG);
  const [isConfirming, setIsConfirming] = useState(false);
  const [orderlyMarkets] = useMarkets(MarketsType.ALL);
  const order = ["BTC/USDC", "ETH/USDC", "BERA/USDC", "S/USDC"];
  const mergeList = useMemo(() => {
    let list = [];
    const listV3 =
      orderlyMarkets?.map((i) => ({
        ...i,
        tokenSymbol: i.symbol?.split("_")?.[1],
        symbol: i.symbol?.split("_")?.[1] + "/USDC",
        askPrice: parseValue(i?.mark_price || 0, 30),
        decimals: i?.quote_dp,
        price24HChanged: (i?.["24h_close"] - i?.["24h_open"]) / i?.["24h_open"] * 100,
      })) || [];
    list = mergelistAssetsVersions([], [], listV3);
    const priorityTokens = list.filter((item) => order.includes(item.symbol));
    const otherTokens = list.filter((item) => !order.includes(item.symbol));
    priorityTokens.sort((a, b) => {
      return order.indexOf(a.symbol) - order.indexOf(b.symbol);
    });
    const sortedList = [...priorityTokens, ...otherTokens];
    return sortedList;
  }, [orderlyMarkets]);
  // console.log("????", orderlyMarkets);

  // const leaderboard = usePrivateQuery(
  //   "/v1/volume/broker/daily?page=1&size=500&aggregateBy=ACCOUNT&sort=descending_perp_volume&start_date=2025-01-01&end_date=2025-03-31"
  // );

  const [orderlyPosition] = usePositionStream();
  const { data: accountInfo } = useAccountInfo();

  const pairInfo = useQuery("/v1/public/info");
  const [ordersOrderLyData] = useOrderStream({
    status: OrderStatus.INCOMPLETE,
  });
  const ordersOrderLy = useMemo(() => {
    if (ordersOrderLyData) return [...ordersOrderLyData];
    return [];
  }, [ordersOrderLyData]);
  const { data: orderlyClosedPositionData } = usePrivateQuery("/v1/position_history?limit=1000");
  const { data: orderlyTradeActivitiesData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=FILLED"
  );
  const { data: orderlyTradeActivitiesCancelData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=CANCELLED"
  );
  const token = useMemo(() => {
    return defaultChartToken?.split("/USD")?.[0];
  }, [defaultChartToken]);

  const orderlySymbol = useMemo(() => {
    if (token) return `PERP_${token}_USDC`;
    return null;
  }, [token]);
  const { data: trades } = useMarketTradeStream(orderlySymbol, { limit: 30 });
  const { freeCollateral } = useCollateral();
  const orderlyClosedPosition = useMemo(() => {
    if (orderlyClosedPositionData && orderlyClosedPositionData.length > 0) {
      return orderlyClosedPositionData?.map((item) => ({
        ...item,
        closedAt: Math.floor(item.close_timestamp / 1000),
        isV3: true,
      }));
    }
    return [];
  }, [orderlyClosedPositionData]);
  const orderlyTradeActivities = useMemo(() => {
    if (
      (orderlyTradeActivitiesData && orderlyTradeActivitiesData.length > 0) ||
      (orderlyTradeActivitiesCancelData && orderlyTradeActivitiesCancelData.length > 0)
    ) {
      return [
        ...(orderlyTradeActivitiesData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.updated_time / 1000),
        })) || []),
        ...(orderlyTradeActivitiesCancelData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.updated_time / 1000),
        })) || []),
      ].sort((a, b) => b.updated_time - a.updated_time);;
    }
    return [];
  }, [orderlyTradeActivitiesData, orderlyTradeActivitiesCancelData]);
  const tradeActivityMerged = useMemo(() => {
    return [...orderlyTradeActivities];
  }, [orderlyTradeActivities]);
  // console.log("????", tradeActivityMerged);

  const mergedPosition = useMemo(() => {
    return [
      ...(orderlyPosition?.rows?.map((i) => ({ ...i, isV3: true, createdAt: Math.floor(i.timestamp / 1000) })) || []),
    ];
  }, [orderlyPosition?.rows]);

  const mergedOrders = useMemo(() => {
    return [
      ...ordersOrderLy?.map((i) => ({
        ...i,
        isV3: true,
        createdAt: Math.floor(i.created_time / 1000),
        timestamp: i.timestamp,
      })),
    ];
  }, [ordersOrderLy]);

  const mergedClosePositions = useMemo(() => {
    return [...orderlyClosedPosition];
  }, [orderlyClosedPosition]);

  const [isProChart, setIsProChart] = useState(false);

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trade";

  const LIST_SECTIONS = [POSITIONS, ORDERS, CLOSED_POSITION, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-trade", LIST_SECTIONS[0]);
  const [posOrders, setPosOrders] = useState();
  const [orderPos, setOrderPos] = useState();
  useEffect(() => {
    if (listSection !== ORDERS) {
      setPosOrders(undefined);
    }
  }, [listSection]);
  useEffect(() => {
    if (listSection !== POSITIONS) {
      setOrderPos(undefined);
    }
  }, [listSection]);
  const LIST_SECTIONS_LABELS = {
    [ORDERS]: mergedOrders.length
      ? `Pending Orders <span class="number-tag">${mergedOrders.length}</span>`
      : `Pending Orders`,
    [POSITIONS]: mergedPosition.length
      ? `Positions <span class="number-tag">${mergedPosition.length}</span>`
      : `Positions`,
    [CLOSED_POSITION]: `Position History`,
    [TRADES]: `Order History`,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  const riskRate = useMemo(() => {
    if (
      orderlyPosition?.aggregated &&
      orderlyPosition?.aggregated?.margin_ratio &&
      orderlyPosition?.aggregated?.maintenance_margin_ratio
    ) {
      return orderlyPosition?.aggregated.maintenance_margin_ratio / orderlyPosition?.aggregated.margin_ratio;
    }
    return 0;
  }, [orderlyPosition?.aggregated]);
  const getListSection = () => {
    return (
      <div>
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          {/* <div className="align-right Exchange-should-show-position-lines">
            {!isProChart ? (
              <Checkbox
                isChecked={savedShouldShowPositionLines}
                setIsChecked={setSavedShouldShowPositionLines}
                className={cx("chart-positions", { active: savedShouldShowPositionLines })}
              >
                <div className="group-actions">
                  <span>Chart positions</span>
                </div>
              </Checkbox>
            ) : null}
          </div> */}
        </div>

        <div className="group-switch-mobile" onClick={() => setIsProChart(!isProChart)}>
          <div
            style={{
              color: !isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Basic
          </div>
          <span>/</span>
          <div
            style={{
              color: isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Pro
          </div>
        </div>

        {listSection === POSITIONS && (
          <PositionListMerged
            positions={mergedPosition}
            loading={false}
            chainId={chainId}
            library={library}
            account={account}
            active={active}
            accountInfo={accountInfo}
            aggregated={orderlyPosition?.aggregated}
            pairInfo={pairInfo?.data}
          />
        )}
        {listSection === ORDERS && (
          <OrderList
            account={account}
            orders={mergedOrders}
            loading={false}
            library={library}
            positions={mergedPosition}
            pairInfo={pairInfo?.data}
          />
        )}
        {listSection === CLOSED_POSITION && (
          <ClosedPositionList account={account} positions={mergedClosePositions} loading={false} chainId={chainId} />
        )}
        {listSection === TRADES && (
          <TradeActivityList positions={tradeActivityMerged} loading={false} chainId={chainId} />
        )}
      </div>
    );
  };

  return (
    <div className="exchange-v2">
      <div className="Exchange page-layout Exchange-updated-ui exchange-v1 trade-v1">
        <div className={`Exchange-content ${showChart ? "chart-pad-show-content" : ""}`}>
          <div className={classNames("Exchange-left", { hideCondition: !!mobilePosition })}>
            <ExchangeTVChartV3
              mobilePosition={mobilePosition}
              setMobilePosition={setMobilePosition}
              setSwapOption={setSwapOption}
              isProChart={isProChart}
              swapOption={swapOption}
              chainId={chainId}
              positions={mergedPosition}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              defaultChartToken={defaultChartToken}
              setDefaultChartToken={setDefaultChartToken}
              theme={theme}
              setTradeVersionState={setTradeVersionState}
              orderlyMarkets={orderlyMarkets}
              mergeList={mergeList}
              pairs={pairInfo?.data}
              setChooseOrderBookPrice={setChooseOrderBookPrice}
              ordersV3={ordersOrderLy}
              orders={mergedOrders}
              onSelectSymbolToken={(token) => setDefaultChartToken(token)}
              trades={trades}
            />
            <div className="Exchange-lists large">{getListSection()}</div>
          </div>
          <div className={classNames("Exchange-right", { hideCondition: !mobilePosition })}>
            <SwapBoxWithOrderly
              chainId={chainId}
              active={active}
              connectWallet={connectWallet}
              library={library}
              account={account}
              swapOption={swapOption}
              defaultChartToken={defaultChartToken}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              mergeList={mergeList}
              pairInfo={pairInfo?.data}
              aggregated={orderlyPosition?.aggregated}
              isShowConfirmModal={isShowConfirmModal}
              setIsShowConfirmModal={setIsShowConfirmModal}
              chooseOrdeBookPrice={chooseOrdeBookPrice}
              setChooseOrderBookPrice={setChooseOrderBookPrice}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
              ordersV3={mergedOrders}
              trades={trades}
            />
            <div className="summary-container">
              <div className="bold title fz-12">Margin Summary</div>
              <ExchangeInfoRow
                label={
                  <Tooltip
                    className="tooltip-s fz-12"
                    renderContent={() => {
                      return (
                        <div className="tooltip-content">
                          <div>The Risk rate is used to assess the risk level of an account.</div>
                          <div>
                            When the Risk rate reaches 100%, the account will be liquidated Risk rate = Maintenance
                            margin ratio / Margin ratio * 100%
                          </div>
                        </div>
                      );
                    }}
                    popupStyled={{ width: "100px" }}
                    position={"left-top"}
                    handle="Risk Rate"
                  />
                }
              >
                {renderRisk(parseValue(riskRate || 0, 30))}
              </ExchangeInfoRow>
              <ExchangeInfoRow
                label={
                  <Tooltip
                    className="tooltip-s fz-12"
                    renderContent={() => {
                      return (
                        <div className="tooltip-content">
                          <div>
                            The margin ratio represents the proportion of collateral relative to the total position
                            value.
                          </div>

                          <div>Account margin ratio = (Total collateral value / Total position notional) * 100%</div>
                        </div>
                      );
                    }}
                    popupStyled={{ width: "100px" }}
                    position={"left-top"}
                    handle="Margin Ratio"
                  />
                }
              >
                {orderlyPosition?.aggregated?.margin_ratio
                  ? formatNumber(limitDecimals(orderlyPosition?.aggregated?.margin_ratio * 100, 2))
                  : "-"}
                %
              </ExchangeInfoRow>
              <ExchangeInfoRow
                label={
                  <Tooltip
                    className="tooltip-s fz-12"
                    renderContent={() => {
                      return (
                        <div className="tooltip-content">
                          <div>
                            The minimum margin ratio required to protect your positions from being liquidated. If the
                            Margin ratio falls below the Maintenance margin ratio, the account will be liquidated.
                          </div>

                          <div>
                            Account maintenance margin ratio = Sum(Position notional * Symbol maintenance Margin Ratio)
                            / Total position notional * 100%
                          </div>
                        </div>
                      );
                    }}
                    popupStyled={{ width: "100px" }}
                    position={"left-top"}
                    handle="Maintenance Margin"
                  />
                }
              >
                {orderlyPosition?.aggregated?.maintenance_margin_ratio
                  ? formatNumber(limitDecimals(orderlyPosition?.aggregated?.maintenance_margin_ratio * 100, 2))
                  : "-"}
                %
              </ExchangeInfoRow>
              {/* <ExchangeInfoRow
                label={
                  <Tooltip
                    className="tooltip-s"
                    renderContent={() => {
                      return (
                        <div className="tooltip-content">
                          <div>Free collateral for placing new orders.</div>
                          <div>
                            Free collateral = Total balance + Total unsettlement PnL - Total position initial margin
                          </div>
                        </div>
                      );
                    }}
                    popupStyled={{ width: "100px" }}
                    position={"left-top"}
                    handle="Free Collateral"
                  />
                }
              >
                {freeCollateral > 0 ? formatAmount(parseValue(freeCollateral || 0, 18), 18, 2, true) : "0.00"} USDC
              </ExchangeInfoRow> */}
            </div>
          </div>
          <div className={classNames("Exchange-lists small", { hideCondition: !mobilePosition })}>
            {getListSection()}
          </div>
        </div>
        {false && (
          <div className={`pad-chart-container ${showChart ? "chart-pad-open" : ""}`}>
            <div className="show-ui-content">
              <div className="index-chart">
                <img src={theme?.isLightTheme ? ChartPadLight : ChartPad} alt="" />{" "}
                {isMobileScreen ? "Chart" : "Index chart"}
              </div>
              <div className="chart-tick-container">
                {!isProChart && showChart ? (
                  <Checkbox
                    isChecked={savedShouldShowPositionLines}
                    setIsChecked={setSavedShouldShowPositionLines}
                    className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
                  >
                    <div className="group-actions">
                      <span>Chart positions</span>
                    </div>
                  </Checkbox>
                ) : null}
                <div className="view-chart" onClick={() => setShowChart(!showChart)}>
                  {showChart ? "Hide" : "Show"} chart
                  <img style={showChart ? { transform: "rotate(180deg)" } : {}} src={ArrowUp} alt="" />
                </div>
              </div>
            </div>
            <div style={!showChart ? { display: "none" } : {}}></div>
          </div>
        )}

        {/* <Footer /> */}
      </div>
    </div>
  );
});
