import styled from "styled-components";

import FinancialBear from "img/home/Financial.png";
import { IconFinancial, ImageBgParttern } from "assets";

const Financial = () => {
  return (
    <Wrapper>
      <h1>
        Yummy Empowers Financial
        <br />
        Inclusion For All
      </h1>

      <div className="Financial-body">
        <div className="Financial-bodyItem Financial-bodyLeft">
          <div className="icon-left">
            <IconFinancial />
          </div>
          <div className="Financial-bodyBox">
            <span>Flexible Margin Modes</span>
            Trade your way with support for both Cross and Isolated Margin. Maximize capital efficiency across positions
            or manage risk with isolated margin — the choice is yours.
          </div>
          <div className="Financial-bodyBox">
            <span>Hybrid Liquidity Engine</span>
            Combines onchain pool-based liquidity with access to institutional-grade orderbooks for deep, reliable
            execution and low slippage in any market condition.
          </div>
        </div>
        <img src={FinancialBear} className="image-logo" alt="Financial" />
        <div className="Financial-bodyItem Financial-bodyRight">
          <div className="icon-right">
            <IconFinancial />
          </div>
          <div className="Financial-bodyBox">
            <span>Performance with Transparency</span>
            CEX-like execution speed and tight spreads, powered by cutting-edge infrastructure — while maintaining full
            onchain transparency and self-custody.
          </div>
          <div className="Financial-bodyBox">
            <span>Berachain Synergy</span>
            Built natively on Berachain to leverage its gasless transactions, staking rewards, and ecosystem-wide
            composability — delivering an optimized DeFi trading experience.
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 24px;
  margin: 0 auto;
  background-image: url(${ImageBgParttern});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    padding: 48px 16px;
  }

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
  }

  .Financial-body {
    display: flex;
    align-items: center;
    align-self: stretch;
    max-width: 1368px;
    margin: 0 auto;

    & > img {
      width: 400px;
      height: 426px;
    }
  }

  .Financial-bodyItem {
    position: relative;
    display: flex;
    height: 348px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;

    @media screen and (max-width: 1023px) {
      flex-direction: row;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: 100%;
    }
  }

  .Financial-bodyLeft {
    text-align: right;

    .Financial-bodyBox {
      background: linear-gradient(270deg, rgba(1, 84, 128, 0.3) 0%, rgba(1, 84, 128, 0.06) 100%);
      align-items: flex-end;

      @media screen and (max-width: 1023px) {
        align-items: flex-start;
        text-align: left;
      }

      &::before {
        border-left: none;
        right: 0;
        filter: drop-shadow(-2px 0px 8px rgba(30, 223, 236, 0.5));
      }
    }
  }

  .Financial-bodyRight {
    .Financial-bodyBox {
      &::before {
        border-right: none;
        left: 0;
        filter: drop-shadow(2px 0px 8px rgba(30, 223, 236, 0.5));
      }
    }
  }

  .Financial-bodyBox {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;

    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    background: linear-gradient(270deg, rgba(1, 84, 128, 0.06) 0%, rgba(1, 84, 128, 0.3) 100%);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 8px;
      border: 4px solid #1edfec;
    }

    span {
      color: #1ac3cf;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
  }

  @media screen and (max-width: 1023px) {
    h1 {
    }

    .Financial-body {
      flex-direction: column;

      & > img {
        width: 225px;
        height: 240px;
      }
    }

    .Financial-bodyItem {
    }

    .Financial-bodyLeft {
      .Financial-bodyBox {
        background: linear-gradient(270deg, rgba(1, 84, 128, 0.06) 0%, rgba(1, 84, 128, 0.3) 100%);

        &::before {
          border-right: none;
          border-left: 4px solid #1edfec;
          left: 0;
          filter: drop-shadow(2px 0px 8px rgba(30, 223, 236, 0.5));
        }
      }
    }

    .Financial-bodyBox {
    }

    .Financial-bodyBox {
    }
  }

  .icon-left {
    position: absolute;
    top: 46%;
    right: -103px;
    transform: translateY(-50%);
    transform: rotate(-90deg);

    @media screen and (max-width: 1023px) {
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -26px;
      transform: translateY(0);
      transform: rotate(0deg);
      width: fit-content;
      height: fit-content;
      top: unset;
    }
  }

  .icon-right {
    position: absolute;
    top: 46%;
    left: -103px;
    transform: translateY(-50%);
    transform: rotate(90deg);

    @media screen and (max-width: 1023px) {
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -26px;
      transform: translateY(0);
      transform: rotate(180deg);
      width: fit-content;
      height: fit-content;
      bottom: unset;
    }
  }

  .image-logo {
    transform: translateY(4%);
  }
`;

export default Financial;
