import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { createContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import moment from "moment";
import { getServerUrl, getServerUrlV2 } from "config/backend";
import { ARBITRUM, BASE, BERACHAIN_BARTIO, FANTOM, FANTOM_LEGACY, OP } from "config/chains";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { useYMYInfo, useYmyPrice, useTotalYmyStaked } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  ACTIVE_CHAIN_IDS,
  ACTIVE_CHAIN_IDS_V2,
  YLP_DECIMALS,
  YMY_DECIMALS,
  USD_DECIMALS,
  arrayURLFetcher,
  getStatsInfo,
  getStatsInfoV2,
} from "lib/legacy";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";
import { gql } from "@apollo/client";
import YlpManager from "abis/YlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";
import { isEmpty } from "lodash";
import { getYmyGraphClient } from "lib/subgraph";
const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { data: dataStatsAllV2 } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrlV2(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { ymyPrice } = useYmyPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );
  const { ymyPrice: ymyPriceFromBARTIO } = useYMYInfo(chainId, library, active);
  const currentStats = getStatsInfo(dataStatsAll);
  const currentStatsV2 = getStatsInfoV2(dataStatsAllV2);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const ylpManagerAddress = getContract(chainId, "YlpManager");
  // const ylpManagerAddressFANTOM = getContract(FANTOM, "YlpManager");
  // const ylpManagerAddressOP = getContract(OP, "YlpManager");
  // const ylpManagerAddressARB = getContract(ARBITRUM, "YlpManager");
  // const ylpManagerAddressBase = getContract(BASE, "YlpManager");
  const ylpManagerAddressBARTIO = getContract(BERACHAIN_BARTIO, "YlpManager");

  const readerAddress = getContract(chainId, "Reader");
  const ymyAddress = getContract(chainId, "YMY");
  const ylpAddress = getContract(chainId, "YLP");
  const usdyAddress = getContract(chainId, "USDY");
  const ylpVesterAddress = getContract(chainId, "YlpVester");
  const ymyVesterAddress = getContract(chainId, "YmyVester");
  const tokensForSupplyQuery = [ymyAddress, ylpAddress, usdyAddress];

  const { data: aums } = useSWR([`Dashboard:getAums`, chainId, ylpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, YlpManager),
  });
  const utcToday = moment().utc().startOf("day").unix();
  const { data: DailyStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => `https://api.yummy.fi/stats/bartio/daily-stats?from=${utcToday}&to=${utcToday}`),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );
  const [aumsAllChain, setAumsAllChain] = useState({});

  useEffect(() => {
    const query = gql(` {
      ylpStats(where: {id_gte: "${utcToday}"}) {
        aumInUsdy
      }
    }`);

    (async () => {
      const notIncludeLegacy = ACTIVE_CHAIN_IDS.filter((chainId) => chainId !== FANTOM_LEGACY);

      const data = await Promise.all(notIncludeLegacy.map((chainId) => getYmyGraphClient(chainId)?.query({ query })));

      const result = {};

      notIncludeLegacy.forEach((chainId, index) => {
        result[chainId] = data[index].data.ylpStats.length ? data[index].data.ylpStats[0].aumInUsdy : undefined;
      });

      setAumsAllChain(result);
    })();
  }, []);

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let adjustedUsdySupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdyAmount) {
      adjustedUsdySupply = adjustedUsdySupply.add(tokenInfo.usdyAmount);
    }
  }

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 30000,
    }
  );

  const { data: ymySupplyOfYmyVester } = useSWR(
    [`Dashboard:ymySupplyOfYmyVester:${active}`, chainId, ymyAddress, "balanceOf", ymyVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: ymySupplyOfYlpVester } = useSWR(
    [`Dashboard:ymySupplyOfYlpVester:${active}`, chainId, ymyAddress, "balanceOf", ylpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const {
    total: totalYmyStaked,
    // fantom: fantomYmyStaked,
    // fantomlegacy: fantomYmyStakedLegacy,
    // op: opYmyStaked,
    // oplegacy: opYmyStakedLegacy,
    // arbitrum: arbitrumStakedYmy,
    // base: baseStakedYmy,
    bartio: bartioYmyStaked,
  } = useTotalYmyStaked();
  let currentChainYmyStaked = bartioYmyStaked;
  // if (chainId === OP) currentChainYmyStaked = opYmyStaked;
  // if (chainId === ARBITRUM) currentChainYmyStaked = arbitrumStakedYmy;
  // if (chainId === BASE) currentChainYmyStaked = baseStakedYmy;
  if (chainId === BERACHAIN_BARTIO) currentChainYmyStaked = bartioYmyStaked;

  const { data: aumsBARTIO } = useSWR(
    [`Dashboard:getAumsBARTIO`, BERACHAIN_BARTIO, ylpManagerAddressBARTIO, "getAums"],
    {
      fetcher: contractFetcher(library, YlpManager),
      refreshInterval: 30000,
    }
  );

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, ylpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, ylpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 30000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR(
  //   [`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"],
  //   {
  //     fetcher: contractFetcher(library, YlpManager),
  //     refreshInterval: 30000,
  //   }
  // );

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, ylpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsBase } = useSWR([`Dashboard:getAumsBase`, BASE, ylpManagerAddressBase, "getAums"], {
  //   fetcher: contractFetcher(library, YlpManager),
  //   refreshInterval: 30000,
  // });

  let aumBARTIO;
  if (aumsBARTIO && aumsBARTIO.length > 0) {
    aumBARTIO = aumsBARTIO[0].add(aumsBARTIO[1]).div(2);
  }

  let ylpPrice;
  let ylpSupply;
  let ylpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    ylpSupply = totalSupplies[3];
    if (ymySupplyOfYmyVester && ymySupplyOfYlpVester) {
      ylpSupply = ylpSupply.sub(ymySupplyOfYmyVester).sub(ymySupplyOfYlpVester);
    }

    ylpPrice =
      aum && aum.gt(0) && ylpSupply.gt(0)
        ? aum.mul(expandDecimals(1, YLP_DECIMALS)).div(ylpSupply)
        : expandDecimals(1, USD_DECIMALS);
    ylpMarketCap = ylpPrice.mul(ylpSupply).div(expandDecimals(1, YLP_DECIMALS));
  }

  let tvl;
  if (currentChainYmyStaked && aum && ymyPrice) {
    tvl = ymyPrice.mul(currentChainYmyStaked).div(expandDecimals(1, YMY_DECIMALS)).add(aum);
  }

  const [bartioTvl, v2bartioTvl] = useMemo(() => {
    let bartioLogValue;
    if (bartioYmyStaked && aumBARTIO && ymyPriceFromBARTIO) {
      // bartioLogValue = ymyPriceFromBARTIO.mul(bartioYmyStaked).div(expandDecimals(1, YMY_DECIMALS)).add(aumBARTIO);
      bartioLogValue = parseValue(DailyStatsAll?.[0]?.[0]?.ymyStakedValue || 0, 30).add(
        parseValue(aumsAllChain[BERACHAIN_BARTIO], 12) || BigNumber.from(0)
      );
    }

    let v2bartioTvl = BigNumber.from(0);

    if (currentStatsV2?.[BERACHAIN_BARTIO]?.yslpTvl) {
      v2bartioTvl = parseValue(currentStatsV2?.[BERACHAIN_BARTIO]?.yslpTvl || 0, USD_DECIMALS);
    }

    return [
      // fantomLogValue,
      // fantomLegacyLogValue,
      // opLogValue,
      // arbLogValue,
      // baseLogValue,
      bartioLogValue,
      v2bartioTvl,
    ];
  }, [bartioYmyStaked, aumBARTIO, ymyPriceFromBARTIO, currentStatsV2?.nlpTvl]);

  const totalTvl = useMemo(() => {
    let totalLogVal;
    if (bartioTvl) {
      totalLogVal = bartioTvl;
    }
    return totalLogVal;
  }, [bartioTvl]);

  const totalTvlAll = useMemo(() => {
    let totalLogValAll;
    if (totalTvl && v2bartioTvl) {
      totalLogValAll = totalTvl.add(v2bartioTvl);
    }
    return totalLogValAll;
  }, [totalTvl, v2bartioTvl]);

  // let totalTvl;
  // if (fantomTvl && fantomLegacyTvl && opTvl && arbTvl && baseTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(baseTvl);
  // }

  const totalAllVersion = useMemo(() => {
    if (!isEmpty(currentStats) && !isEmpty(currentStatsV2)) {
      return {
        totalTradingVolume: currentStats?.totalVolume?.add(parseValue(currentStatsV2?.volume || 0, USD_DECIMALS)),
        totalFees: currentStats?.totalFees?.add(parseValue(currentStatsV2?.fees || 0, USD_DECIMALS)),
        totalFeeSince: currentStats?.feeSinceToNow?.add(parseValue(currentStatsV2?.feeSince || 0, USD_DECIMALS)),
        longOpenInterest: currentStats?.longOpenInterest?.add(
          parseValue(currentStatsV2?.longAmountInterest || 0, USD_DECIMALS)
        ),
        shortOpenInterest: currentStats?.shortOpenInterest?.add(
          parseValue(currentStatsV2?.shortAmountInterest || 0, USD_DECIMALS)
        ),
        totalUsers: currentStats?.totalUser?.add(parseValue(currentStatsV2?.users || 0, 0)),
      };
    }
    return {};
  }, [currentStats, currentStatsV2]);
  // console.log("v2bartioTvl", v2bartioTvl, bartioTvl);
  const valueProvider = useMemo(() => {
    return {
      ylpPrice,
      ymyPrice,
      totalValueLocked: {
        total: totalTvl,
        totalAll: totalTvlAll,
        totalV2: v2bartioTvl,
        [BERACHAIN_BARTIO]: bartioTvl,
        v2: {
          [BERACHAIN_BARTIO]: v2bartioTvl,
        },
      },
      totalFees: {
        total: currentStats.totalFees,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalFees,
        // [FANTOM]: currentStats[FANTOM]?.totalFees,
        // [OP]: currentStats[OP]?.totalFees,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalFees,
        [BERACHAIN_BARTIO]: currentStats[BERACHAIN_BARTIO]?.totalFees,
      },
      totalUsers: {
        total: currentStats.totalUser,
        // [FANTOM]: currentStats[FANTOM]?.totalUser,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalUser,
        // [OP]: currentStats[OP]?.totalUser,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalUser,
        [BERACHAIN_BARTIO]: currentStats[BERACHAIN_BARTIO]?.totalUser,
      },
      totalTradingVolume: {
        total: currentStats.totalVolume,
        // [FANTOM]: currentStats[FANTOM]?.totalVolume,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalVolume,
        // [OP]: currentStats[OP]?.totalVolume,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalVolume,
        [BERACHAIN_BARTIO]: currentStats[BERACHAIN_BARTIO]?.totalVolume,
      },
      longOpenInterest: {
        total: currentStats.longOpenInterest,
        // [FANTOM]: currentStats[FANTOM]?.longOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.longOpenInterest,
        // [OP]: currentStats[OP]?.longOpenInterest,
        // [ARBITRUM]: currentStats[ARBITRUM]?.longOpenInterest,
        [BERACHAIN_BARTIO]: currentStats[BERACHAIN_BARTIO]?.longOpenInterest,
      },
      shortOpenInterest: {
        total: currentStats.shortOpenInterest,
        [BERACHAIN_BARTIO]: currentStats[BERACHAIN_BARTIO]?.shortOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.shortOpenInterest,
        // [OP]: currentStats[OP]?.shortOpenInterest,
        // [ARBITRUM]: currentStats[ARBITRUM]?.shortOpenInterest,
        // [BASE]: currentStats[BASE]?.shortOpenInterest,
      },
      feeSinceToNow: {
        total: currentStats.feeSinceToNow,
        [BERACHAIN_BARTIO]: {
          value: currentStats[BERACHAIN_BARTIO]?.feeSinceToNow,
          timestamps: currentStats[BERACHAIN_BARTIO]?.feeSinceTimestamp,
        },
        // [FANTOM_LEGACY]: {
        //   value: currentStats[FANTOM_LEGACY]?.feeSinceToNow,
        //   timestamps: currentStats[FANTOM_LEGACY]?.feeSinceTimestamp,
        // },
        // [OP]: {
        //   value: currentStats[OP]?.feeSinceToNow, //TODO
        //   timestamps: currentStats[OP]?.feeSinceTimestamp,
        // },
        // [ARBITRUM]: {
        //   value: currentStats[ARBITRUM]?.feeSinceToNow,
        //   timestamps: currentStats[ARBITRUM]?.feeSinceTimestamp,
        // },
        // [BASE]: {
        //   value: currentStats[BASE]?.feeSinceToNow,
        //   timestamps: currentStats[BASE]?.feeSinceTimestamp,
        // },
      },
      v2: currentStatsV2,
      totalAllVersion,
    };
  }, [
    ylpPrice,
    ymyPrice,
    totalTvl,
    totalTvlAll,
    v2bartioTvl,
    bartioTvl,
    currentStats,
    currentStatsV2,
    totalAllVersion,
  ]);

  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
