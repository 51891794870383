import { useEffect, useState } from "react";
import axios from "axios";

export const DEFAULT_SORT = "pnl DESC";

function useOrderlyOpenInterest(chainId, options = {}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getContests() {
      try {
        const {data: response} = await axios.get(
          "https://api.orderly.org/v1/public/market_info/traders_open_interests",
          options
        );
        // console.log("?????", response?.data?.rows);

        setData(response?.data?.rows || []);
      } catch (error) {
      } finally {
      }
    }

    getContests();
    const timerId = setInterval(getContests, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [chainId]);

  return {
    data,
  };
}

export default useOrderlyOpenInterest;
