import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import { CLOSED_POSITION } from "lib/legacy";
import { cloneElement, forwardRef, useMemo, useState } from "react";
import styled from "styled-components";
import { useChainId } from "lib/chains";
import { useLocalStorageByChainId } from "lib/localStorage";
import "./Exchange.css";
import "./Exchange.scss";
import { useAccountInfo, useOrderStream, usePositionStream, usePrivateQuery, useQuery } from "@orderly.network/hooks";
import { OrderStatus } from "@orderly.network/types";
import ClosedPositionList from "components/TradeMerged/ClosedPositionList";
import OrderList from "components/TradeMerged/OrderList";
import PositionListMerged from "components/TradeMerged/PositionList";
import TradeActivityList from "components/TradeMerged/TradeActivityList";

export const ExchangeMergePortfolio = forwardRef((props, ref) => {
  const { active, library, account } = useWeb3React();
  // const account = "0x1B99b649e7b154C6F0efDCaf280F87269F9D4D04"; //FIX ME
  const { chainId } = useChainId();

  const flagOrdersEnabled = true;

  const [orderlyPosition] = usePositionStream();
  const pairInfo = useQuery("/v1/public/info");
  const { data: accountInfo } = useAccountInfo();

  const { data: orderlyClosedPositionData } = usePrivateQuery("/v1/position_history?limit=1000");

  const [ordersOrderLyData] = useOrderStream({
    status: OrderStatus.INCOMPLETE,
  });
  const ordersOrderLy = useMemo(() => {
    if (ordersOrderLyData) return [...ordersOrderLyData];
    return [];
  }, [ordersOrderLyData]);

  const { data: orderlyTradeActivitiesData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=FILLED"
  );
  const { data: orderlyTradeActivitiesCancelData } = usePrivateQuery(
    "/v1/orders?size=500&page=1&source_type=ALL&status=CANCELLED"
  );

  const orderlyTradeActivities = useMemo(() => {
    if (
      (orderlyTradeActivitiesData && orderlyTradeActivitiesData.length > 0) ||
      (orderlyTradeActivitiesCancelData && orderlyTradeActivitiesCancelData.length > 0)
    ) {
      return [
        ...(orderlyTradeActivitiesData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.created_time / 1000),
        })) || []),
        ...(orderlyTradeActivitiesCancelData?.map((item) => ({
          ...item,
          isV3: true,
          createdAt: Math.floor(item.created_time / 1000),
        })) || []),
      ];
    }
    return [];
  }, [orderlyTradeActivitiesData, orderlyTradeActivitiesCancelData]);

  const tradeActivityMerged = useMemo(() => {
    return [...orderlyTradeActivities].sort((a, b) => b.createdAt - a.createdAt);
  }, [orderlyTradeActivities]);
  const mergedPosition = useMemo(() => {
    return [
      ...(orderlyPosition?.rows?.map((i) => ({ ...i, isV3: true, createdAt: Math.floor(i.timestamp / 1000) })) || []),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [orderlyPosition?.rows]);

  const mergedOrders = useMemo(() => {
    return [
      ...ordersOrderLy?.map((i) => ({
        ...i,
        isV3: true,
        createdAt: Math.floor(i.created_time / 1000),
        timestamp: i.timestamp,
      })),
    ].sort((a, b) => b.createdAt - a.createdAt);
  }, [ordersOrderLy]);
  const orderlyClosedPosition = useMemo(() => {
    if (orderlyClosedPositionData && orderlyClosedPositionData.length > 0) {
      return orderlyClosedPositionData?.map((item) => ({
        ...item,
        closedAt: Math.floor(item.close_timestamp / 1000),
        isV3: true,
      }));
    }
    return [];
  }, [orderlyClosedPositionData]);
  const mergedClosePositions = useMemo(() => {
    return [...orderlyClosedPosition].sort((a, b) => b.closedAt - a.closedAt);
  }, [orderlyClosedPosition]);

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trade";

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, CLOSED_POSITION, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-trade", LIST_SECTIONS[0]);

  const TABS = [
    {
      label: "Positions ",
      key: "openPositions",
      count: mergedPosition.length || 0,
    },
    {
      label: "Pending Orders ",
      key: "orders",
      count: mergedOrders.length || 0,
    },
    {
      label: "Position History",
      key: "closedPositions",
    },
    {
      label: "Order History",
      key: "tradeHistories",
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  const renderTabSection = () => {
    return {
      openPositions: (
        <PositionListMerged
          positions={mergedPosition}
          loading={false}
          chainId={chainId}
          library={library}
          account={account}
          active={active}
          accountInfo={accountInfo}
          aggregated={orderlyPosition?.aggregated}
          pairInfo={pairInfo?.data}
        />
      ),
      orders: (
        <OrderList
          account={account}
          orders={mergedOrders}
          loading={false}
          library={library}
          positions={mergedPosition}
          pairInfo={pairInfo?.data}
        />
      ),
      closedPositions: (
        <ClosedPositionList account={account} positions={mergedClosePositions} loading={false} chainId={chainId} />
      ),
      tradeHistories: <TradeActivityList positions={tradeActivityMerged} loading={false} chainId={chainId} />,
    }[activeTab.key];
  };
  return (
    <div className={`Portfolio-positions Exchange-updated-ui exchange-v2`} style={{zIndex: 2}}>
      <div className="Portfolio-tabs">
        {TABS.map((tab) => {
          return (
            <div
              className={cx("tab", {
                active: activeTab.key === tab.key,
              })}
              onClick={() => setActiveTab(tab)}
              key={tab.key}
            >
              {tab.label}{" "}
              {tab.hasOwnProperty("count") ? (
                <Styledcount
                  className={cx("", {
                    styleActive: activeTab.key === tab.key,
                  })}
                >
                  {tab.count}
                </Styledcount>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="Portfolio-tab-content Exchange-lists" style={{paddingTop: "16px"}}>
        {cloneElement(renderTabSection(), {
          account,
          chainId,
          library,
          active,
        })}
      </div>
    </div>
  );
});
const Styledcount = styled.div`
  border-radius: 4px;
  border: 1px solid #41454d;
  background: var(--Nature-2, #212224);
  color: #919499;
  height: 20px;
  padding: 0 6px;
  font-size: 10px;

  &.styleActive {
    color: #fff;
  }
`;
