import styled from "styled-components";
import "../styles/AnalyticsV2.styled";

import { useThemeContext } from "contexts/ThemeProvider";
import { shortenAddress } from "lib/legacy";
import { useMediaQuery } from "react-responsive";

import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top2Light from "img/top2Light.svg";
import top3 from "img/top3.svg";
import top3Light from "img/top3Light.svg";

import { commify } from "@ethersproject/units";
import useTopTrader from "hooks/useTopTrader";
import moment from "moment";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";
import { DataTable, SkeletonLoadingTable, TableRow } from "../helper/table";

const topsImg = [top1, top2, top3];
const topsImgLight = [top1, top2Light, top3Light];

const TopTraders = ({ chainId, isValidChain }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, isLoading, data, currentPage, setCurrentPage, rowsPerPage } = useTopTrader(chainId);

  const isEmpty = !isLoading && data?.rows?.length === 0;

  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          {
            <div className="top-filter">
              <div className="time-box">
                <span>Time:</span>
                <div className="time">
                  <div>{data?.metadata?.startDate}</div>-<div>{data?.metadata?.endDate}</div>
                </div>
              </div>
              <div className="last-update">
                Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
              </div>
            </div>
          }

          {isEmpty ? (
            <NoItemFound />
          ) : (
            <DataTable $rowHeightSkeletonMobile="146.38px" className={`${lightThemeClassName} table-wrapper`}>
              <div className="head">
                <div className="col-name">Rank</div>
                <div
                  className="col-name"
                  style={{
                    minWidth: 540,
                  }}
                >
                  Wallet
                </div>
                <div className="col-name">Total Volume (USDC)</div>
                <div className="col-name">Points</div>
              </div>
              <div className="body">
                {!isLoading &&
                  data?.rows &&
                  data?.rows?.map((row, i) => {
                    const rank = i + 1;

                    return (
                      <TableRow
                        key={i}
                        // noScaleOnHover
                        // to={`/analytics/trader/${row.account}`}
                      >
                        <div d-label="Top" className={`col-body top-${rank}`}>
                          {!isLightTheme && (
                            <>{topsImg[rank - 1] ? <img src={topsImg[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                          )}
                          {isLightTheme && (
                            <>
                              {topsImgLight[rank - 1] ? <img src={topsImgLight[rank - 1]} alt={`top-${rank}`} /> : rank}
                            </>
                          )}
                        </div>
                        <div
                          d-label="Wallet"
                          className="col-body user col-min-width"
                          style={{
                            minWidth: 540,
                          }}
                        >
                          {/* <Avatar address={row.account} size="24px" chainId={chainId} NFTid={undefined} /> */}
                          {isMobile ? shortenAddress(row.address, 13) : row.address}
                        </div>

                        <div d-label="Total Volume (USDC)" className="col-body ">
                          {commify(row.perp_volume?.toFixed(0))}
                        </div>
                        <div d-label="Points" className="col-body ">
                          {commify((row.perp_volume / 1000)?.toFixed(2))}
                        </div>
                      </TableRow>
                    );
                  })}
              </div>
              {isLoading && <SkeletonLoadingTable noHeading />}{" "}
              {!isEmpty && (
                <PaginationV2
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  // setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                  rows={data?.count}
                />
              )}
            </DataTable>
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .body-row {
    cursor: default !important;
  }
  .pagination-v2 {
    margin-top: 24px;
  }
  .top-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .time-box {
      gap: 4px;
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      span {
        color: #848e9c;
      }
      .time {
        display: flex;
        gap: 4px;
      }
      @media screen and (max-width: 700px) {
        span {
          display: none;
        }
      }
    }
    .last-update {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: #848e9c;
    }
  }
  .head {
    .col-name {
      font-size: 14px;
      color: #848e9c;
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .filter-block {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      margin-bottom: 16px;

      &:has(.filter-child) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .top-filter {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-block {
      display: flex;
      /* flex-direction: column; */
      gap: 8px;
      align-items: center;

      .ant-select-selector {
        padding-left: 8px;
      }
    }
  }
`;

export default TopTraders;
