import { LoadingOutlined } from "@ant-design/icons";
import { commify } from "@ethersproject/units";
import cx from "classnames";
import PositionDropdownV3 from "components/ExchangeV2/PositionDropdownV3";
import Tooltip from "components/Tooltip/Tooltip";
import ic_ArrowDown from "img/trade/arrow-red.svg";
import ic_ArrowUp from "img/trade/arrow-up-green.svg";
import { renderRisk } from "lib/helper";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { useMemo } from "react";
import styled from "styled-components";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#FFA722" }} spin />;

const PositionItemMobileV3 = (props) => {
  const {
    setSelectedPosition,
    setIsPositionShareModalOpen,
    position,
    handleSelectedPosition,
    handleSelectAddTPSL,
    handleSelectClosePosition,
    aggregated,
  } = props;
  const tokenSymbol = getBaseOrderlyAsset(position?.symbol);

  const maxPriceDecimals = useMemo(() => {
    let decimals = 1;
    const martkPrice = position?.mark_price;
    if (martkPrice) {
      decimals = martkPrice?.toString()?.split(".")?.[1]?.length || 1;
    }
    return decimals;
  }, [position?.mark_price]);
  const risk =
    position?.est_liq_price && position?.mark_price
      ? Math.abs(position?.mark_price - position?.est_liq_price) / position?.mark_price
      : null;
  const leverage =
    position?.notional && aggregated?.total_collateral_value
      ? limitDecimals(position?.notional / aggregated?.total_collateral_value, 2)
      : null;
  return (
    <div className="App-card">
      <div className="App-card-title">
        <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} style={{ top: 13 }} />
        <span className="Exchange-list-title" style={{ paddingLeft: "4px" }}>
          <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="" /> {tokenSymbol}/USDC
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="App-card-row">
          <div className="label">
            <span>Quantity</span>
          </div>
          <div className="position-list-collateral">
            <div>{Math.abs(position?.position_qty)}</div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Mark Price</span>
          </div>
          <div>
            <div>
              {formatAmount(parseValue(position?.mark_price || 0, 30), 30, decimalsRules(position?.mark_price), true)}
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Open Price</span>
          </div>
          <div>
            {formatAmount(
              parseValue(position?.average_open_price || 0, 30),
              30,
              decimalsRules(position?.average_open_price),
              true
            )}
          </div>
        </div>

        <div className="App-card-row">
          <div className="label">
            <span>Liq.Price</span>
          </div>
          <div>
            <div>
              {formatAmount(
                parseValue(position?.est_liq_price || 0, 30),
                30,
                decimalsRules(position?.est_liq_price),
                true
              )}
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Unreal. PnL</span>
          </div>
          <div
            className={cx("", {
              positive: position?.unrealized_pnl > 0,
              negative: position?.unrealized_pnl < 0,
            })}
          >
            <>
              {position?.unrealized_pnl === 0 ? null : position?.unrealized_pnl < 0 ? "-" : ""}
              {`${formatAmount(parseValue(position?.unrealized_pnl, 30), 30, 2, true).replace("-", "")}`}{" "}
              <span
                className={cx("", {
                  positive: position?.unrealized_pnl > 0,
                  negative: position?.unrealized_pnl < 0,
                })}
                style={{ marginLeft: "4px" }}
              >
                ({commify(limitDecimals((position?.unrealized_pnl_ROI || 0) * 100, 2)).replace("-", "")}
                %)
              </span>
            </>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Notional</span>
          </div>
          <div className="position-list-collateral">
            <div>
              {position?.notional ? formatAmount(parseValue(position?.notional || 0, 30), 30, 2, true) : "-"}{" "}
              <span className="muted">USDC</span>
            </div>
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>
              <Tooltip
                className="tooltip-s"
                renderContent={() => {
                  return (
                    <div className="tooltip-content">
                      <div>
                        1. For cross margin: The minimum equity to keep your position (Margin = Position size * Mark
                        price * MMR).
                      </div>
                      <div style={{ marginTop: "4px" }}>2. For isolated margin: Your current collateral.</div>
                    </div>
                  );
                }}
                popupStyled={{ width: "100px" }}
                position={"left-bottom"}
                handle="Margin"
              />
            </span>
          </div>
          <div>
            <div>
              {position?.mm ? formatAmount(parseValue(position?.mm || 0, 30), 30, 2, true) : "-"}{" "}
              <span className="muted">USDC</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.3rem" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => handleSelectedPosition(position)}
          // disabled={position?.size == 0}
        >
          <span>View</span>
        </button>
        <button
          className="App-button-option App-card-option border-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleSelectClosePosition(position);
          }}
        >
          <span>Close</span>
        </button>
        <PositionDropdownV3
          position={position}
          handleShare={() => {
            setSelectedPosition(position);
            setIsPositionShareModalOpen(true);
          }}
          handleAddTPSL={() => {
            handleSelectAddTPSL(position);
          }}
        />
      </div>
    </div>
  );
};

const StyledButton = styled.div`
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  top: -2px;
`;
export default PositionItemMobileV3;
