import cx from "classnames";
import { useEffect, useMemo, useState } from "react";

import { useOrderEntry, useTPSLOrder } from "@orderly.network/hooks";
import { positions } from "@orderly.network/perp";
import { OrderSide, OrderType } from "@orderly.network/types";
import { helperToast } from "lib/helperToast";
import { STOP_LOSS, TAKE_PROFIT, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, parseValue, usdFormatter } from "lib/numbers";
import styled from "styled-components";
import { preventSpecialCharacters } from "utils/helpers";
import Modal from "../Modal/Modal";
import Tab from "../Tab/Tab";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PercentageButtons from "./PercentageButtons";
import "./PositionSeller.css";

export default function EditTPSLOrderly(props) {
  const { setIsVisible, isVisible } = props;

  return (
    <div className="PositionEditor">
      <Modal
        className={`PositionSeller-modal confirmation-modal`}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={"Add Take Profit / Stop Loss"}
        allowContentTouchMove
      >
        <div className="order-title-line order-title-line-details">
          <div className="position-info-container">
            <StyledWrap className="head">
              <div className="header">
                <div className={`long-tag`} />
                {/* <div className={`${position?.position_qty > 0 ? `long-tag` : `short-tag`}`} /> */}
                <img src={`https://oss.orderly.network/static/symbol_logo/${"tokenSymbol"}.png`} alt="tk" />{" "}
                <div className="position-info">
                  <div className="title">{"tokenSymbol"}/USDC</div>
                </div>
              </div>
            </StyledWrap>
          </div>
        </div>
        <div className="content-container" style={{ marginTop: "16px" }}>
          <div className="Exchange-double-input">
            <div className="Exchange-double-input-header">
              <span>Trigger Price</span>
              <span>(Est. PnL -)</span>
            </div>
            <div className="Exchange-double-input-body">
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label">Price</div>
                <input placeholder="0.00" />
              </div>
              <div className="Exchange-double-input-body-item">
                <div className="Exchange-double-input-label">PnL (%)</div>
                <input placeholder="0.00" />
              </div>
            </div>
          </div>

          <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
            <div className="Exchange-swap-section-bottom">
              <div className="input-label">Quantity</div>
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  // value={closeQuantity}
                  // onChange={handleChangeQuantityInput}
                  onKeyDown={preventSpecialCharacters}
                />
              </div>
              <div className="PositionEditor-token-symbol">-</div>
            </div>
          </div>
          {/* <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(posQty, 30, 30, false))}
              value={closeQuantity}
            /> */}
        </div>
        <div className="Exchange-swap-button-container">
          <button
            className="default-btn Exchange-swap-button"
            // onClick={onHandleAddTPSL}
            // disabled={isDisableButton() || isConfirm}
          >
            {/* {getPrimaryText()} */}
            Edit TP/SL
          </button>
        </div>
      </Modal>
    </div>
  );
}
export const Divider = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 12px 0 12px 0;
`;
const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 12px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
