import styled from "styled-components";

const Overview = () => {
  return (
    <Wrapper>
      <div className="Overview-head">
        <span>Market</span>
        <span>Est. funding</span>
        <span>Last funding</span>
        <span>1d avg.</span>
        <span>3d avg.</span>
        <span>7d avg.</span>
        <span>14d avg.</span>
        <span>30d avg.</span>
        <span>90d avg.</span>
        <span>Positive rate</span>
      </div>
      <div className="Overview-body">
        {[...Array(10)].map((_, index) => (
          <div className="Overview-bodyRow" key={index}>
            <span>SEI-PERP</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
            <span>+0.00984%</span>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #1e1e1e;
  border-radius: 16px;

  .Overview-head {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.3fr;
    height: 48px;
    align-items: center;

    span {
      padding: 0 8px;

      &:last-child {
        text-align: right;
      }
    }
  }

  .Overview-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .Overview-bodyRow {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.3fr;

    border-top: 1px solid #2c2c2c;
    align-items: center;
    height: 48px;

    &:hover {
      background-color: #2c2c2c;
      cursor: pointer;
    }

    span {
      padding: 0 8px;

      &:last-child {
        text-align: right;
      }
    }
  }
`;

export default Overview;
