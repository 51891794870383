import { commify } from "@ethersproject/units";
import { decimalsRules, getBaseOrderlyAsset } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import styled from "styled-components";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import "./PositionSeller.css";
import { useMemo } from "react";
import { StyledWrap } from "components/Exchange/OrderEditorV3";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export default function TradeActivityV3(props) {
  const { isVisible, position, setIsVisible } = props;

  const tokenSymbol = getBaseOrderlyAsset(position.symbol);
  const isLong = position?.side === "BUY";
  const getPosSize = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") return "-";
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child)
        return formatAmount(
          parseValue(child?.average_executed_price * child?.total_executed_quantity || 0, 30),
          30,
          decimalsRules(child?.average_executed_price * child?.total_executed_quantity),
          true
        );
    } else if (position?.status === "CANCELLED") {
      return formatAmount(
        parseValue((position?.average_executed_price || position?.price) * position?.visible_quantity || 0, 30),
        30,
        decimalsRules(position?.average_executed_price * position?.visible_quantity),
        true
      );
    }
    return formatAmount(
      parseValue(position?.average_executed_price * position?.total_executed_quantity || 0, 30),
      30,
      decimalsRules(position?.average_executed_price * position?.total_executed_quantity),
      true
    );
  };
  const getFilledQuantity = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") {
        const child = position?.child_orders.find((x) => x?.visible_quantity);
        if (child) return `${child?.total_executed_quantity}/${child?.visible_quantity}`;
        else return "Entire position";
      }
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return `${child?.total_executed_quantity}/${child?.quantity}`;
    } else {
      // if (position?.status === "CANCELLED") return "-";
      return `${position?.total_executed_quantity}/${position?.quantity}`;
    }
  };
  const getFilledRealized = useMemo(() => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") return "-";
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return child.realized_pnl;
    } else return position.realized_pnl;
  }, [position]);

  const getFees = useMemo(() => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") return "-";
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return child.total_fee;
    } else return position.total_fee;
  }, [position]);

  const getTime = useMemo(() => {
    if (position?.algo_type?.includes("TP_SL")) {
      if (position?.algo_status === "CANCELLED") return position.updated_time;
      const child = position?.child_orders.find((x) => x?.status === "FILLED");
      if (child) return child.trigger_time;
    } else return position.updated_time;
  }, [position]);
  const getOrderlyType = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      let isTP,
        isSL = false;
      if (position?.child_orders?.[0]?.trigger_price) {
        isTP = true;
      }
      if (position?.child_orders?.[1]?.trigger_price) {
        isSL = true;
      }
      if (isTP && isSL) return "TP/SL";
      else if (isTP) return "Take profit";
      else return "Stop loss";
    }
    if (position?.type === "MARKET" && position?.algo_type) return "Stop Market";
    if (position?.algo_type && position?.algo_type !== "BRACKET") return "Stop Limit";
    return position?.type === "POST_ONLY"
      ? "Post Only"
      : position?.type === "IOC"
      ? "IOC"
      : position?.type === "FOK"
      ? "FOK"
      : position?.type === "LIMIT"
      ? "Limit"
      : "Market";
  };
  const getPriceExecute = () => {
    if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED") return "-";
    if (position?.algo_type?.includes("TP_SL")) {
      const tp = position?.child_orders?.[0]?.average_executed_price;
      const sl = position?.child_orders?.[1]?.average_executed_price;
      if (tp) return "TP " + formatAmount(parseValue(tp || 0, 30), 30, decimalsRules(tp), true);
      else if (sl) {
        return "SL " + formatAmount(parseValue(sl || 0, 30), 30, decimalsRules(sl), true);
      }
    } else if (position?.average_executed_price)
      return formatAmount(
        parseValue(position?.average_executed_price || 0, 30),
        30,
        decimalsRules(position?.average_executed_price),
        true
      );
    return "-";
  };
  const getPriceTrigger = () => {
    if (position?.algo_type?.includes("TP_SL") || position?.algo_type?.includes("BRACKET")) {
      if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED") {
        let isTP, isSL;
        let tp, sl;
        let child_orders = position?.child_orders || [];
        if (child_orders.length > 0 && child_orders.length < 2) {
          child_orders = child_orders[0]?.child_orders;
        }
        if (child_orders?.length > 0) {
          tp = child_orders?.[0];
          sl = child_orders?.[1];
          if (tp?.trigger_price) isTP = true;
          if (sl?.trigger_price) isSL = true;
          if (isTP && isSL)
            return (
              <div>
                <div>
                  <span className="">TP </span>
                  {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
                </div>
                <div style={{ marginTop: 4 }}>
                  <span className="">SL </span>
                  {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
                </div>
              </div>
            );
          else if (isTP)
            return (
              <>
                <span>
                  <span className="">TP </span>
                  {formatAmount(parseValue(tp?.trigger_price || 0, 30), 30, decimalsRules(tp?.trigger_price), true)}
                </span>
              </>
            );
          else if (isSL)
            return (
              <>
                <span>
                  <span className="">SL </span>
                  {formatAmount(parseValue(sl?.trigger_price || 0, 30), 30, decimalsRules(sl?.trigger_price), true)}
                </span>
              </>
            );
        }
      } else {
      }
    } else {
      if (position?.algo_status === "CANCELLED" || position.status === "CANCELLED")
        if (position?.price || position?.trigger_trade_price || position?.trigger_price)
          return formatAmount(
            parseValue(position?.price || position?.trigger_trade_price || position?.trigger_price || 0, 30),
            30,
            decimalsRules(position?.price || position?.trigger_trade_price || position?.trigger_price),
            true
          );
    }
    return "-";
  };
  const getReduceOnly = () => {
    if (position?.algo_type?.includes("TP_SL")) {
      return "Yes";
    }
    if (position.reduce_only) return "Yes";
    return "No";
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Order History"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <StyledWrap className="head">
                <div className="header">
                  <div className={`${isLong ? `long-tag` : `short-tag`}`} />
                  <img src={`https://oss.orderly.network/static/symbol_logo/${tokenSymbol}.png`} alt="tk" />{" "}
                  <div className="position-info">
                    <div className="title">
                      {tokenSymbol}/USDC <span className="cancel-order-txt">{getOrderlyType()}</span>
                    </div>
                  </div>
                </div>
              </StyledWrap>
            </div>
          </div>
          <Wrap className="content-container" style={{ marginBottom: 24, marginTop: 16 }}>
            <div className="gray-divider" />
            <div className="order-details-container">
              <div className="title">Order History</div>
              <div className="square-container">
                <ExchangeInfoRow label={`Avg. Price`}>{getPriceExecute()}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Trigger Price`}>{getPriceTrigger()}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Filled/Quantity`}>{getFilledQuantity()}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Reduce Only`}>{getReduceOnly()}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Post Only`}>{position.type === "POST_ONLY" ? "Yes" : "No"}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Status`}>
                  {position?.algo_status === "CANCELLED" || position.status === "CANCELLED"
                    ? "Cancelled"
                    : ["FILLED"]?.includes(position?.status || position.algo_status)
                    ? "Filled"
                    : "Pending"}
                </ExchangeInfoRow>
                <ExchangeInfoRow
                  label={`Realized PnL`}
                  isPositive={getFilledRealized > 0 ? true : getFilledRealized < 0 ? false : undefined}
                  textStyled={{
                    color: getFilledRealized > 0 ? "#0ECB81" : getFilledRealized === 0 ? "#fff" : "#E43E53",
                  }}
                >
                  {position?.realized_pnl === 0 ? (
                    <span>
                      {commify(limitDecimals(position?.total_fee || 0, 2))} <span className="muted">USDC</span>
                    </span>
                  ) : (
                    <span>
                      {getFilledRealized > 0 ? "+" : getFilledRealized === 0 ? "" : "-"}
                      {commify(limitDecimals(getFilledRealized, 2)).replace("-", "")}{" "}
                      <span className="muted">USDC</span>
                    </span>
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  {commify(limitDecimals(getFees || 0, 2)).replace("-", "")}{" "}
                  <span style={{ marginLeft: 4 }} className="muted">
                    USDC
                  </span>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Time`}>
                  {moment(new Date(getTime)).format(dateFormat)}&nbsp;
                  {moment(new Date(getTime)).format(timeFormat)}
                </ExchangeInfoRow>
              </div>
            </div>
          </Wrap>
        </Modal>
      )}
    </div>
  );
}

const Wrap = styled.div`
  @media screen and (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-bottom: 16px !important;
  }
`;
