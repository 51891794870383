import { commify } from "@ethersproject/units";
import { Trans } from "@lingui/macro";
import { Switch } from "antd";
import Checkbox from "components/Checkbox/Checkbox";
import { limitDecimals } from "lib/numbers";
import { useEffect, useState } from "react";
import { preventSpecialCharacters } from "utils/helpers";
import styled from "styled-components";
export const ANCHOR_PRICE = "Price";
export const ANCHOR_PNL = "PnL";
const TPSLCardV3 = ({
  onActiveTakeProfit,
  show,
  setShow,
  maxPricePercision,
  setValue,
  onChanetakeProfitPrice,
  onChanestopLossPrice,
  formattedOrder,
  takeProfitPrice,
  setTakeProfitPrice,
  stopLossPrice,
  setStopLossPrice,
  setValues,
  executeType,
  openPrice,
  isLong,
  tpPnl,
  slPnl,
  setTpPnl,
  setSlPnl,
}) => {
  const [tpAnchorInput, setTpAnchorInput] = useState(ANCHOR_PRICE);
  const [slAnchorInput, setSLAnchorInput] = useState(ANCHOR_PRICE);

  // console.log("?????", { formattedOrder, takeProfitPrice, stopLossPrice, slPnl, tpPnl });
  const onChange = () => {
    onActiveTakeProfit && onActiveTakeProfit(!show);
    setShow(!show);
  };
  useEffect(() => {
    if (show) {
    } else {
      setValues({
        algo_type: undefined,
        tp_trigger_price: undefined,
        sl_trigger_price: undefined,
      });
      setTakeProfitPrice("");
      setStopLossPrice("");
    }
  }, [show]);

  useEffect(() => {
    if (tpAnchorInput === ANCHOR_PRICE) {
      if (Number(limitDecimals(tpPnl || 0, 2)) !== Number(limitDecimals((formattedOrder?.tp_ROI || 0) * 100, 2))) {
        setTpPnl(Number(limitDecimals((formattedOrder?.tp_ROI || 0) * 100, 2)));
      }
    } else {
      if (tpPnl && Number(tpPnl)) {
        const offset = (openPrice * tpPnl) / 100;
        if (isLong) {
          if (offset) {
            setTakeProfitPrice(Number(limitDecimals(offset + openPrice, maxPricePercision)));
            setValue("tp_trigger_price", Number(limitDecimals(offset + openPrice, maxPricePercision)), {
              shouldUpdateLastChangedField: true,
            });
          } else {
            setTakeProfitPrice("");
            setValue("tp_trigger_price", undefined, { shouldUpdateLastChangedField: true });
          }
        } else {
          if (offset) {
            setTakeProfitPrice(Number(limitDecimals(openPrice - offset, maxPricePercision)));
            setValue("tp_trigger_price", Number(limitDecimals(openPrice - offset, maxPricePercision)), {
              shouldUpdateLastChangedField: true,
            });
          } else {
            setTakeProfitPrice("");
            setValue("tp_trigger_price", undefined, { shouldUpdateLastChangedField: true });
          }
        }
      } else {
        setTakeProfitPrice("");
        setValue("tp_trigger_price", undefined, { shouldUpdateLastChangedField: true });
      }
    }
  }, [formattedOrder?.tp_ROI, formattedOrder?.tp_trigger_price, tpAnchorInput]);
  useEffect(() => {
    if (slAnchorInput === ANCHOR_PRICE) {
      if (
        Number(limitDecimals(slPnl || 0, 2)) !== Math.abs(Number(limitDecimals((formattedOrder?.sl_ROI || 0) * 100, 2)))
      ) {
        setSlPnl(Math.abs(Number(limitDecimals((formattedOrder?.sl_ROI || 0) * 100, 2))));
      }
    } else {
      if (slPnl && Number(slPnl)) {
        const offset = (openPrice * slPnl) / 100;
        if (isLong) {
          if (offset) {
            setStopLossPrice(Number(limitDecimals(openPrice - offset, maxPricePercision)));
            setValue("sl_trigger_price", Number(limitDecimals(openPrice - offset, maxPricePercision)), {
              shouldUpdateLastChangedField: true,
            });
          } else {
            setStopLossPrice("");
            setValue("sl_trigger_price", undefined, { shouldUpdateLastChangedField: true });
          }
        } else {
          if (offset) {
            setStopLossPrice(Number(limitDecimals(openPrice + offset, maxPricePercision)));
            setValue("sl_trigger_price", Number(limitDecimals(offset + openPrice, maxPricePercision)), {
              shouldUpdateLastChangedField: true,
            });
          } else {
            setStopLossPrice("");
            setValue("sl_trigger_price", undefined, { shouldUpdateLastChangedField: true });
          }
        }
      } else {
        setStopLossPrice("");
        setValue("sl_trigger_price", undefined, { shouldUpdateLastChangedField: true });
      }
    }
  }, [formattedOrder?.sl_ROI, formattedOrder?.tp_trigger_price, slAnchorInput]);

  const onTriggerValueChangeTP = (evt) => {
    const value = evt.target.value;
    if (tpAnchorInput !== ANCHOR_PRICE) setTpAnchorInput(ANCHOR_PRICE);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        setTakeProfitPrice(evt.target.value || "");
        setValues({
          tp_trigger_price: evt.target.value,
          algo_type: "BRACKET",
        });
        onChanetakeProfitPrice(evt.target.value);
      }
    } else {
      setTakeProfitPrice(evt.target.value || "");
      if (!value && !stopLossPrice)
        setValues({
          tp_trigger_price: evt.target.value,
          algo_type: undefined,
        });
      else
        setValues({
          tp_trigger_price: evt.target.value,
          algo_type: "BRACKET",
        });
      onChanetakeProfitPrice(evt.target.value);
    }
  };
  const onTriggerValueChangeSL = (evt) => {
    const value = evt.target.value;
    if (slAnchorInput !== ANCHOR_PRICE) setSLAnchorInput(ANCHOR_PRICE);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= maxPricePercision) {
        // setValue("sl_trigger_price", evt.target.value);
        setValues({
          sl_trigger_price: evt.target.value,
          algo_type: "BRACKET",
        });
        setStopLossPrice(evt.target.value || "");
        onChanestopLossPrice(evt.target.value);
      }
    } else {
      if (!value && !stopLossPrice)
        setValues({
          sl_trigger_price: evt.target.value,
          algo_type: undefined,
        });
      else
        setValues({
          sl_trigger_price: evt.target.value,
          algo_type: "BRACKET",
        });
      setStopLossPrice(evt.target.value || "");
      onChanestopLossPrice(evt.target.value);
    }
  };
  const onPnlValueChangeTP = (evt) => {
    const value = evt.target.value;
    if (tpAnchorInput !== ANCHOR_PNL) setTpAnchorInput(ANCHOR_PNL);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= 2) {
        // setValue("sl_trigger_price", evt.target.value);
        setValues({
          tp_ROI: evt.target.value,
          algo_type: "BRACKET",
        });
        setTpPnl(evt.target.value || "");
      }
    } else {
      if (!value && !takeProfitPrice)
        setValues({
          tp_ROI: evt.target.value,
          algo_type: undefined,
        });
      else
        setValues({
          tp_ROI: evt.target.value,
          algo_type: "BRACKET",
        });

      setTpPnl(evt.target.value || "");
    }
  };
  const onPnlValueChangeSL = (evt) => {
    const value = evt.target.value;
    if (slAnchorInput !== ANCHOR_PNL) setSLAnchorInput(ANCHOR_PNL);
    if (value?.toString().includes(".")) {
      const arr = value.toString().split(".");
      if (arr[1].length <= 2) {
        // setValue("sl_trigger_price", evt.target.value);
        setValues({
          sl_ROI: evt.target.value,
          algo_type: "BRACKET",
        });
        setSlPnl(evt.target.value || "");
      }
    } else {
      if (!value && !stopLossPrice)
        setValues({
          sl_ROI: evt.target.value,
          algo_type: undefined,
        });
      else
        setValues({
          sl_ROI: evt.target.value,
          algo_type: "BRACKET",
        });
      setSlPnl(evt.target.value || "");
    }
  };
  return (
    <div className="toogle-card-container" style={{ marginBottom: 0 }}>
      <div className="title-container" style={{ marginBottom: show ? 16 : 0 }}>
        <div className="title">
          <Checkbox isChecked={show} setIsChecked={() => onChange()}>
            <div className="group-actions">
              <span className="">TP/SL</span>
            </div>
          </Checkbox>
        </div>
        {/* <Switch defaultChecked onChange={onChange} checked={show} disabled={executeType} /> */}
      </div>
      {show && (
        <Wrapper>
          <div className="wrap-tp-sl">
            <div className="wrap-tp-sl-header">
              <div className="wrap-tp-sl-header-title">Take Profit</div>
              <div className="wrap-tp-sl-header-pnl">
                (Est. PnL{" "}
                <span className={`${formattedOrder?.tp_pnl && formattedOrder?.tp_pnl >= 0 ? "positive" : ""}`}>
                  {formattedOrder?.tp_pnl && formattedOrder?.tp_pnl >= 0
                    ? "+" + commify(limitDecimals(formattedOrder?.tp_pnl))
                    : "-"}
                </span>
                )
              </div>
            </div>
            <div className="wrap-tp-sl-body">
              <div className="Exchange-swap-section exchange-section-first exchange-take-profit-container">
                {/* <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <div className="Exchange-swap-usd" style={{ width: "100px" }}>
                      <Trans>Price</Trans>
                    </div>
                  </div>
                </div> */}
                <div className="Exchange-swap-section-bottom">
                  <div className="input-label fz-12" style={{ fontSize: 12 }}>
                    Price
                  </div>
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={takeProfitPrice}
                      onChange={onTriggerValueChangeTP}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                </div>
              </div>

              <div className="wrap-tp-sl-body-pnl">
                <div className="fz-12">Offset(%)</div>
                <div>
                  <input
                    className="input-percent-pnl"
                    type="number"
                    min="0"
                    placeholder="0.0"
                    value={tpPnl}
                    onChange={onPnlValueChangeTP}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-tp-sl">
            <div className="wrap-tp-sl-header">
              <div className="wrap-tp-sl-header-title">Stop Loss</div>
              <div className="wrap-tp-sl-header-pnl">
                (Est. PnL{" "}
                <span className={`${formattedOrder?.sl_pnl && formattedOrder?.sl_pnl <= 0 ? "negative" : ""}`}>
                  {formattedOrder?.sl_pnl && formattedOrder?.sl_pnl <= 0
                    ? commify(limitDecimals(formattedOrder?.sl_pnl))
                    : "-"}
                </span>
                )
              </div>
            </div>
            <div className="wrap-tp-sl-body">
              <div className="Exchange-swap-section exchange-section-first exchange-take-profit-container">
                <div className="Exchange-swap-section-bottom">
                  <div className="input-label fz-12" style={{ fontSize: 12 }}>
                    Price
                  </div>
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={stopLossPrice}
                      onChange={onTriggerValueChangeSL}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                </div>
              </div>
              <div className="wrap-tp-sl-body-pnl">
                <div className="fz-12">Offset(%)</div>
                <div>
                  <input
                    className="input-percent-pnl"
                    type="number"
                    min="0"
                    placeholder="0.0"
                    value={slPnl}
                    onChange={onPnlValueChangeSL}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
              </div>


            </div>
          </div>
          {/* {takeProfitTriggerError && <span className="validate-error">{takeProfitTriggerError}</span>} */}
          {/* {stopLossPrice && (
            <div className="Exchange-info-row" style={{ marginTop: "16px" }}>
              <div className="Exchange-info-label">
                <Trans>Est. Loss</Trans>
              </div>
              <div className="align-right">
                <div className="Exchange-list-info-label negative" style={{ fontSize: "14px" }}>
                  {formattedOrder?.sl_pnl && formattedOrder?.sl_pnl <= 0
                    ? commify(limitDecimals(formattedOrder?.sl_pnl))
                    : "-"}
                </div>
              </div>
            </div>
          )} */}
        </Wrapper>
      )}
    </div>
  );
};
export default TPSLCardV3;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .wrap-tp-sl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }

  .wrap-tp-sl-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    color: var(--Text-Text_Secondary, #848e9c);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
  }

  .wrap-tp-sl-header-pnl {
    .est-tp-pnl {
      color: var(--Functional-Green, #0ecb81);
    }
    .est-sl-pnl {
      color: #fff;
    }
  }

  .wrap-tp-sl-body {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .wrap-tp-sl-body-pnl {
    display: flex;
    width: 125px;
    min-width: 125px;
    white-space: nowrap;
    padding: 8px;
    align-items: flex-start;
    gap: 6px;
    border-radius: var(--Border-Radius-Medium, 4px);
    background: var(--Background-Input_Background, rgba(0, 0, 0, 0.8));

    color: var(--Text-Text_Secondary, #848e9c);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    input {
      text-align: right;
    }
  }

  .input-percent-pnl {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--Text-Text_Primary, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding: 0;
  }
`;
