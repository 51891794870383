import { IconLogo } from "assets";
import React from "react";
import styled from "styled-components";

const listMenu = [
  {
    title: "Product",
    child: [
      {
        title: "Perp",
        url: "https://app.yummy.fi/#/trade",
      },
      // {
      //   title: "Swap",
      //   url: "",
      // },
      // {
      //   title: "Earn",
      //   url: "",
      // },
    ],
  },
  {
    title: "Resources",
    child: [
      // {
      //   title: "White paper",
      //   url: "",
      // },
      {
        title: "Audit report",
        url: "https://spywolf.co/audits/Yummy_Finance.pdf",
      },
      {
        title: "Docs",
        url: "https://docs.yummy.fi/",
      },
    ],
  },
  // {
  //   title: "Other",
  //   child: [
  //     {
  //       title: "Privacy policy",
  //       url: "",
  //     },
  //     {
  //       title: "Term of Service",
  //       url: "",
  //     },
  //     {
  //       title: "Cookies notice",
  //       url: "",
  //     },
  //     {
  //       title: "FAQs",
  //       url: "",
  //     },
  //   ],
  // },
];

const FooterLanding = () => {
  return (
    <Wrapper>
      <div className="head-footer">
        <IconLogo />
        <div className="menu-footer">
          {listMenu.map((item, index) => (
            <div className="menu-item-footer" key={index}>
              <div className="menu-item-title-footer">{item.title}</div>
              <div className="menu-item-child-footer">
                {item.child.map((child, index) => (
                  <div className="menu-item-child-item-footer" key={index}>
                    <a className="menu-item-child-item-link-footer" href={child.url}>
                      {child.title}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="footer-bottom">Copyrights © {new Date().getFullYear()} Yummy Finance. All rights reserved.</div>
    </Wrapper>
  );
};

export default FooterLanding;

const Wrapper = styled.div`
  background: #040d12;

  .head-footer {
    display: flex;
    padding: 72px 24px 36px 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 1023px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      padding: 36px 24px;
    }

    @media screen and (max-width: 767px) {
      padding: 36px 16px 24px 16px;
    }

    max-width: 1368px;
    margin: 0 auto;
  }

  .menu-footer {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
    }
  }

  .menu-item-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .menu-item-title-footer {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }

  .menu-item-child-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .menu-item-child-item-link-footer {
    color: #667180;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */

    text-decoration: none;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .footer-bottom {
    display: flex;
    padding: 24px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 2px solid #081d28;
    text-align: center;

    color: #667180;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
`;
